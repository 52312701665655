import { useEffect, useState } from 'react';
import moment from 'moment';
import { isEmpty, orderBy } from 'lodash';
import FullOverlayLoader from 'components/core/FullOverlayLoader';
import ProjectMapContainerVagInt from 'components/MapContainer/ProjectMapContainerVagInt';
import ProjectViewHeaderVegIntl from 'components/ProjectViewHeader/ProjectViewHeaderVegIntl';
import ProjectCompareViewHeaderVegINTL from 'components/ProjectViewHeader/ProjectCompareViewHeaderVegINTL';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { getEnableDataLayers } from 'store/repositoryfile';
import { getVectorFiles } from 'store/vectorfile';
import { getDataLayers, getGeoserverLayerStyle } from 'store/repositoryfile/actions';
import { DataLayerFileType, DataLayerTableType, DataLayerType } from 'store/repositoryfile/types';
import { getExtractVectorFiles } from 'store/vectorfile/actions';
import { getRiskanalysisdata } from 'store/riskanalysis/actions';
import { ProjectCategoryTypes } from 'store/projects/types';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const ProjectViewVegInt = () => {
  const dispatch = useAppDispatch();
  const activeEnableDataLayers: DataLayerType[] | [] = useAppSelector((state) =>
    getEnableDataLayers(state)
  );
  const { isRequestingRiskanalysis } = useAppSelector((state) => state.riskanalysis);
  const { isRequestingVectorFiles } = useAppSelector((state) => state.vectorfile);
  const { isUpoadedAnnotationRequesting, isAnnotationPointRequesting } = useAppSelector(
    (state) => state.annotations
  );
  const { isLoadingDataLayers } = useAppSelector((state) => state.repositoryFiles);
  const vectorRepoFiles: Array<DataLayerFileType> | [] = useAppSelector(getVectorFiles);
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const [activeDataLayer, setActiveDataLayer] = useState<DataLayerTableType | null>(null);
  const [isActiveCompare, setActiveCompare] = useState(false);
  const [leftActiveDataLayer, setLeftActiveDataLayer] = useState<DataLayerTableType | null>(null);
  const [rightActiveDataLayer, setRightActiveDataLayer] = useState<DataLayerTableType | null>(null);
  const [isShowSliderPopup, setShowSliderPopup] = useState<boolean>(false);
  const [isShowRiskTable, setShowRiskTable] = useState<boolean>(false);
  const [isRiskSegment, setRiskSegment] = useState<boolean>(false);

  const handleDatalayerCallback = (layerStatus: boolean, data: DataLayerType[] | []) => {
    if (layerStatus) {
      const firstActiveDataLayer = orderBy(
        data,
        (o: DataLayerType) => {
          return moment(o.date);
        },
        ['asc']
      )[0];

      if (!isEmpty(firstActiveDataLayer)) {
        setActiveDataLayer({
          id: Number(firstActiveDataLayer.id),
          date: firstActiveDataLayer.date
        });
        getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
          dispatch(getGeoserverLayerStyle(+firstActiveDataLayer.id, reToken));
          dispatch(getExtractVectorFiles(+firstActiveDataLayer.id, reToken));
          if (
            (selectedProject.type === ProjectCategoryTypes.VegetationManagementINTL ||
              selectedProject.type === ProjectCategoryTypes.VegetationManagementLite) &&
            !isActiveCompare
          ) {
            dispatch(
              getRiskanalysisdata(
                +selectedOrganization.id,
                +selectedProject.id,
                Number(firstActiveDataLayer.id),
                reToken
              )
            );
          }
        });
      }
    }
  };

  useEffect(() => {
    if (selectedOrganization && selectedProject) {
      getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
        dispatch(
          getDataLayers(
            +selectedOrganization.id,
            +selectedProject.id,
            reToken,
            handleDatalayerCallback
          )
        );
      });
    }
  }, []);

  const handleLulcVectorFileCall = (datalayerId: number) => {
    getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
      dispatch(getGeoserverLayerStyle(datalayerId, reToken));
      dispatch(getExtractVectorFiles(datalayerId, reToken));
    });
  };

  // eslint-disable-next-line
  const handleRiskAnalysisCall = (datalayerId: number) => {
    getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
      if (
        selectedProject.type === ProjectCategoryTypes.VegetationManagementINTL ||
        selectedProject.type === ProjectCategoryTypes.VegetationManagementLite
      ) {
        dispatch(
          getRiskanalysisdata(+selectedOrganization.id, +selectedProject.id, datalayerId, reToken)
        );
      }
    });
  };

  const handleActiveLayer = (layerData: DataLayerTableType) => {
    setActiveDataLayer(layerData);
    setLeftActiveDataLayer(layerData);
    setRightActiveDataLayer(layerData);

    if (!isEmpty(layerData)) {
      handleLulcVectorFileCall(layerData.id);
      // if (!isActiveCompare) {
      //   handleRiskAnalysisCall(layerData.id);
      // }
    }
  };

  const handleProjectCompareView = () => {
    setActiveCompare(true);
  };

  const handleCloseCompareView = () => {
    setActiveCompare(false);
  };

  const handleLeftActiveLayer = (layerData: DataLayerTableType) => {
    setLeftActiveDataLayer(layerData);
    if (!isEmpty(layerData)) {
      handleLulcVectorFileCall(layerData.id);
      // handleRiskAnalysisCall(layerData.id);
    }
  };

  const handleRightActiveLayer = (layerData: DataLayerTableType) => {
    setRightActiveDataLayer(layerData);
    if (!isEmpty(layerData)) {
      handleLulcVectorFileCall(layerData.id);
      // handleRiskAnalysisCall(layerData.id);
    }
  };

  const handleAnnotationTabClick = (isShow: boolean) => {
    setShowSliderPopup(isShow);
  };

  const handleHideSliderPopup = (isHide: boolean) => {
    setShowSliderPopup(isHide);
  };

  const handleShowRiskTable = (isShow: boolean) => {
    setShowRiskTable(isShow);
  };

  const handleSelectedRiskSegment = (isShow: boolean) => {
    setRiskSegment(isShow);
  };

  return (
    <div className="main-satellite-map">
      <div className="split-satellite-map">
        {(isRequestingRiskanalysis ||
          isRequestingVectorFiles ||
          isLoadingDataLayers ||
          isAnnotationPointRequesting ||
          isUpoadedAnnotationRequesting) && <FullOverlayLoader />}
        {isActiveCompare ? (
          <ProjectCompareViewHeaderVegINTL
            dataLayers={activeEnableDataLayers}
            activeDataLayer={activeDataLayer}
            handleLeftActiveLayer={handleLeftActiveLayer}
            handleRightActiveLayer={handleRightActiveLayer}
            handleCloseCompareView={handleCloseCompareView}
          />
        ) : (
          <ProjectViewHeaderVegIntl
            isShowPopup={isShowSliderPopup}
            dataLayers={activeEnableDataLayers}
            isShowRiskTable={isShowRiskTable}
            project={selectedProject}
            handleActiveLayer={handleActiveLayer}
            handleProjectCompareView={handleProjectCompareView}
            handleHideSliderPopup={handleHideSliderPopup}
          />
        )}
        <ProjectMapContainerVagInt
          isRiskSegment={isRiskSegment}
          project={selectedProject}
          activeDataLayer={activeDataLayer}
          vectorRepoFiles={vectorRepoFiles}
          isActiveCompare={isActiveCompare}
          leftActiveDataLayer={leftActiveDataLayer}
          rightActiveDataLayer={rightActiveDataLayer}
          handleAnnotationTabClick={handleAnnotationTabClick}
          isShowRiskTable={isShowRiskTable}
          handleShowRiskTable={handleShowRiskTable}
          handleSelectedRiskSegment={handleSelectedRiskSegment}
        />
      </div>
    </div>
  );
};

export default ProjectViewVegInt;
