import { useEffect, useState } from 'react';
import { Button, Drawer, Table, Typography } from 'antd';
import { nth } from 'lodash';
import moment from 'moment';
import { Tab, Tabs } from 'react-tabs-scrollable';
import type { ColumnsType } from 'antd/es/table';
import CloseIcon from 'assets/svg/CloseIcon';
import LeftIcon from 'assets/svg/LeftIcon';
import RightIcon from 'assets/svg/RightIcon';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { white } from 'constants/color.constant';
import { useAppSelector } from 'hooks/useReduxHook';
import { getDataLayerFiles } from 'store/repositoryfile';
import { downloadDatalayerFiles } from 'store/repositoryfile/actions';
import { DataLayerFileType, DataLayerType } from 'store/repositoryfile/types';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const { Text } = Typography;

interface DownloadDataDrawerProps {
  isOpen: boolean;
  dataLayers: DataLayerType[] | [];
  handleCancelDownload: Function;
}

const DownloadDataDrawer = ({
  isOpen,
  handleCancelDownload,
  dataLayers
}: DownloadDataDrawerProps) => {
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const [activeLayerDateId, setActiveLayerDateId] = useState<number | null>(null);
  const activeDataLayerFiles: DataLayerFileType[] | [] = useAppSelector((state) =>
    getDataLayerFiles(state, activeLayerDateId)
  );
  const [selectedDownloadFiles, setSelectedDownloadFiles] = useState<DataLayerFileType[] | []>([]);
  const [isDownloadFiles, setDownloadFiles] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    if (activeLayerDateId) return;
    setSelectedDownloadFiles([]);
    if (dataLayers && dataLayers.length > 0) {
      setActiveLayerDateId(dataLayers[0].id);
    }
  }, [dataLayers]);

  const columns: ColumnsType<DataLayerFileType> = [
    {
      title: 'Select All',
      dataIndex: 'name',
      render: (text: string) => <Text>{text}</Text>
    }
  ];

  const rowSelection = {
    onChange: (_: any, selectedFiles: DataLayerFileType[] | []) => {
      setSelectedDownloadFiles(selectedFiles);
    }
  };

  const handleCancelDownloadData = () => {
    if (dataLayers && dataLayers.length > 0) {
      setActiveLayerDateId(dataLayers[0].id);
    }
    handleCancelDownload();
  };

  const handleDownloadData = async () => {
    if (!activeLayerDateId) return;
    if (selectedDownloadFiles && selectedDownloadFiles.length === 0) return;
    setDownloadFiles(true);
    getAsyncStorageValue(ACCESS_TOKEN).then(async (token: string) => {
      await downloadDatalayerFiles(
        +selectedOrganization.id,
        +selectedProject.id,
        activeLayerDateId,
        selectedDownloadFiles,
        token
      );

      setDownloadFiles(false);
    });
  };

  const handleActiveDataLayer = (data: DataLayerType) => {
    setActiveLayerDateId(data.id);
  };

  const handleonTabClick = (_: any, index: number) => {
    const data: DataLayerType | undefined = nth(dataLayers, index);
    if (!data) return;
    if (handleActiveDataLayer) handleActiveDataLayer(data);
    setActiveTab(index);
  };

  return (
    <Drawer
      className="download-data-layer"
      title="Download Data"
      placement="right"
      width={572}
      closeIcon={<CloseIcon onClick={handleCancelDownloadData} />}
      closable
      open={isOpen}
      style={{ zIndex: 9999 }}
      footer={
        <div className="download-foolter">
          <Button onClick={handleDownloadData} loading={isDownloadFiles} disabled={isDownloadFiles}>
            Download
          </Button>
        </div>
      }
    >
      <div className="download-content">
        <div className="download-slider-div">
          {dataLayers && dataLayers.length > 0 && (
            <Tabs
              activeTab={activeTab}
              onTabClick={handleonTabClick}
              hideNavBtnsOnMobile={false}
              tabsScrollAmount={1}
              tabsUpperContainerClassName="layer-tab-container-dwn"
              tabsContainerClassName="layer-tablist-container-dwn"
              leftNavBtnClassName="left-nav-btn-dwn"
              rightNavBtnClassName="right-nav-btn-dwn"
              navBtnClassName="nav-btns-dwn"
              navBtnAs="div"
              rightBtnIcon={<RightIcon color={white} />}
              leftBtnIcon={<LeftIcon color={white} />}
              navBtnContainerClassName="nav-btn-main-dwn"
            >
              {dataLayers.map((layer, index) => (
                <Tab key={index} className="layer-btn-dwn">
                  <Text style={{ display: 'block' }}>{moment(layer.date).format('D MMM')}</Text>
                  <Text>{moment(layer.date).format('YYYY')}</Text>
                </Tab>
              ))}
            </Tabs>
          )}
        </div>
        <div>
          <Table
            className="download-data-table"
            rowSelection={{
              type: 'checkbox',
              ...rowSelection
            }}
            rowClassName="download-row"
            columns={columns}
            dataSource={activeDataLayerFiles}
            pagination={false}
            scroll={{ y: 'calc(100vh - 233px)' }}
            rowKey={(data: DataLayerFileType) => data.id}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default DownloadDataDrawer;
