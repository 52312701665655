import { toast } from 'react-toastify';
import { orderBy } from 'lodash';
import { ACCESS_TOKEN, TOKEN_EXPIRE_ERROR } from 'constants/common.constant';
import { Response } from 'types/common.types';
import { apiCall } from 'utils/connect';
import { RISK_ANALYSIS_DATA_URL } from 'utils/apiUrls';
import { getAsyncStorageValue } from 'utils/localStorage';
import { AppDispatch } from '..';

import {
  setRequestingRiskanalysis,
  setRiskTrees,
  setRiskBuffer,
  setRiskTowers,
  setRiskTransmissionLine,
  setriskTransmissionSegments,
  setRiskLines
} from '.';

export const getRiskanalysisdata =
  (orgId: number, projectId: number, dlId: number, token: string, callback?: Function) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingRiskanalysis(true));

      const onSuccess = (response: Response<any>) => {
        dispatch(setRiskTrees(response.data.trees));
        dispatch(setRiskBuffer(response.data.buffer));
        dispatch(setRiskTowers(orderBy(response.data.towers, ['id'], ['asc'])));
        dispatch(setRiskTransmissionLine(response.data.transmission_line));
        dispatch(
          setriskTransmissionSegments(
            orderBy(response.data.transmission_line_segments, ['id'], ['asc'])
          )
        );
        dispatch(setRiskLines(response.data.line_risk));
        if (callback) callback();
        dispatch(setRequestingRiskanalysis(false));
      };
      const onFailure = (error: Error) => {
        dispatch(setRiskTrees([]));
        dispatch(setRiskTowers([]));
        dispatch(setRiskBuffer(null));
        dispatch(setRiskTransmissionLine(null));
        dispatch(setriskTransmissionSegments([]));
        dispatch(setRiskLines([]));
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getRiskanalysisdata(orgId, projectId, dlId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingRiskanalysis(false));
        }
      };

      apiCall(
        'GET',
        RISK_ANALYSIS_DATA_URL(orgId, projectId, dlId),
        '',
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRiskTrees([]));
      dispatch(setRiskTowers([]));
      dispatch(setRiskBuffer(null));
      dispatch(setRiskTransmissionLine(null));
      dispatch(setriskTransmissionSegments([]));
      dispatch(setRequestingRiskanalysis(false));
      toast.error(error.message);
    }
  };
