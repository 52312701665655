import { createSelector } from '@reduxjs/toolkit';
import { filter, isEmpty } from 'lodash';
import { RootState } from '..';
import { annotationSlice } from './slice';
import { UploadedAnnotation } from './types';

export const {
  setUpoadedAnnotationRequesting,
  setUploadedAnnotationPoints,
  setAnnotationPointRequesting,
  setAddAnnotationPointRequesting,
  setAddedAnnotationPoints,
  setRequestDeleteAnnotation,
  setRequestEditAnnotation
} = annotationSlice.actions;
export default annotationSlice.reducer;

const selectedAnnotationPoints = (state: RootState) => state.annotations.addedAnnotationPoints;
const selectedUploadedAnnotationPoints = (state: RootState) =>
  state.annotations.uploadedAnnotationPoints;
const selectDate = (state: RootState, id: number | null) => id;

export const getAddedAnnotationPoints = createSelector(
  [selectedAnnotationPoints, selectDate],
  (annotationPoints, layerId) => {
    if (!layerId) return [];

    return filter(annotationPoints, ['dl_id', layerId]);
  }
);

export const getUploadedAnnotationPoints = createSelector(
  [selectedUploadedAnnotationPoints, selectDate],
  (annotationPoints, layerId) => {
    if (!layerId) return [];

    const data: UploadedAnnotation[] | [] = filter(
      annotationPoints,
      (antsData: UploadedAnnotation) => {
        return !isEmpty(antsData.properties);
      }
    );
    if (data && data.length === 0) return [];

    return filter(data, ['dl_id', layerId]);
  }
);
