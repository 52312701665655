import { HILL_SHADE, JET } from 'constants/common.constant';
import { upperFirst } from 'lodash';
import { DataLayerFileType } from 'store/repositoryfile/types';

export const shortLabel = (string: string) => {
  const label = string
    .split(' ')
    .map((word) => upperFirst(word[0]))
    .join('');

  return label;
};

export const analyticsFormKey = (string: string) => {
  return string
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .split(' ')
    .join('_')
    .toLowerCase();
};

export const fromateFeatureKey = (string: string) => {
  return string.replace('  ', ' ').replace('( ', '(').toLowerCase().trim();
};

export const readFileAsyncAsText = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
    reader.readAsText(file);
  });
};

export const getNumber = (length: any) => {
  if (!length) return 0;
  const num = length.toString().split(' ');
  return Number(num[0]);
};

export const getCost = (cost: any, length: any) => {
  if (!cost || !length) return 0;

  const costRoute = Number(cost) * Number(getNumber(length));
  return Number(costRoute);
};

export const numberWithCommas = (x: number) => {
  const cost = x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  return Number(cost);
};

export const getWmtsParams = (repoFile: DataLayerFileType, style?: string | null) => {
  if (!repoFile) return null;

  let wmtsParams = {
    layer: `skies_explorer:${repoFile.gs_layer_name!}`,
    style: '',
    format: 'image/png8',
    transparent: true,
    maxZoom: 20,
    pane: 'overlayPane',
    tilematrixset: 'EPSG:900913',
    height: 256,
    width: 256
  };

  if (style && style === HILL_SHADE) {
    wmtsParams = {
      ...wmtsParams,
      layer: `skies_explorer:${repoFile.gs_layer_name}_${style}`
    };
  }

  if (style && style === JET) {
    wmtsParams = {
      ...wmtsParams,
      layer: `skies_explorer:${repoFile.gs_layer_name!}`,
      style: `skies_explorer:${style}_${repoFile.gs_layer_name}`
    };
  }

  return wmtsParams;
};

export const getLULCLeftWmtsParams = (repoFile: DataLayerFileType, env: string) => {
  if (!repoFile) return null;

  const wmtsParams = {
    layer: `skies_explorer:${repoFile.gs_layer_name}`,
    env,
    format: 'image/png8',
    transparent: true,
    maxZoom: 20,
    pane: 'overlayPane',
    opacity: 1,
    tilematrixset: 'EPSG:900913',
    height: 256,
    width: 256
  };

  return wmtsParams;
};

export const getLULCRightWmtsParams = (repoFile: DataLayerFileType, env: string) => {
  if (!repoFile) return null;

  const wmtsParams = {
    layer: `skies_explorer:${repoFile.gs_layer_name}`,
    env,
    format: 'image/png8',
    transparent: true,
    maxZoom: 20,
    pane: 'overlayPane',
    opacity: 1,
    tilematrixset: 'EPSG:900913',
    height: 256,
    width: 256
  };

  return wmtsParams;
};
