import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const RightIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.3334 6.00024C6.3334 6.19024 6.26923 6.3794 6.14007 6.53357L1.9734 11.5336C1.67923 11.8869 1.1534 11.9352 0.800068 11.6402C0.446734 11.3461 0.399234 10.8211 0.693401 10.4669L4.4234 5.99107L0.827567 1.52274C0.539234 1.1644 0.595901 0.639405 0.954234 0.351071C1.31257 0.0627384 1.83673 0.119405 2.1259 0.477737L6.14923 5.47774C6.27173 5.63024 6.3334 5.81524 6.3334 6.00024Z"
        fill={color}
      />
    </svg>
  );
};
RightIcon.defaultProps = {
  color: white,
  height: 12,
  width: 7,
  onClick: () => {}
};
export default RightIcon;
