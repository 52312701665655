import { toast } from 'react-toastify';
import { ACCESS_TOKEN, TOKEN_EXPIRE_ERROR } from 'constants/common.constant';
import { Response } from 'types/common.types';
import { apiCall } from 'utils/connect';
import {
  ADD_ANNOTATIONS_URL,
  EDIT_DELETE_ANNOTATIONS_URL,
  GET_ANNOTATIONS_URL,
  GET_UPLOADED_ANNOTATIONS_URL
} from 'utils/apiUrls';
import { getAsyncStorageValue } from 'utils/localStorage';
import { AppDispatch } from '..';
import {
  AddedAnnotation,
  MarkingEditPayload,
  MarkingAddPayload,
  UploadedAnnotation
} from './types';
import {
  setAddAnnotationPointRequesting,
  setAddedAnnotationPoints,
  setAnnotationPointRequesting,
  setRequestDeleteAnnotation,
  setRequestEditAnnotation,
  setUploadedAnnotationPoints,
  setUpoadedAnnotationRequesting
} from '.';

export const getUploadedAnnotations =
  (orgId: number, projectId: number, token: string) => (dispatch: AppDispatch) => {
    try {
      dispatch(setUpoadedAnnotationRequesting(true));

      const onSuccess = (response: Response<UploadedAnnotation[]>) => {
        dispatch(setUploadedAnnotationPoints(response.data));
        dispatch(setUpoadedAnnotationRequesting(false));
      };
      const onFailure = (error: Error) => {
        dispatch(setUploadedAnnotationPoints([]));
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getUploadedAnnotations(orgId, projectId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setUpoadedAnnotationRequesting(false));
        }
      };

      apiCall(
        'GET',
        GET_UPLOADED_ANNOTATIONS_URL(orgId, projectId),
        '',
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setUploadedAnnotationPoints([]));
      dispatch(setUpoadedAnnotationRequesting(false));
      toast.error(error.message);
    }
  };

export const getAddedAnnotations =
  (orgId: number, projectId: number, token: string) => (dispatch: AppDispatch) => {
    try {
      dispatch(setAnnotationPointRequesting(true));

      const onSuccess = (response: Response<AddedAnnotation[]>) => {
        dispatch(setAddedAnnotationPoints(response.data));
        dispatch(setAnnotationPointRequesting(false));
      };
      const onFailure = (error: Error) => {
        dispatch(setAddedAnnotationPoints([]));
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getAddedAnnotations(orgId, projectId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setAnnotationPointRequesting(false));
        }
      };

      apiCall('GET', GET_ANNOTATIONS_URL(orgId, projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setAddedAnnotationPoints([]));
      dispatch(setAnnotationPointRequesting(false));
      toast.error(error.message);
    }
  };

export const addAnnotationsPoint =
  (
    payload: MarkingAddPayload,
    orgId: number,
    projectId: number,
    dlId: number,
    token: string,
    callback?: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setAddAnnotationPointRequesting(true));

      const onSuccess = (response: Response<boolean>) => {
        dispatch(getAddedAnnotations(orgId, projectId, token));
        dispatch(setAddAnnotationPointRequesting(false));
        if (callback) callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addAnnotationsPoint(payload, orgId, projectId, dlId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setAddAnnotationPointRequesting(false));
        }
      };

      apiCall(
        'POST',
        ADD_ANNOTATIONS_URL(orgId, projectId, dlId),
        { ...payload },
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setAddAnnotationPointRequesting(false));
      toast.error(error.message);
    }
  };

export const deleteAnnotation =
  (
    orgId: number,
    projectId: number,
    dlId: number,
    annId: number,
    token: string,
    callback?: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestDeleteAnnotation(true));

      const onSuccess = (response: Response<boolean>) => {
        dispatch(getAddedAnnotations(orgId, projectId, token));
        dispatch(setRequestDeleteAnnotation(false));
        if (callback) callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(deleteAnnotation(orgId, projectId, dlId, annId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestDeleteAnnotation(false));
        }
      };

      apiCall(
        'DELETE',
        EDIT_DELETE_ANNOTATIONS_URL(orgId, projectId, dlId, annId),
        '',
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestDeleteAnnotation(false));
      toast.error(error.message);
    }
  };

export const editAnnotationsPoint =
  (
    payload: MarkingEditPayload,
    orgId: number,
    projectId: number,
    dlId: number,
    annId: number,
    token: string,
    callback?: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestEditAnnotation(true));

      const onSuccess = (response: Response<boolean>) => {
        dispatch(getAddedAnnotations(orgId, projectId, token));
        dispatch(setRequestEditAnnotation(false));
        if (callback) callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(
              editAnnotationsPoint(payload, orgId, projectId, dlId, annId, reToken, callback)
            );
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestEditAnnotation(false));
        }
      };

      apiCall(
        'PUT',
        EDIT_DELETE_ANNOTATIONS_URL(orgId, projectId, dlId, annId),
        { ...payload },
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestEditAnnotation(false));
      toast.error(error.message);
    }
  };
