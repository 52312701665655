import { Avatar, Typography } from 'antd';
import './styles.less';

const { Text } = Typography;

const UserDetail = ({ cName, fName, lName, role }: any) => {
  return (
    <div className="user-detail">
      <Avatar className="user-avatar">{cName || '-'}</Avatar>
      <div className="user-title">
        <Text className="user-name">
          {fName || '-'} {lName}
        </Text>
        <Text className="user-role">{role || '-'}</Text>
      </div>
    </div>
  );
};

export default UserDetail;
