import { IconProps } from 'types/common.types';

const WhatchIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.29038 2.30147C3.11133 1.4777 4.24579 0.96875 5.5 0.96875C8.00254 0.96875 10.0312 2.99746 10.0312 5.5C10.0312 8.00254 8.00254 10.0312 5.5 10.0312C2.99746 10.0312 0.96875 8.00254 0.96875 5.5C0.96875 5.24112 0.758883 5.03125 0.5 5.03125C0.241117 5.03125 0.03125 5.24112 0.03125 5.5C0.03125 8.52031 2.47969 10.9688 5.5 10.9688C8.52031 10.9688 10.9688 8.52031 10.9688 5.5C10.9688 2.47969 8.52031 0.03125 5.5 0.03125C3.98657 0.03125 2.61598 0.646639 1.62633 1.6397C1.61043 1.65566 1.59591 1.67248 1.58278 1.69001L0.796798 0.904029C0.711058 0.818288 0.583458 0.789886 0.469445 0.831164C0.355432 0.872443 0.275589 0.975949 0.264611 1.09671L0.04364 3.52739C0.0352433 3.61975 0.0683061 3.71107 0.133886 3.77665C0.199465 3.84223 0.290786 3.87529 0.383149 3.8669L2.81383 3.64592C2.93459 3.63495 3.03809 3.5551 3.07937 3.44109C3.12065 3.32708 3.09225 3.19948 3.00651 3.11374L2.23893 2.34617C2.25688 2.33272 2.27409 2.31782 2.29038 2.30147Z"
        fill={color}
      />
      <path
        d="M5.96875 2.375C5.96875 2.11612 5.75888 1.90625 5.5 1.90625C5.24112 1.90625 5.03125 2.11612 5.03125 2.375V5.5C5.03125 5.66162 5.11451 5.81184 5.25156 5.8975L7.12656 7.06937C7.3461 7.20658 7.63529 7.13984 7.7725 6.92031C7.90971 6.70078 7.84297 6.41158 7.62344 6.27438L5.96875 5.2402V2.375Z"
        fill={color}
      />
    </svg>
  );
};
WhatchIcon.defaultProps = {
  color: '#B6B6B6',
  height: 11,
  width: 11
};

export default WhatchIcon;
