import React, { memo, useMemo, useState } from 'react';
import { LatLng } from 'leaflet';
import { Marker, Popup, Tooltip, useMap } from 'react-leaflet';
import { Button, Space, Typography } from 'antd';
import { TowerIcon } from 'constants/map.constants';
import { useAppSelector } from 'hooks/useReduxHook';
import { TowerType } from 'store/riskanalysis/types';
import { ProjectCategoryTypes } from 'store/projects/types';

interface RendorTowerProps {
  towers: TowerType[] | [];
}

const { Text } = Typography;

const RendorTower = ({ towers }: RendorTowerProps) => {
  const map = useMap();
  const { selectedProject } = useAppSelector((state) => state.projects);
  const [isShowIcon, setShowIcon] = useState<boolean>(false);
  map.on('zoomend', (e: any) => {
    const zoomLevel = e.target.getZoom();
    if (zoomLevel >= 15) {
      setShowIcon(true);
    } else {
      setShowIcon(false);
    }
  });

  const rendorTower = useMemo(() => {
    if (towers && towers.length === 0) return null;

    return towers.map((data: TowerType, index: number) => {
      const coords: any = data.geometry.coordinates;
      if (coords && coords.length === 0) return null;

      return (
        isShowIcon && (
          <Marker
            key={`tower-${index}`}
            position={new LatLng(coords[1], coords[0])}
            icon={TowerIcon}
          >
            {selectedProject?.type !== ProjectCategoryTypes.VegetationManagementLite && (
              <>
                <Popup
                  className="annts-point-popup risk-marker-popup"
                  minWidth={232}
                  maxHeight={226}
                >
                  <Space direction="vertical">
                    <div>
                      <Text className="annts-market-title">Tower ID</Text>
                      <Text className="annts-market-data">{data?.id || '-'}</Text>
                    </div>
                    <div>
                      <Text className="annts-market-title">Tower Height</Text>
                      <Text className="annts-market-data">{`${data?.height} m` || '-'}</Text>
                    </div>
                  </Space>
                </Popup>
                <Tooltip className="annts-point-tooltip" direction="right">
                  <Button type="primary">{data?.name}</Button>
                </Tooltip>
              </>
            )}
          </Marker>
        )
      );
    });
  }, [towers, isShowIcon]);

  // eslint-disable-next-line
  return <>{rendorTower}</>;
};

export default memo(RendorTower);
