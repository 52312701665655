import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from './types';

const initialState: UserState = {
  isLoadingRSA: false,
  rsaKey: null,
  isLoadingLogin: false,
  user: null,
  isLoadingLogout: false,
  isRefreshToken: false,
  isRequestingResetPassword: false,
  isResetPassword: false,
  isRequestingUserDetail: false,
  isRequestingUserEdit: false,
  isRequestingUserPicture: false,
  isRequestingUserResetPassword: false,
  userInfo: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setRsaRequest: (state, action: PayloadAction<boolean>) => {
      state.isLoadingRSA = action.payload;
    },
    setRSA: (state, action) => {
      state.rsaKey = action.payload;
    },
    setLoginRequest: (state, action) => {
      state.isLoadingLogin = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLogoutRequest: (state, action) => {
      state.isLoadingLogout = action.payload;
    },
    setRequestingRefreshToken: (state, action) => {
      state.isRefreshToken = action.payload;
    },
    setRequestingResetPassword: (state, action) => {
      state.isRequestingResetPassword = action.payload;
    },
    setResetPassword: (state, action) => {
      state.isResetPassword = action.payload;
    },
    setRequestingUserDetail: (state, action) => {
      state.isRequestingUserDetail = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setRequestingUserEdit: (state, action) => {
      state.isRequestingUserEdit = action.payload;
    },
    setRequestingUserPicture: (state, action) => {
      state.isRequestingUserPicture = action.payload;
    },
    setRequestingUserResetPassword: (state, action) => {
      state.isRequestingUserResetPassword = action.payload;
    }
  }
});
