import { upperFirst, xorBy } from 'lodash';
import { MemberT, OrganizationMember } from 'store/organizations/types';
import { TeamMember } from 'store/projects/types';
import { UserRole } from 'store/users/types';
import { decryptValue } from 'utils/decodeEncodeData';
import { RootState } from '..';
import { projectSlice } from './slice';

export const {
  setRequestingProjects,
  setProjects,
  setAddProjectRequesting,
  setSelectedProject,
  setRequestProject,
  setProject,
  setProjectMemberRequest,
  setAvailableProjectMemberRequesting,
  setAvailableProjectMembers,
  setRequestingRemoveMemberFromProject,
  setRequestingRemoveMemberFromOrganization,
  setRequestProjectType,
  setProjectType
} = projectSlice.actions;
export default projectSlice.reducer;

export const getTeamMembers = (members: TeamMember[]): Array<MemberT> => {
  return members
    ?.filter((m) => m.role !== UserRole.SuperAdmin)
    .map((member: TeamMember) => {
      if (member.first_name && member.last_name) {
        const firstname = upperFirst(decryptValue(member.first_name));
        const lastname = upperFirst(decryptValue(member.last_name));

        return {
          label: upperFirst(`${firstname} ${lastname}`),
          value: member.id,
          role: member.role
        };
      }

      return {
        label: decryptValue(member.email),
        value: member.id,
        role: member.role
      };
    });
};

export const getMember = (member: OrganizationMember | TeamMember): MemberT => {
  if (member.first_name && member.last_name) {
    const firstname = upperFirst(decryptValue(member.first_name));
    const lastname = upperFirst(decryptValue(member.last_name));

    return {
      label: upperFirst(`${firstname} ${lastname}`),
      value: member.id,
      role: member.role
    };
  }

  return {
    label: decryptValue(member.email),
    value: member.id,
    role: member.role
  };
};

export const getTeamMember = (member: OrganizationMember | TeamMember) => {
  if (member.first_name && member.last_name) {
    return {
      label: upperFirst(`${member.first_name} ${member.last_name}`),
      value: member.id,
      role: member.role
    };
  }

  return {
    label: member.email,
    value: member.id,
    role: member.role
  };
};

export const selectAvailableProjectMembers = (state: RootState) => {
  const { projectMembers } = state.projects;
  const { orginsationMembers } = state.organizations;

  return xorBy(orginsationMembers, projectMembers, 'id').map((member: any) => {
    if (member.first_name && member.last_name) {
      const firstname = upperFirst(decryptValue(member.first_name));
      const lastname = upperFirst(decryptValue(member.last_name));
      return {
        label: upperFirst(`${firstname} ${lastname}`),
        value: member.id,
        role: member.role
      };
    }

    return {
      label: decryptValue(member.email),
      value: member.id,
      role: member.role
    };
  });
};
