import { memo, useEffect, useMemo, useRef, useState } from 'react';
import L from 'leaflet';
import { FeatureGroup, MapContainer } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { filter, includes, isEmpty, remove } from 'lodash';
import { Modal } from 'antd';
import { lightGreen } from 'constants/color.constant';
import { ACCESS_TOKEN, defaultCenter } from 'constants/common.constant';
import { LightLocation } from 'constants/map.constants';
import AddNotationPointForm from 'components/core/AddNotationPointForm';
import AddNotationPolygonForm from 'components/core/AddNotationPolygonForm';
import AddNotationPolylineForm from 'components/core/AddNotationPolylineForm';
import DSMDEMTileLayer from 'components/Map/DSMDEMTileLayer';
import ProjectViewSidebar from 'components/MapContainer/ProjectViewSidebar';
import RendorAnnotationsMarkings from 'components/Map/RendorAnnotationsMarkings';
import RendorEditAnnotationMarking from 'components/Map/RendorEditAnnotationMarking';
import RendorUploadedAnnotationsMarkings from 'components/Map/RendorUploadedAnnotationsMarkings';
import SateliteImageTileLayer from 'components/Map/SateliteImageTileLayer';
import RendorTransmissionLine from 'components/Map/RendorTransmissionLine';
import RendorBuffers from 'components/Map/RendorBuffers';
import RendorTower from 'components/Map/RendorTower';
import RendorRiskSegment from 'components/Map/RendorRiskSegment';
import RendorRiskTrees from 'components/Map/RendorRiskTrees';
import SplitMap from 'components/Map/SplitMapLayer';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { getAddedAnnotations, getUploadedAnnotations } from 'store/annotations/actions';
import { getAddedAnnotationPoints, getUploadedAnnotationPoints } from 'store/annotations';
import { AddedAnnotation, UploadedAnnotation } from 'store/annotations/types';
import { Project, ProjectCategoryTypes } from 'store/projects/types';
import {
  getDEMRepoFile,
  getDSMRepoFile,
  getLULCRepoFile,
  getLulcStyleLayers,
  getOrthomosiacRepoFile,
  getSataliteImagesRepoFile
} from 'store/repositoryfile';
import { DataLayerFileType, DataLayerTableType, LulcDataType } from 'store/repositoryfile/types';
import { RiskAnalysisState, TransmissionSegmentType, TreeType } from 'store/riskanalysis/types';
import { CoordinateType, DrawType, Maptype } from 'types/common.types';
import { getCenterPosition, getCenterPositionPolygon } from 'utils/xml';
import { getAsyncStorageValue } from 'utils/localStorage';
import ControlMapCenter from '../Map/ControlMapCenter';
import MapActionControl from '../Map/MapActionControl';
import MapLayers from '../Map/MapLayers';
import RenderVectorLayers from '../Map/RenderVectorLayers';
import LULCTileLayers from '../Map/LULCTileLayers';
import RiskAnalysisTable from '../core/RiskAnalysisTable/RiskAnalysisTable';
import RiskPopup from './RiskPopup';
import '../Map/styles.less';
import './styles.less';

interface ProjectMapContainerProps {
  project: Project;
  vectorRepoFiles: Array<DataLayerFileType> | [];
  activeDataLayer: DataLayerTableType | null;
  isActiveCompare: boolean;
  leftActiveDataLayer: DataLayerTableType | null;
  rightActiveDataLayer: DataLayerTableType | null;
  handleAnnotationTabClick: (data: boolean) => void;
  handleShowRiskTable: (data: boolean) => void;
  handleSelectedRiskSegment: (data: boolean) => void;
  isShowRiskTable: boolean;
}

const ProjectMapContainer = ({
  project,
  activeDataLayer,
  vectorRepoFiles,
  isActiveCompare,
  leftActiveDataLayer,
  rightActiveDataLayer,
  handleAnnotationTabClick,
  handleShowRiskTable,
  isShowRiskTable,
  handleSelectedRiskSegment
}: ProjectMapContainerProps) => {
  const markerRef: any = useRef(null);
  const drawRef: any = useRef(null);
  const featureGroupRef: any = useRef();
  const allDataFeatureGroupRef: any = useRef();
  const dispatch = useAppDispatch();
  const {
    riskTrees,
    riskTowers,
    riskBuffer,
    riskTransmissionLine,
    isRequestingRiskanalysis
  }: RiskAnalysisState = useAppSelector((state) => state.riskanalysis);
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { vectorFiles } = useAppSelector((state) => state.vectorfile);
  // single layer
  const geoserverLayerStyles: LulcDataType[] | [] = useAppSelector((state) =>
    getLulcStyleLayers(state)
  );
  const lulcRepoFile: DataLayerFileType = useAppSelector((state) =>
    getLULCRepoFile(state, activeDataLayer?.id!)
  );
  const demRepoFile: DataLayerFileType = useAppSelector((state) =>
    getDEMRepoFile(state, activeDataLayer?.id!)
  );
  const dsmRepoFile: DataLayerFileType = useAppSelector((state) =>
    getDSMRepoFile(state, activeDataLayer?.id!)
  );
  const sataliteImagesRepoFile: DataLayerFileType = useAppSelector((state) =>
    getSataliteImagesRepoFile(state, activeDataLayer?.id!)
  );
  const orthomosiacRepoFile: DataLayerFileType = useAppSelector((state) =>
    getOrthomosiacRepoFile(state, activeDataLayer?.id!)
  );

  // left layer
  const leftSataliteImagesRepoFile: DataLayerFileType = useAppSelector((state) =>
    getSataliteImagesRepoFile(state, leftActiveDataLayer?.id!)
  );
  const leftDemRepoFile: DataLayerFileType = useAppSelector((state) =>
    getDEMRepoFile(state, leftActiveDataLayer?.id!)
  );
  const leftDsmRepoFile: DataLayerFileType = useAppSelector((state) =>
    getDSMRepoFile(state, leftActiveDataLayer?.id!)
  );
  const leftOrthomosiacRepoFile: DataLayerFileType = useAppSelector((state) =>
    getOrthomosiacRepoFile(state, leftActiveDataLayer?.id!)
  );

  // right layer
  const rightSataliteImagesRepoFile: DataLayerFileType = useAppSelector((state) =>
    getSataliteImagesRepoFile(state, rightActiveDataLayer?.id!)
  );
  const rightDemRepoFile: DataLayerFileType = useAppSelector((state) =>
    getDEMRepoFile(state, rightActiveDataLayer?.id!)
  );
  const rightDsmRepoFile: DataLayerFileType = useAppSelector((state) =>
    getDSMRepoFile(state, rightActiveDataLayer?.id!)
  );
  const rightOrthomosiacRepoFile: DataLayerFileType = useAppSelector((state) =>
    getOrthomosiacRepoFile(state, rightActiveDataLayer?.id!)
  );

  // annotations markings list
  const addedAnnotations: AddedAnnotation[] | [] = useAppSelector((state) =>
    getAddedAnnotationPoints(state, activeDataLayer?.id!)
  );
  const uploadedAnnotations: UploadedAnnotation[] | [] = useAppSelector((state) =>
    getUploadedAnnotationPoints(state, activeDataLayer?.id!)
  );

  const [mapCenter, setMapCenter] = useState<any | null>(null);
  const [zoomLevel, setZoomLevel] = useState<number>(14);
  const [selectedLulcLayer, setSelectedLulcLayer] = useState<string[]>([]);
  const [vectorLayerIds, setVectorLayerIds] = useState<number[]>([]);
  const [isShowDEMLegend, setShowDEMLegend] = useState<boolean>(false);
  const [isShowDSMLegend, setShowDSMLegend] = useState<boolean>(false);
  const [isShowSatelliteImages, setShowSatelliteImages] = useState<boolean>(false);
  const [isShowOrthomosiac, setShowOrthomosiac] = useState<boolean>(false);
  const [selectedDEMStyle, setSelectedDEMStyle] = useState<string | null>(null);
  const [selectedDSMStyle, setSelectedDSMStyle] = useState<string | null>(null);
  const [activeAnnotationsTab, setActiveAnnotationsTab] = useState<string | null>(null);
  const [selectedAddedAnnotationsMarkings, setSelectedAddedAnnotationsMarkings] = useState<
    number[]
  >([]);
  const [selectedUploadedAnnotationsMarkings, setSelectedUploadedAnnotationsMarkings] = useState<
    number[]
  >([]);
  const [editAnnotationMarking, setEditAnnotationMarking] = useState<AddedAnnotation | null>(null);
  /* Draw Shape */
  const [isOpenAddEditMarkerModal, setOpenAddEditMarkerModal] = useState<boolean>(false);
  const [isEditPoint, setEditPoint] = useState<boolean>(false);
  const [pointLatLng, setPointLatLng] = useState<any>([]);
  const [isEditPolyline, setEditPolyline] = useState<boolean>(false);
  const [newPoliLineCoords, setNewPoliLineCoords] = useState<any>([]);
  const [isEditPolygon, setEditPolygon] = useState<boolean>(false);
  const [newPolygonCoords, setNewPolygonCoords] = useState<any>([]);
  const [centroidPoint, setCentroidPoint] = useState<any>(null);
  const [mapType, setMapType] = useState<string>(Maptype.Roadmap);
  const [selectedRiskSegmentData, setSelectedRiskSegmentData] =
    useState<TransmissionSegmentType | null>(null);
  const [selectedRiskSegmentTrees, setSelectedRiskSegmentTrees] = useState<TreeType[] | []>([]);
  const [polygonArea, setPolygonArea] = useState<number | null>(null); // eslint-disable-line

  useEffect(() => {
    if (selectedProject) {
      setMapCenter(selectedProject.coordinates);
    }
  }, []);

  useEffect(() => {
    if (vectorFiles && vectorFiles.length > 0) {
      const vectorids = vectorFiles.map((file: DataLayerFileType) => file.id);
      setVectorLayerIds(vectorids);
    }
  }, [vectorFiles]);

  useEffect(() => {
    if (addedAnnotations && addedAnnotations.length > 0) {
      const addedAnnotationIds = addedAnnotations.map(
        (annotation: AddedAnnotation) => annotation.id
      );
      setSelectedAddedAnnotationsMarkings(addedAnnotationIds);
    }
  }, [addedAnnotations]);

  useEffect(() => {
    if (uploadedAnnotations && uploadedAnnotations.length > 0) {
      const uploadedAnnotationIds: number[] | [] = uploadedAnnotations.map(
        (annotation: UploadedAnnotation) => Number(annotation.id)
      );
      setSelectedUploadedAnnotationsMarkings(uploadedAnnotationIds);
    }
  }, [uploadedAnnotations]);

  useEffect(() => {
    if (activeDataLayer && selectedProject.type === ProjectCategoryTypes.SolarPowerPlant) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getUploadedAnnotations(+selectedOrganization.id, +project.id, token));
        dispatch(getAddedAnnotations(+selectedOrganization.id, +project.id, token));
      });
    }
  }, [activeDataLayer]);

  const handleVectorLayerIds = (fileId: number) => {
    if (includes(vectorLayerIds, fileId)) {
      remove(vectorLayerIds, (route: number) => route === fileId);
    } else {
      vectorLayerIds.push(fileId);
    }

    setVectorLayerIds([...vectorLayerIds]);
  };

  const handleShowLULCLayerOnMap = (selectedStyle: string) => {
    if (includes(selectedLulcLayer, selectedStyle)) {
      remove(selectedLulcLayer, (style: string) => style === selectedStyle);
    } else {
      selectedLulcLayer.push(selectedStyle);
    }

    setSelectedLulcLayer([...selectedLulcLayer]);
  };

  const handleShowMarkings = (markingData: AddedAnnotation) => {
    if (includes(selectedAddedAnnotationsMarkings, markingData.id)) {
      remove(selectedAddedAnnotationsMarkings, (id: number) => id === markingData.id);
    } else {
      const center: any =
        !isEmpty(markingData.properties) &&
        markingData.properties.coordinates &&
        markingData.properties.coordinates?.split(',').map(Number);
      setMapCenter(center || mapCenter);
      selectedAddedAnnotationsMarkings.push(markingData.id);
    }

    setSelectedAddedAnnotationsMarkings([...selectedAddedAnnotationsMarkings]);
  };

  const handleShowUploadedMarkings = (markingId: number) => {
    if (includes(selectedUploadedAnnotationsMarkings, markingId)) {
      remove(selectedUploadedAnnotationsMarkings, (id: number) => id === markingId);
    } else {
      selectedUploadedAnnotationsMarkings.push(markingId);
    }

    setSelectedUploadedAnnotationsMarkings([...selectedUploadedAnnotationsMarkings]);
  };

  const handleShowDemLegend = (isCheck: boolean) => {
    setShowDEMLegend(isCheck);
    setShowDSMLegend(false);
    setSelectedDSMStyle(null);
  };

  const handleShowDsmLegend = (isCheck: boolean) => {
    setShowDSMLegend(isCheck);
    setShowDEMLegend(false);
    setSelectedDEMStyle(null);
  };

  const handleDemStyle = (demStyle: string | null) => {
    setSelectedDEMStyle(demStyle);
    setSelectedDSMStyle(null);
  };

  const handleDsmStyle = (dsmStyle: string | null) => {
    setSelectedDSMStyle(dsmStyle);
    setSelectedDEMStyle(null);
  };

  const handleShowSatelliteImagesFile = (isCheck: boolean) => {
    setShowSatelliteImages(isCheck);
  };

  const handleShowOrthomosiacFile = (isCheck: boolean) => {
    setShowOrthomosiac(isCheck);
  };

  const handleSelectedAnnotationsTab = (activekey: string | null) => {
    setActiveAnnotationsTab(activekey);

    if (!activekey) {
      setEditAnnotationMarking(null);
    }

    if (activekey === CoordinateType.LineString) {
      setTimeout(
        // eslint-disable-next-line
        () => drawRef?.current?._toolbars?.draw._modes.polyline.handler.enable()
      );
    } else if (activekey === CoordinateType.Polygon) {
      setTimeout(
        // eslint-disable-next-line
        () => drawRef?.current?._toolbars?.draw._modes.polygon.handler.enable()
      );
    } else if (activekey === CoordinateType.Point) {
      setTimeout(
        // eslint-disable-next-line
        () => drawRef?.current?._toolbars?.draw._modes.marker.handler.enable()
      );
    }
  };

  const handleEditMarking = (data: AddedAnnotation | null) => {
    setEditAnnotationMarking(data);
    if (data) {
      remove(selectedAddedAnnotationsMarkings, (id: number) => id === data.id);
      setSelectedAddedAnnotationsMarkings([...selectedAddedAnnotationsMarkings]);
      setTimeout(
        // eslint-disable-next-line
        () => drawRef?.current?._toolbars?.edit._modes.edit.handler.enable()
      );

      if (data.geometry.type === CoordinateType.Point) {
        setPointLatLng(data.geometry.coordinates);
        setMapCenter(data.geometry.coordinates);
        setEditPoint(true);
      } else if (data.geometry.type === CoordinateType.LineString) {
        setMapCenter(data.geometry.coordinates[0]);
        setNewPoliLineCoords(data.geometry.coordinates);
        setEditPolyline(true);
      } else if (data.geometry.type === CoordinateType.Polygon) {
        const polygonMapCenter: any =
          data.properties && data.properties?.coordinates
            ? data.properties?.coordinates.split(',').map((item: any) => {
                return Number(item);
              })
            : mapCenter;
        setMapCenter(polygonMapCenter);
        setNewPolygonCoords(data.geometry.coordinates);
        setEditPolygon(true);
      }
    }
  };

  /* Draw Polyline */
  const handleOnCreatePolyline = (e: any) => {
    const geoJOSN = e.layer.toGeoJSON();
    const lineCoords: any = geoJOSN.geometry.coordinates;
    // @ts-ignore
    const length = L.GeometryUtil.readableDistance(e.layer.getLatLngs()[0]); // eslint-disable-line
    const centeroid = e.target.getCenter();
    setCentroidPoint(centeroid);

    setNewPoliLineCoords(lineCoords);
    setOpenAddEditMarkerModal(true);
  };

  const handleOnEditPolyline = (e: any) => {
    let coords: any = [];
    e.layers.eachLayer((layer: any) => {
      const geoJOSN = layer.toGeoJSON();
      coords = geoJOSN.geometry.coordinates;
    });

    if (coords && coords.length === 0) return;
    const centeroid = e.target.getCenter();
    setCentroidPoint(centeroid);
    setNewPoliLineCoords(coords);
    setOpenAddEditMarkerModal(true);
    setEditPolyline(true);
  };

  const handleRemoveMarkerLine = () => {
    featureGroupRef.current.clearLayers();
    setNewPoliLineCoords([]);
    setEditPolyline(false);
    setOpenAddEditMarkerModal(false);
    setEditAnnotationMarking(null);
    setPointLatLng([]);
  };

  /* Draw Polygon */
  const handleOnCreatePolygon = (e: any) => {
    const geoJOSN = e.layer.toGeoJSON();
    // @ts-ignore
    const area = L.GeometryUtil.geodesicArea(e.layer.getLatLngs()[0]) / 1000000;
    const polygonCoords: any = geoJOSN.geometry.coordinates.map((coords: any) => {
      return coords.map((coord: any) => {
        return [coord[1], coord[0]];
      });
    });

    const centeroid = e.target.getCenter();
    setCentroidPoint(centeroid);
    setNewPolygonCoords(polygonCoords);
    setOpenAddEditMarkerModal(true);
    setPolygonArea(area);
  };

  const handleOnEditPolygon = (e: any) => {
    let coords: any = [];
    let area: number = 0;
    e.layers.eachLayer((layer: any) => {
      const geoJOSN = layer.toGeoJSON();
      coords = geoJOSN.geometry.coordinates.map((coords: any) => {
        return coords.map((coord: any) => {
          return [coord[1], coord[0]];
        });
      });
      // @ts-ignore
      area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]) / 1000000;
    });
    if (coords && coords.length === 0) return;
    const centeroid = e.target.getCenter();
    setCentroidPoint(centeroid);
    setNewPolygonCoords(coords);
    setPolygonArea(area);
    setOpenAddEditMarkerModal(true);
    setEditPolygon(true);
  };

  const handleRemoveMarkerPolygon = () => {
    featureGroupRef.current.clearLayers();
    setOpenAddEditMarkerModal(false);
    setEditPolygon(false);
    setEditAnnotationMarking(null);
  };

  /* Draw Point */
  const handleOnCreatePoint = (e: any) => {
    const geoJOSN = e.layer.toGeoJSON();
    const coord = geoJOSN.geometry.coordinates;

    setPointLatLng([coord[1], coord[0]]);
    setOpenAddEditMarkerModal(true);
  };

  const handleOnEditPoint = (e: any) => {
    let coords: any = [];
    e.layers.eachLayer((layer: any) => {
      const geoJOSN = layer.toGeoJSON();
      coords = geoJOSN.geometry.coordinates;
    });

    if (coords && coords.length === 0) return;
    setPointLatLng([coords[1], coords[0]]);
    setOpenAddEditMarkerModal(true);
  };

  const handleRemoveMarkerPoint = () => {
    featureGroupRef.current.clearLayers();
    setPointLatLng([]);
    setOpenAddEditMarkerModal(false);
    setEditAnnotationMarking(null);
  };

  const handleOnCreateShap = (e: any) => {
    if (e.layerType === DrawType.Polyline) {
      handleOnCreatePolyline(e);
    } else if (e.layerType === DrawType.Polygon) {
      handleOnCreatePolygon(e);
    } else if (e.layerType === DrawType.Point) {
      handleOnCreatePoint(e);
    }
  };

  const handleOnEditeShap = (e: any) => {
    e.layers.eachLayer((layer: any) => {
      if (layer.toGeoJSON().geometry.type === 'LineString') {
        handleOnEditPolyline(e);
      } else if (layer.toGeoJSON().geometry.type === 'Polygon') {
        handleOnEditPolygon(e);
      } else if (layer.toGeoJSON().geometry.type === 'Point') {
        handleOnEditPoint(e);
      }
    });
  };

  const handleOnMounted = (ctl: any) => {
    drawRef.current = ctl;
  };

  const handleMapType = (type: string) => {
    setMapType(type);
  };

  const handleSelectedSegmentTrees = (data: TransmissionSegmentType | null) => {
    const trees: TreeType[] = [];
    if (!isEmpty(data) && !isEmpty(data.trees_id)) {
      filter(riskTrees, (tree: TreeType) => {
        if (data.trees_id.includes(tree.id as never)) trees.push(tree);
      });
    }
    return trees;
  };

  const handleRiskSegmentData = (data: TransmissionSegmentType | null) => {
    setSelectedRiskSegmentData(data);
    setSelectedRiskSegmentTrees(handleSelectedSegmentTrees(data));
    handleSelectedRiskSegment(!isEmpty(data));
    if (!isEmpty(data)) {
      let centerLatLng;
      if (data.geometry.type === CoordinateType.MultiPolygon) {
        centerLatLng = getCenterPosition(data.geometry.coordinates);
      }
      if (data.geometry.type === CoordinateType.Polygon) {
        centerLatLng = getCenterPositionPolygon(data.geometry.coordinates);
      }

      setMapCenter(!isEmpty(centerLatLng) ? [centerLatLng.lat, centerLatLng.lng] : null);
      setZoomLevel(18);
    }
  };

  const handleSelectedRiskCatTrees = (riskCatTrees: TreeType[] | []) => {
    setSelectedRiskSegmentTrees(riskCatTrees);
  };

  const handleShowAllRiskTree = (segment: TransmissionSegmentType | null) => {
    handleRiskSegmentData(segment);
  };

  const handleShowVectorFiles = (isCheck: boolean) => {
    if (isCheck) {
      if (vectorFiles && vectorFiles.length > 0) {
        const vectorids = vectorFiles.map((file: DataLayerFileType) => file.id);
        setVectorLayerIds(vectorids);
      } else {
        setVectorLayerIds([]);
      }
    } else {
      setVectorLayerIds([]);
    }
  };

  const handleShowRasterFiles = (isCheck: boolean) => {
    const lulcStyle: string[] = [];
    if (!isCheck) {
      if (!isEmpty(geoserverLayerStyles)) {
        geoserverLayerStyles.map((layerStyle: LulcDataType) => lulcStyle.push(layerStyle.value));

        setSelectedLulcLayer(lulcStyle);
      } else {
        setSelectedLulcLayer([]);
      }
    } else {
      setSelectedLulcLayer([]);
    }
  };

  const handleShowUploadAnnotation = (isCheck: boolean) => {
    if (isCheck) {
      if (uploadedAnnotations && uploadedAnnotations.length > 0) {
        const uploadedAnnotationIds: number[] | [] = uploadedAnnotations.map(
          (annotation: UploadedAnnotation) => Number(annotation.id)
        );
        setSelectedUploadedAnnotationsMarkings(uploadedAnnotationIds);
      } else {
        setSelectedUploadedAnnotationsMarkings([]);
      }
    } else {
      setSelectedUploadedAnnotationsMarkings([]);
    }
  };

  const handleShowMarkedAnnotation = (isCheck: boolean) => {
    if (isCheck) {
      if (addedAnnotations && addedAnnotations.length > 0) {
        const addedAnnotationIds = addedAnnotations.map(
          (annotation: AddedAnnotation) => annotation.id
        );
        setSelectedAddedAnnotationsMarkings(addedAnnotationIds);
      } else {
        setSelectedAddedAnnotationsMarkings([]);
      }
    } else {
      setSelectedAddedAnnotationsMarkings([]);
    }
  };

  const isBool: boolean =
    activeAnnotationsTab === CoordinateType.Point ||
    activeAnnotationsTab === CoordinateType.LineString ||
    activeAnnotationsTab === CoordinateType.Polygon;

  const mapContainer = useMemo(() => {
    return (
      <MapContainer
        center={mapCenter || defaultCenter}
        zoom={zoomLevel}
        maxZoom={20}
        zoomControl={false}
        className="map-container"
      >
        <MapLayers maptype={mapType} />
        <FeatureGroup ref={allDataFeatureGroupRef}>
          {!isActiveCompare && (
            <>
              {!isEmpty(vectorLayerIds) && vectorRepoFiles && vectorRepoFiles.length > 0 && (
                <RenderVectorLayers
                  vectorRepoFiles={vectorRepoFiles}
                  vectorLayerIds={vectorLayerIds}
                />
              )}
              {!isEmpty(lulcRepoFile) && geoserverLayerStyles.length > 0 && (
                <LULCTileLayers
                  selectedLulcLayer={selectedLulcLayer}
                  geoserverLayerStyles={geoserverLayerStyles}
                  lulcRepoFile={lulcRepoFile}
                />
              )}
              {!isEmpty(demRepoFile) && isShowDEMLegend && (
                <DSMDEMTileLayer repoFile={demRepoFile} style={selectedDEMStyle} />
              )}
              {!isEmpty(dsmRepoFile) && isShowDSMLegend && (
                <DSMDEMTileLayer repoFile={dsmRepoFile} style={selectedDSMStyle} />
              )}
              {!isEmpty(sataliteImagesRepoFile) && isShowSatelliteImages && (
                <SateliteImageTileLayer repoFile={sataliteImagesRepoFile} />
              )}
              {!isEmpty(orthomosiacRepoFile) && isShowOrthomosiac && (
                <SateliteImageTileLayer repoFile={orthomosiacRepoFile} />
              )}

              {/* Start Risk analysis */}
              {selectedProject.type === ProjectCategoryTypes.VegetationManagement && (
                <RendorBuffers buffers={riskBuffer} />
              )}
              {selectedProject.type === ProjectCategoryTypes.VegetationManagement && (
                <RendorTower towers={riskTowers} />
              )}
              {selectedProject.type === ProjectCategoryTypes.VegetationManagement && (
                <RendorTransmissionLine transmissionLine={riskTransmissionLine} />
              )}
              {selectedRiskSegmentData && (
                <RendorRiskSegment riskSegmentData={selectedRiskSegmentData} />
              )}
              {selectedRiskSegmentTrees && (
                <RendorRiskTrees selectedSegmentTrees={selectedRiskSegmentTrees} />
              )}
              {/* End Risk analysis */}
              {/* Start annotations */}
              {selectedProject.type === ProjectCategoryTypes.SolarPowerPlant &&
                addedAnnotations &&
                addedAnnotations.length > 0 &&
                selectedAddedAnnotationsMarkings &&
                selectedAddedAnnotationsMarkings.length > 0 && (
                  <RendorAnnotationsMarkings
                    addedAnnotations={addedAnnotations}
                    selectedMarkings={selectedAddedAnnotationsMarkings}
                  />
                )}
              {/* uploaded annotations rendor */}
              {selectedProject.type === ProjectCategoryTypes.SolarPowerPlant &&
                uploadedAnnotations &&
                uploadedAnnotations.length > 0 &&
                selectedUploadedAnnotationsMarkings &&
                selectedUploadedAnnotationsMarkings.length > 0 && (
                  <RendorUploadedAnnotationsMarkings
                    uploadedAnnotations={uploadedAnnotations}
                    selectedMarkings={selectedUploadedAnnotationsMarkings}
                  />
                )}
            </>
          )}
        </FeatureGroup>
        <FeatureGroup ref={featureGroupRef}>
          <EditControl
            position="bottomright"
            onCreated={handleOnCreateShap}
            onEdited={handleOnEditeShap}
            onMounted={handleOnMounted}
            draw={{
              rectangle: false,
              polyline:
                activeAnnotationsTab === CoordinateType.LineString
                  ? {
                      allowIntersection: false,
                      shapeOptions: {
                        color: lightGreen,
                        fillColor: lightGreen,
                        fillOpacity: 0.3,
                        weight: 1.5
                      },
                      showLength: true
                    }
                  : false,
              circle: false,
              circlemarker: false,
              polygon:
                activeAnnotationsTab === CoordinateType.Polygon
                  ? {
                      allowIntersection: false,
                      shapeOptions: {
                        fillColor: lightGreen,
                        color: lightGreen,
                        fillOpacity: 0.3,
                        weight: 2
                      },
                      showArea: true
                    }
                  : false,
              marker:
                activeAnnotationsTab === CoordinateType.Point
                  ? {
                      icon: LightLocation
                    }
                  : false
            }}
            edit={{ remove: isBool, edit: isBool }}
          />
          {selectedProject.type === ProjectCategoryTypes.SolarPowerPlant &&
            !isEmpty(editAnnotationMarking) && (
              <RendorEditAnnotationMarking selectedEditMarking={editAnnotationMarking} />
            )}
        </FeatureGroup>
        {/* End annotations */}
        {isActiveCompare && (
          <SplitMap
            isShowSatelliteImages={isShowSatelliteImages}
            leftSataliteImagesRepoFile={leftSataliteImagesRepoFile}
            rightSataliteImagesRepoFile={rightSataliteImagesRepoFile}
            isShowDEMLegend={isShowDEMLegend}
            selectedDEMStyle={selectedDEMStyle}
            leftDemRepoFile={leftDemRepoFile}
            rightDemRepoFile={rightDemRepoFile}
            isShowDSMLegend={isShowDSMLegend}
            selectedDSMStyle={selectedDSMStyle}
            leftDsmRepoFile={leftDsmRepoFile}
            rightDsmRepoFile={rightDsmRepoFile}
            isShowOrthomosiac={isShowOrthomosiac}
            leftOrthomosiacRepoFile={leftOrthomosiacRepoFile}
            rightOrthomosiacRepoFile={rightOrthomosiacRepoFile}
          />
        )}
        <MapActionControl
          mapType={mapType}
          boundRef={allDataFeatureGroupRef}
          selectedRiskData={selectedRiskSegmentData}
          handleMapType={handleMapType}
          isRequestingRiskanalysis={isRequestingRiskanalysis}
        />
        <ControlMapCenter zoomLevel={zoomLevel} center={mapCenter} />
      </MapContainer>
    );
  }, [
    mapType,
    markerRef,
    allDataFeatureGroupRef,
    featureGroupRef,
    activeDataLayer,
    vectorRepoFiles,
    project,
    mapCenter,
    isActiveCompare,
    vectorLayerIds,
    lulcRepoFile,
    geoserverLayerStyles,
    selectedLulcLayer,
    demRepoFile,
    isShowDEMLegend,
    selectedDEMStyle,
    dsmRepoFile,
    isShowDSMLegend,
    selectedDSMStyle,
    sataliteImagesRepoFile,
    isShowSatelliteImages,
    orthomosiacRepoFile,
    isShowOrthomosiac,
    leftSataliteImagesRepoFile,
    rightSataliteImagesRepoFile,
    activeAnnotationsTab,
    selectedAddedAnnotationsMarkings,
    selectedUploadedAnnotationsMarkings,
    editAnnotationMarking,
    isOpenAddEditMarkerModal,
    isEditPolyline,
    isEditPolygon,
    isEditPoint,
    newPoliLineCoords,
    newPolygonCoords,
    pointLatLng,
    isBool,
    riskTowers,
    riskBuffer,
    riskTransmissionLine,
    selectedRiskSegmentData,
    selectedRiskSegmentTrees,
    zoomLevel
  ]);

  // eslint-disable-next-line
  return (
    <>
      {mapContainer}
      <ProjectViewSidebar
        project={project}
        activeDataLayer={activeDataLayer}
        vectorRepoFiles={vectorRepoFiles}
        vectorLayerIds={vectorLayerIds}
        lulcRepoFile={lulcRepoFile}
        demRepoFile={demRepoFile}
        dsmRepoFile={dsmRepoFile}
        sataliteImagesRepoFile={
          sataliteImagesRepoFile || leftSataliteImagesRepoFile || rightSataliteImagesRepoFile
        }
        orthomosiacRepoFile={orthomosiacRepoFile}
        selectedLulcLayer={selectedLulcLayer}
        lulcStyleLayers={geoserverLayerStyles}
        isActiveCompare={isActiveCompare}
        addedAnnotations={addedAnnotations}
        uploadedAnnotations={uploadedAnnotations}
        selectedMarkings={selectedAddedAnnotationsMarkings}
        selectedUploadMarkings={selectedUploadedAnnotationsMarkings}
        editAnnotationMarking={editAnnotationMarking}
        handleVectorLayerIds={handleVectorLayerIds}
        handleShowLULCLayerOnMap={handleShowLULCLayerOnMap}
        handleDemStyle={handleDemStyle}
        handleShowDemLegend={handleShowDemLegend}
        handleDsmStyle={handleDsmStyle}
        handleShowDsmLegend={handleShowDsmLegend}
        handleShowOrthomosiacFile={handleShowOrthomosiacFile}
        handleShowSatelliteImagesFile={handleShowSatelliteImagesFile}
        handleSelectedAnnotationsTab={handleSelectedAnnotationsTab}
        handleShowMarkings={handleShowMarkings}
        handleShowUploadedMarkings={handleShowUploadedMarkings}
        handleEditMarking={handleEditMarking}
        handleAnnotationTabClick={handleAnnotationTabClick}
        handleShowRiskTable={handleShowRiskTable}
        handleRiskSegmentData={handleRiskSegmentData}
        handleShowVectorFiles={handleShowVectorFiles}
        handleShowRasterFiles={handleShowRasterFiles}
        handleShowUploadAnnotation={handleShowUploadAnnotation}
        handleShowMarkedAnnotation={handleShowMarkedAnnotation}
        handleSelectedRiskCatTrees={handleSelectedRiskCatTrees}
        handleShowAllRiskTree={handleShowAllRiskTree}
      />
      {isShowRiskTable && <RiskAnalysisTable handleShowRiskTable={handleShowRiskTable} />}
      {project &&
        project.type === ProjectCategoryTypes.VegetationManagement &&
        !isEmpty(riskBuffer) &&
        !isEmpty(riskTransmissionLine) && <RiskPopup />}
      {isOpenAddEditMarkerModal && (
        <Modal
          centered
          className="add-polyline-modal"
          title="Add Annotation"
          closable={false}
          width={278}
          open={isOpenAddEditMarkerModal}
          footer={null}
        >
          {activeAnnotationsTab === CoordinateType.LineString && (
            <AddNotationPolylineForm
              isEdit={isEditPolyline}
              lineCoords={newPoliLineCoords}
              centroidPoint={centroidPoint}
              activeDataLayer={activeDataLayer}
              handleRemoveMarkerLine={handleRemoveMarkerLine}
              editMarkingData={editAnnotationMarking}
            />
          )}
          {activeAnnotationsTab === CoordinateType.Polygon && (
            <AddNotationPolygonForm
              isEdit={isEditPolygon}
              polygonCoords={newPolygonCoords}
              centroidPoint={centroidPoint}
              activeDataLayer={activeDataLayer}
              handleRemoveMarkerPolygon={handleRemoveMarkerPolygon}
              editMarkingData={editAnnotationMarking}
            />
          )}
          {activeAnnotationsTab === CoordinateType.Point && (
            <AddNotationPointForm
              isEdit={isEditPoint}
              pointLatLng={pointLatLng}
              handleRemoveMarkerPoint={handleRemoveMarkerPoint}
              activeDataLayer={activeDataLayer}
              editMarkingData={editAnnotationMarking}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default memo(ProjectMapContainer);
