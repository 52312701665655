import { useState } from 'react';
import { Collapse, Typography } from 'antd';
import { includes, isEmpty } from 'lodash';
import LineIcon from 'assets/svg/LineIcon';
import LocationIcon from 'assets/svg/LocationIcon';
import PolygonIcon from 'assets/svg/PolygonIcon';
import { white } from 'constants/color.constant';
import SwitchButtonv2 from 'components/core/SwitchButtonv2';
import AnnotationsPointCard from 'components/core/AnnotationsPointCard';
import AnnotationsPolygonCard from 'components/core/AnnotationsPolygonCard';
import AnnotationsPolylineCard from 'components/core/AnnotationsPolylineCard';
import UploadedAnnotationCard from 'components/core/UploadedAnnotationCard';
import { AddedAnnotation, UploadedAnnotation } from 'store/annotations/types';
import { annotationStyles } from 'styles/projectView.styles';
import { CoordinateType } from 'types/common.types';

const { Panel } = Collapse;
const { Text } = Typography;

interface AnnotationsTabProps {
  selectedMarkings: number[];
  selectedUploadMarkings: number[];
  addedAnnotations: AddedAnnotation[] | [];
  uploadedAnnotations: UploadedAnnotation[] | [];
  handleShowMarkings: (data: AddedAnnotation) => void;
  handleShowUploadedMarkings: (data: number) => void;
  handleShowUploadAnnotation: (data: boolean) => void;
  handleShowMarkedAnnotation: (data: boolean) => void;
}

const AnnotationsTab = ({
  addedAnnotations,
  selectedMarkings,
  selectedUploadMarkings,
  uploadedAnnotations,
  handleShowMarkings,
  handleShowUploadedMarkings,
  handleShowUploadAnnotation,
  handleShowMarkedAnnotation
}: AnnotationsTabProps) => {
  const [activePanel, setActivePanel] = useState<string | string[]>(['1', '2']);

  const handleCollapsePanel = (activeTab: string | string[]) => setActivePanel(activeTab);
  const handeOnDisableEnableUploadAnnotation = (isCheck: boolean) =>
    handleShowUploadAnnotation(isCheck);
  const handeOnDisableEnableMarkedAnnotation = (isCheck: boolean) =>
    handleShowMarkedAnnotation(isCheck);

  return (
    <div className="annotations-tab">
      <Collapse
        collapsible="icon"
        bordered={false}
        onChange={handleCollapsePanel}
        activeKey={activePanel}
        expandIconPosition="end"
        ghost
      >
        <Panel
          className="annotations-panel upload-panel"
          header={
            <>
              <Text>Uploaded</Text>
              <SwitchButtonv2
                isChecked={!isEmpty(selectedUploadMarkings)}
                isDisabled={isEmpty(uploadedAnnotations)}
                handleOnChange={handeOnDisableEnableUploadAnnotation}
                classname="layer-switch"
              />
            </>
          }
          key="1"
        >
          {uploadedAnnotations && uploadedAnnotations.length > 0 ? (
            uploadedAnnotations.map((anotation: UploadedAnnotation) => {
              if (
                anotation.geometry.type === CoordinateType.Point ||
                anotation.geometry.type === CoordinateType.MultiPoint
              ) {
                return (
                  <UploadedAnnotationCard
                    key={anotation.id}
                    icon={<LocationIcon color={white} />}
                    annotation={anotation}
                    selectedUploadMarkings={selectedUploadMarkings}
                    handleShowUploadedMarkings={handleShowUploadedMarkings}
                  />
                );
              }
              if (
                anotation.geometry.type === CoordinateType.LineString ||
                anotation.geometry.type === CoordinateType.MultilineString
              ) {
                return (
                  <UploadedAnnotationCard
                    key={anotation.id}
                    icon={<LineIcon />}
                    annotation={anotation}
                    selectedUploadMarkings={selectedUploadMarkings}
                    handleShowUploadedMarkings={handleShowUploadedMarkings}
                  />
                );
              }
              if (
                anotation.geometry.type === CoordinateType.Polygon ||
                anotation.geometry.type === CoordinateType.MultiPolygon
              ) {
                return (
                  <UploadedAnnotationCard
                    key={anotation.id}
                    icon={<PolygonIcon />}
                    annotation={anotation}
                    selectedUploadMarkings={selectedUploadMarkings}
                    handleShowUploadedMarkings={handleShowUploadedMarkings}
                  />
                );
              }
              return true;
            })
          ) : (
            <Text style={annotationStyles.annotationLabel}>No uploaded markings</Text>
          )}
        </Panel>
        <Panel
          className={`annotations-panel${!includes(activePanel, '1') ? ' added-panel' : ''}`}
          header={
            <>
              <Text>Marked</Text>
              <SwitchButtonv2
                isChecked={!isEmpty(selectedMarkings)}
                isDisabled={isEmpty(addedAnnotations)}
                handleOnChange={handeOnDisableEnableMarkedAnnotation}
                classname="layer-switch"
              />
            </>
          }
          key="2"
        >
          {addedAnnotations && addedAnnotations.length > 0 ? (
            addedAnnotations.map((anotation: AddedAnnotation) => {
              if (anotation.geometry.type === CoordinateType.Point) {
                return (
                  <AnnotationsPointCard
                    key={anotation.id}
                    anotation={anotation}
                    icon={<LocationIcon color={white} />}
                    selectedMarkings={selectedMarkings}
                    handleShowMarkings={handleShowMarkings}
                  />
                );
              }
              if (anotation.geometry.type === CoordinateType.LineString) {
                return (
                  <AnnotationsPolylineCard
                    key={anotation.id}
                    icon={<LineIcon />}
                    anotation={anotation}
                    selectedMarkings={selectedMarkings}
                    handleShowMarkings={handleShowMarkings}
                  />
                );
              }
              if (anotation.geometry.type === CoordinateType.Polygon) {
                return (
                  <AnnotationsPolygonCard
                    key={anotation.id}
                    icon={<PolygonIcon />}
                    anotation={anotation}
                    selectedMarkings={selectedMarkings}
                    handleShowMarkings={handleShowMarkings}
                  />
                );
              }
              return true;
            })
          ) : (
            <Text style={annotationStyles.annotationLabel}>No added markings</Text>
          )}
        </Panel>
      </Collapse>
    </div>
  );
};

export default AnnotationsTab;
