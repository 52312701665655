import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cropper from 'react-easy-crop';
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Typography,
  Upload,
  UploadProps
} from 'antd';
import FileIcon from 'assets/svg/FileIcon.svg';
import LeftArrowIcon from 'assets/svg/LeftArrowIcon';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { routes as routeName } from 'constants/pageRoutes.constants';
import RequiredTitle from 'components/core/RequiredTitle';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { editOrganization, getOrganization } from 'store/organizations/actions';
import { EditOrganizationPayload } from 'store/organizations/types';
import { orgStyles } from 'styles/organizations.styles';
import getCroppedImg, { dataURLtoFile } from 'utils/cropImage';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const { Text } = Typography;

const EditOrganization = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // eslint-disable-next-line
  const { isLoadingAddOrganization, selectedOrganization } = useAppSelector(
    (state) => state.organizations
  );
  const [form] = Form.useForm();
  const [isOpenCropModal, setOpenCropModal] = useState<boolean>(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [file, setFile] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [croppedImage, setCroppedImage] = useState<string>('');

  useEffect(() => {
    if (selectedOrganization) {
      form.setFieldsValue({
        OrgName: selectedOrganization.name
      });
      setCroppedImage(selectedOrganization.logo_url);
    }
  }, []);

  useEffect(() => {
    if (selectedOrganization) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getOrganization(+selectedOrganization.id, token));
      });
    }
  }, []);

  const props: UploadProps = {
    maxCount: 1,
    beforeUpload: (file: File) => {
      setFile(URL.createObjectURL(file));
      setOpenCropModal(true);
      return false;
    },
    showUploadList: false
  };

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCroppedImage = useCallback(async () => {
    const croppedImage: any = await getCroppedImg(file, croppedAreaPixels);
    const cropFile = await dataURLtoFile(croppedImage, selectedOrganization.id.toString());

    setSelectedFile(cropFile);
    setCroppedImage(croppedImage);
    setOpenCropModal(false);
  }, [croppedAreaPixels]);

  const handleCancelCropped = () => {
    setOpenCropModal(false);
    setFile('');
    setSelectedFile(undefined);
    setCroppedImage('');
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  const handleSkip = () => {
    form.resetFields();
    setFile('');
    setSelectedFile(undefined);
    setCroppedImage('');
    setOpenCropModal(false);
    navigate(routeName.Organization, { replace: true });
  };

  const handleAddOrganization = () => {
    form.submit();
  };

  const handleCallback = () => {
    form.resetFields();
    navigate(routeName.Organization, { replace: true });
  };

  const handleSubmitAddOrganization = (values: EditOrganizationPayload) => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(
        editOrganization(
          values.OrgName,
          +selectedOrganization.id,
          token,
          handleCallback,
          selectedFile
        )
      );
    });
  };
  const handleNavigate = () => {
    navigate(routeName.Organization);
  };

  return (
    <>
      <div className="main-add-edit-org">
        <Row className="org-header" justify="space-between">
          <Col span={24} className="org-title flex">
            <Button
              icon={<LeftArrowIcon color="black" />}
              type="text"
              shape="circle"
              className="back-icon"
              onClick={handleNavigate}
            />
            <Text style={{ marginLeft: '17.5px', fontSize: '22px' }}>Edit Organization</Text>
          </Col>
          <Col span={24} className="main-org-list">
            <Row className="main-add-org-content">
              <Col className="org-detail-form-div">
                <Form className="add-org-form" form={form} onFinish={handleSubmitAddOrganization}>
                  <RequiredTitle
                    title="Name of the Organization"
                    isRequired
                    style={{ marginBottom: '18px' }}
                  />
                  <Form.Item
                    className="input-item"
                    name="OrgName"
                    rules={[{ required: true, message: 'Enter name of organization' }]}
                  >
                    <Input className="form-input-text" placeholder="Enter name of organization" />
                  </Form.Item>
                  <div className="input-item">
                    <RequiredTitle style={{ marginBottom: '18px' }} title="Name of Client User" />
                    <Row justify="space-between">
                      <Col className="user-name-div">
                        <Form.Item style={{ marginBottom: 0 }}>
                          <Input
                            readOnly
                            className="form-input-text"
                            placeholder="Enter first name"
                          />
                        </Form.Item>
                      </Col>
                      <Col className="user-name-div">
                        <Form.Item style={{ marginBottom: 0 }}>
                          <Input
                            readOnly
                            className="form-input-text"
                            placeholder="Enter last name"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <RequiredTitle
                    style={{ marginBottom: '18px' }}
                    title="Email address of Client User"
                  />
                  <Form.Item className="input-item">
                    <Input
                      readOnly
                      className="form-input-text"
                      placeholder="Enter email address of admin"
                    />
                  </Form.Item>
                </Form>
                <div className="skip-btn-content">
                  <Button type="ghost" onClick={handleSkip}>
                    Cancel
                  </Button>
                </div>
              </Col>
              <Divider type="vertical" style={orgStyles.detailDivider} />
              <Col className="org-detail-img-div">
                <RequiredTitle className="org-logo-title" title="Upload Organization Logo" />
                <div className="org-logo-img">
                  {croppedImage ? (
                    <>
                      <Image
                        style={{ height: '100%' }}
                        src={croppedImage}
                        alt="logo"
                        preview={false}
                      />
                      <div style={orgStyles.uploadImage}>
                        <Upload
                          {...props}
                          className="input-image"
                          accept="image/png, image/svg+xml, image/jpeg, image/jpg"
                        >
                          <Button
                            className="upload-image-btn"
                            style={{ opacity: 0 }}
                            icon={
                              <Image
                                width={30}
                                src={FileIcon}
                                alt="FileIcon"
                                preview={false}
                                style={{ opacity: 0 }}
                              />
                            }
                          />
                        </Upload>
                      </div>
                    </>
                  ) : (
                    <Upload
                      {...props}
                      className="input-image"
                      accept="image/png, image/svg+xml, image/jpeg, image/jpg"
                    >
                      <Button
                        className="upload-image-btn"
                        icon={<Image width={30} src={FileIcon} alt="FileIcon" preview={false} />}
                      />
                    </Upload>
                  )}
                </div>
                <div className="submit-btn-content">
                  <Button
                    loading={isLoadingAddOrganization}
                    onClick={handleAddOrganization}
                    type="primary"
                  >
                    Save Changes
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Modal
        key={1}
        open={isOpenCropModal}
        centered
        width={700}
        title="Crop Picture"
        closable
        className="crop-image-modal"
        footer={[
          <input
            key={22}
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e: any) => {
              setZoom(e.target.value);
            }}
            className="zoom-range"
          />,
          <div className="crop-modal-btn" key={33}>
            <Button type="ghost" onClick={handleCancelCropped}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleCroppedImage}>
              Save
            </Button>
          </div>
        ]}
      >
        <div className="cropper-content" key={11}>
          <div className="crop-container">
            <Cropper
              image={file}
              crop={crop}
              zoom={zoom}
              maxZoom={3}
              aspect={16 / 9}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditOrganization;
