import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Typography, Image } from 'antd';
import { Tab, Tabs } from 'react-tabs-scrollable';
import { isEmpty, nth } from 'lodash';
import moment from 'moment';
import { routes } from 'constants/pageRoutes.constants';
import { ACCESS_TOKEN } from 'constants/common.constant';
import DownloadIcon from 'assets/svg/DownloadIcon';
import SatsureSkies from 'assets/svg/SatsureLogoWhite.svg';
import SplitIcon from 'assets/svg/SplitIcon';
import LeftIcon from 'assets/svg/LeftIcon';
import RightIcon from 'assets/svg/RightIcon';
import { white } from 'constants/color.constant';
import DownloadDataDrawer from 'components/Modals/DownloadDataDrawer';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { setProject, setSelectedProject } from 'store/projects';
import { clearRepositoryFiles } from 'store/repositoryfile';
import { DataLayerType } from 'store/repositoryfile/types';
import { clearVectorfile } from 'store/vectorfile';
import { getRiskanalysisdata } from 'store/riskanalysis/actions';
import { Project, ProjectCategoryTypes } from 'store/projects/types';
import { clearRiskAnalysis } from 'store/riskanalysis';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const { Text } = Typography;

interface ProjectViewHeaderVegIntlProps {
  isShowPopup: boolean;
  dataLayers: DataLayerType[] | [];
  handleActiveLayer: Function;
  isShowRiskTable: boolean;
  project: Project;
  handleProjectCompareView: () => void;
  handleHideSliderPopup: (data: boolean) => void;
}

const ProjectViewHeaderVegIntl = ({
  isShowPopup,
  dataLayers,
  project,
  handleActiveLayer,
  handleProjectCompareView,
  handleHideSliderPopup,
  isShowRiskTable
}: ProjectViewHeaderVegIntlProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const [isDownloadModal, setDownloadModal] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);

  // useEffect(() => {
  //   const data: DataLayerType | undefined = nth(dataLayers, activeTab);
  //   if (isEmpty(data)) return;
  //   if (handleActiveLayer) handleActiveLayer({ id: data.id, date: data.date });
  // }, [activeTab]);

  const handleonTabClick = (e: any, index: number) => {
    setActiveTab(index);
    const data: DataLayerType | undefined = nth(dataLayers, index);

    if (isEmpty(data)) return;
    if (handleActiveLayer) handleActiveLayer({ id: data.id, date: data.date });
    getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
      if (
        project.type === ProjectCategoryTypes.VegetationManagementINTL ||
        project.type === ProjectCategoryTypes.VegetationManagementLite
      ) {
        dispatch(getRiskanalysisdata(+selectedOrganization.id, +project.id, data.id, reToken));
      }
    });
  };

  let rigtht = '145px';
  if (dataLayers.length === 1) {
    rigtht = '14px';
  } else if (dataLayers.length === 2) {
    rigtht = '75px';
  }

  const handleBackNavigate = () => {
    dispatch(setProject(null));
    dispatch(setSelectedProject(null));
    dispatch(clearRiskAnalysis());
    dispatch(clearRepositoryFiles());
    dispatch(clearVectorfile());
    navigate(`${routes.ProjectList}?organization_id=${selectedOrganization?.id}`, {
      replace: true
    });
  };

  const handleCancelDownload = () => {
    setDownloadModal(false);
  };

  const handleShowDownloadModal = () => {
    setDownloadModal(true);
  };

  return (
    <>
      <Row className="project-view-header projectview-subheader">
        <Col span={8} className="home-back">
          <div onClick={handleBackNavigate}>
            <Image className="skies-logo" src={SatsureSkies} alt="SatsureSkies" preview={false} />
          </div>
          <div className="vertical-line" />
          <Text>DISCOVER</Text>
        </Col>

        <Col span={6} className={`${!isShowRiskTable ? 'show-slider' : 'hide-slider'}`}>
          <Tabs
            activeTab={activeTab}
            onTabClick={handleonTabClick}
            hideNavBtnsOnMobile={false}
            tabsScrollAmount={1}
            tabsUpperContainerClassName={`layer-tab-container ${
              dataLayers.length <= 2 ? 'center-layer' : ''
            }`}
            tabsContainerClassName="layer-tablist-container"
            leftNavBtnClassName={`${
              activeTab === 0 ? 'left-nav-btn disable-nav-btn' : 'left-nav-btn'
            }`}
            rightNavBtnClassName={`${
              activeTab === dataLayers.length - 1
                ? 'right-nav-btn disable-nav-btn'
                : 'right-nav-btn'
            }`}
            navBtnClassName="nav-btns"
            navBtnAs="div"
            rightBtnIcon={<RightIcon color={white} />}
            leftBtnIcon={<LeftIcon color={white} />}
            navBtnContainerClassName="nav-btn-main"
          >
            {dataLayers.map((layer, index) => (
              <Tab key={index} className="layer-btn">
                <Text style={{ display: 'block' }}>{moment(layer.date).format('D MMM')}</Text>
                <Text>{moment(layer.date).format('YYYY')}</Text>
              </Tab>
            ))}
          </Tabs>
        </Col>
        {isShowPopup && (
          <div className="add-annts-tooltip" style={{ marginRight: rigtht }}>
            <div className="arrow-up" />
            <div className="tooltip-content">
              <Text>Just to clarify, You are marking for the above date.</Text>
              <div>
                <Button type="link" onClick={() => handleHideSliderPopup(false)}>
                  Okay
                </Button>
              </div>
            </div>
          </div>
        )}
        <Col span={8} className={`${!isShowRiskTable ? 'show-slider' : 'hide-slider'}`}>
          {project?.type === ProjectCategoryTypes.VegetationManagementINTL && (
            <div onClick={handleProjectCompareView}>
              <SplitIcon width={33} />
            </div>
          )}
          <div onClick={handleShowDownloadModal}>
            <DownloadIcon />
          </div>
        </Col>
      </Row>
      <DownloadDataDrawer
        isOpen={isDownloadModal}
        dataLayers={dataLayers}
        handleCancelDownload={handleCancelDownload}
      />
    </>
  );
};

export default ProjectViewHeaderVegIntl;
