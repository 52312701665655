import { Switch } from 'antd';

interface SwitchButtonv1Props {
  isDisabled: boolean;
  handleOnChange: any;
  isDefaultChecked?: boolean;
  classname?: string;
}

const SwitchButtonv1 = ({
  isDisabled,
  handleOnChange,
  isDefaultChecked,
  classname
}: SwitchButtonv1Props) => {
  return (
    <Switch
      size="small"
      defaultChecked={isDefaultChecked}
      disabled={isDisabled}
      className={classname}
      onChange={handleOnChange}
    />
  );
};
SwitchButtonv1.defaultProps = {
  classname: '',
  isDefaultChecked: false
};

export default SwitchButtonv1;
