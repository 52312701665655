import { createSlice } from '@reduxjs/toolkit';
import { ProjectState } from './types';

const initialState: ProjectState = {
  isLoadingProjects: false,
  projects: [],
  project: null,
  isAddProjectRequesting: false,
  selectedProject: null,
  isRequestProject: false,
  isAddProjectMemberRequest: false,
  isAvailableProjectMemberRequesting: false,
  projectMembers: [],
  isRequestingRemoveMemberFromProject: false,
  isRequestingRemoveMemberFromOrganization: false,
  isRequestingCloseProject: false,
  isRequestingProjectType: false,
  projectTypes: []
};

export const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setRequestingProjects: (state, action) => {
      state.isLoadingProjects = action.payload;
    },
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setAddProjectRequesting: (state, action) => {
      state.isAddProjectRequesting = action.payload;
    },
    setSelectedProject: (state, action) => {
      state.selectedProject = action.payload;
    },
    setRequestProject: (state, action) => {
      state.isRequestProject = action.payload;
    },
    setProject: (state, action) => {
      state.project = action.payload;
    },
    setProjectMemberRequest: (state, action) => {
      state.isAddProjectMemberRequest = action.payload;
    },
    setAvailableProjectMemberRequesting: (state, action) => {
      state.isAvailableProjectMemberRequesting = action.payload;
    },
    setAvailableProjectMembers: (state, action) => {
      state.projectMembers = action.payload;
    },
    setRequestProjectType: (state, action) => {
      state.isRequestingProjectType = action.payload;
    },
    setProjectType: (state, action) => {
      state.projectTypes = action.payload;
    },
    setRequestingRemoveMemberFromProject: (state, action) => {
      state.isRequestingRemoveMemberFromProject = action.payload;
    },
    setRequestingRemoveMemberFromOrganization: (state, action) => {
      state.isRequestingRemoveMemberFromOrganization = action.payload;
    }
  }
});
