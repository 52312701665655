import { IconProps } from 'types/common.types';

const IdIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.875 2.375C0.875 1.87772 1.07254 1.40081 1.42417 1.04917C1.77581 0.697544 2.25272 0.5 2.75 0.5H10.25C10.7473 0.5 11.2242 0.697544 11.5758 1.04917C11.9275 1.40081 12.125 1.87772 12.125 2.375V8.625C12.125 9.12228 11.9275 9.59919 11.5758 9.95083C11.2242 10.3025 10.7473 10.5 10.25 10.5H2.75C2.25272 10.5 1.77581 10.3025 1.42417 9.95083C1.07254 9.59919 0.875 9.12228 0.875 8.625V2.375Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.375 3H9.625M8.375 5.5H9.625M3.375 8H9.625M3.375 4.25C3.375 4.58152 3.5067 4.89946 3.74112 5.13388C3.97554 5.3683 4.29348 5.5 4.625 5.5C4.95652 5.5 5.27446 5.3683 5.50888 5.13388C5.7433 4.89946 5.875 4.58152 5.875 4.25C5.875 3.91848 5.7433 3.60054 5.50888 3.36612C5.27446 3.1317 4.95652 3 4.625 3C4.29348 3 3.97554 3.1317 3.74112 3.36612C3.5067 3.60054 3.375 3.91848 3.375 4.25Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
IdIcon.defaultProps = {
  color: '#C7C7CC',
  height: 11,
  width: 13
};
export default IdIcon;
