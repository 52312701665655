import { Drawer, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { isEmpty } from 'lodash';
import * as XLSX from 'xlsx';
import CloseIcon from 'assets/svg/CloseIcon';
import DownloadIcon from 'assets/svg/DownloadIcon';
import { blueshade } from 'constants/color.constant';
import { useAppSelector } from 'hooks/useReduxHook';
import { TransmissionSegmentType } from 'store/riskanalysis/types';
import { CoordinateType } from 'types/common.types';
import { getCenterPosition, getCenterPositionPolygon } from 'utils/xml';
import './styles.less';

const { Text } = Typography;

interface RiskAnalysisTableProps {
  handleShowRiskTable: (data: boolean) => void;
}

const RiskAnalysisTable = ({ handleShowRiskTable }: RiskAnalysisTableProps) => {
  const { riskBuffer, riskTransmissionLine, riskTransmissionSegments } = useAppSelector(
    (state) => state.riskanalysis
  );

  const riskPopupdata: any = [
    {
      'Route Length':
        riskTransmissionLine && riskTransmissionLine.length
          ? `${(riskTransmissionLine.length / 1000).toFixed(2)}km`
          : '0 km',
      'Total No. of Towers': riskTransmissionLine ? riskTransmissionLine.total_towers : 0,
      'Corridor Width': riskBuffer ? `${riskBuffer.buffer_width}m` : '0 m'
    }
  ];

  const columns: ColumnsType<TransmissionSegmentType> = [
    {
      title: 'Corridor Segments',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Location of Segments',
      dataIndex: 'geometry',
      key: 'latlng',
      width: 0,
      render: (record) => {
        let centerLatLng = null;
        if (record.type === CoordinateType.MultiPolygon) {
          centerLatLng = getCenterPosition(record.coordinates);
        } else if (record.type === CoordinateType.Polygon) {
          centerLatLng = getCenterPositionPolygon(record.coordinates);
        }

        return !isEmpty(centerLatLng) ? (
          <Text style={{ color: 'white' }}>
            <Text style={{ color: 'white', display: 'block' }}>{centerLatLng.lat.toFixed(6)},</Text>

            <Text style={{ color: 'white', display: 'block' }}>{centerLatLng.lng.toFixed(6)}</Text>
          </Text>
        ) : (
          <Text style={{ color: 'white' }}>-</Text>
        );
      }
    },
    {
      title: 'No. High Risk Trees',
      dataIndex: 'total_high_risk_trees',
      key: 'total_high_risk_trees'
    },
    {
      title: 'No. Medium Risk Trees',
      dataIndex: 'total_med_risk_trees',
      key: 'total_med_risk_trees'
    },
    {
      title: 'No. Low Risk Trees',
      dataIndex: 'total_low_risk_trees',
      key: 'total_low_risk_trees'
    },
    {
      title: 'Average Health of Trees',
      dataIndex: 'averagehealth',
      key: 'averagehealth',
      render: () => {
        return <Text style={{ color: `${blueshade}` }}>N / A</Text>;
      }
    },
    {
      title: 'Vegetation Score',
      dataIndex: 'vegetationscore',
      key: 'vegetationscore',
      render: () => {
        return <Text style={{ color: `${blueshade}` }}>-</Text>;
      }
    },
    {
      title: '% Completion of Pruning',
      dataIndex: 'completionpruning',
      key: 'completionpruning',
      render: () => {
        return <Text style={{ color: `${blueshade}` }}>-</Text>;
      }
    },
    {
      title: 'Pruning Status',
      dataIndex: 'pruningstatus',
      key: 'pruningstatus',
      render: () => {
        return <Text style={{ color: `${blueshade}` }}>N / A</Text>;
      }
    },
    {
      title: 'Last Pruning Date',
      dataIndex: 'lastpruningdate',
      key: 'lastpruningdate',
      render: () => {
        return <Text style={{ color: `${blueshade}` }}>-</Text>;
      }
    }
  ];

  const handleCloseRiskTable = () => {
    handleShowRiskTable(false);
  };

  const handleDownloadRiskdata = () => {
    const fileExtension = '.xls';
    const fileName = 'risk-segments';
    const table1: any = [
      {
        A: 'Corridor Segments',
        B: 'Location of Segments',
        C: 'No. High Risk Trees',
        D: 'No. Medium Risk Trees',
        E: 'No. Low Risk Trees',
        F: 'Average Health of Tress',
        G: 'Vegetation Score',
        H: '% Completion of Pruning',
        I: 'Pruning Status',
        J: 'Last Pruning Date'
      }
    ];

    const table2: any = [
      {
        A: 'Route Length',
        B: 'Total No. of Towers',
        C: 'Corridor Width'
      }
    ];

    const segments = [...riskTransmissionSegments];

    segments.forEach((data): any => {
      let centerLatLng = null;
      if (data.geometry.type === CoordinateType.MultiPolygon) {
        centerLatLng = getCenterPosition(data.geometry.coordinates);
      } else if (data.geometry.type === CoordinateType.Polygon) {
        centerLatLng = getCenterPositionPolygon(data.geometry.coordinates);
      }
      table1.push({
        A: data.name,
        B: !isEmpty(centerLatLng)
          ? [centerLatLng.lat.toFixed(6), centerLatLng.lng.toFixed(6)].join(',')
          : '-',
        C: data.total_high_risk_trees,
        D: data.total_med_risk_trees,
        E: data.total_low_risk_trees,
        F: 'N / A',
        G: '-',
        H: '-',
        I: 'N / A',
        J: '-'
      });
    });

    const riskDetailData = [...riskPopupdata];

    riskDetailData.forEach((data): any => {
      return table2.push({
        A: data['Route Length'],
        B: data['Total No. of Towers'],
        C: data['Corridor Width']
      });
    });

    const finalData = table2
      .concat([''])
      .concat([{ A: 'All Risk Segmnets' }])
      .concat([''])
      .concat(table1);

    const wb1 = XLSX.utils.book_new();

    const ws1 = XLSX.utils.json_to_sheet(finalData, { skipHeader: true });
    XLSX.utils.book_append_sheet(wb1, ws1, 'Transmission Line');

    XLSX.writeFile(wb1, fileName + fileExtension);
  };

  return (
    <Drawer
      title="Risk Analysis"
      placement="left"
      open
      width="100%"
      closable={false}
      className="risk-drawer"
      extra={
        <div className="table-main-header">
          <div>Risk Analysis</div>
          <div>
            <Text>
              Route Length :{' '}
              {riskTransmissionLine && riskTransmissionLine.length
                ? (riskTransmissionLine.length / 1000).toFixed(2)
                : 0}{' '}
              km
            </Text>
            <Text>Total No. of Towers : {riskTransmissionLine?.total_towers || 0}</Text>
            <Text>Corridor Width : {riskBuffer?.buffer_width || 0} m</Text>
          </div>
          <div>
            <DownloadIcon height={14} width={13} onClick={handleDownloadRiskdata} />
            <CloseIcon onClick={() => handleCloseRiskTable()} height={10} width={10} />
          </div>
        </div>
      }
    >
      <div className="risk-table-main">
        <div className="main-risk-table">
          <Table
            className="risk-analysis-table"
            scroll={{ y: 'calc(100vh - 215px)' }}
            columns={columns}
            dataSource={riskTransmissionSegments || []}
            pagination={false}
            rowKey={(record) => record.id}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default RiskAnalysisTable;
