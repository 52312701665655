import { isEmpty } from 'lodash';
import { Typography } from 'antd';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { BiChevronRight } from 'react-icons/bi';
import TableIcon from 'assets/svg/TableIcon';
import { white } from 'constants/color.constant';
import RiskSegmentCard from 'components/core/RiskSegmentCard';
import RiskSegmentDataCard from 'components/core/RiskSegmentDataCard';
import { useAppSelector } from 'hooks/useReduxHook';
import { TransmissionSegmentType, TreeType } from 'store/riskanalysis/types';
import RiskLegend from './RiskLegend';

const { Text } = Typography;

interface RiskAnalysisProps {
  handleShowRiskTable: (data: boolean) => void;
  selectedRiskData: TransmissionSegmentType | null;
  handleShowRiskData: (data: TransmissionSegmentType | null, index: number) => void;
  handleShowAllRiskTree: (segment: TransmissionSegmentType | null) => void;
  handleSelectedRiskCatTrees: (trees: TreeType[] | []) => void;
}
const RiskAnalysis = ({
  selectedRiskData,
  handleShowRiskTable,
  handleShowRiskData,
  handleSelectedRiskCatTrees,
  handleShowAllRiskTree
}: RiskAnalysisProps) => {
  const { isRequestingRiskanalysis, riskTransmissionSegments } = useAppSelector(
    (state) => state.riskanalysis
  );

  const handeleAllRisk = () => {
    handleShowRiskTable(true);
  };

  const handleClickShowRiskData = (data: TransmissionSegmentType | null, index: number) => {
    handleShowRiskData(data, index);
  };

  return (
    <div className="riskanalysis-tab">
      <div className="risk-div" onClick={handeleAllRisk}>
        <div style={{ width: '100%' }}>
          <TableIcon />
          <Text className="risk">All Risks</Text>
        </div>
        <div>
          <BiChevronRight size={20} />
        </div>
      </div>

      {isEmpty(selectedRiskData) ? (
        <div className="risk-segment-main">
          {isRequestingRiskanalysis && (
            <div className="flex-box-center">
              <AiOutlineLoading3Quarters color={white} className="loading-icon" />
            </div>
          )}
          {!isRequestingRiskanalysis &&
            !isEmpty(riskTransmissionSegments) &&
            riskTransmissionSegments.map((data: TransmissionSegmentType, index: number) => {
              return (
                <RiskSegmentCard
                  key={data.id}
                  riskData={data}
                  segmentIndex={index}
                  handleClickShowRiskData={handleClickShowRiskData}
                />
              );
            })}
        </div>
      ) : (
        <div className="risk-segment-main risk-segment-data-card">
          <RiskSegmentDataCard
            selectedRiskData={selectedRiskData}
            handleSelectedRiskCatTrees={handleSelectedRiskCatTrees}
            handleShowAllRiskTree={handleShowAllRiskTree}
          />
        </div>
      )}
      <div className="risk-legend">
        <div className="legend-title">
          <Text>LEGEND</Text>
        </div>
        <div className="main-risk-legend-content">
          <RiskLegend />
        </div>
      </div>
    </div>
  );
};

export default RiskAnalysis;
