import { createSlice } from '@reduxjs/toolkit';
import { AnnotationsState } from './types';

const initialState: AnnotationsState = {
  isUpoadedAnnotationRequesting: false,
  isAnnotationPointRequesting: false,
  isAddAnnotationPointRequesting: false,
  isRequestDeleteAnnotation: false,
  isRequestEditAnnotation: false,
  uploadedAnnotationPoints: [],
  addedAnnotationPoints: []
};

export const annotationSlice = createSlice({
  name: 'annotations',
  initialState,
  reducers: {
    setUpoadedAnnotationRequesting: (state, action) => {
      state.isUpoadedAnnotationRequesting = action.payload;
    },
    setUploadedAnnotationPoints: (state, action) => {
      state.uploadedAnnotationPoints = action.payload;
    },
    setAnnotationPointRequesting: (state, action) => {
      state.isAnnotationPointRequesting = action.payload;
    },
    setAddAnnotationPointRequesting: (state, action) => {
      state.isAddAnnotationPointRequesting = action.payload;
    },
    setAddedAnnotationPoints: (state, action) => {
      state.addedAnnotationPoints = action.payload;
    },
    setRequestDeleteAnnotation: (state, action) => {
      state.isRequestDeleteAnnotation = action.payload;
    },
    setRequestEditAnnotation: (state, action) => {
      state.isRequestEditAnnotation = action.payload;
    }
  }
});
