import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const DownloadWhiteIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.94839 0.708313C5.30372 0.708313 4.76595 1.20101 4.70965 1.84322C4.59195 3.18596 4.56216 4.53468 4.62038 5.88076C4.55011 5.88546 4.47984 5.89037 4.40958 5.8955L3.16833 5.98598C2.37496 6.04381 1.93145 6.92845 2.35977 7.59877C3.27325 9.02836 4.45029 10.2712 5.82816 11.261L6.32544 11.6182C6.72863 11.9078 7.27167 11.9078 7.67485 11.6182L8.17214 11.261C9.55 10.2712 10.727 9.02836 11.6405 7.59877C12.0688 6.92845 11.6253 6.04381 10.832 5.98598L9.59071 5.8955C9.52046 5.89037 9.45019 5.88546 9.37991 5.88076C9.43813 4.53468 9.40835 3.18595 9.29064 1.84321C9.23435 1.201 8.69658 0.708313 8.0519 0.708313H5.94839ZM5.90139 6.42892C5.80663 4.93957 5.82433 3.44512 5.95436 1.95831H8.04594C8.17597 3.44512 8.19367 4.93957 8.0989 6.42892C8.08828 6.59589 8.14505 6.76015 8.2565 6.88494C8.36795 7.00973 8.52478 7.08462 8.69189 7.09285C8.96134 7.10612 9.23068 7.12257 9.49983 7.14219L10.4013 7.2079C9.60169 8.38565 8.60045 9.4142 7.44286 10.2457L7.00015 10.5638L6.55744 10.2457C5.39985 9.4142 4.39861 8.38565 3.599 7.2079L4.50046 7.14219C4.76962 7.12257 5.03895 7.10612 5.3084 7.09285C5.47551 7.08461 5.63234 7.00973 5.74379 6.88494C5.85525 6.76015 5.91201 6.59589 5.90139 6.42892Z"
        fill={color}
      />
      <path
        d="M1.79175 12.1666C1.79175 11.8215 1.51193 11.5416 1.16675 11.5416C0.82157 11.5416 0.541748 11.8215 0.541748 12.1666V13.8333C0.541748 14.6387 1.19467 15.2916 2.00008 15.2916H12.0001C12.8055 15.2916 13.4584 14.6387 13.4584 13.8333V12.1666C13.4584 11.8215 13.1786 11.5416 12.8334 11.5416C12.4882 11.5416 12.2084 11.8215 12.2084 12.1666V13.8333C12.2084 13.9484 12.1151 14.0416 12.0001 14.0416H2.00008C1.88502 14.0416 1.79175 13.9484 1.79175 13.8333V12.1666Z"
        fill={color}
      />
    </svg>
  );
};

DownloadWhiteIcon.defaultProps = {
  color: primary,
  height: 16,
  width: 14,
  onClick: () => {}
};

export default DownloadWhiteIcon;
