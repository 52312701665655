import { useContext, useState } from 'react';
import moment from 'moment';
import { Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { toast } from 'react-toastify';
import DeleteIcon from 'assets/svg/DeleteIcon';
import DownloadIcon from 'assets/svg/DownloadWhiteIcon';
import LoaderIcon from 'assets/svg/LoaderIcon';
import EditFileIcon from 'assets/svg/EditFileIcon';
import { TimezoneContext } from 'context/createusecontext';
import { ACCESS_TOKEN, DateFormat2, TimeFormat } from 'constants/common.constant';
import EditRepofile from 'components/Modals/RepositoryFile/EditRepofile';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { DataLayerFileType, RepoFileType } from 'store/repositoryfile/types';
import {
  deleteRepoFile,
  getDownloadLULCFile,
  getDownloadRepoFile
} from 'store/repositoryfile/actions';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

interface FileRepositoryTableProps {
  activeLayerId: number | null;
  dataLayerFiles: DataLayerFileType[] | [];
}

const FileRepositoryTable = ({ activeLayerId, dataLayerFiles }: FileRepositoryTableProps) => {
  const timeZone = useContext(TimezoneContext);
  const dispatch = useAppDispatch();
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const { isRequestingDeleteRepoFile, isLoadingDataLayers, isRequestingDownloadRepoFile } =
    useAppSelector((state) => state.repositoryFiles);
  const [isEditFileModal, setEditFileModal] = useState<boolean>(false);
  const [selectedEditFile, setSelectedEditFile] = useState<any>(false);
  const [downloadFileId, setDownloadFileId] = useState<number | null>(null);

  const handleEditFile = (file: any) => {
    setSelectedEditFile(file);
    setEditFileModal(true);
  };
  const handleDownloadFile = (file: DataLayerFileType) => {
    if (!activeLayerId) {
      toast.error('Something went wrong');
      return;
    }
    setDownloadFileId(file.id);
    if (file.type === RepoFileType.Rasterfiles) {
      getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
        dispatch(
          getDownloadLULCFile(
            +selectedOrganization.id,
            +selectedProject.id,
            activeLayerId,
            file.id,
            reToken
          )
        );
      });
    } else {
      getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
        dispatch(
          getDownloadRepoFile(
            +selectedOrganization.id,
            +selectedProject.id,
            activeLayerId,
            file.id,
            reToken
          )
        );
      });
    }
  };

  const handleCallBackDeleteFile = (status: boolean) => {
    if (!status) {
      toast.error('Something went wrong');
    }
  };

  const handleConfirmDeleteFile = (fileId: number) => {
    if (!activeLayerId) {
      toast.error('Something went wrong');
      return;
    }
    getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
      dispatch(
        deleteRepoFile(
          +selectedOrganization.id,
          +selectedProject.id,
          activeLayerId,
          fileId,
          reToken,
          handleCallBackDeleteFile
        )
      );
    });
  };

  const handleCancelEditFile = (isCancel: boolean) => {
    setEditFileModal(isCancel);
  };

  const columns: ColumnsType<DataLayerFileType> = [
    {
      title: 'NAME OF FILE',
      dataIndex: 'name',
      key: 'name',
      width: '350px'
    },
    {
      title: 'FILE TYPE',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'CATEGORY',
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: 'UPDATED ON',
      dataIndex: 'updated_on',
      key: 'updated_on',
      width: '140px',
      render: (updatedon) => {
        return (
          <>
            <div className="update-on-content">
              {updatedon ? `${moment.utc(updatedon).local().format(DateFormat2)}` : ''}
            </div>
            <div className="update-on-content">
              {updatedon
                ? `${moment.utc(updatedon).local().format(TimeFormat)} ${timeZone?.timezone}`
                : '-'}
            </div>
          </>
        );
      }
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: '160px',
      render: (_, record) => (
        <div className="repo-action">
          <div onClick={() => handleEditFile(record)}>
            <EditFileIcon />
          </div>
          <div onClick={() => handleDownloadFile(record)}>
            {isRequestingDownloadRepoFile && downloadFileId === record.id ? (
              <LoaderIcon />
            ) : (
              <DownloadIcon />
            )}
          </div>
          <Popconfirm
            title="Are you sure to delete this file?"
            okButtonProps={{ loading: isRequestingDeleteRepoFile }}
            onConfirm={() => handleConfirmDeleteFile(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteIcon height={18} />
          </Popconfirm>
        </div>
      )
    }
  ];

  return (
    <>
      <div className="main-repo-table">
        <div className="repo-table">
          <Table
            loading={isLoadingDataLayers}
            className="main-table"
            scroll={{ y: 'calc(100vh - 410px)' }}
            dataSource={dataLayerFiles}
            columns={columns}
            pagination={false}
            rowKey={(data: DataLayerFileType) => `${data.type}-${data.id}`}
          />
        </div>
      </div>
      <EditRepofile
        open={isEditFileModal}
        handleCancelEditFile={handleCancelEditFile}
        repoFile={selectedEditFile}
        activeLayerId={activeLayerId}
      />
    </>
  );
};

export default FileRepositoryTable;
