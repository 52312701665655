import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const MapLayerIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.96183 0.800381C8.40257 0.50849 7.64474 0.503986 7.08101 0.795126C4.92021 1.91108 2.96031 3.1906 1.24283 4.60978C0.977244 4.82924 0.788761 5.14814 0.791977 5.52351C0.79518 5.89735 0.987498 6.21199 1.25147 6.42783C2.95469 7.82048 4.91619 9.10116 7.03743 10.2083C7.5967 10.5002 8.35453 10.5047 8.91826 10.2135C11.0791 9.09757 13.039 7.81805 14.7564 6.39888C15.022 6.17942 15.2105 5.86051 15.2073 5.48514C15.2041 5.11131 15.0118 4.79667 14.7478 4.58082C13.0446 3.18818 11.0831 1.9075 8.96183 0.800381ZM7.6546 1.90575C7.85431 1.80261 8.18239 1.80359 8.38347 1.90853C10.4079 2.96513 12.2733 4.18071 13.8893 5.49369C12.2636 6.82779 10.4038 8.03945 8.34467 9.1029C8.14495 9.20604 7.81687 9.20507 7.6158 9.10012C5.59135 8.04353 3.72601 6.82794 2.10999 5.51497C3.73566 4.18087 5.59545 2.96921 7.6546 1.90575Z"
        fill={color}
      />
      <path
        d="M15.6638 8.58185C15.8466 8.87465 15.7575 9.2602 15.4647 9.443L10.3753 12.6204C9.68468 13.0516 8.82784 13.2509 7.99956 13.251C7.17128 13.251 6.3144 13.0518 5.62369 12.6207L0.590078 9.47925C0.297249 9.29649 0.208015 8.91096 0.390768 8.61813C0.573522 8.3253 0.959058 8.23606 1.25189 8.41882L6.2855 11.5603C6.73895 11.8433 7.35495 12.001 7.99949 12.001C8.64403 12.0009 9.25996 11.8431 9.71331 11.5601L14.8027 8.38268C15.0955 8.19988 15.481 8.28905 15.6638 8.58185Z"
        fill={color}
      />
      <path
        d="M15.6638 11.7107C15.8466 12.0035 15.7575 12.3891 15.4647 12.5719L11.0573 15.3243C10.1759 15.8747 9.07345 16.1336 7.99952 16.1337C6.9256 16.1338 5.82305 15.8749 4.94157 15.3247L0.590014 12.6081C0.297207 12.4254 0.208019 12.0398 0.390808 11.747C0.573597 11.4542 0.959144 11.365 1.25195 11.5478L5.6035 14.2643C6.24768 14.6664 7.10929 14.8838 7.99945 14.8837C8.88962 14.8836 9.75114 14.6662 10.3952 14.264L14.8026 11.5117C15.0954 11.3288 15.481 11.4179 15.6638 11.7107Z"
        fill={color}
      />
    </svg>
  );
};
MapLayerIcon.defaultProps = {
  color: white,
  height: 17,
  width: 16
};

export default MapLayerIcon;
