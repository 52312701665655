import { memo, useMemo } from 'react';
import { Space, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { Polyline, Popup } from 'react-leaflet';
import { CoordinateType } from 'types/common.types';
import { RiskLinesType } from 'store/riskanalysis/types';
import { RiskLineColor } from 'constants/common.constant';

const { Text } = Typography;
interface RendorRiskLinesProps {
  risklineData: RiskLinesType[] | [];
}

const RendorRiskLines = ({ risklineData }: RendorRiskLinesProps) => {
  const allRisklineData = useMemo(() => {
    if (isEmpty(risklineData)) return null;

    // eslint-disable-next-line
    return risklineData.map((data: RiskLinesType, i: number) => {
      if (data.geometry.type === CoordinateType.MultilineString) {
        const coords: any = data.geometry.coordinates;

        const newLineCoords: any = [];
        coords.map((coord: any) => {
          const d = coord.map((latlng: any) => {
            return [latlng[1], latlng[0]];
          });
          return newLineCoords.push(d);
        });

        if (newLineCoords && newLineCoords.length === 0) return null;

        return (
          <Polyline
            key={`${data.geometry.type}-${i}-${data.Length}`}
            // @ts-ignore
            positions={newLineCoords}
            pathOptions={{
              color: RiskLineColor[data.risk_cat],
              weight: 4
            }}
            pane="tooltipPane"
          >
            <Popup className="annts-point-popup risk-line-popup" minWidth={232} maxHeight={226}>
              <Space direction="vertical">
                <div>
                  <Text className="annts-market-title">Risk Category</Text>
                  <Text className="annts-market-data">{data?.risk_cat || '-'}</Text>
                </div>
                <div>
                  <Text className="annts-market-title">Risk Length</Text>
                  <Text className="annts-market-data">
                    {data?.Length ? `${data?.Length.toFixed(3)} m` : '-'}
                  </Text>
                </div>
                <div>
                  <Text className="annts-market-title">Action Type</Text>
                  <Text className="annts-market-data">{data?.Work_Type || '-'}</Text>
                </div>
              </Space>
            </Popup>
          </Polyline>
        );
      }
      if (data.geometry.type === CoordinateType.LineString) {
        const coords: any = data.geometry.coordinates;
        const newLineCoords: any = [];
        coords.map((coord: any) => {
          const newLatlng: any = [coord[1], coord[0]];
          return newLineCoords.push(newLatlng);
        });
        if (newLineCoords && newLineCoords.length === 0) return null;

        return (
          <Polyline
            key={`${data.geometry.type}-${data.COD_SEG}`}
            // @ts-ignore
            positions={newLineCoords}
            pathOptions={{
              color: RiskLineColor[data.risk_cat],
              fillOpacity: 1,
              weight: 4
            }}
            pane="tooltipPane"
          >
            <Popup className="annts-point-popup risk-line-popup" minWidth={232} maxHeight={226}>
              <Space direction="vertical">
                <div>
                  <Text className="annts-market-title">Risk Category</Text>
                  <Text className="annts-market-data">{data?.risk_cat || '-'}</Text>
                </div>
                <div>
                  <Text className="annts-market-title">Risk Length</Text>
                  <Text className="annts-market-data">{data?.Length || '-'}</Text>
                </div>
                <div>
                  <Text className="annts-market-title">Action Type</Text>
                  <Text className="annts-market-data">{data?.Work_Type || '-'}</Text>
                </div>
              </Space>
            </Popup>
          </Polyline>
        );
      }
    });
  }, [risklineData]);

  // eslint-disable-next-line
  return <>{allRisklineData}</>;
};

export default memo(RendorRiskLines);
