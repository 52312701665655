import { chain, upperFirst } from 'lodash';
import { UserRole } from 'store/users/types';
import { decryptValue } from 'utils/decodeEncodeData';
import { RootState } from '..';
import { organizationSlice } from './slice';
import { OrganizationMember } from './types';

export const {
  setSelectedOrganization,
  setOrganizations,
  setRequestingOrganizations,
  setRequestingOrganization,
  setRequestingAddOrganization,
  setOrganizationMembers,
  setRequestingOrganizationMember,
  setNewRequestOrganizationMembers,
  setRequestingEnableDisableUser
} = organizationSlice.actions;
export default organizationSlice.reducer;

export const selectOrganizationMember = (state: RootState) => {
  const { orginsationMembers } = state.organizations;

  return orginsationMembers
    ?.filter((m: OrganizationMember) => m.role !== UserRole.Client)
    .map((member: OrganizationMember) => {
      if (member.first_name && member.last_name) {
        const firstname = upperFirst(decryptValue(member.first_name));
        const lastname = upperFirst(decryptValue(member.last_name));
        return {
          label: upperFirst(`${firstname} ${lastname}`),
          value: member.id
        };
      }

      return {
        label: decryptValue(member.email),
        value: member.id
      };
    });
};

export const getOrganizationTeamMembers = (state: RootState) => {
  const { orginsationMembers } = state.organizations;

  if (!orginsationMembers || orginsationMembers.length === 0) return [];
  const members = chain(orginsationMembers)
    .map((data: OrganizationMember) => {
      return {
        ...data,
        email: decryptValue(data.email),
        first_name: data.first_name ? upperFirst(decryptValue(data.first_name)) : '',
        last_name: data.last_name ? upperFirst(decryptValue(data.last_name)) : ''
      };
    })
    .orderBy(['role', 'first_name', 'email'], ['asc', 'asc', 'asc'])
    .value();

  return members;
};
