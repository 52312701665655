import { IconProps } from 'types/common.types';

const EyeCloseIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8315 0.831456C12.0145 0.648398 12.0145 0.351602 11.8315 0.168544C11.6484 -0.0145146 11.3516 -0.0145146 11.1685 0.168544L1.16854 10.1685C0.985485 10.3516 0.985485 10.6484 1.16854 10.8315C1.3516 11.0145 1.6484 11.0145 1.83146 10.8315L3.72834 8.93457C4.55198 9.31451 5.49491 9.5625 6.5 9.5625C8.13611 9.5625 9.6075 8.90538 10.6677 8.09165C11.1986 7.68426 11.6341 7.23179 11.9397 6.79145C12.2395 6.35949 12.4375 5.90529 12.4375 5.5C12.4375 5.09471 12.2395 4.64051 11.9397 4.20855C11.6341 3.76821 11.1986 3.31574 10.6677 2.90835C10.4986 2.77855 10.319 2.65273 10.13 2.53293L11.8315 0.831456ZM8.45544 4.20747L7.7692 4.89371C7.85705 5.07729 7.90625 5.2829 7.90625 5.5C7.90625 6.27665 7.27665 6.90625 6.5 6.90625C6.2829 6.90625 6.07729 6.85705 5.89371 6.7692L5.20747 7.45544C5.57799 7.70084 6.02231 7.84375 6.5 7.84375C7.79442 7.84375 8.84375 6.79442 8.84375 5.5C8.84375 5.02231 8.70084 4.57799 8.45544 4.20747Z"
        fill={color}
      />
      <path
        d="M6.86772 3.18493C6.95181 3.19818 7.03836 3.17373 7.09856 3.11353L8.17206 2.04003C8.30441 1.90768 8.24933 1.68311 8.06794 1.63695C7.56953 1.51011 7.04386 1.4375 6.5 1.4375C4.86389 1.4375 3.39251 2.09462 2.33226 2.90835C1.80145 3.31574 1.3659 3.76821 1.06031 4.20855C0.760538 4.64051 0.5625 5.09471 0.5625 5.5C0.5625 5.90529 0.760538 6.35949 1.06031 6.79145C1.308 7.14837 1.64108 7.51325 2.04106 7.85564C2.13791 7.93854 2.2818 7.93029 2.37195 7.84014L4.11353 6.09856C4.17373 6.03836 4.19818 5.95181 4.18493 5.86772C4.16605 5.74792 4.15625 5.6251 4.15625 5.5C4.15625 4.20558 5.20558 3.15625 6.5 3.15625C6.6251 3.15625 6.74792 3.16605 6.86772 3.18493Z"
        fill={color}
      />
    </svg>
  );
};
EyeCloseIcon.defaultProps = {
  color: '#F2F2F2',
  height: 11,
  width: 13,
  onClick: () => {}
};

export default EyeCloseIcon;
