import { createSlice } from '@reduxjs/toolkit';
import { RiskAnalysisState } from './types';

const initialState: RiskAnalysisState = {
  isRequestingRiskanalysis: false,
  riskTrees: [],
  riskBuffer: null,
  riskTowers: [],
  riskTransmissionLine: null,
  riskTransmissionSegments: [],
  riskLines: []
};

export const riskAnalysisSlice = createSlice({
  name: 'riskanalysis',
  initialState,
  reducers: {
    setRequestingRiskanalysis: (state, action) => {
      state.isRequestingRiskanalysis = action.payload;
    },
    setRiskTrees: (state, action) => {
      state.riskTrees = action.payload;
    },
    setRiskBuffer: (state, action) => {
      state.riskBuffer = action.payload;
    },
    setRiskTowers: (state, action) => {
      state.riskTowers = action.payload;
    },
    setRiskTransmissionLine: (state, action) => {
      state.riskTransmissionLine = action.payload;
    },
    setriskTransmissionSegments: (state, action) => {
      state.riskTransmissionSegments = action.payload;
    },
    setRiskLines: (state, action) => {
      state.riskLines = action.payload;
    },
    clearRiskAnalysis: () => {
      return initialState;
    }
  }
});
