import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const MapLocationIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 9C8.25 6.92893 9.92893 5.25 12 5.25C14.0711 5.25 15.75 6.92893 15.75 9C15.75 11.0711 14.0711 12.75 12 12.75C9.92893 12.75 8.25 11.0711 8.25 9ZM12 6.75C10.7574 6.75 9.75 7.75736 9.75 9C9.75 10.2426 10.7574 11.25 12 11.25C13.2426 11.25 14.25 10.2426 14.25 9C14.25 7.75736 13.2426 6.75 12 6.75Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.45641 8.12724C5.73017 4.80603 8.50555 2.25 11.838 2.25H12.162C15.4945 2.25 18.2698 4.80603 18.5436 8.12724C18.6903 9.90751 18.1404 11.6753 17.0097 13.0581L13.4149 17.4545C12.6836 18.3488 11.3164 18.3488 10.5851 17.4545L6.99032 13.0581C5.85958 11.6752 5.30967 9.90751 5.45641 8.12724ZM11.838 3.75C9.28621 3.75 7.16097 5.70726 6.95134 8.25046C6.83652 9.64343 7.2668 11.0266 8.15154 12.1086L11.7463 16.505C11.8774 16.6653 12.1226 16.6653 12.2537 16.505L15.8485 12.1086C16.7332 11.0266 17.1635 9.64343 17.0487 8.25046C16.839 5.70726 14.7138 3.75 12.162 3.75H11.838Z"
        fill={color}
      />
      <path
        d="M7.67082 16.3354C7.85606 15.9649 7.70589 15.5144 7.33541 15.3292C6.96493 15.1439 6.51442 15.2941 6.32918 15.6646L4.32918 19.6646C4.21293 19.8971 4.22536 20.1732 4.36201 20.3943C4.49867 20.6154 4.74007 20.75 5 20.75H19C19.2599 20.75 19.5013 20.6154 19.638 20.3943C19.7746 20.1732 19.7871 19.8971 19.6708 19.6646L17.6708 15.6646C17.4856 15.2941 17.0351 15.1439 16.6646 15.3292C16.2941 15.5144 16.1439 15.9649 16.3292 16.3354L17.7865 19.25H6.21353L7.67082 16.3354Z"
        fill={color}
      />
    </svg>
  );
};
MapLocationIcon.defaultProps = {
  color: primary,
  height: 24,
  width: 24
};

export default MapLocationIcon;
