import { useState } from 'react';
import { includes, isEmpty } from 'lodash';
import { Collapse, Typography } from 'antd';
import EyeCloseIcon from 'assets/svg/EyeCloseIcon';
import EyeOpenIcon from 'assets/svg/EyeOpenIcon';
import { lightGrey1 } from 'constants/color.constant';
import SwitchButtonv1 from 'components/core/SwitchButtonv1';
import { useAppSelector } from 'hooks/useReduxHook';
import { DataLayerFileType, LulcDataType } from 'store/repositoryfile/types';
import './styles.less';

const { Text } = Typography;
const { Panel } = Collapse;

interface LayerControlProps {
  vectorRepoFiles: Array<DataLayerFileType> | [];
  vectorLayerIds: number[];
  selectedLulcLayer: string[];
  lulcRepoFile: DataLayerFileType;
  lulcStyleLayers: LulcDataType[];
  handleVectorLayerIds: (id: number) => void;
  handleShowLULCLayerOnMap: (id: string) => void;
  handleShowVectorFiles: (data: boolean) => void;
  handleShowRasterFiles: (data: boolean) => void;
}

const LayerControl = ({
  vectorRepoFiles,
  vectorLayerIds,
  lulcRepoFile,
  selectedLulcLayer,
  lulcStyleLayers,
  handleVectorLayerIds,
  handleShowLULCLayerOnMap,
  handleShowVectorFiles,
  handleShowRasterFiles
}: LayerControlProps) => {
  const { isRequestingGeoserverLayerStyles } = useAppSelector((state) => state.repositoryFiles);
  const { isRequestingVectorFiles } = useAppSelector((state) => state.vectorfile);
  const [activePanel, setActivePanel] = useState<string | string[]>(['1', '2']);

  const handleShowVectorLayerOnMap = (fileId: number) => handleVectorLayerIds(fileId);
  const handleCollapsePanel = (activeTab: string | string[]) => setActivePanel(activeTab);
  const handleOnDisableEnableVectorFiles = (isCheck: boolean) => handleShowVectorFiles(isCheck);
  const handleOnDisableEnableRasterLayers = (isCheck: boolean) => handleShowRasterFiles(isCheck);

  return (
    <div className="annotations-tab">
      <Collapse
        onChange={handleCollapsePanel}
        activeKey={activePanel}
        className="vector-layer"
        expandIconPosition="end"
      >
        <Panel
          className="uploaded-layer upload-layer-content"
          collapsible="icon"
          header={
            <>
              <Text>Vector Files</Text>
              <SwitchButtonv1
                isDefaultChecked={!isEmpty(vectorLayerIds)}
                isDisabled={isEmpty(vectorRepoFiles)}
                handleOnChange={handleOnDisableEnableVectorFiles}
                classname="layer-switch"
              />
            </>
          }
          key="1"
        >
          <div className="satsure-layer-inr">
            {isRequestingVectorFiles && <Text className="empty-layers">Loading...</Text>}
            {vectorRepoFiles && vectorRepoFiles.length > 0 ? (
              vectorRepoFiles.map((file: DataLayerFileType, index: number) => (
                <div className="vector-layer-content" key={index}>
                  <div>
                    <div
                      className="box-color"
                      style={
                        file.color
                          ? { backgroundColor: `${file.color}` }
                          : { backgroundColor: 'black' }
                      }
                    />
                    <Text
                      className={`layer-content-title ${
                        vectorLayerIds.includes(file.id) ? 'active-layer' : ''
                      }`}
                    >
                      {file.name}
                    </Text>
                  </div>
                  <Text onClick={() => handleShowVectorLayerOnMap(file.id)}>
                    {vectorLayerIds.includes(file.id) ? (
                      <EyeOpenIcon color="#F2F2F2" />
                    ) : (
                      <EyeCloseIcon color={lightGrey1} />
                    )}
                  </Text>
                </div>
              ))
            ) : (
              <Text className="empty-layers">No layers found!</Text>
            )}
          </div>
        </Panel>
        <Panel
          collapsible="icon"
          className={`uploaded-layer satsure-layer${
            !includes(activePanel, '1') ? ' added-panel' : ''
          }`}
          header={
            <>
              <Text>Raster Layers</Text>
              <SwitchButtonv1
                isDefaultChecked={!isEmpty(lulcRepoFile)}
                isDisabled={isEmpty(lulcRepoFile)}
                handleOnChange={handleOnDisableEnableRasterLayers}
                classname="layer-switch"
              />
            </>
          }
          key="2"
        >
          <div className="satsure-layer-inr">
            {isRequestingGeoserverLayerStyles && <Text className="empty-layers">Loading...</Text>}
            {!isEmpty(lulcRepoFile) &&
              lulcStyleLayers &&
              lulcStyleLayers.length > 0 &&
              lulcStyleLayers.map((style: LulcDataType) => {
                return (
                  <div className="vector-layer-content" key={style.value}>
                    <div>
                      <div
                        className="box-color"
                        style={
                          style.color
                            ? { backgroundColor: `${style.color}` }
                            : { backgroundColor: 'black' }
                        }
                      />
                      <Text
                        className={`layer-content-title ${
                          !selectedLulcLayer.includes(style.value) ? 'active-layer' : ''
                        }`}
                      >
                        {style.title}
                      </Text>
                    </div>
                    <Text onClick={() => handleShowLULCLayerOnMap(style.value)}>
                      {!selectedLulcLayer.includes(style.value) ? (
                        <EyeOpenIcon color="#F2F2F2" />
                      ) : (
                        <EyeCloseIcon color={lightGrey1} />
                      )}
                    </Text>
                  </div>
                );
              })}
          </div>
          {isEmpty(lulcRepoFile) && <Text className="empty-layers">No layers found!</Text>}
        </Panel>
      </Collapse>
    </div>
  );
};

export default LayerControl;
