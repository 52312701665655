import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import { orderBy } from 'lodash';
import LeftArrowIcon from 'assets/svg/LeftArrowIcon';
import RequiredTitle from 'components/core/RequiredTitle';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { routes } from 'constants/pageRoutes.constants';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { editProject } from 'store/projects/actions';
import { setProject, setSelectedProject } from 'store/projects';
import { AddProject, ProjectPayload, ProjectType } from 'store/projects/types';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const { Text } = Typography;
const { Option } = Select;

const EditProject = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const { selectedProject, projectTypes } = useAppSelector((state) => state.projects);

  useEffect(() => {
    if (selectedProject) {
      const coord =
        selectedProject.coordinates && selectedProject.coordinates.length > 0
          ? selectedProject.coordinates.join(',')
          : '';
      form.setFieldsValue({
        project_title: selectedProject.name,
        coordinate: coord,
        type: selectedProject.type
      });
    }
  }, []);

  const handleNavigate = () => {
    dispatch(setProject(null));
    dispatch(setSelectedProject(null));
    navigate(`${routes.ProjectList}?organization_id=${selectedOrganization.id}`, { replace: true });
  };

  const handleCallback = (status: boolean) => {
    if (status) {
      handleNavigate();
    }
  };

  const handleSubmitEditProject = (values: AddProject) => {
    const coord: number[] | [] = values.coordinate
      ? values.coordinate.split(',').map((item) => Number(item.trim()))
      : [];

    const payload: ProjectPayload = {
      project_title: values.project_title,
      type: +values.type,
      coordinate: coord
    };

    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(
        editProject(payload, selectedProject.id, selectedOrganization.id, token, handleCallback)
      );
    });
  };

  return (
    <div className="main-add-edit-org">
      <Row className="org-header" justify="space-between">
        <Col span={24} className="org-title flex" onClick={handleNavigate}>
          <Button
            icon={<LeftArrowIcon color="black" />}
            type="text"
            shape="circle"
            className="back-icon"
          />
          <Text style={{ marginLeft: '17.5px', fontSize: '22px' }}>Edit Project</Text>
        </Col>
        <Col span={24} className="main-org-list">
          <Row className="main-add-org-content">
            <Col className="org-detail-form-div">
              <Form className="add-org-form" form={form} onFinish={handleSubmitEditProject}>
                <RequiredTitle
                  title="Enter name of the Project"
                  isRequired
                  style={{ marginBottom: '18px' }}
                />
                <Form.Item
                  className="input-item"
                  name="project_title"
                  rules={[{ required: true, message: 'Enter name of project' }]}
                >
                  <Input className="form-input-text" placeholder="Enter name of project" />
                </Form.Item>
                <RequiredTitle
                  title="Type of Project"
                  isRequired
                  style={{ marginBottom: '18px' }}
                />
                <Form.Item className="input-item" name="type" rules={[{ required: true }]}>
                  <Select className="member-select" placeholder="Select" allowClear>
                    {projectTypes &&
                      projectTypes.length > 0 &&
                      orderBy(projectTypes, ['type']).map((types: ProjectType) => {
                        return (
                          <Option value={types.id} key={types.type}>
                            {types.type}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <RequiredTitle isRequired style={{ marginBottom: '18px' }} title="Co ordinates" />
                <Form.Item
                  className="input-item"
                  name="coordinate"
                  rules={[{ required: true, message: 'Enter co-ordinates of project' }]}
                >
                  <Input className="form-input-text" placeholder="Enter co-ordinates of project" />
                </Form.Item>
                <div className="skip-btn-content btn-content">
                  <Button type="ghost" onClick={handleNavigate}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Save Changes
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default EditProject;
