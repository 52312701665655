import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const EditIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6141 0.891371C10.4969 0.774161 10.3379 0.708313 10.1722 0.708313C10.0064 0.708313 9.84744 0.774161 9.73023 0.891371L2.06991 8.55169C1.99139 8.63022 1.9352 8.72824 1.90714 8.83568L1.07381 12.026C1.01773 12.2407 1.07968 12.469 1.23658 12.6259C1.39347 12.7828 1.62179 12.8448 1.83647 12.7887L5.02683 11.9554C5.13427 11.9273 5.23229 11.8711 5.31082 11.7926L12.9711 4.13228C13.2152 3.8882 13.2152 3.49247 12.9711 3.24839L10.6141 0.891371ZM3.07371 9.31567L10.1722 2.2172L11.6453 3.69034L4.54684 10.7888L2.55287 11.3096L3.07371 9.31567Z"
        fill={color}
      />
      <path
        d="M1.33333 11.6673H2.26667L8.01667 5.91732L7.08333 4.98398L1.33333 10.734V11.6673ZM10.8667 4.95065L8.03333 2.15065L8.96667 1.21732C9.22222 0.961762 9.53622 0.833984 9.90867 0.833984C10.2807 0.833984 10.5944 0.961762 10.85 1.21732L11.7833 2.15065C12.0389 2.40621 12.1722 2.71465 12.1833 3.07598C12.1944 3.43687 12.0722 3.7451 11.8167 4.00065L10.8667 4.95065ZM9.9 5.93398L2.83333 13.0007H0V10.1673L7.06667 3.10065L9.9 5.93398Z"
        fill={color}
      />
    </svg>
  );
};
EditIcon.defaultProps = {
  color: white,
  height: 13,
  width: 13,
  onClick: () => {}
};

export default EditIcon;
