import { toast } from 'react-toastify';
import { find } from 'lodash';
import { ACCESS_TOKEN, TOKEN_EXPIRE_ERROR } from 'constants/common.constant';
import { Response } from 'types/common.types';
import {
  ADD_DATA_LAYER_REPO_FILE_URL,
  ADD_DATE_DATA_LAYERS_URL,
  DOWNLOAD_REPO_FILE_URL,
  EDIT_DELETE_DATE_DATA_LAYERS_URL,
  EDIT_DELETE_REPOSITORY_FILE_URL,
  ENABLE_DISABLE_DATA_LAYER_URL,
  GET_DATA_LAYERS_URL,
  GET_FILE_TYPES_URL,
  GET_GEOSERVER_LAYER_STYLE_URL
} from 'utils/apiUrls';
import { Timeout, apiCall, downloadReadableStreamFile, fileUploadApicall } from 'utils/connect';
import { getAsyncStorageValue } from 'utils/localStorage';
import {
  AddDateDataLayerPayload,
  DataLayerFileType,
  DataLayerType,
  DownloadFileResponse,
  FileType,
  GeoserverLayerLegendType,
  RepoFileCategory,
  RepoFileType
} from './types';
import { AppDispatch, RootState } from '..';
import {
  setDataLayers,
  setFileTypes,
  setRequestAddDateDataLayers,
  setRequestAddRepoFileDataLayers,
  setRequestEditDateDataLayers,
  setRequestEditRepoFile,
  setRequestFileTypes,
  setRequestingDataLayers,
  setRequestingDownloadRepoFile,
  setRequestingDeleteRepoFile,
  setRequestDeleteDateDataLayers,
  setRequestingGeoserverLayerStyles,
  setGeoserverLayerStyles,
  setEnableDisableDatalayer
} from '.';

export const getDataLayers =
  (orgId: number, projectId: number, token: string, callback?: Function) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingDataLayers(true));

      const onSuccess = (response: Response<Array<DataLayerType>>) => {
        dispatch(setDataLayers(response.data));
        dispatch(setRequestingDataLayers(false));
        if (callback) {
          callback(response.status, response.data);
        }
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getDataLayers(orgId, projectId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingDataLayers(false));
        }
      };

      apiCall('GET', GET_DATA_LAYERS_URL(orgId, projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingDataLayers(false));
      toast.error(error.message);
    }
  };

export const addDateDataLayer =
  (
    payload: AddDateDataLayerPayload,
    orgId: number,
    projectId: number,
    token: string,
    callback: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestAddDateDataLayers(true));

      const onSuccess = (response: Response<boolean>) => {
        // toast.success('Project added successfully');
        dispatch(getDataLayers(orgId, projectId, token));
        dispatch(setRequestAddDateDataLayers(false));
        callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addDateDataLayer(payload, orgId, projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestAddDateDataLayers(false));
        }
      };

      apiCall(
        'POST',
        ADD_DATE_DATA_LAYERS_URL(orgId, projectId),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestAddDateDataLayers(false));
      toast.error(error.message);
    }
  };

export const editDateDataLayer =
  (
    payload: AddDateDataLayerPayload,
    orgId: number,
    projectId: number,
    dlId: number,
    token: string,
    callback: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestEditDateDataLayers(true));

      const onSuccess = (response: Response<boolean>) => {
        // toast.success('Project added successfully');
        dispatch(getDataLayers(orgId, projectId, token));
        dispatch(setRequestEditDateDataLayers(false));
        callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(editDateDataLayer(payload, orgId, projectId, dlId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestEditDateDataLayers(false));
        }
      };

      apiCall(
        'PUT',
        EDIT_DELETE_DATE_DATA_LAYERS_URL(orgId, projectId, dlId),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestEditDateDataLayers(false));
      toast.error(error.message);
    }
  };

export const deleteDateDataLayer =
  (orgId: number, projectId: number, dlId: number, token: string, callback: Function) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestDeleteDateDataLayers(true));

      const onSuccess = (response: Response<boolean>) => {
        // toast.success('Project added successfully');
        dispatch(getDataLayers(orgId, projectId, token));
        dispatch(setRequestDeleteDateDataLayers(false));
        callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(deleteDateDataLayer(orgId, projectId, dlId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestDeleteDateDataLayers(false));
        }
      };

      apiCall(
        'DELETE',
        EDIT_DELETE_DATE_DATA_LAYERS_URL(orgId, projectId, dlId),
        '',
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestDeleteDateDataLayers(false));
      toast.error(error.message);
    }
  };

export const getFileTypes = (token: string) => (dispatch: AppDispatch) => {
  try {
    dispatch(setRequestFileTypes(true));

    const onSuccess = (response: Response<Array<DataLayerType>>) => {
      dispatch(setFileTypes(response.data));
      dispatch(setRequestFileTypes(false));
    };
    const onFailure = (error: Error) => {
      if (error.message === TOKEN_EXPIRE_ERROR) {
        getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
          dispatch(getFileTypes(reToken));
        });
      } else {
        toast.error(error.message);
        dispatch(setRequestFileTypes(false));
      }
    };

    apiCall('GET', GET_FILE_TYPES_URL, '', onSuccess, onFailure, token);
  } catch (error: any) {
    dispatch(setRequestFileTypes(false));
    toast.error(error.message);
  }
};

export const addRepoFileDataLayer =
  (
    orgId: number,
    projectId: number,
    dlId: number,
    categoryId: number,
    token: string,
    callback: Function,
    file?: File,
    lulcStyleFile?: File
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestAddRepoFileDataLayers(true));

      const onSuccess = (response: Response<boolean>) => {
        // toast.success('Project added successfully');
        dispatch(getDataLayers(orgId, projectId, token));
        dispatch(setRequestAddRepoFileDataLayers(false));
        callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(
              addRepoFileDataLayer(
                orgId,
                projectId,
                dlId,
                categoryId,
                reToken,
                callback,
                file,
                lulcStyleFile
              )
            );
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestAddRepoFileDataLayers(false));
        }
      };

      fileUploadApicall(
        ADD_DATA_LAYER_REPO_FILE_URL(orgId, projectId, dlId, categoryId.toString()),
        onSuccess,
        onFailure,
        token,
        file,
        lulcStyleFile
      );
    } catch (error: any) {
      dispatch(setRequestAddRepoFileDataLayers(false));
      toast.error(error.message);
    }
  };

export const editRepositoryFile =
  (
    payload: FileType,
    orgId: number,
    projectId: number,
    dlId: number,
    fileId: number,
    token: string,
    callback: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestEditRepoFile(true));

      const onSuccess = (response: Response<boolean>) => {
        // toast.success('Project added successfully');
        dispatch(getDataLayers(orgId, projectId, token));
        dispatch(setRequestEditRepoFile(false));
        callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(
              editRepositoryFile(payload, orgId, projectId, dlId, fileId, reToken, callback)
            );
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestEditRepoFile(false));
        }
      };

      apiCall(
        'PUT',
        EDIT_DELETE_REPOSITORY_FILE_URL(orgId, projectId, dlId, fileId),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestEditRepoFile(false));
      toast.error(error.message);
    }
  };

export const deleteRepoFile =
  (
    orgId: number,
    projectId: number,
    dlId: number,
    fileId: number,
    token: string,
    callback?: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingDeleteRepoFile(true));

      const onSuccess = (response: Response<boolean>) => {
        dispatch(getDataLayers(orgId, projectId, token));
        dispatch(setRequestingDeleteRepoFile(false));
        if (callback) callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(deleteRepoFile(orgId, projectId, dlId, fileId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingDeleteRepoFile(false));
        }
      };

      apiCall(
        'DELETE',
        EDIT_DELETE_REPOSITORY_FILE_URL(orgId, projectId, dlId, fileId),
        '',
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingDeleteRepoFile(false));
      toast.error(error.message);
    }
  };

export const getDownloadRepoFile =
  (
    orgId: number,
    projectId: number,
    dlId: number,
    fileId: number,
    token: string,
    callback?: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingDownloadRepoFile(true));

      const onSuccess = (response: Response<DownloadFileResponse>) => {
        const link = document.createElement('a');
        link.href = response.data.url;
        link.setAttribute('download', response.data.file_name);
        document.body.appendChild(link);
        link.click();
        dispatch(setRequestingDownloadRepoFile(false));
        if (callback) callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getDownloadRepoFile(orgId, projectId, dlId, fileId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingDownloadRepoFile(false));
        }
      };

      apiCall(
        'GET',
        DOWNLOAD_REPO_FILE_URL(orgId, projectId, dlId, fileId),
        '',
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingDownloadRepoFile(false));
      toast.error(error.message);
    }
  };

export const getDownloadLULCFile =
  (
    orgId: number,
    projectId: number,
    dlId: number,
    fileId: number,
    token: string,
    callback?: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingDownloadRepoFile(true));

      const onSuccess = (blob: any, filename: string) => {
        const newBlob = new Blob([blob]);
        const blobUrl = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', filename || 'lulc.zip');
        document.body.appendChild(link);
        link.click();

        // clean up Url
        window.URL.revokeObjectURL(blobUrl);
        dispatch(setRequestingDownloadRepoFile(false));
        if (callback) callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getDownloadLULCFile(orgId, projectId, dlId, fileId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingDownloadRepoFile(false));
        }
      };

      downloadReadableStreamFile(
        'GET',
        DOWNLOAD_REPO_FILE_URL(orgId, projectId, dlId, fileId),
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingDownloadRepoFile(false));
      toast.error(error.message);
    }
  };

export const getGeoserverLayerStyle =
  (dlId: number, token: string) => (dispatch: AppDispatch, getState: RootState) => {
    const state = getState();
    const { dataLayers } = state.repositoryFiles;

    if (dataLayers && dataLayers.length === 0) return;

    const files = find(dataLayers, (data: DataLayerType) => data.id === dlId);

    if (files && files.length === 0) return;

    const isLULCfile = find(
      files.files,
      (file: DataLayerFileType) => file.category === RepoFileCategory.LULC
    );

    if (!isLULCfile) return;

    try {
      dispatch(setRequestingGeoserverLayerStyles(true));

      const onSuccess = (response: Response<Array<GeoserverLayerLegendType>>) => {
        dispatch(setGeoserverLayerStyles(response.data));
        dispatch(setRequestingGeoserverLayerStyles(false));
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getGeoserverLayerStyle(dlId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingGeoserverLayerStyles(false));
        }
      };

      apiCall(
        'GET',
        GET_GEOSERVER_LAYER_STYLE_URL(isLULCfile.gs_layer_name),
        '',
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setRequestingGeoserverLayerStyles(false));
    }
  };

export const downloadDatalayerFiles = async (
  orgId: number,
  projectId: number,
  dlId: number,
  dataLayerFiles: DataLayerFileType[] | [],
  accessToken: string
) => {
  const promises = dataLayerFiles.map((file: DataLayerFileType) => {
    const formData = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        Authorization: accessToken
      },
      signal: Timeout(100).signal
    };

    if (file.type === RepoFileType.Rasterfiles) {
      return fetch(DOWNLOAD_REPO_FILE_URL(orgId, projectId, dlId, file.id), formData)
        .then((response) => {
          let filename = response.headers.get('content-disposition');
          filename = filename ? filename.split('=')[1] : '';
          filename = filename || '';

          return (
            response
              .blob()
              // @ts-ignore
              .then((blob: any) => {
                const newBlob = new Blob([blob]);
                const blobUrl = window.URL.createObjectURL(newBlob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', filename || 'lulc.zip');
                document.body.appendChild(link);
                link.click();

                // clean up Url
                window.URL.revokeObjectURL(blobUrl);

                return blob;
              })
              .catch((error) => {
                return error;
              })
          );
        })
        .catch((error) => {
          return error;
        });
    }

    return fetch(DOWNLOAD_REPO_FILE_URL(orgId, projectId, dlId, file.id), formData)
      .then((response) => {
        return (
          response
            .json()
            // @ts-ignore
            .then((response: Response<DownloadFileResponse>) => {
              const link = document.createElement('a');
              link.href = response.data.url;
              link.setAttribute('download', response.data.file_name);
              document.body.appendChild(link);
              link.click();

              return response.data;
            })
            .catch((error) => {
              return error;
            })
        );
      })
      .catch((error) => {
        return error;
      });
  });

  return Promise.all(promises)
    .then(() => {})
    .catch(() => {
      toast.error('Download failed');
    });
};

export const enableDisableDatalayer =
  (orgId: number, projectId: number, dlId: number, token: string, callback?: Function) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setEnableDisableDatalayer(true));

      const onSuccess = (response: Response<boolean>) => {
        dispatch(getDataLayers(orgId, projectId, token));
        dispatch(setEnableDisableDatalayer(false));
        if (callback) callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(enableDisableDatalayer(orgId, projectId, dlId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setEnableDisableDatalayer(false));
        }
      };

      apiCall(
        'POST',
        ENABLE_DISABLE_DATA_LAYER_URL(orgId, projectId, dlId),
        '',
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setEnableDisableDatalayer(false));
      toast.error(error.message);
    }
  };
