import { Fragment, memo, useMemo } from 'react';
import { Button, Space, Typography } from 'antd';
import { filter, includes, last } from 'lodash';
import { Circle, Marker, Polygon, Polyline, Popup, Tooltip } from 'react-leaflet';
import { lightGreen } from 'constants/color.constant';
import { LightLocation } from 'constants/map.constants';
import { AddedAnnotation } from 'store/annotations/types';
import { CoordinateType } from 'types/common.types';
import RenderGeometryLabel from './RenderGeometryLabel';

const { Text } = Typography;
interface RendorAnnotationsMarkingsProps {
  addedAnnotations: AddedAnnotation[];
  selectedMarkings: Array<number>;
}

const RendorAnnotationsMarkings = ({
  addedAnnotations,
  selectedMarkings
}: RendorAnnotationsMarkingsProps) => {
  const allAnnotationMarkings = useMemo(() => {
    if (selectedMarkings && selectedMarkings.length === 0) return null;

    const selectedAnnotationsMarkings = filter(addedAnnotations, (annotation: AddedAnnotation) =>
      includes(selectedMarkings, annotation.id)
    );

    return selectedAnnotationsMarkings.map((data: AddedAnnotation, i: number) => {
      if (data.geometry.type === CoordinateType.Point) {
        const coords: any = data.geometry.coordinates;

        return (
          <Marker key={data.id} position={coords} icon={LightLocation}>
            <Popup className="annts-point-popup" minWidth={232} maxHeight={226} offset={[0, 160]}>
              <Space direction="vertical">
                <div>
                  <Text className="annts-market-title">NAME</Text>
                  <Text className="annts-market-data">{data.properties.name}</Text>
                </div>
                <div>
                  <Text className="annts-market-title">CATERGORY</Text>
                  <Text className="annts-market-data">{data.properties.category}</Text>
                </div>
                <div>
                  <Text className="annts-market-title">CO-ORDINATES</Text>
                  <Text className="annts-market-data">{data.geometry.coordinates.join()}</Text>
                </div>
                <div>
                  <Text className="annts-market-title">NOTE :</Text>
                  <Text className="annts-market-data">{data.properties.note}</Text>
                </div>
              </Space>
            </Popup>
            <Tooltip className="annts-point-tooltip" direction="right">
              <Button type="primary">{data.properties.name}</Button>
            </Tooltip>
          </Marker>
        );
      }
      if (data.geometry.type === CoordinateType.LineString) {
        const coords: any = data.geometry.coordinates;
        const lastCoord = last(coords);

        return (
          <Fragment key={`${data.id}-${i}`}>
            {coords.map((coord: any, index: number) => {
              return (
                <Circle
                  key={`${data.id}-${index + 1}-${i}`}
                  pathOptions={{ color: lightGreen, fillColor: lightGreen, fillOpacity: 1.5 }}
                  center={coord}
                  radius={10}
                />
              );
            })}
            <Polyline
              key={`${data.id}`}
              // @ts-ignore
              positions={coords}
              pathOptions={{
                color: lightGreen,
                fillColor: lightGreen,
                fillOpacity: 0.3,
                weight: 1
              }}
              pane="shadowPane"
            >
              <Popup className="annts-point-popup" minWidth={232} maxHeight={226} offset={[0, 160]}>
                <Space direction="vertical">
                  <div>
                    <Text className="annts-market-title">NAME</Text>
                    <Text className="annts-market-data">{data.properties.name}</Text>
                  </div>
                  <div>
                    <Text className="annts-market-title">CATERGORY</Text>
                    <Text className="annts-market-data">{data.properties.category}</Text>
                  </div>
                  <div>
                    <Text className="annts-market-title">LINE LENGTH</Text>
                    <Text className="annts-market-data">{data.properties.distance} km</Text>
                  </div>
                  <div>
                    <Text className="annts-market-title">NOTE :</Text>
                    <Text className="annts-market-data">{data.properties.note}</Text>
                  </div>
                </Space>
              </Popup>
              <RenderGeometryLabel label={`${data.properties.distance}km`} coordinate={lastCoord} />
              <Tooltip className="annts-point-tooltip" direction="right">
                <Button type="primary">{data.properties.name}</Button>
              </Tooltip>
            </Polyline>
          </Fragment>
        );
      }
      if (data.geometry.type === CoordinateType.Polygon) {
        const coords: any = data.geometry.coordinates;

        return (
          <Polygon
            key={`${data.id}-${i}`}
            positions={coords}
            pathOptions={{
              fillColor: lightGreen,
              color: lightGreen,
              fillOpacity: 0.3,
              weight: 2
            }}
            pane="shadowPane"
          >
            <Popup
              className="annts-point-popup"
              position={last(coords[0])}
              minWidth={232}
              maxHeight={272}
            >
              <Space direction="vertical">
                <div>
                  <Text className="annts-market-title">NAME</Text>
                  <Text className="annts-market-data">{data.properties.name}</Text>
                </div>
                <div>
                  <Text className="annts-market-title">CATERGORY</Text>
                  <Text className="annts-market-data">{data.properties.category}</Text>
                </div>
                <div>
                  <Text className="annts-market-title">AREA</Text>
                  <Text className="annts-market-data">{data.properties?.area || '-'} Sq. km</Text>
                </div>
                <div>
                  <Text className="annts-market-title">CENTROID CO-ORDINATES</Text>
                  <Text className="annts-market-data">{data.properties.coordinates || '-'}</Text>
                </div>
                <div>
                  <Text className="annts-market-title">NOTE :</Text>
                  <Text className="annts-market-data">{data.properties.note}</Text>
                </div>
              </Space>
            </Popup>
            <Tooltip className="annts-point-tooltip" direction="center">
              <Button type="primary">{data.properties.name}</Button>
            </Tooltip>
          </Polygon>
        );
      }
      return null;
    });
  }, [addedAnnotations, selectedMarkings]);

  // eslint-disable-next-line
  return <>{allAnnotationMarkings}</>;
};

export default memo(RendorAnnotationsMarkings);
