import { MenuProps } from 'antd';

export const items1: MenuProps['items'] = [
  {
    label: 'Change Date',
    key: 'change_date'
  },
  {
    type: 'divider'
  },
  {
    label: 'Delete',
    key: 'delete'
  },
  {
    type: 'divider'
  },
  {
    label: 'Disable',
    key: 'disable'
  }
];

export const items2: MenuProps['items'] = [
  {
    label: 'Change Date',
    key: 'change_date'
  },
  {
    type: 'divider'
  },
  {
    label: 'Delete',
    key: 'delete'
  },
  {
    type: 'divider'
  },
  {
    label: 'Enable',
    key: 'enable'
  }
];
