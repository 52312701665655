import L from 'leaflet';
import PointYellow from 'assets/png/LocationIcon.png';
import LightLocationIcon from 'assets/svg/LightLocationIcon.svg';
import RoundIcon from 'assets/svg/RoundIcon.svg';
import RoundTowerIcon from 'assets/svg/TowerIcon.svg';
import { RepoFileCategory } from 'store/repositoryfile/types';

export const PointYellowIcon = L.icon({
  iconUrl: PointYellow,
  iconSize: [26, 28],
  iconAnchor: [13, 25]
});

export const LightLocation = L.icon({
  iconUrl: LightLocationIcon,
  iconSize: [18, 28],
  iconAnchor: [13, 25]
});

export const RoundLocationIcon = L.icon({
  iconUrl: RoundIcon,
  iconSize: [18, 28],
  iconAnchor: [13, 25]
});

export const TowerIcon = L.icon({
  iconUrl: RoundTowerIcon,
  iconSize: [20, 20]
});

export const vectorCategories = [
  RepoFileCategory.VectorPoint,
  RepoFileCategory.VectorPolyline,
  RepoFileCategory.VectorPolygon
];

export const VegetationCategories = [
  RepoFileCategory.Trees,
  RepoFileCategory.TowerLocation,
  RepoFileCategory.TransmissionCorridor,
  RepoFileCategory.TransmissionLine,
  RepoFileCategory.TransmissionSegment
];

export const TreeZindex: { [key: string]: string } = {
  LowOrNoRisk: 'tilePane',
  HighRisk: 'overlayPane',
  Proximity: 'shadowPane',
  high: 'shadowPane',
  med: 'overlayPane',
  low: 'tilePane'
};
