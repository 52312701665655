import { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { Button, Drawer, Typography } from 'antd';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import CloseIcon from 'assets/svg/CloseIcon';
import PlusIcon from 'assets/svg/PlusIcon';
import { white } from 'constants/color.constant';
import ProjectViewTab from 'components/core/ProjectViewTab';
import DemDsmLegend from 'components/Map/DemDsmLegend';
import LayerControl from 'components/Map/LayerControl';
import SwitchButtonv2 from 'components/core/SwitchButtonv2';
import {
  ProjectTabType,
  ProjectViewTabKey,
  projectCompareViewTabs,
  projectViewTabs1,
  projectViewTabs2,
  projectViewTabs
} from 'constants/common.constant';
import { AddedAnnotation, UploadedAnnotation } from 'store/annotations/types';
import { Project, ProjectCategoryTypes } from 'store/projects/types';
import { TransmissionSegmentType, TreeType } from 'store/riskanalysis/types';
import { DataLayerFileType, DataLayerTableType, LulcDataType } from 'store/repositoryfile/types';
import { sidebarStyles } from 'styles/projectView.styles';
import AnnotationsTab from './AnnotationsTab';
import AddAnnotations from './AddAnnotations';
import RiskAnalysis from './RiskAnalysis';
import RiskAnalysisVegInt from './RiskAnalysisVegInt';
import './styles.less';

const { Text } = Typography;

interface ProjectViewSidebarVagIntProps {
  project: Project;
  activeDataLayer: DataLayerTableType | null;
  vectorRepoFiles: Array<DataLayerFileType> | [];
  vectorLayerIds: number[];
  selectedMarkings: number[];
  selectedUploadMarkings: number[];
  selectedLulcLayer: string[];
  lulcRepoFile: DataLayerFileType;
  demRepoFile: DataLayerFileType;
  dsmRepoFile: DataLayerFileType;
  satelliteImagesRepoFile: DataLayerFileType | undefined;
  orthomosiacRepoFile: DataLayerFileType;
  lulcStyleLayers: LulcDataType[];
  isActiveCompare: boolean;
  isRiskSegment: boolean;
  addedAnnotations: AddedAnnotation[] | [];
  uploadedAnnotations: UploadedAnnotation[] | [];
  editAnnotationMarking: AddedAnnotation | null;
  handleVectorLayerIds: (id: number) => void;
  handleShowLULCLayerOnMap: (id: string) => void;
  handleDemStyle: (style: string | null) => void;
  handleDsmStyle: (style: string | null) => void;
  handleShowDemLegend: (isShow: boolean) => void;
  handleShowDsmLegend: (isShow: boolean) => void;
  handleShowSatelliteImagesFile: (isShow: boolean) => void;
  handleShowOrthomosiacFile: (isShow: boolean) => void;
  handleSelectedAnnotationsTab: (selectedTab: string | null) => void;
  handleShowMarkings: (data: AddedAnnotation) => void;
  handleShowUploadedMarkings: (id: number) => void;
  handleEditMarking: (data: AddedAnnotation | null) => void;
  handleAnnotationTabClick: (data: boolean) => void;
  handleShowRiskTable: (data: boolean) => void;
  handleRiskSegmentData: (data: TransmissionSegmentType | null, index: number | null) => void;
  handleShowVectorFiles: (data: boolean) => void;
  handleShowRasterFiles: (data: boolean) => void;
  handleShowUploadAnnotation: (data: boolean) => void;
  handleShowMarkedAnnotation: (data: boolean) => void;
  handleSelectedRiskCatTrees: (trees: TreeType[] | []) => void;
  handleShowAllRiskTree: (segment: TransmissionSegmentType | null) => void;
  handleBackRiskAnalysisMain: Function;
}

const ProjectViewSidebarVagInt = ({
  project,
  activeDataLayer,
  vectorRepoFiles,
  vectorLayerIds,
  lulcRepoFile,
  demRepoFile,
  dsmRepoFile,
  // eslint-disable-next-line
  satelliteImagesRepoFile,
  orthomosiacRepoFile,
  selectedLulcLayer,
  lulcStyleLayers,
  isActiveCompare,
  selectedMarkings,
  selectedUploadMarkings,
  addedAnnotations,
  uploadedAnnotations,
  editAnnotationMarking,
  isRiskSegment,
  handleVectorLayerIds,
  handleShowLULCLayerOnMap,
  handleShowDemLegend,
  handleShowDsmLegend,
  handleDemStyle,
  handleDsmStyle,
  handleShowSatelliteImagesFile,
  handleShowOrthomosiacFile,
  handleSelectedAnnotationsTab,
  handleShowMarkings,
  handleShowUploadedMarkings,
  handleEditMarking,
  handleAnnotationTabClick,
  handleShowRiskTable,
  handleRiskSegmentData,
  handleShowVectorFiles,
  handleShowRasterFiles,
  handleShowUploadAnnotation,
  handleShowMarkedAnnotation,
  handleSelectedRiskCatTrees,
  handleShowAllRiskTree,
  handleBackRiskAnalysisMain
}: ProjectViewSidebarVagIntProps) => {
  const [isOpenSidebar, setOpenSidebar] = useState<boolean>(true);
  const [activeTabKey, setActiveTabKey] = useState<string>('risk analysis');
  // eslint-disable-next-line
  const [isActiveSatellite, setActiveSatellite] = useState<boolean>(false);
  const [isActiveOrtho, setActiveOrtho] = useState<boolean>(false);
  const [isActiveDem, setActiveDem] = useState<boolean>(false);
  const [isActiveDsm, setActiveDsm] = useState<boolean>(false);
  const [isActiveAddAnnotations, setActiveAddAnnotations] = useState<boolean>(false);
  const [selectedDEMStyle, setSelectedDEMStyle] = useState<string | null>(null);
  const [selectedDSMStyle, setSelectedDSMStyle] = useState<string | null>(null);
  const [selectedRiskData, setSelectedRiskData] = useState<TransmissionSegmentType | null>(null);
  const isSolarPowerProjectType: boolean =
    project && project.type === ProjectCategoryTypes.SolarPowerPlant;
  const isVegetationProjectType: boolean =
    project && project.type === ProjectCategoryTypes.VegetationManagement;
  // eslint-disable-next-line
  const tabs = isActiveCompare
    ? projectCompareViewTabs
    : // eslint-disable-next-line
    isVegetationProjectType
    ? projectViewTabs2
    : isSolarPowerProjectType
    ? projectViewTabs1
    : projectViewTabs;

  const handleBackClickTab = () => {
    if (selectedRiskData) {
      setSelectedRiskData(null);
      handleRiskSegmentData(null, null);
    } else {
      setActiveTabKey('risk analysis');
      setActiveSatellite(false);
      setActiveOrtho(false);
      setActiveDsm(false);
      setActiveDem(false);
      setSelectedDSMStyle(null);
      setSelectedDEMStyle(null);
      handleShowDsmLegend(false);
      handleShowDemLegend(false);
      handleDemStyle(null);
      handleDsmStyle(null);
    }
  };

  useEffect(() => {
    setActiveTabKey('risk analysis');
    handleBackClickTab();
  }, [isActiveCompare]);

  // eslint-disable-next-line
  const handleOnChangeSatellite = (checked: boolean) => {
    setActiveSatellite(checked);
    handleShowSatelliteImagesFile(checked);
  };
  const handleOnChangeOrtho = (checked: boolean) => {
    setActiveOrtho(checked);
    handleShowOrthomosiacFile(checked);
  };
  const handleOnChangeDem = (checked: boolean) => {
    setActiveDem(checked);
    setSelectedDEMStyle(null);
    setActiveDsm(false);
    setSelectedDSMStyle(null);
    handleShowDemLegend(checked);
    handleDsmStyle(null);
  };
  const handleOnChangeDsm = (checked: boolean) => {
    setActiveDsm(checked);
    setSelectedDSMStyle(null);
    handleShowDsmLegend(checked);
    setActiveDem(false);
    setSelectedDEMStyle(null);
    handleDemStyle(null);
  };

  const handleShowDrawer = () => {
    setOpenSidebar(!isOpenSidebar);
  };

  const handleClickTab = (activeTab: string) => {
    setActiveTabKey(activeTab);
  };

  const handleOnClose = () => {
    setOpenSidebar(false);
    setActiveTabKey('risk analysis');
  };

  const handleOnDemStyle = (demStyle: string) => {
    if (selectedDEMStyle === demStyle) {
      handleDemStyle(null);
      setSelectedDEMStyle(null);
    } else {
      handleDemStyle(demStyle);
      setSelectedDEMStyle(demStyle);
    }
  };

  const handleOnDsmStyle = (dsmStyle: string) => {
    if (selectedDSMStyle === dsmStyle) {
      handleDsmStyle(null);
      setSelectedDSMStyle(null);
    } else {
      handleDsmStyle(dsmStyle);
      setSelectedDSMStyle(dsmStyle);
    }
  };

  const handleAddCloseAnnotations = (isShowHide: boolean) => {
    setActiveAddAnnotations(isShowHide);
    handleSelectedAnnotationsTab(null);
    handleAnnotationTabClick(true);
  };

  const handleShowRiskData = (data: TransmissionSegmentType | null, index: number) => {
    setSelectedRiskData(data);
    handleRiskSegmentData(data, index);
  };

  return (
    <div className="view-left-sidebar">
      <Drawer
        title={null}
        placement="left"
        className="sidebar-drawer"
        closeIcon={null}
        onClose={handleOnClose}
        open={isOpenSidebar}
      >
        <div className="drawer-content">
          <div
            className={`content-list${activeTabKey ? ' active-tab-list' : ''}`}
            style={{ height: activeTabKey ? '100%' : 'calc(100% - 105px)' }}
          >
            {!activeTabKey &&
              [{ title: 'Risk Analysis', value: 'risk analysis' }].map((tab: ProjectTabType) => {
                return (
                  <ProjectViewTab key={tab.value} tabData={tab} handleClickTab={handleClickTab} />
                );
              })}
            {activeTabKey && (
              <>
                <div className={`content-tab content-${activeTabKey}-tab`}>
                  {
                    // eslint-disable-next-line
                    activeTabKey === ProjectViewTabKey.ANNOTATIONS ? (
                      isActiveAddAnnotations ? (
                        <>
                          <div
                            onClick={() => {
                              handleBackClickTab();
                              handleAnnotationTabClick(false);
                            }}
                          >
                            <Text>Add {capitalize(activeTabKey)}</Text>
                          </div>
                          <div
                            className="annotations-icon"
                            onClick={() => {
                              handleAddCloseAnnotations(false);
                              handleAnnotationTabClick(false);
                            }}
                          >
                            <CloseIcon />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            onClick={() => {
                              handleBackClickTab();
                              handleEditMarking(null);
                            }}
                          >
                            <BiChevronLeft color={white} size={18} />
                            <Text>{capitalize(activeTabKey)}</Text>
                          </div>
                          <div onClick={() => handleAddCloseAnnotations(true)}>
                            <PlusIcon />
                          </div>
                        </>
                      )
                    ) : (
                      <>
                        {isRiskSegment && (
                          <BiChevronLeft
                            color={white}
                            size={18}
                            onClick={() => {
                              handleBackClickTab();
                              handleBackRiskAnalysisMain();
                            }}
                          />
                        )}
                        <Text onClick={() => handleBackClickTab()}>{capitalize(activeTabKey)}</Text>
                      </>
                    )
                  }
                </div>
                {activeTabKey === ProjectViewTabKey.FEATURES && (
                  <>
                    {/* <div
                      className="content-tab content-tab-inr"
                      style={sidebarStyles.featureContent}
                    >
                      <Text>Satellite Images</Text>
                      <SwitchButtonv2
                        isChecked={isActiveSatellite}
                        isDisabled={Boolean(!satelliteImagesRepoFile)}
                        handleOnChange={handleOnChangeSatellite}
                        classname="layer-switch"
                      />
                    </div> */}
                    <div
                      className="content-tab content-tab-inr"
                      style={sidebarStyles.featureContent}
                    >
                      <Text>Orthomosiac</Text>
                      <SwitchButtonv2
                        isChecked={isActiveOrtho}
                        isDisabled={Boolean(!orthomosiacRepoFile)}
                        handleOnChange={handleOnChangeOrtho}
                        classname="layer-switch"
                      />
                    </div>
                    <div
                      className="content-tab content-tab-inr"
                      style={{ borderBottom: isActiveDem ? 0 : '0.5px solid #c7c7cc' }}
                    >
                      <Text>DEM</Text>
                      <SwitchButtonv2
                        isChecked={isActiveDem}
                        isDisabled={Boolean(!demRepoFile)}
                        handleOnChange={handleOnChangeDem}
                        classname="layer-switch"
                      />
                    </div>
                    {isActiveDem && (
                      <DemDsmLegend
                        selectedDEMStyle={selectedDEMStyle}
                        handleOnDemStyle={handleOnDemStyle}
                      />
                    )}
                    <div
                      className="content-tab content-tab-inr"
                      style={{
                        borderBottom: 0,
                        borderTop: isActiveDem ? '0.5px solid #c7c7cc' : 0
                      }}
                    >
                      <Text>DSM</Text>
                      <SwitchButtonv2
                        isChecked={isActiveDsm}
                        isDisabled={Boolean(!dsmRepoFile)}
                        handleOnChange={handleOnChangeDsm}
                        classname="layer-switch"
                      />
                    </div>
                    {isActiveDsm && (
                      <DemDsmLegend
                        selectedDSMStyle={selectedDSMStyle}
                        handleOnDsmStyle={handleOnDsmStyle}
                      />
                    )}
                  </>
                )}
                {activeTabKey === ProjectViewTabKey.LAYERS && (
                  <LayerControl
                    vectorRepoFiles={vectorRepoFiles}
                    vectorLayerIds={vectorLayerIds}
                    lulcRepoFile={lulcRepoFile}
                    selectedLulcLayer={selectedLulcLayer}
                    lulcStyleLayers={lulcStyleLayers}
                    handleVectorLayerIds={handleVectorLayerIds}
                    handleShowLULCLayerOnMap={handleShowLULCLayerOnMap}
                    handleShowVectorFiles={handleShowVectorFiles}
                    handleShowRasterFiles={handleShowRasterFiles}
                  />
                )}
                {activeTabKey === ProjectViewTabKey.ANNOTATIONS && !isActiveAddAnnotations && (
                  <AnnotationsTab
                    selectedMarkings={selectedMarkings}
                    selectedUploadMarkings={selectedUploadMarkings}
                    addedAnnotations={addedAnnotations}
                    uploadedAnnotations={uploadedAnnotations}
                    handleShowMarkings={handleShowMarkings}
                    handleShowUploadedMarkings={handleShowUploadedMarkings}
                    handleShowUploadAnnotation={handleShowUploadAnnotation}
                    handleShowMarkedAnnotation={handleShowMarkedAnnotation}
                  />
                )}
                {activeTabKey === ProjectViewTabKey.ANNOTATIONS && isActiveAddAnnotations && (
                  <AddAnnotations
                    activeDataLayer={activeDataLayer}
                    selectedMarkings={selectedMarkings}
                    editAnnotationMarking={editAnnotationMarking}
                    handleChangeActiveTab={handleSelectedAnnotationsTab}
                    handleShowMarkings={handleShowMarkings}
                    handleEditMarking={handleEditMarking}
                  />
                )}
                {activeTabKey === ProjectViewTabKey.RISKANALYSIS &&
                  project &&
                  project.type === ProjectCategoryTypes.VegetationManagement && (
                    <RiskAnalysis
                      selectedRiskData={selectedRiskData}
                      handleShowRiskTable={handleShowRiskTable}
                      handleShowRiskData={handleShowRiskData}
                      handleShowAllRiskTree={handleShowAllRiskTree}
                      handleSelectedRiskCatTrees={handleSelectedRiskCatTrees}
                    />
                  )}
                {activeTabKey === ProjectViewTabKey.RISKANALYSIS &&
                  project &&
                  (project.type === ProjectCategoryTypes.VegetationManagementINTL ||
                    project.type === ProjectCategoryTypes.VegetationManagementLite) && (
                    <RiskAnalysisVegInt
                      project={project}
                      selectedRiskData={selectedRiskData}
                      handleShowRiskData={handleShowRiskData}
                    />
                  )}
              </>
            )}
          </div>
          {/* {!activeTabKey && <ProjectDetailVegInt project={project} />} */}
        </div>
      </Drawer>
      <Button
        className={`collapse-btn${!isOpenSidebar ? ' collapsed' : ''}`}
        onClick={handleShowDrawer}
        icon={isOpenSidebar ? <BiChevronLeft /> : <BiChevronRight />}
      />
    </div>
  );
};

export default ProjectViewSidebarVagInt;
