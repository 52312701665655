import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

interface RenderGeometryLabelProps {
  coordinate: any;
  label: String;
}

const RenderGeometryLabel = ({ coordinate, label }: RenderGeometryLabelProps) => {
  const map = useMap();
  const mainFeatureGroup = L.featureGroup();

  useEffect(() => {
    const html =
      // eslint-disable-next-line
      '<span style="color: #43ffdd; font-size: 12px !important; padding-left: 14px;">' +
      label +
      '</span>';
    const myIcon = L.divIcon({ className: 'node-label-text', html });
    const marker = L.marker(coordinate, { icon: myIcon });

    mainFeatureGroup.addLayer(marker);
    mainFeatureGroup.addTo(map);

    // eslint-disable-next-line
    return () => {
      mainFeatureGroup.removeFrom(map);
    };
  }, [label]); // eslint-disable-line

  return null;
};

export default RenderGeometryLabel;
