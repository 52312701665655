import { Typography } from 'antd';
import EyeOpenIcon from 'assets/svg/EyeOpenIcon';
import EyeCloseIcon from 'assets/svg/EyeCloseIcon';
import { lightGrey1, white } from 'constants/color.constant';
import { MONOCHROME, elevetion, JET, HILL_SHADE } from 'constants/common.constant';
import JetLegend from 'components/core/MapLegend/JetLegend';
import MonochromeLegend from 'components/core/MapLegend/MonochromeLegend';

interface DemDsmLegendProps {
  selectedDEMStyle?: string | null;
  handleOnDemStyle?: (style: string) => void;
  selectedDSMStyle?: string | null;
  handleOnDsmStyle?: (style: string) => void;
}
const { Text } = Typography;

const DemDsmLegend = ({
  handleOnDemStyle,
  selectedDEMStyle,
  handleOnDsmStyle,
  selectedDSMStyle
}: DemDsmLegendProps) => {
  return (
    <>
      <div>
        <div className="active-tab-content" style={{ borderTop: `0.5px dashed ${lightGrey1}` }}>
          <Text>Monochrome</Text>
          {selectedDEMStyle === MONOCHROME || selectedDSMStyle === MONOCHROME ? (
            <div
              onClick={() => {
                if (handleOnDsmStyle) {
                  handleOnDsmStyle(MONOCHROME);
                }
                if (handleOnDemStyle) {
                  handleOnDemStyle(MONOCHROME);
                }
              }}
            >
              <EyeOpenIcon color={white} />
            </div>
          ) : (
            <div
              onClick={() => {
                if (handleOnDsmStyle) {
                  handleOnDsmStyle(MONOCHROME);
                }
                if (handleOnDemStyle) {
                  handleOnDemStyle(MONOCHROME);
                }
              }}
            >
              <EyeCloseIcon color={lightGrey1} />
            </div>
          )}
        </div>
        {(selectedDEMStyle === MONOCHROME || selectedDSMStyle === MONOCHROME) && (
          <MonochromeLegend elevetion={elevetion} />
        )}
      </div>
      <div>
        <div className="active-tab-content">
          <Text>Jet</Text>
          {selectedDEMStyle === JET || selectedDSMStyle === JET ? (
            <div
              onClick={() => {
                if (handleOnDsmStyle) {
                  handleOnDsmStyle(JET);
                }
                if (handleOnDemStyle) {
                  handleOnDemStyle(JET);
                }
              }}
            >
              <EyeOpenIcon color={white} />
            </div>
          ) : (
            <div
              onClick={() => {
                if (handleOnDsmStyle) {
                  handleOnDsmStyle(JET);
                }
                if (handleOnDemStyle) {
                  handleOnDemStyle(JET);
                }
              }}
            >
              <EyeCloseIcon color={lightGrey1} />
            </div>
          )}
        </div>
        {(selectedDEMStyle === JET || selectedDSMStyle === JET) && (
          <JetLegend elevetion={elevetion} />
        )}
      </div>
      <div
        className="active-tab-content"
        style={{
          paddingBottom: 10
        }}
      >
        <Text>Hill Shade</Text>
        {selectedDEMStyle === HILL_SHADE || selectedDSMStyle === HILL_SHADE ? (
          <div
            onClick={() => {
              if (handleOnDsmStyle) {
                handleOnDsmStyle(HILL_SHADE);
              }
              if (handleOnDemStyle) {
                handleOnDemStyle(HILL_SHADE);
              }
            }}
          >
            <EyeOpenIcon color={white} />
          </div>
        ) : (
          <div
            onClick={() => {
              if (handleOnDsmStyle) {
                handleOnDsmStyle(HILL_SHADE);
              }
              if (handleOnDemStyle) {
                handleOnDemStyle(HILL_SHADE);
              }
            }}
          >
            <EyeCloseIcon color={lightGrey1} />
          </div>
        )}
      </div>
    </>
  );
};

DemDsmLegend.defaultProps = {
  selectedDEMStyle: null,
  handleOnDemStyle: () => {},
  selectedDSMStyle: null,
  handleOnDsmStyle: () => {}
};

export default DemDsmLegend;
