import { IconProps } from 'types/common.types';

const SatsureIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 164 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.8088 36.692L40.8415 14.8438L52.8587 36.692H48.2176L40.8492 22.7844L37.1302 29.8188H40.9577L39.0982 33.2746H35.2939L33.4886 36.692H28.8088Z"
        fill="#42444a"
      />
      <path
        d="M60.6687 36.7534H56.7637V18.0614H49.4186V14.3906H68.0061V18.0614H60.6687V36.7534Z"
        fill={color}
      />
      <path
        d="M153.723 17.8541H139.358V33.2592H153.723V36.715H135.515V14.3906H153.723V17.8541Z"
        fill={color}
      />
      <path d="M141.884 27.2838H153.01V23.8203H141.884V27.2838Z" fill={color} />
      <path
        d="M27.6621 27.6917C27.3212 26.9007 26.8563 26.2095 26.2597 25.6182C25.6708 25.0346 24.9735 24.5661 24.1755 24.2359C23.3852 23.898 22.5329 23.7291 21.6341 23.7291H16.9233C16.5282 23.7291 16.1563 23.6599 15.8076 23.514C15.4667 23.3681 15.1568 23.1684 14.9011 22.9073C14.6376 22.6462 14.4362 22.3467 14.289 21.9935C14.1418 21.6479 14.072 21.2793 14.072 20.88C14.072 20.4883 14.1418 20.1197 14.289 19.7741C14.4362 19.4362 14.6376 19.129 14.9011 18.8756C15.1568 18.6222 15.4667 18.4148 15.8076 18.2689C16.1563 18.123 16.5282 18.0539 16.9233 18.0539H26.2287V14.3984H16.9233C16.0245 14.3984 15.1723 14.5751 14.3897 14.913C13.5994 15.2509 12.9021 15.7193 12.3055 16.3106C11.7166 16.8943 11.244 17.5854 10.8953 18.3687C10.5544 19.1521 10.384 19.9968 10.384 20.88C10.384 21.7708 10.5544 22.6232 10.9031 23.4065C11.244 24.1898 11.7166 24.8887 12.3055 25.4723C12.9021 26.0636 13.5994 26.5321 14.3897 26.87C15.1723 27.2079 16.0245 27.3845 16.9233 27.3845H21.6341C22.0215 27.3845 22.4012 27.4536 22.7498 27.5995C23.0907 27.7454 23.4007 27.9528 23.6564 28.2062C23.912 28.4596 24.1212 28.7668 24.2684 29.1047C24.4157 29.4503 24.4854 29.8189 24.4854 30.2106C24.4854 30.6022 24.4157 30.9785 24.2684 31.3241C24.1212 31.662 23.912 31.9615 23.6564 32.2226C23.4007 32.476 23.0907 32.6834 22.7498 32.8216C22.4012 32.9675 22.0215 33.0443 21.6341 33.0443H10.8489V36.6921H21.6341C22.5329 36.6921 23.3852 36.5231 24.1755 36.1852C24.9658 35.8473 25.6708 35.3712 26.2597 34.7876C26.8563 34.2039 27.3212 33.5128 27.6621 32.7294C28.003 31.9461 28.1735 31.1014 28.1735 30.2106C28.1735 29.3197 28.003 28.475 27.6621 27.6917Z"
        fill={color}
      />
      <path
        d="M87.0895 27.6917C86.7486 26.9007 86.2837 26.2095 85.6871 25.6182C85.0983 25.0346 84.4009 24.5661 83.6029 24.2359C82.8126 23.898 81.9603 23.7291 81.0615 23.7291H76.3507C75.9556 23.7291 75.5837 23.6599 75.235 23.514C74.8941 23.3681 74.5842 23.1684 74.3285 22.9073C74.0651 22.6462 73.8636 22.3467 73.7164 21.9935C73.5692 21.6479 73.4995 21.2793 73.4995 20.88C73.4995 20.4883 73.5692 20.1197 73.7164 19.7741C73.8636 19.4362 74.0651 19.129 74.3285 18.8756C74.5842 18.6222 74.8941 18.4148 75.235 18.2689C75.5837 18.123 75.9556 18.0539 76.3507 18.0539H85.6561V14.3984H76.3507C75.452 14.3984 74.5997 14.5751 73.8171 14.913C73.0268 15.2509 72.3295 15.7193 71.7329 16.3106C71.1441 16.8943 70.6714 17.5854 70.3228 18.3687C69.9819 19.1521 69.8114 19.9968 69.8114 20.88C69.8114 21.7708 69.9819 22.6232 70.3228 23.4065C70.6714 24.1898 71.1441 24.8887 71.7329 25.4723C72.3295 26.0636 73.0268 26.5321 73.8171 26.87C74.5997 27.2079 75.452 27.3845 76.3507 27.3845H81.0615C81.4489 27.3845 81.8286 27.4536 82.1773 27.5995C82.5182 27.7454 82.8281 27.9528 83.0838 28.2062C83.3395 28.4596 83.5487 28.7668 83.6959 29.1047C83.8431 29.4503 83.9128 29.8189 83.9128 30.2106C83.9128 30.6022 83.8431 30.9785 83.6959 31.3241C83.5487 31.662 83.3395 31.9615 83.0838 32.2226C82.8281 32.476 82.5182 32.6834 82.1773 32.8216C81.8286 32.9675 81.4489 33.0443 81.0615 33.0443H70.2763V36.6921H81.0615C81.9526 36.6921 82.8126 36.5231 83.6029 36.1852C84.3932 35.8473 85.0983 35.3712 85.6871 34.7876C86.2837 34.2039 86.7486 33.5128 87.0895 32.7294C87.4304 31.9461 87.6009 31.1014 87.6009 30.2106C87.6009 29.3197 87.4304 28.475 87.0895 27.6917Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.586 17.8771H124.048C126.093 17.8771 127.743 19.5205 127.743 21.5403C127.743 23.5676 126.093 25.2111 124.048 25.2111H117.586V17.8771ZM127.844 36.7073H132.408L126.357 28.2982C129.208 27.346 131.261 24.6735 131.261 21.5326C131.261 17.5853 128.038 14.3906 124.063 14.3906H117.586H115.641H113.696V36.715H117.586V28.6668H122.111L127.844 36.7073Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.716 14.4062V27.4845C105.716 30.5025 103.244 32.9523 100.191 32.9523C97.1387 32.9523 94.667 30.5025 94.667 27.4845V14.4062H90.917V27.4845C90.917 32.5606 95.0699 36.6692 100.191 36.6692C105.313 36.6692 109.466 32.5606 109.466 27.4845V14.4062H105.716Z"
        fill={color}
      />
    </svg>
  );
};
SatsureIcon.defaultProps = {
  color: '#42444a',
  height: 51,
  width: 164
};
export default SatsureIcon;
