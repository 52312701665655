import { lightGrey1, white } from 'constants/color.constant';

export const riskstyles = {
  cardDivider: {
    borderTop: `0.5px solid ${lightGrey1}`,
    margin: '7px 0 5px 0'
  },
  dataCardDivider: {
    borderTop: `0.5px solid ${lightGrey1}`,
    margin: '3px 0'
  },
  dataCardDivider1: {
    borderTop: `0.5px solid ${lightGrey1}`,
    margin: '8px 0'
  }
};
export const annotationStyles = {
  annotationLabel: {
    color: white,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 8
  }
};
export const sidebarStyles = {
  featureContent: {
    borderBottom: `0.5px solid ${lightGrey1}`
  }
};
export const projectListStyles = {
  listTable: {
    backgroundColor: 'unset !important',
    height: '100% !important',
    display: 'flex',
    alignItem: 'center'
  }
};
