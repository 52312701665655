import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { filter, orderBy } from 'lodash';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Button, Empty, Input, Modal, PageHeader, Tooltip, Typography } from 'antd';
import { BsArrowUpShort } from 'react-icons/bs';
import EditFileIcon from 'assets/svg/EditFileIcon';
import FolderIcon from 'assets/svg/FolderIcon';
import LeftArrowIcon from 'assets/svg/LeftArrowIcon';
import ListIcon from 'assets/svg/ListIcon';
import MapViewIcon from 'assets/svg/MapViewIcon';
import MapLocationIcon from 'assets/svg/MapLocationIcon';
import { TimezoneContext } from 'context/createusecontext';
import { primary } from 'constants/color.constant';
import { ACCESS_TOKEN, DateFormat2, TimeFormat, USER_ROLE } from 'constants/common.constant';
import { routes } from 'constants/pageRoutes.constants';
import AvatarList from 'components/Avatar/AvatarList';
import AddProjectMemberModal from 'components/Modals/AddProjectMember/AddProjectMemberModal';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { getOrganizationMembers } from 'store/organizations/actions';
import { setSelectedProject } from 'store/projects';
import { getAvailableProjectMembers, getProjectTypes, getProjects } from 'store/projects/actions';
import { Project, ProjectCategoryTypes } from 'store/projects/types';
import { UserRole } from 'store/users/types';
import {
  setRiskBuffer,
  setRiskTowers,
  setRiskTrees,
  setRiskTransmissionLine,
  setriskTransmissionSegments
} from 'store/riskanalysis';
import { projectListStyles } from 'styles/projectView.styles';
import { getAsyncStorageValue, getStorageValue } from 'utils/localStorage';
import './styles.less';

const { Text } = Typography;

const ProjectList = () => {
  const timeZone = useContext(TimezoneContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userRole = getStorageValue(USER_ROLE);
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const { projects } = useAppSelector((state) => state.projects);
  const [filterText, setFilterText] = useState<string>('');
  const [isOpenAddProjectMemberModal, setAddProjectMemberModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Project | null>(null);

  const handleEmptyRiskdata = () => {
    dispatch(setRiskTrees([]));
    dispatch(setRiskBuffer(null));
    dispatch(setRiskTowers([]));
    dispatch(setRiskTransmissionLine(null));
    dispatch(setriskTransmissionSegments([]));
  };

  useEffect(() => {
    if (selectedOrganization) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getProjects(selectedOrganization.id, token));
        dispatch(setSelectedProject(null));
        handleEmptyRiskdata();
        dispatch(getProjectTypes(token));
        if (userRole !== UserRole.Client) {
          dispatch(getOrganizationMembers(selectedOrganization?.id, token));
        }
      });
    }
  }, []);

  const handleEditProject = (record: Project) => {
    dispatch(setSelectedProject(record));
    navigate(
      `/edit-project/${record.id}?organization_id=${selectedOrganization?.id}&project_id=${record.id}`
    );
  };

  const handleNavigateFileRepo = (record: Project) => {
    dispatch(setSelectedProject(record));
    navigate(
      `${routes.RepoFile}?organization_id=${selectedOrganization?.id}&project_id=${record.id}`
    );
  };

  const handleNavigateProjectView = (record: Project) => {
    dispatch(setSelectedProject(record));

    if (
      record &&
      (record.type === ProjectCategoryTypes.VegetationManagementINTL ||
        record.type === ProjectCategoryTypes.VegetationManagementLite)
    ) {
      navigate(
        `${routes.ProjectViewVegInt}?organization_id=${selectedOrganization?.id}&project_id=${record.id}`
      );
    } else {
      navigate(
        `${routes.ProjectView}?organization_id=${selectedOrganization?.id}&project_id=${record.id}`
      );
    }
  };

  const handleCloseAddProjectMemberModal = () => {
    setSelectedRow(null);
    setAddProjectMemberModal(false);
  };

  const handleAddProjectMemberForm = (row: Project) => {
    if (userRole !== UserRole.Client) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getAvailableProjectMembers(selectedOrganization.id, row.id, token));
      });
    }
    setAddProjectMemberModal(!isOpenAddProjectMemberModal);
    setSelectedRow(row);
  };

  const sortFunctionForLastUpdatedDate = (rowA: Project, rowB: Project) => {
    const a1 = new Date(rowA.last_updated || new Date()).getTime();
    const b1 = new Date(rowB.last_updated || new Date()).getTime();
    if (a1 < b1) {
      return 1;
    }
    if (a1 > b1) {
      return -1;
    }
    return 0;
  };

  const customStyles = {
    noData: { style: projectListStyles.listTable }
  };

  const projectDataFilter: Array<Project> | [] = filter(projects, (item: Project) => {
    if (filterText && filterText?.length >= 1) {
      return item.name.toLowerCase().includes(filterText.toLowerCase());
    }
    return projects;
  });

  const columns: TableColumn<Project>[] = [
    {
      name: 'PROJECT ID',
      width: '150px',
      selector: (row) => row.id,
      sortable: true
    },
    {
      name: 'PROJECT NAME',
      width: '200px',
      style: { paddingLeft: '2px' },
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: 'TYPE OF PROJECT',
      width: '250px',
      style: { paddingLeft: '2px' },
      selector: (row) => row.type
    },
    {
      name: 'COORDINATES',
      width: '220px',
      style: { paddingRight: '3px', paddingLeft: '2px' },
      cell: (row) =>
        useMemo(
          () => (
            <div className="project-coords">
              {row.coordinates && row.coordinates.length > 0 ? (
                <>
                  <Text style={{ display: 'block' }}>{Number(row.coordinates[0]).toFixed(6)},</Text>
                  <Text style={{ display: 'block' }}>{Number(row.coordinates[1]).toFixed(6)}</Text>
                </>
              ) : (
                '-'
              )}
            </div>
          ),
          [row.coordinates]
        )
    },
    {
      name: 'LAST UPDATED',
      width: '200px',
      style: { paddingLeft: '2px' },
      sortable: true,
      sortFunction: sortFunctionForLastUpdatedDate,
      cell: (row) =>
        useMemo(() => {
          const date: any = row.last_updated
            ? moment.utc(row.last_updated).local().format(DateFormat2)
            : '-';
          const time: any = row.last_updated
            ? `${moment.utc(row.last_updated).local().format(TimeFormat)} ${timeZone?.timezone}`
            : '';

          return (
            <div className="project-last-update">
              <Text style={{ display: 'block' }}>{date}</Text>
              <Text style={{ display: 'block' }}>{time}</Text>
            </div>
          );
        }, [row.last_updated])
    },
    {
      name: 'TEAM MEMBERS',
      style: { paddingLeft: '9px' },
      cell: (row) =>
        useMemo(
          () => (
            <AvatarList project={row} handleAddProjectMemberForm={handleAddProjectMemberForm} />
          ),
          [row.team_members.length]
        )
    },
    {
      name: 'ACTIONS',
      cell: (row) =>
        useMemo(
          () => (
            <div className="project-action">
              {userRole === UserRole.SuperAdmin && (
                <>
                  <Tooltip
                    placement="bottom"
                    overlayClassName="tooltip-title"
                    title={<Text>Edit</Text>}
                  >
                    <Button
                      type="link"
                      style={{ padding: '0 5px', height: '20px' }}
                      onClick={() => handleEditProject(row)}
                      icon={<EditFileIcon color={primary} />}
                    />
                  </Tooltip>
                  <Tooltip
                    placement="bottom"
                    overlayClassName="tooltip-title"
                    title={<Text>File Repo</Text>}
                  >
                    <Button
                      type="link"
                      style={{ padding: '0 5px', height: '20px' }}
                      onClick={() => handleNavigateFileRepo(row)}
                      icon={<FolderIcon />}
                    />
                  </Tooltip>
                </>
              )}
              <Tooltip
                placement="bottom"
                overlayClassName="tooltip-title"
                title={<Text>Map View</Text>}
              >
                <Button
                  type="link"
                  onClick={() => handleNavigateProjectView(row)}
                  icon={<MapLocationIcon />}
                />
              </Tooltip>
            </div>
          ),
          [row]
        )
    }
  ];

  return (
    <div className="project-list-main">
      <PageHeader
        className="project-list-header"
        onBack={() => navigate(routes.Organization)}
        backIcon={
          userRole === UserRole.SuperAdmin ? (
            <Button
              icon={<LeftArrowIcon color="black" />}
              type="text"
              shape="circle"
              className="back-icon"
            />
          ) : null
        }
        title={selectedOrganization?.name}
        subTitle={
          <Input
            className="project-search"
            type="text"
            placeholder="Search by Project Name..."
            value={filterText}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const filterValue = (e.target as HTMLInputElement).value;
              setFilterText(filterValue);
            }}
          />
        }
        extra={[
          userRole === UserRole.SuperAdmin && (
            <Button
              className="user-mng-btn"
              key="3"
              type="link"
              onClick={() =>
                navigate(`${routes.UserManagement}?organization_id=${selectedOrganization?.id}`)
              }
            >
              User Management
            </Button>
          ),
          userRole === UserRole.SuperAdmin && (
            <Button
              className="create-peoject-btn"
              key="2"
              onClick={() =>
                navigate(`${routes.AddProject}?organization_id=${selectedOrganization?.id}`)
              }
            >
              Create project
            </Button>
          ),
          <Button.Group className="list-map-view" key="list_map_view">
            <Tooltip
              placement="bottom"
              overlayClassName="tooltip-title"
              title={<Text>List View</Text>}
            >
              <Button type="primary" key="list_view" icon={<ListIcon />} />
            </Tooltip>
            <Tooltip
              placement="bottomRight"
              overlayClassName="tooltip-title"
              title={<Text>Map View</Text>}
            >
              <Button
                onClick={() =>
                  navigate(`${routes.Mapview}?organization_id=${selectedOrganization?.id}`)
                }
                icon={<MapViewIcon color="#231F20" />}
                key="map_view"
              />
            </Tooltip>
          </Button.Group>
        ]}
      />
      <div className="project-list">
        <DataTable
          fixedHeader
          responsive
          fixedHeaderScrollHeight="500px"
          columns={columns}
          data={orderBy(projectDataFilter, ['name'], ['asc'])}
          sortIcon={<BsArrowUpShort />}
          subHeader
          subHeaderWrap
          subHeaderComponent={null}
          onRowClicked={(row) => handleNavigateProjectView(row)}
          noDataComponent={
            <Empty
              imageStyle={{ height: 0 }}
              description={<Text className="descriptionText">NO PROJECTS FOUND</Text>}
            />
          }
          customStyles={customStyles}
        />
        <div className="main-project-footer">
          <div className="companyProjectContent">
            <Text>Company’s Projects</Text>
            <Text>{projects?.length > 0 ? projects.length : 0}</Text>
          </div>
        </div>
      </div>
      {isOpenAddProjectMemberModal && selectedRow && (
        <Modal
          open={isOpenAddProjectMemberModal}
          okText="Create"
          cancelText="Cancel"
          centered
          width={697}
          className="newModal"
          closable={false}
          footer={null}
        >
          <AddProjectMemberModal
            handleCloseAddProjectMemberModal={handleCloseAddProjectMemberModal}
            selectedRow={selectedRow}
            selectedOrganization={selectedOrganization}
          />
        </Modal>
      )}
    </div>
  );
};

export default ProjectList;
