import React from 'react';
import { Typography } from 'antd';
import { isEmpty } from 'lodash';
import EyeCloseIcon from 'assets/svg/EyeCloseIcon';
import EyeOpenIcon from 'assets/svg/EyeOpenIcon';
import { white } from 'constants/color.constant';
import { AddedAnnotation } from 'store/annotations/types';
import { getCenterPosition, getCenterPositionPolygon } from 'utils/xml';
import { CoordinateType } from 'types/common.types';

const { Text } = Typography;
interface AnnotationsPolygonCardProps {
  icon: React.ReactNode;
  anotation: AddedAnnotation;
  selectedMarkings: number[];
  handleShowMarkings: (data: AddedAnnotation) => void;
}

const AnnotationsPolygonCard = ({
  icon,
  anotation,
  selectedMarkings,
  handleShowMarkings
}: AnnotationsPolygonCardProps) => {
  let centerLatLng;
  if (anotation.geometry.type === CoordinateType.MultiPolygon) {
    centerLatLng = getCenterPosition(anotation.geometry.coordinates);
  } else if (anotation.geometry.type === CoordinateType.Polygon) {
    centerLatLng = getCenterPositionPolygon(anotation.geometry.coordinates);
  }

  return (
    <div className="tab-inr-content">
      <div className="tab-content-head">
        <div>
          {icon}
          <div>
            <Text>{!isEmpty(anotation.properties) ? anotation.properties?.name : ''}</Text>
            <Text>{anotation.properties?.category}</Text>
          </div>
        </div>
        <div>
          {selectedMarkings.includes(anotation.id) ? (
            <EyeOpenIcon color={white} onClick={() => handleShowMarkings(anotation)} />
          ) : (
            <EyeCloseIcon color={white} onClick={() => handleShowMarkings(anotation)} />
          )}
        </div>
      </div>
      <div className="tab-content-desc">
        <Text>Area - {!isEmpty(anotation.properties) ? anotation.properties?.area : 0} sq km</Text>
        <Text>
          Coordinates -{' '}
          {
            // eslint-disable-next-line
            centerLatLng && centerLatLng.lat
              ? centerLatLng.lat > 0
                ? `${centerLatLng.lng.toFixed(3)} N,`
                : `${centerLatLng.lng.toFixed(3)} S,`
              : ''
          }
          {
            // eslint-disable-next-line
            centerLatLng && centerLatLng.lng
              ? centerLatLng.lng > 0
                ? ` ${centerLatLng.lat.toFixed(3)} E`
                : ` ${centerLatLng.lat.toFixed(3)} W`
              : ''
          }
        </Text>
        <Text>E Count - {!isEmpty(anotation.properties) ? anotation.properties?.count : 0}</Text>
      </div>
    </div>
  );
};

export default AnnotationsPolygonCard;
