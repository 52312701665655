import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import moment from 'moment';
import { nth } from 'lodash';
import { Button } from 'antd';
import LeftIcon from 'assets/svg/LeftIcon';
import RightIcon from 'assets/svg/RightIcon';
import { DateFormat2 } from 'constants/common.constant';
import NavButton from 'components/core/NavButton';
import { DataLayerType } from 'store/repositoryfile/types';
import './styles.less';

interface SlickSliderProps {
  dataLayers: DataLayerType[];
  dataIndex?: number;
  slideWidth?: string;
  infinite?: boolean;
  slidesPerView?: number;
  handleActiveLayer?: Function;
  className?: string;
  handleActiveDataLayer?: (data: DataLayerType) => void;
}

const SlickSlider = ({
  dataLayers,
  dataIndex,
  slidesPerView,
  slideWidth,
  infinite,
  handleActiveLayer,
  className,
  handleActiveDataLayer
}: SlickSliderProps) => {
  const sliderRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    if (dataIndex) {
      // @ts-ignore
      sliderRef.current.slickGoTo(dataIndex);
      setActiveIndex(dataIndex);
    }
  }, [dataIndex]);

  const settings = {
    focusOnSelect: true,
    infinite,
    slidesToShow: slidesPerView,
    slidesToScroll: 1,
    initialSlide: dataIndex,
    afterChange: (current: any) => setActiveIndex(current),
    prevArrow: (
      <NavButton>
        <Button className="slick-prev slick-arrow" icon={<LeftIcon />} />
      </NavButton>
    ),
    nextArrow: (
      <NavButton>
        <Button className="slick-next slick-arrow" icon={<RightIcon />} />
      </NavButton>
    )
  };

  useEffect(() => {
    const data: DataLayerType | undefined = nth(dataLayers, activeIndex);
    if (!data) return;
    if (handleActiveLayer) handleActiveLayer({ id: data.id, date: data.date });
    if (handleActiveDataLayer) handleActiveDataLayer(data);
  }, [activeIndex]);

  return (
    <div className={className} style={{ width: slideWidth }}>
      <Slider ref={sliderRef} className="layer-slider" {...settings}>
        {dataLayers.map((layer: DataLayerType) => (
          <div className="layer-slide" key={layer.id}>
            {layer.date ? moment(layer.date).format(DateFormat2) : '-'}
          </div>
        ))}
      </Slider>
    </div>
  );
};

SlickSlider.defaultProps = {
  dataIndex: 0,
  slideWidth: '65px',
  infinite: false,
  slidesPerView: 1,
  className: '',
  handleActiveLayer: () => {},
  handleActiveDataLayer: () => {}
};

export default SlickSlider;
