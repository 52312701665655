import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const FolderIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3613 13.5795C17.9557 13.97 17.4179 14.2212 16.8253 14.2636L15.1874 14.3808C11.7336 14.6279 8.26649 14.6279 4.81264 14.3808L3.29864 14.2725C2.10261 14.1869 1.1161 13.3027 0.90058 12.1232C0.463915 9.73327 0.389159 7.29129 0.678818 4.87915L0.951304 2.61004C1.11301 1.26343 2.25541 0.25 3.6117 0.25H5.90327C7.02102 0.25 7.95229 1.04846 8.1578 2.10612L16.4722 2.10612C17.7786 2.10612 18.8913 3.05533 19.0972 4.34536L19.1613 4.74679C19.1748 4.83114 19.1877 4.91554 19.2002 5H19.5362C21.0086 5 22.0209 6.47983 21.4873 7.85212L20.2378 11.065C19.8498 12.0626 19.1989 12.9301 18.3613 13.5795ZM17.616 4.58178L17.6801 4.98322C17.6809 4.98881 17.6818 4.99441 17.6827 5H8.37046C7.23475 5 6.21588 5.69815 5.80589 6.75727L3.47405 12.7812L3.40568 12.7763C2.8922 12.7396 2.46868 12.36 2.37615 11.8536C1.96652 9.61162 1.89639 7.32081 2.16812 5.058L2.4406 2.78888C2.51179 2.19611 3.01467 1.75 3.6117 1.75H5.90327C6.34331 1.75 6.70003 2.10672 6.70003 2.54677C6.70003 3.13183 7.17432 3.60612 7.75939 3.60612H16.4722C17.0414 3.60612 17.5263 4.0197 17.616 4.58178ZM5.03921 12.8931C8.38224 13.1266 11.7376 13.1238 15.0804 12.8846L16.7183 12.7674L16.9128 12.7492L16.9115 12.7468C17.792 12.25 18.4734 11.4636 18.8398 10.5213L20.0893 7.30845C20.2405 6.91947 19.9536 6.5 19.5362 6.5H8.37046C7.85423 6.5 7.3911 6.81734 7.20475 7.29876L5.03921 12.8931Z"
        fill={color}
      />
    </svg>
  );
};
FolderIcon.defaultProps = {
  color: primary,
  height: 15,
  width: 22
};

export default FolderIcon;
