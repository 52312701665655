import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import { orderBy } from 'lodash';
import LeftArrowIcon from 'assets/svg/LeftArrowIcon';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { routes } from 'constants/pageRoutes.constants';
import RequiredTitle from 'components/core/RequiredTitle';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { addProject } from 'store/projects/actions';
import { AddProject as AddProjectType, ProjectPayload, ProjectType } from 'store/projects/types';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const { Text } = Typography;
const { Option } = Select;

const AddProject = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const { isAddProjectRequesting, projectTypes } = useAppSelector((state) => state.projects);

  const handleNavigate = () => {
    navigate(`${routes.ProjectList}?organization_id=${selectedOrganization?.id}`);
  };

  const handleCallback = (status: boolean) => {
    if (status) {
      handleNavigate();
    }
  };

  const handleSubmitAddProject = (values: AddProjectType) => {
    const coord: number[] = values.coordinate.split(',').map((item) => Number(item.trim()));

    const payload: ProjectPayload = {
      project_title: values.project_title,
      type: +values.type,
      coordinate: coord
    };

    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(addProject(payload, selectedOrganization.id, token, handleCallback));
    });
  };
  return (
    <div className="main-add-edit-org">
      <Row className="org-header" justify="space-between">
        <Col span={24} className="org-title flex" onClick={handleNavigate}>
          <Button
            icon={<LeftArrowIcon color="black" />}
            type="text"
            shape="circle"
            className="back-icon"
          />
          <Text style={{ marginLeft: '12px', fontSize: '24px' }}>Create Project</Text>
        </Col>
        <Col span={24} className="main-org-list">
          <Row className="main-add-org-content">
            <Col className="org-detail-form-div">
              <Form className="add-org-form" form={form} onFinish={handleSubmitAddProject}>
                <RequiredTitle
                  title="Enter name of the Project"
                  isRequired
                  style={{ marginBottom: '18px' }}
                />
                <Form.Item
                  className="input-item"
                  name="project_title"
                  rules={[{ required: true, message: 'Required!' }]}
                >
                  <Input className="form-input-text" placeholder="Enter name of the Project" />
                </Form.Item>
                <RequiredTitle
                  title="Type of Project"
                  isRequired
                  style={{ marginBottom: '18px' }}
                />
                <Form.Item
                  className="input-item"
                  name="type"
                  rules={[{ required: true, message: 'Required!' }]}
                >
                  <Select className="member-select" placeholder="Select" allowClear>
                    {projectTypes &&
                      projectTypes.length > 0 &&
                      orderBy(projectTypes, ['type']).map((types: ProjectType) => {
                        return (
                          <Option value={types.id} key={types.type}>
                            {types.type}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <RequiredTitle isRequired style={{ marginBottom: '18px' }} title="Co ordinates" />
                <Form.Item
                  className="input-item"
                  name="coordinate"
                  rules={[{ required: true, message: 'Required!' }]}
                >
                  <Input className="form-input-text" placeholder="Enter co-ordinates of project" />
                </Form.Item>
                <div className="skip-btn-content btn-content">
                  <Button type="ghost" onClick={handleNavigate}>
                    Skip
                  </Button>
                  <Button type="primary" htmlType="submit" loading={isAddProjectRequesting}>
                    Create
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AddProject;
