import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'scripts/wmts';
import { DataLayerFileType } from 'store/repositoryfile/types';
import { GET_GEOSERVER_WMTS_URL } from 'utils/apiUrls';

interface WMTSLULCTileLayerProps {
  selectedLulcLayer: string[];
  lulcRepoFile: DataLayerFileType;
  env: string;
}

const WMTSLULCTileLayer = ({ env, selectedLulcLayer, lulcRepoFile }: WMTSLULCTileLayerProps) => {
  const map = useMap();
  const wmsLayerGroup = L.featureGroup();

  useEffect(() => {
    if (selectedLulcLayer.includes(env)) return;

    const wmtsParams = {
      layer: `skies_explorer:${lulcRepoFile.gs_layer_name}`,
      env: `${env}:1`,
      format: 'image/png8',
      transparent: true,
      maxZoom: 20,
      pane: 'overlayPane',
      opacity: 1,
      tilematrixset: 'EPSG:900913',
      height: 256,
      width: 256
    };

    const wmtslayer = L.tileLayer
      // @ts-ignore
      .wmts(GET_GEOSERVER_WMTS_URL, wmtsParams)
      .addTo(map);
    wmsLayerGroup.addLayer(wmtslayer);
    wmsLayerGroup.addTo(map);

    // eslint-disable-next-line
    return () => {
      wmsLayerGroup.removeFrom(map);
    };
  }, [selectedLulcLayer]);

  return null;
};

export default WMTSLULCTileLayer;
