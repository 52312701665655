import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { filter, find, orderBy } from 'lodash';
import { ProjectCategoryTypes } from 'store/projects/types';
import { RootState } from '..';
import { repoFileSlice } from './slice';
import {
  DataLayerFileType,
  DataLayerType,
  FileTypes,
  GeoserverLayerLegendType,
  LulcDataType,
  RepoFileCategory,
  RepoFileType
} from './types';

export const {
  setRequestingDataLayers,
  setDataLayers,
  setRequestAddDateDataLayers,
  setRequestEditDateDataLayers,
  setRequestFileTypes,
  setFileTypes,
  setRequestAddRepoFileDataLayers,
  setRequestEditRepoFile,
  setRequestingDeleteRepoFile,
  setRequestingDownloadRepoFile,
  setRequestDeleteDateDataLayers,
  setRequestingGeoserverLayerStyles,
  setGeoserverLayerStyles,
  setEnableDisableDatalayer,
  clearRepositoryFiles
} = repoFileSlice.actions;
export default repoFileSlice.reducer;

const selectDataLayers = (state: RootState) => state.repositoryFiles.dataLayers;
const selectFileTypes = (state: RootState) => state.repositoryFiles.fileTypes;
const selectDate = (state: RootState, id: number | null) => id;
const selectFileType = (state: RootState, name: string | null) => name;
const selectProjectType = (state: RootState, type: string | null, projectType: string) =>
  projectType;

export const getEnableDataLayers = createSelector([selectDataLayers], (dataLayers) => {
  if (dataLayers && dataLayers.length === 0) return [];
  const filteredLayer = filter(dataLayers, ['is_enabled', true]);
  if (!filteredLayer) return [];

  return orderBy(
    filteredLayer,
    (o: DataLayerType) => {
      return moment(o.date);
    },
    ['asc']
  );
});

export const getDataLayerFiles = createSelector(
  [selectDataLayers, selectDate],
  (dataLayers, layerId) => {
    if (!layerId) return [];
    const filteredLayer = find(dataLayers, ['id', layerId]);
    if (!filteredLayer) return [];

    return filteredLayer.files;
  }
);

export const getDataLayerDateList = createSelector([selectDataLayers], (dataLayers) => {
  if (dataLayers && dataLayers.length === 0) {
    return [];
  }
  return dataLayers.map((layer: DataLayerType) => {
    return { id: layer.id, date: layer.date };
  });
});

export const getFileType = createSelector([selectFileTypes], (fileTypes) => {
  if (fileTypes && fileTypes.length === 0) {
    return [];
  }
  return fileTypes.map((type: FileTypes) => {
    return { name: type.name };
  });
});

export const getFileTypeCategory = createSelector(
  [selectFileTypes, selectFileType, selectProjectType],
  (fileTypes, type, projectType) => {
    if (!type) return [];

    const typeCategoryList = find(fileTypes, ['name', type]);
    if (!typeCategoryList) return [];

    if (
      projectType === ProjectCategoryTypes.VegetationManagementINTL ||
      projectType === ProjectCategoryTypes.VegetationManagementLite
    ) {
      if (type === RepoFileType.Rasterfiles) {
        return [
          {
            name: 'LULC',
            id: 4,
            project_type_name: null
          },
          {
            name: 'Satellite Images',
            id: 5,
            project_type_name: null
          }
        ];
      }
      if (type === RepoFileType.PDFreport) {
        return [
          {
            name: 'PDF',
            id: 9,
            project_type_name: null
          }
        ];
      }
      return filter(
        typeCategoryList.categories,
        (data: FileTypes) =>
          data.project_type_name === ProjectCategoryTypes.VegetationManagementINTL ||
          data.project_type_name === ProjectCategoryTypes.VegetationManagementLite ||
          data.project_type_name === ProjectCategoryTypes.VegetationManagement
      );
    }

    if (projectType === ProjectCategoryTypes.VegetationManagement) {
      return filter(
        typeCategoryList.categories,
        (data: FileTypes) =>
          data.project_type_name === null ||
          data.project_type_name === ProjectCategoryTypes.VegetationManagement
      );
    }

    return filter(
      typeCategoryList.categories,
      (data: FileTypes) => data.project_type_name === null
    );
  }
);

export const getLULCRepoFile = createSelector(
  [selectDataLayers, selectDate],
  (dataLayers, layerId) => {
    if (!layerId) return null;
    const filteredLayer = find(dataLayers, { id: layerId, is_enabled: true });
    if (!filteredLayer) return null;
    const { files } = filteredLayer;
    if (files && files.length === 0) return null;

    // eslint-disable-next-line
    return find(files, (file: DataLayerFileType) => file.category === RepoFileCategory.LULC);
  }
);

export const getDSMRepoFile = createSelector(
  [selectDataLayers, selectDate],
  (dataLayers, layerId) => {
    if (!layerId) return null;
    const filteredLayer = find(dataLayers, { id: layerId, is_enabled: true });
    if (!filteredLayer) return null;
    const { files } = filteredLayer;
    if (files && files.length === 0) return null;

    // eslint-disable-next-line
    return find(files, (file: DataLayerFileType) => file.category === RepoFileCategory.DSM);
  }
);

export const getDEMRepoFile = createSelector(
  [selectDataLayers, selectDate],
  (dataLayers, layerId) => {
    if (!layerId) return null;
    const filteredLayer = find(dataLayers, { id: layerId, is_enabled: true });
    if (!filteredLayer) return null;
    const { files } = filteredLayer;
    if (files && files.length === 0) return null;

    // eslint-disable-next-line
    return find(files, (file: DataLayerFileType) => file.category === RepoFileCategory.DEM);
  }
);

export const getSataliteImagesRepoFile = createSelector(
  [selectDataLayers, selectDate],
  (dataLayers, layerId) => {
    if (!layerId) return null;
    const filteredLayer = find(dataLayers, { id: layerId, is_enabled: true });
    if (!filteredLayer) return null;
    const { files } = filteredLayer;
    if (files && files.length === 0) return null;

    // eslint-disable-next-line
    return find(
      files,
      (file: DataLayerFileType) => file.category === RepoFileCategory.SataliteImages
    );
  }
);

export const getSataliteImagesRepoFiles = createSelector(
  [selectDataLayers, selectDate],
  (dataLayers, layerId) => {
    if (!layerId) return [];
    const filteredLayer = find(dataLayers, { id: layerId, is_enabled: true });
    if (!filteredLayer) return [];
    const { files } = filteredLayer;
    if (files && files.length === 0) return [];

    // eslint-disable-next-line
    return filter(
      files,
      (file: DataLayerFileType) => file.category === RepoFileCategory.SataliteImages
    );
  }
);

export const getOrthomosiacRepoFile = createSelector(
  [selectDataLayers, selectDate],
  (dataLayers, layerId) => {
    if (!layerId) return null;
    const filteredLayer = find(dataLayers, { id: layerId, is_enabled: true });
    if (!filteredLayer) return null;
    const { files } = filteredLayer;
    if (files && files.length === 0) return null;

    // eslint-disable-next-line
    return find(files, (file: DataLayerFileType) => file.category === RepoFileCategory.Orthomosiac);
  }
);

const selectedGeoserverLayerStyles = (state: RootState) =>
  state.repositoryFiles.geoserverLayerStyles;
export const getLulcStyleLayers = createSelector(
  [selectedGeoserverLayerStyles],
  (geoserverLayerStyles): LulcDataType[] => {
    if (!geoserverLayerStyles) return [];
    const layers = geoserverLayerStyles.map((style: GeoserverLayerLegendType) => {
      return { title: style.label, value: style.env, color: style.color };
    });

    return orderBy(layers, ['title'], ['asc']);
  }
);

// riskanalysis-tab-veg-int
