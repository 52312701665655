import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Image } from 'antd';
import { findIndex } from 'lodash';
import CloseIcon from 'assets/svg/CloseIcon';
import SatsureSkies from 'assets/svg/SatsureLogoWhite.svg';
import { routes } from 'constants/pageRoutes.constants';
import SlickSlider from 'components/SlickSlider';
import { useAppDispatch } from 'hooks/useReduxHook';
import { setProject, setSelectedProject } from 'store/projects';
import { clearRepositoryFiles } from 'store/repositoryfile';
import { DataLayerTableType, DataLayerType } from 'store/repositoryfile/types';
import { clearVectorfile } from 'store/vectorfile';
import { clearRiskAnalysis } from 'store/riskanalysis';

interface ProjectCompareViewHeaderVegINTLProps {
  dataLayers: DataLayerType[] | [];
  activeDataLayer: DataLayerTableType | null;
  handleLeftActiveLayer: Function;
  handleRightActiveLayer: Function;
  handleCloseCompareView: () => void;
}

const ProjectCompareViewHeaderVegINTL = ({
  dataLayers,
  activeDataLayer,
  handleLeftActiveLayer,
  handleRightActiveLayer,
  handleCloseCompareView
}: ProjectCompareViewHeaderVegINTLProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [dataIndex, setDataIndex] = useState(0);

  useEffect(() => {
    const dataIndexNum = findIndex(dataLayers, (o: DataLayerType) => {
      return o.id === activeDataLayer?.id;
    });
    if (dataIndexNum) {
      setDataIndex(dataIndexNum);
    }
  }, [dataLayers]);

  const handleBackNavigate = () => {
    dispatch(setProject(null));
    dispatch(setSelectedProject(null));
    dispatch(clearRiskAnalysis());
    dispatch(clearRepositoryFiles());
    dispatch(clearVectorfile());
    navigate(routes.ProjectList, { replace: true });
  };

  return (
    <Row className="project-view-header project-compare-header">
      <Col span={2} className="home-back">
        <div onClick={handleBackNavigate}>
          <Image className="skies-logo" src={SatsureSkies} alt="SatsureSkies" preview={false} />
        </div>
      </Col>
      <Col span={10}>
        {dataLayers && dataLayers.length > 0 && (
          <SlickSlider
            dataLayers={dataLayers}
            dataIndex={dataIndex}
            handleActiveLayer={handleLeftActiveLayer}
          />
        )}
      </Col>
      <Col span={10}>
        {dataLayers && dataLayers.length > 0 && (
          <SlickSlider
            dataLayers={dataLayers}
            dataIndex={dataIndex}
            handleActiveLayer={handleRightActiveLayer}
          />
        )}
      </Col>
      <Col span={2}>
        <div onClick={handleCloseCompareView}>
          <CloseIcon />
        </div>
      </Col>
    </Row>
  );
};

export default ProjectCompareViewHeaderVegINTL;
