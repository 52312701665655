import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalize, isEmpty, upperFirst } from 'lodash';
import { Col, Dropdown, Image, MenuProps, Row, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import LogOutIcon from 'assets/svg/LogOutIcon.svg';
import HeaderLogo from 'assets/svg/SatsureLogoWhite.svg';
import SettingIcon from 'assets/svg/SettingIcon.svg';
import UserIcon from 'assets/svg/UserIcon';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import UserDetail from 'components/core/UserDetail';
import { USER_ROLE } from 'constants/common.constant';
import { routes } from 'constants/pageRoutes.constants';
import { userLogout } from 'store/users/actions';
import { UserRole, userRoleText } from 'store/users/types';
import { shortLabel } from 'utils/utils';
import { getStorageValue } from 'utils/localStorage';
import { decryptValue } from 'utils/decodeEncodeData';
import './styles.less';

const { Text } = Typography;

const MainHeader = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const userRole = getStorageValue(USER_ROLE);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [compressName, setCompressName] = useState<string | null>(null);
  const [role, setUserRole] = useState<string | null>(null);

  useEffect(() => {
    if (!isEmpty(user)) {
      setUserRole(userRoleText[userRole]);
      if (user.firstname && user.lastname) {
        setFirstName(upperFirst(decryptValue(user.firstname)));
        setLastName(upperFirst(decryptValue(user.lastname)));
        setCompressName(
          shortLabel(`${decryptValue(user.firstname)} ${decryptValue(user.lastname)}`)
        );
      } else {
        setFirstName(upperFirst(decryptValue(user.email)));
        setCompressName(capitalize(user.email.charAt(0)));
      }
    }
  }, []);

  const handleNavigateLogo = () => {
    if (userRole === UserRole.SuperAdmin) {
      navigate(routes.Organization, { replace: true });
    } else {
      navigate(`${routes.ProjectList}?organization_id=${selectedOrganization?.id}`, {
        replace: true
      });
    }
  };

  const handleNavigateSetting = () => {
    navigate(routes.Settings);
  };

  const handleLogoutCallback = () => {
    navigate(routes.LoginUrl, { replace: true });
    window.location.reload();
  };

  const handleNavigateLogout = () => {
    dispatch(userLogout(handleLogoutCallback));
  };

  const items: MenuProps['items'] = [
    {
      label: <UserDetail cName={compressName} fName={firstName} lName={lastName} role={role} />,
      key: '0'
    },
    {
      label: (
        <div className="profile-setting" onClick={handleNavigateSetting}>
          <Image src={SettingIcon} alt="SettingIcon" preview={false} />
          <Text>Settings</Text>
        </div>
      ),
      key: '1'
    },
    {
      label: (
        <div className="profile-setting" onClick={handleNavigateLogout}>
          <Image src={LogOutIcon} alt="LogOutIcon" preview={false} />
          <Text>Log out</Text>
        </div>
      ),
      key: '2'
    }
  ];

  return (
    <Header className="main-header">
      <Row className="main-header-content">
        <Col span={4} className="header-logo" onClick={handleNavigateLogo}>
          <Image src={HeaderLogo} alt="skies logo" preview={false} />
        </Col>
        <Col span={16} className="header-text">
          SKIES DISCOVER
        </Col>
        <Col span={4} className="header-user-detail">
          <Dropdown menu={{ items }} trigger={['click']}>
            <a onClick={(e) => e.preventDefault()} className="user-icon">
              <Text>
                <UserIcon />
              </Text>
            </a>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};

export default MainHeader;
