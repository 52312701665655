import { CoordinatePointsType, CoordinateType } from 'types/common.types';

export enum RepoFileType {
  Rasterfiles = 'Raster files',
  Vectorfiles = 'Vector files',
  PDFreport = 'PDF report'
}
export enum RepoFileCategory {
  Orthomosiac = 'Orthomosiac',
  DEM = 'DEM',
  DSM = 'DSM',
  LULC = 'LULC',
  SataliteImages = 'Satellite Images',
  VectorPoint = 'Vector - Point',
  VectorPolyline = 'Vector - Polyline',
  VectorPolygon = 'Vector - Polygon',
  PDF = 'PDF',
  Trees = 'Trees',
  TowerLocation = 'Tower location',
  TransmissionLine = 'Transmission line route',
  TransmissionSegment = 'Transmission line segments',
  TransmissionCorridor = 'Transmission line corridor'
}

export interface CoordinatesType {
  points: CoordinatePointsType[] | [];
  length: number | undefined;
  type: CoordinateType;
}

export interface UploadLulcFilePayload {
  orgId: number;
  projectId: number;
  activeLayerId: number;
  categoryId: number;
}

export interface DataLayerFileType {
  category: string;
  gs_layer_name: string | null;
  id: number;
  name: string;
  type: string;
  updated_on: string | null;
  coordinates?: CoordinatesType;
  shapType?: string;
  color?: string;
}

export interface DataLayerType {
  date: string;
  id: number;
  is_enabled: boolean;
  files: DataLayerFileType[] | [];
}

export interface AddDateDataLayerPayload {
  date: string;
}

export interface DataLayerTableType {
  id: number;
  date: string;
}

export interface CategoryType {
  id: number;
  name: string;
}

export interface FileTypes {
  name: string;
  categories: CategoryType[] | [];
  project_type_name: string | null;
}

export interface FileType {
  name: string;
}

export interface DownloadFileResponse {
  file_name: string;
  url: string;
}

export interface GeoserverLayerLegendType {
  label: string;
  color: string;
  env: string;
}

export interface LulcDataType {
  title: string;
  value: string;
  color: string;
}

export interface RepoFileState {
  isLoadingDataLayers: boolean;
  isRequestingAddDateDataLayer: boolean;
  isRequestingEditDateDataLayer: boolean;
  isRequestingFileTypes: boolean;
  isRequestingAddRepoFileDataLayer: boolean;
  isRequestingEditRepoFile: boolean;
  isRequestingDeleteRepoFile: boolean;
  isRequestingDownloadRepoFile: boolean;
  isRequestingDeleteDateDataLayer: boolean;
  isRequestingGeoserverLayerStyles: boolean;
  isEnableDisableDatalayer: boolean;
  dataLayers: DataLayerType[] | [];
  fileTypes: FileTypes[] | [];
  geoserverLayerStyles: GeoserverLayerLegendType[] | [];
}
