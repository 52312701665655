import React, { useEffect, useMemo, useRef } from 'react';
import { Button, Space, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { FeatureGroup, Polygon, Popup, Tooltip, useMap } from 'react-leaflet';
import { lightGreen } from 'constants/color.constant';
import { useAppSelector } from 'hooks/useReduxHook';
import { TransmissionSegmentType } from 'store/riskanalysis/types';
import { ProjectCategoryTypes } from 'store/projects/types';
import { reformatRiskCoordinates } from 'utils/xml';

interface RendorRiskSegmentProps {
  riskSegmentData: TransmissionSegmentType | null;
  isDashed?: boolean;
}

const { Text } = Typography;

const RendorRiskSegment = ({ riskSegmentData, isDashed }: RendorRiskSegmentProps) => {
  const map = useMap();
  const { selectedProject } = useAppSelector((state) => state.projects);
  const riskSegmentFeature: any = useRef();
  useEffect(() => {
    setTimeout(() => {
      if (!isEmpty(riskSegmentFeature.current?.getBounds())) {
        map.fitBounds(riskSegmentFeature.current.getBounds());
      }
    }, 0);
  }, [riskSegmentFeature]);

  const rendorSegment = useMemo(() => {
    if (isEmpty(riskSegmentData)) return null;
    const reformCoords: any = reformatRiskCoordinates(riskSegmentData);

    if (reformCoords && reformCoords.length === 0) return null;

    return (
      <FeatureGroup ref={riskSegmentFeature}>
        <Polygon
          positions={reformCoords}
          pathOptions={{
            fillColor: lightGreen,
            color: lightGreen,
            fillOpacity: isDashed ? 0.3 : 0.5,
            weight: 2,
            dashArray: isDashed ? [15, 15] : [0, 0]
          }}
          pane="tilePane"
        >
          <Popup className="annts-point-popup buffer-point-popup" minWidth={232}>
            <Space direction="vertical">
              <div>
                <Text className="annts-market-title">
                  {selectedProject?.type === ProjectCategoryTypes.VegetationManagementLite
                    ? 'Segment ID'
                    : 'Segment Name'}
                </Text>
                <Text className="annts-market-data">
                  {riskSegmentData?.name || riskSegmentData?.id || '-'}
                </Text>
              </div>
              <div>
                <Text className="annts-market-title">Segment Length</Text>
                <Text className="annts-market-data">
                  {riskSegmentData?.span_length !== null
                    ? `${riskSegmentData?.span_length.toFixed(1)} m`
                    : '-'}
                </Text>
              </div>
              {riskSegmentData?.Voltage && (
                <div>
                  <Text className="annts-market-title">Voltage</Text>
                  <Text className="annts-market-data">{riskSegmentData?.Voltage || '-'}</Text>
                </div>
              )}
              <div>
                <Text className="annts-market-title">
                  {selectedProject?.type === ProjectCategoryTypes.VegetationManagementLite
                    ? 'Length of Proximity Risk'
                    : 'Length of High Risk'}
                </Text>
                {selectedProject?.type !== ProjectCategoryTypes.VegetationManagementLite && (
                  <Text className="annts-market-data">
                    {riskSegmentData?.high_risk_length !== null
                      ? `${riskSegmentData?.high_risk_length.toFixed(3)} m`
                      : '-'}
                  </Text>
                )}
                {selectedProject?.type === ProjectCategoryTypes.VegetationManagementLite && (
                  <Text className="annts-market-data">
                    {riskSegmentData?.proximity_risk_length !== null
                      ? `${riskSegmentData?.proximity_risk_length.toFixed(3)} m`
                      : '-'}
                  </Text>
                )}
              </div>
              <div>
                <Text className="annts-market-title">
                  {selectedProject?.type === ProjectCategoryTypes.VegetationManagementLite
                    ? 'Length of High Risk'
                    : 'Length of Medium Risk'}
                </Text>
                {selectedProject?.type !== ProjectCategoryTypes.VegetationManagementLite && (
                  <Text className="annts-market-data">
                    {riskSegmentData?.med_risk_length !== null
                      ? `${riskSegmentData?.med_risk_length.toFixed(3)} m`
                      : '-'}
                  </Text>
                )}
                {selectedProject?.type === ProjectCategoryTypes.VegetationManagementLite && (
                  <Text className="annts-market-data">
                    {riskSegmentData?.high_risk_length !== null
                      ? `${riskSegmentData?.high_risk_length.toFixed(3)} m`
                      : '-'}
                  </Text>
                )}
              </div>
              <div>
                <Text className="annts-market-title">Length of Low Risk</Text>
                <Text className="annts-market-data">
                  {riskSegmentData?.low_risk_length !== null
                    ? `${riskSegmentData?.low_risk_length.toFixed(3)} m`
                    : '-'}
                </Text>
              </div>
              {selectedProject?.type !== ProjectCategoryTypes.VegetationManagementLite &&
                riskSegmentData?.total_high_risk_trees && (
                  <div>
                    <Text className="annts-market-title">Total High Risk Trees</Text>
                    <Text className="annts-market-data">
                      {riskSegmentData?.total_high_risk_trees || 0}
                    </Text>
                  </div>
                )}
              {selectedProject?.type !== ProjectCategoryTypes.VegetationManagementLite &&
                riskSegmentData?.total_med_risk_trees && (
                  <div>
                    <Text className="annts-market-title">Total Medium Risk Trees</Text>
                    <Text className="annts-market-data">
                      {riskSegmentData?.total_med_risk_trees || 0}
                    </Text>
                  </div>
                )}
              {selectedProject?.type !== ProjectCategoryTypes.VegetationManagementLite &&
                riskSegmentData?.total_low_risk_trees && (
                  <div>
                    <Text className="annts-market-title">Total Low Risk Trees</Text>
                    <Text className="annts-market-data">
                      {riskSegmentData?.total_low_risk_trees || 0}
                    </Text>
                  </div>
                )}
            </Space>
          </Popup>
          <Tooltip className="annts-point-tooltip" sticky direction="right">
            <Button type="primary">{riskSegmentData?.name}</Button>
          </Tooltip>
        </Polygon>
      </FeatureGroup>
    );
  }, [riskSegmentData]);

  // eslint-disable-next-line
  return <>{rendorSegment}</>;
};

RendorRiskSegment.defaultProps = {
  isDashed: false
};

export default RendorRiskSegment;
