import React, { useEffect, useState } from 'react';
import { Divider, Typography } from 'antd';
import { isEmpty } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyIcon from 'assets/svg/CopyIcon';
import LocationIcon from 'assets/svg/LocationIcon';
import { primary, white } from 'constants/color.constant';
import { useAppSelector } from 'hooks/useReduxHook';
import { riskstyles } from 'styles/projectView.styles';
import { TransmissionSegmentType } from 'store/riskanalysis/types';
// import { getSelectedRiskSegmentsData } from 'store/riskanalysis';
import { ProjectCategoryTypes } from 'store/projects/types';
import { CoordinateType } from 'types/common.types';
import { getCenterPosition, getCenterPositionPolygon } from 'utils/xml';

const { Text } = Typography;
interface RiskSegmentDataCardProps {
  selectedRiskData: TransmissionSegmentType | null;
}

const RiskSegmentDataCardVegINTL = ({ selectedRiskData }: RiskSegmentDataCardProps) => {
  const { selectedProject } = useAppSelector((state) => state.projects);
  const [isCopied, setCopied] = useState(false);

  // VEG INTL
  // const selectedSegmentData = useAppSelector((state) =>
  //   getSelectedRiskSegmentsData(state, selectedRiskData)
  // );
  if (isEmpty(selectedRiskData)) return null;

  let centerLatLng;
  if (selectedRiskData.geometry.type === CoordinateType.MultiPolygon) {
    centerLatLng = getCenterPosition(selectedRiskData.geometry.coordinates);
  } else if (selectedRiskData.geometry.type === CoordinateType.Polygon) {
    centerLatLng = getCenterPositionPolygon(selectedRiskData.geometry.coordinates);
  }

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [isCopied]);

  return (
    <div className="risk-segment-data-content risk-segment-data-content-intl">
      <div className="risk-data-content">
        <Text>
          Segment ID:
          {selectedProject?.type === ProjectCategoryTypes.VegetationManagementLite
            ? selectedRiskData?.id
            : selectedRiskData?.name}
        </Text>
        <div className="lat-long-content">
          <LocationIcon />
          <Text className="lat-long">
            {!isEmpty(centerLatLng)
              ? [centerLatLng.lat.toFixed(6), centerLatLng.lng.toFixed(6)].join(',')
              : '-'}
          </Text>
          <CopyToClipboard
            onCopy={() => setCopied(true)}
            text={
              !isEmpty(centerLatLng)
                ? [centerLatLng.lat.toFixed(6), centerLatLng.lng.toFixed(6)].join(',')
                : '-'
            }
          >
            <CopyIcon color={isCopied ? primary : white} />
          </CopyToClipboard>
          <Text className="copied">{isCopied ? 'Copied' : ''}</Text>
        </div>
        <div className="vector-layer-content" style={{ width: '100%' }}>
          <Text className="layer-content-title" style={{ width: 'unset' }}>
            Segment Length
          </Text>
          <Text className="layer-content-data">
            {selectedRiskData.span_length !== null
              ? `${selectedRiskData.span_length.toFixed(1)} m`
              : '-'}
          </Text>
        </div>
        <div className="vector-layer-content" style={{ width: '100%' }}>
          <Text className="layer-content-title">Voltage</Text>
          <Text className="layer-content-data">{selectedRiskData.Voltage}</Text>
        </div>
        <Divider style={riskstyles.dataCardDivider} />
        <div className="panel-content-main-card">
          <div className="vector-layer-content">
            <div>
              <div className="risk-high risk-box-1" />
              <Text className="layer-content-title" style={{ paddingLeft: 6 }}>
                {selectedProject?.type === ProjectCategoryTypes.VegetationManagementLite
                  ? 'Length of Proximity Risk'
                  : 'Length of High Risk'}
              </Text>
            </div>
            {selectedProject?.type === ProjectCategoryTypes.VegetationManagementLite ? (
              <Text className="layer-content-data">
                {selectedRiskData.proximity_risk_length !== null
                  ? `${selectedRiskData.proximity_risk_length.toFixed(2)} m`
                  : '-'}
              </Text>
            ) : (
              <Text className="layer-content-data">
                {selectedRiskData.high_risk_length !== null
                  ? `${selectedRiskData.high_risk_length.toFixed(3)} m`
                  : '-'}
              </Text>
            )}
          </div>
          <div className="vector-layer-content">
            <div>
              <div className="risk-medium risk-box-1" />
              <Text className="layer-content-title" style={{ paddingLeft: 6 }}>
                {selectedProject?.type === ProjectCategoryTypes.VegetationManagementLite
                  ? 'Length of High Risk'
                  : 'Length of Medium Risk'}
              </Text>
            </div>
            {selectedProject?.type === ProjectCategoryTypes.VegetationManagementLite ? (
              <Text className="layer-content-data">
                {selectedRiskData.high_risk_length !== null
                  ? `${selectedRiskData.high_risk_length.toFixed(2)} m`
                  : '-'}
              </Text>
            ) : (
              <Text className="layer-content-data">
                {selectedRiskData.med_risk_length !== null
                  ? `${selectedRiskData.med_risk_length.toFixed(2)} m`
                  : '-'}
              </Text>
            )}
          </div>
          <div className="vector-layer-content">
            <div>
              <div className="risk-low risk-box-1" />
              <Text className="layer-content-title" style={{ paddingLeft: 6 }}>
                Length of Low Risk
              </Text>
            </div>
            <Text className="layer-content-data">
              {selectedRiskData.low_risk_length !== null
                ? `${selectedRiskData.low_risk_length.toFixed(2)} m`
                : '-'}
            </Text>
          </div>
        </div>
        {selectedProject?.type !== ProjectCategoryTypes.VegetationManagementLite && (
          <Divider style={riskstyles.dataCardDivider} />
        )}
        {selectedProject?.type !== ProjectCategoryTypes.VegetationManagementLite && (
          <div className="panel-content-main-card">
            <div className="vector-layer-content">
              <div>
                <div className="risk-high risk-box" />
                <Text className="layer-content-title" style={{ paddingLeft: 6 }}>
                  No. of High Risk Trees
                </Text>
              </div>
              <Text className="layer-content-data">
                {selectedRiskData.total_high_risk_trees || 0}
              </Text>
            </div>
            <div className="vector-layer-content">
              <div>
                <div className="risk-medium risk-box" />
                <Text className="layer-content-title" style={{ paddingLeft: 6 }}>
                  No. of Medium Risk Trees
                </Text>
              </div>
              <Text className="layer-content-data">
                {selectedRiskData.total_med_risk_trees || 0}
              </Text>
            </div>
            <div className="vector-layer-content">
              <div>
                <div className="risk-low risk-box" />
                <Text className="layer-content-title" style={{ paddingLeft: 6 }}>
                  No. of Low Risk Trees
                </Text>
              </div>
              <Text className="layer-content-data">
                {selectedRiskData.total_low_risk_trees || 0}
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RiskSegmentDataCardVegINTL;
