import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Typography } from 'antd';
import moment from 'moment';
import LeftArrowIcon from 'assets/svg/LeftArrowIcon';
import { TimezoneContext } from 'context/createusecontext';
import { DateFormat2, TimeFormat } from 'constants/common.constant';
import { routes } from 'constants/pageRoutes.constants';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { setProject, setSelectedProject } from 'store/projects';
import { Project } from 'store/projects/types';
import { setDataLayers, setGeoserverLayerStyles } from 'store/repositoryfile';
import { setVectorFiles } from 'store/vectorfile';
import './styles.less';

const { Text } = Typography;

interface ProjectDetailHeaderProps {
  project: Project;
}

const ProjectDetailHeader = ({ project }: ProjectDetailHeaderProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const timeZone = useContext(TimezoneContext);
  const utcUpdateDate =
    project && project.last_updated
      ? `${moment.utc(project.last_updated).local().format(DateFormat2)}, ${moment
          .utc(project.last_updated)
          .local()
          .format(TimeFormat)} ${timeZone?.timezone}`
      : '-';

  const handleBackNavigate = () => {
    dispatch(setDataLayers([]));
    dispatch(setVectorFiles([]));
    dispatch(setGeoserverLayerStyles([]));
    dispatch(setProject(null));
    dispatch(setSelectedProject(null));
    navigate(`${routes.ProjectList}?organization_id=${selectedOrganization?.id}`, {
      replace: true
    });
  };

  return (
    <Row className="main-subheader-content">
      <Col className="back-btn" onClick={handleBackNavigate}>
        <Button icon={<LeftArrowIcon />} type="text" shape="circle" />
        <Text className="back-title">back</Text>
      </Col>
      <Col className="project-id-main">
        <Text className="project-title">PROJECT ID</Text>
        <Text className="project-data">{project && project.id ? project.id : '-'}</Text>
      </Col>
      <Col className="project-name-main">
        <Text className="project-title">PROJECT NAME</Text>
        <Text className="project-data">{project && project.name ? project.name : '-'}</Text>
      </Col>
      <Col className="project-type-main">
        <Text className="project-title">TYPE OF PROJECT</Text>
        <Text className="project-data">{project && project.type ? project.type : '-'}</Text>
      </Col>
      <Col className="project-coord-main">
        <Text className="project-title">CORDINATES</Text>
        <Text className="project-data">
          {project && project.coordinates && project.coordinates.length > 0
            ? `${Number(project.coordinates[0]).toFixed(6)}, ${Number(
                project.coordinates[1]
              ).toFixed(6)}`
            : '-'}
        </Text>
      </Col>
      <Col className="last-update-main">
        <Text className="project-title">LAST UPDATED</Text>
        <Text className="project-data">{utcUpdateDate}</Text>
      </Col>
    </Row>
  );
};

export default ProjectDetailHeader;
