import React from 'react';
import { Typography } from 'antd';
import { isEmpty } from 'lodash';
import EyeCloseIcon from 'assets/svg/EyeCloseIcon';
import EyeOpenIcon from 'assets/svg/EyeOpenIcon';
import { white } from 'constants/color.constant';
import { AddedAnnotation } from 'store/annotations/types';

const { Text } = Typography;
interface AnnotationsPointCardProps {
  icon: React.ReactNode;
  anotation: AddedAnnotation;
  selectedMarkings: number[];
  handleShowMarkings: (data: AddedAnnotation) => void;
}

const AnnotationsPointCard = ({
  icon,
  anotation,
  selectedMarkings,
  handleShowMarkings
}: AnnotationsPointCardProps) => {
  const coords: string = `${anotation.geometry.coordinates[0].toFixed(
    6
  )} N, ${anotation.geometry.coordinates[1].toFixed(6)} E`;

  return (
    <div className="tab-inr-content">
      <div className="tab-content-head">
        <div>
          {icon}
          <div>
            <Text>{!isEmpty(anotation.properties) ? anotation.properties?.name : ''}</Text>
            <Text>{!isEmpty(anotation.properties) ? anotation.properties?.category : ''}</Text>
          </div>
        </div>
        <div>
          {selectedMarkings.includes(anotation.id) ? (
            <EyeOpenIcon color={white} onClick={() => handleShowMarkings(anotation)} />
          ) : (
            <EyeCloseIcon color={white} onClick={() => handleShowMarkings(anotation)} />
          )}
        </div>
      </div>
      <div className="tab-content-desc">
        <Text>Coordinates - {coords} </Text>
      </div>
    </div>
  );
};

export default AnnotationsPointCard;
