import { useContext } from 'react';
import moment from 'moment';
import { Typography } from 'antd';
import BagIcon from 'assets/svg/BagIcon';
import IdIcon from 'assets/svg/IdIcon';
import LocationIcon from 'assets/svg/LocationIcon';
import WhatchIcon from 'assets/svg/WhatchIcon';
import { DateFormat2, TimeFormat } from 'constants/common.constant';
import { TimezoneContext } from 'context/createusecontext';
import { Project } from 'store/projects/types';

const { Text } = Typography;

interface ProjectDetailProps {
  project: Project;
}

const ProjectDetail = ({ project }: ProjectDetailProps) => {
  const timeZone = useContext(TimezoneContext);

  return (
    <div className="project-detail">
      <div>
        <IdIcon />
        <Text>{project && project.id ? project.id : '-'}</Text>
      </div>
      <div>
        <BagIcon />
        <Text>{project && project.type ? project.type : '-'}</Text>
      </div>
      <div>
        <LocationIcon />
        <Text>
          {project && project.coordinates && project.coordinates.length > 0
            ? project.coordinates.join(',')
            : '-'}
        </Text>
      </div>
      <div>
        <WhatchIcon />
        <Text>
          {project && project.last_updated
            ? `${moment.utc(project.last_updated).local().format(DateFormat2)}, ${moment
                .utc(project.last_updated)
                .local()
                .format(TimeFormat)} ${timeZone?.timezone}`
            : '-'}
        </Text>
      </div>
    </div>
  );
};

export default ProjectDetail;
