import { memo, useMemo } from 'react';
import { Button, Space, Typography } from 'antd';
import { filter, includes, isEmpty } from 'lodash';
import { Circle, Marker, Polygon, Polyline, Popup, Tooltip } from 'react-leaflet';
import { LightLocation } from 'constants/map.constants';
import { saffron } from 'constants/color.constant';
import { UploadedAnnotation } from 'store/annotations/types';
import { CoordinateType } from 'types/common.types';

const { Text } = Typography;
interface RendorUploadedAnnotationsMarkingsProps {
  uploadedAnnotations: UploadedAnnotation[];
  selectedMarkings: Array<number>;
}

const RendorUploadedAnnotationsMarkings = ({
  uploadedAnnotations,
  selectedMarkings
}: RendorUploadedAnnotationsMarkingsProps) => {
  const allAnnotationMarkings = useMemo(() => {
    if (selectedMarkings && selectedMarkings.length === 0) return null;

    const selectedAnnotationsMarkings = filter(
      uploadedAnnotations,
      (annotation: UploadedAnnotation) => includes(selectedMarkings, annotation.id)
    );

    // eslint-disable-next-line
    return selectedAnnotationsMarkings.map((data: UploadedAnnotation, i: number) => {
      if (data.geometry.type === CoordinateType.MultilineString) {
        const coords: any = data.geometry.coordinates;
        const newLineCoords: any = [];
        coords.map((coord: any) => {
          coord.map((latlng: any) => {
            const newLatlng: any = [latlng[1], latlng[0]];
            return newLineCoords.push(newLatlng);
          });
          return coord;
        });
        if (newLineCoords && newLineCoords.length === 0) return null;

        return (
          <Polyline
            key={`${data.geometry.type}-${i}`}
            // @ts-ignore
            positions={newLineCoords}
            pathOptions={{
              color: saffron,
              fillColor: saffron,
              fillOpacity: 0.3,
              weight: 1
            }}
          >
            {!isEmpty(data.properties) && (
              <>
                <Popup
                  className="annts-point-popup"
                  minWidth={232}
                  maxHeight={226}
                  offset={[0, 160]}
                >
                  <Space direction="vertical">
                    {Object.keys(data.properties).map((name: any, index: number) => (
                      <div key={`${index}-${name}`}>
                        <Text className="annts-market-title">{name}</Text>
                        <Text className="annts-market-data">{data.properties[name]}</Text>
                      </div>
                    ))}
                  </Space>
                </Popup>
                <Tooltip className="annts-point-tooltip" sticky direction="right">
                  <Button type="primary">{data.geometry.type}</Button>
                </Tooltip>
              </>
            )}
          </Polyline>
        );
      }
      if (data.geometry.type === CoordinateType.LineString) {
        const coords: any = data.geometry.coordinates;
        const newLineCoords: any = [];
        coords.map((coord: any) => {
          const newLatlng: any = [coord[1], coord[0]];
          return newLineCoords.push(newLatlng);
        });
        if (newLineCoords && newLineCoords.length === 0) return null;

        return (
          <>
            {newLineCoords.map((coord: any, index: number) => (
              <Circle
                key={`${data.geometry.type}-${data.id}-${index}`}
                pathOptions={{ color: saffron, fillColor: saffron, fillOpacity: 1 }}
                center={coord}
                radius={100}
              />
            ))}
            <Polyline
              key={`${data.geometry.type}-${data.id}`}
              // @ts-ignore
              positions={newLineCoords}
              pathOptions={{
                color: saffron,
                fillColor: saffron,
                fillOpacity: 0.3,
                weight: 3
              }}
              pane="shadowPane"
            >
              {!isEmpty(data.properties) && (
                <>
                  <Popup
                    className="annts-point-popup"
                    minWidth={232}
                    maxHeight={226}
                    offset={[0, 160]}
                  >
                    <Space direction="vertical">
                      {Object.keys(data.properties).map((name: any, index: number) => (
                        <div key={`${index}-${name}`}>
                          <Text className="annts-market-title">{name}</Text>
                          <Text className="annts-market-data">{data.properties[name]}</Text>
                        </div>
                      ))}
                    </Space>
                  </Popup>
                  <Tooltip className="annts-point-tooltip" sticky direction="right">
                    <Button type="primary">{data.geometry.type}</Button>
                  </Tooltip>
                </>
              )}
            </Polyline>
          </>
        );
      }
      if (data.geometry.type === CoordinateType.MultiPolygon) {
        const coords: any = data.geometry.coordinates;
        const reformCoords: any = [];
        coords.map((points: any) => {
          return points.map((point: any) => {
            return point.map((p: any) => {
              return reformCoords.push([p[1], p[0]]);
            });
          });
        });
        if (reformCoords && reformCoords.length === 0) return null;

        return (
          <Polygon
            key={`${data.geometry.type}-${data.id}`}
            positions={reformCoords}
            pathOptions={{
              fillColor: saffron,
              color: saffron,
              fillOpacity: 0.3,
              weight: 4
            }}
            pane="shadowPane"
          >
            {!isEmpty(data.properties) && (
              <Popup className="annts-point-popup" minWidth={232} maxHeight={226} offset={[0, 160]}>
                <Space direction="vertical">
                  {Object.keys(data.properties).map((name: any, index: number) => (
                    <div key={`${index}-${name}`}>
                      <Text className="annts-market-title">{name}</Text>
                      <Text className="annts-market-data">{data.properties[name]}</Text>
                    </div>
                  ))}
                </Space>
              </Popup>
            )}
            <Tooltip className="annts-point-tooltip" sticky direction="right">
              <Button type="primary">{data.geometry.type}</Button>
            </Tooltip>
          </Polygon>
        );
      }
      if (data.geometry.type === CoordinateType.MultiPoint) {
        const coords: any = data.geometry.coordinates;
        const formatCoords = coords.map((coord: any) => {
          return [coord[1], coord[0]];
        });

        return (
          <Marker
            key={`${data.geometry.type}-${data.id}-${i + 2}`}
            position={formatCoords[0]}
            icon={LightLocation}
          >
            {!isEmpty(data.properties) && (
              <Popup className="annts-point-popup" minWidth={232} maxHeight={226} offset={[0, 160]}>
                <Space direction="vertical">
                  {Object.keys(data.properties).map((name: any, index: number) => (
                    <div key={`${index + 1}-${name}-${data.properties[name]}`}>
                      <Text className="annts-market-title">{name}</Text>
                      <Text className="annts-market-data">{data.properties[name]}</Text>
                    </div>
                  ))}
                </Space>
              </Popup>
            )}
            <Tooltip className="annts-point-tooltip" direction="center">
              <Button type="primary">{data.geometry.type}</Button>
            </Tooltip>
          </Marker>
        );
      }
      if (data.geometry.type === CoordinateType.Point) {
        return null;
      }
      if (data.geometry.type === CoordinateType.Polygon) {
        return null;
      }
    });
  }, [uploadedAnnotations, selectedMarkings]);

  // eslint-disable-next-line
  return <>{allAnnotationMarkings}</>;
};

export default memo(RendorUploadedAnnotationsMarkings);
