import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { HILL_SHADE, JET } from 'constants/common.constant';
import { DataLayerFileType } from 'store/repositoryfile/types';
import { GET_GEOSERVER_WMTS_URL } from 'utils/apiUrls';
import 'scripts/wmts';

interface DSMTileLayerProps {
  repoFile: DataLayerFileType;
  style: string | null;
}

const DSMDEMTileLayer = ({ repoFile, style }: DSMTileLayerProps) => {
  const map = useMap();
  const wmsLayerGroup = L.featureGroup();

  useEffect(() => {
    if (!style) return;

    let wmtsParams = {
      layer: `skies_explorer:${repoFile.gs_layer_name!}`,
      style: '',
      format: 'image/png8',
      transparent: true,
      maxZoom: 20,
      pane: 'overlayPane',
      tilematrixset: 'EPSG:900913',
      height: 256,
      width: 256
    };

    if (style === HILL_SHADE) {
      wmtsParams = {
        ...wmtsParams,
        layer: `skies_explorer:${repoFile.gs_layer_name!}_${style}`
      };
    }

    if (style === JET) {
      wmtsParams = {
        ...wmtsParams,
        style: `skies_explorer:${style}_${repoFile.gs_layer_name!}`
      };
    }

    const wmtslayer = L.tileLayer
      // @ts-ignore
      .wmts(GET_GEOSERVER_WMTS_URL, wmtsParams)
      .addTo(map);
    wmsLayerGroup.addLayer(wmtslayer);
    wmsLayerGroup.addTo(map);

    // eslint-disable-next-line
    return () => {
      wmsLayerGroup.removeFrom(map);
    };
  }, [repoFile, style]);

  return null;
};

export default DSMDEMTileLayer;
