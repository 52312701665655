import React from 'react';
import { IconProps } from 'types/common.types';

const TableIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width || '14'}
      height={height || '14'}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke={color} strokeLinejoin="round" />
      <path d="M4.66675 0.390625V13.6128" stroke={color} />
      <path d="M9.33325 0.390625V13.6128" stroke={color} />
      <path d="M13.6111 3.89062L0.388863 3.89062" stroke={color} />
    </svg>
  );
};

export default TableIcon;
