import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import { ACCESS_TOKEN, REFRESH_TOKEN, AES_KEY, USER_ROLE } from 'constants/common.constant';
import { setOrganizations, setSelectedOrganization } from 'store/organizations';
import { getOrganization } from 'store/organizations/actions';
import { getProjects } from 'store/projects/actions';
import type { Response, Error, JWT } from 'types/common.types';
import {
  RSA_URL,
  LOGIN_URL,
  LOGOUT_URL,
  RESET_PASSWORD_URL,
  RESET_PASSWORD_REQUEST_URL,
  GET_USER_DETAIL_URL,
  ADD_USER_DETAIL_URL,
  USER_RESET_PASSWORD_URL,
  ADD_USER_PIC_URL
} from 'utils/apiUrls';
import { apiCall, fileUploadApicall } from 'utils/connect';
import { decryptRsa } from 'utils/decodeEncodeData';
import { getStorageValue, setStorageValue, removeAllCookies } from 'utils/localStorage';
import { AppDispatch } from '..';
import {
  User,
  Rsa,
  LoginPayload,
  ResetPassWordPayload,
  RequestResetPassWordPayload,
  UserInfo,
  UserEditProfilePayload,
  UserResetPasswordPayload
} from './types';
import {
  setRsaRequest,
  setRSA,
  setLoginRequest,
  setUser,
  setLogoutRequest,
  setRequestingResetPassword,
  setResetPassword,
  setRequestingUserDetail,
  setUserInfo,
  setRequestingUserEdit,
  setRequestingUserResetPassword,
  setRequestingUserPicture
} from '.';

export const getRSA = () => (dispatch: AppDispatch) => {
  try {
    dispatch(setRsaRequest(true));

    const onSuccess = (response: Response<Rsa>) => {
      dispatch(setRSA(response.data.rsa));
      dispatch(setRsaRequest(false));
    };
    const onFailure = (error: Error) => {
      toast.error(error.message);
      dispatch(setRsaRequest(false));
    };

    apiCall('GET', RSA_URL, '', onSuccess, onFailure);
  } catch (error: any) {
    dispatch(setRsaRequest(false));
    toast.error(error.message);
  }
};

export const userLogin = (payload: LoginPayload, callback: Function) => (dispatch: AppDispatch) => {
  try {
    dispatch(setLoginRequest(true));

    const onSuccess = (response: Response<User>) => {
      decryptRsa(response.data.accessToken).then((aesKey: any) => {
        const decodeAccessToken: JWT = jwtDecode(response.data.accessToken);
        dispatch(setUser(response.data));
        setStorageValue(AES_KEY, aesKey);
        setStorageValue(ACCESS_TOKEN, response.data.accessToken);
        setStorageValue(REFRESH_TOKEN, response.data.refreshToken);
        setStorageValue(USER_ROLE, decodeAccessToken.user_claims.role);
        callback(decodeAccessToken.user_claims.role, response.data);
        if (response.data.organisationid) {
          const orgId = response.data.organisationid;
          dispatch(getOrganization(orgId, response.data.accessToken));
          dispatch(getProjects(orgId, response.data.accessToken));
        }
        dispatch(setLoginRequest(false));
        // toast.success('Logged in successfully');
      });
    };
    const onFailure = (error: Error) => {
      dispatch(setLoginRequest(false));
      toast.error(error.message);
    };

    apiCall(
      'POST',
      LOGIN_URL,
      { ...payload, encryptionDetails: 'PKCS1_OAEP' },
      onSuccess,
      onFailure
    );
  } catch (error: any) {
    dispatch(setLoginRequest(false));
    toast.error(error.message);
  }
};

const clearTokens = (callback: Function) => (dispatch: AppDispatch) => {
  removeAllCookies().then(() => {
    callback();
  });
  dispatch(setRSA(null));
  dispatch(setUser(null));
  dispatch(setSelectedOrganization(null));
  dispatch(setOrganizations([]));
};

export const userLogout = (callback: Function) => (dispatch: AppDispatch) => {
  try {
    dispatch(setLogoutRequest(true));

    const onSuccess = () => {
      dispatch(clearTokens(callback));
      dispatch(setLogoutRequest(false));
    };
    const onFailure = () => {
      dispatch(clearTokens(callback));
      dispatch(setLogoutRequest(false));
    };

    const refreshToken = getStorageValue(REFRESH_TOKEN);
    if (refreshToken !== undefined && refreshToken !== null) {
      apiCall('DELETE', LOGOUT_URL, '', onSuccess, onFailure, refreshToken);
    }
  } catch (error: any) {
    dispatch(clearTokens(callback));
    dispatch(setLogoutRequest(false));
  }
};

export const resetPasswordRequest =
  (payload: RequestResetPassWordPayload, callback: Function) => (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingResetPassword(true));

      const onSuccess = () => {
        dispatch(setRequestingResetPassword(false));
        callback(true);
      };

      const onFailure = () => {
        callback(false);
        dispatch(setRequestingResetPassword(false));
      };

      apiCall('POST', RESET_PASSWORD_REQUEST_URL, payload, onSuccess, onFailure);
    } catch (error: any) {
      callback(false);
      dispatch(clearTokens(callback));
      dispatch(setRequestingResetPassword(false));
    }
  };

export const resetPassword =
  (payload: ResetPassWordPayload, token: string, callback: Function) => (dispatch: AppDispatch) => {
    try {
      dispatch(setResetPassword(true));

      const onSuccess = () => {
        dispatch(setResetPassword(false));
        callback(true);
      };
      const onFailure = () => {
        callback(false);
        dispatch(setResetPassword(false));
      };

      apiCall('POST', RESET_PASSWORD_URL, payload, onSuccess, onFailure, token);
    } catch (error: any) {
      callback(false);
      dispatch(clearTokens(callback));
      dispatch(setResetPassword(false));
    }
  };

export const getUserDetail = (userId: string, token: string) => (dispatch: AppDispatch) => {
  try {
    dispatch(setRequestingUserDetail(true));

    const onSuccess = (response: Response<UserInfo>) => {
      dispatch(setUserInfo(response.data));
      dispatch(setRequestingUserDetail(false));
    };
    const onFailure = (error: Error) => {
      toast.error(error.message);
      dispatch(setRequestingUserDetail(false));
    };

    apiCall('GET', GET_USER_DETAIL_URL(userId), '', onSuccess, onFailure, token);
  } catch (error: any) {
    dispatch(setRequestingUserDetail(false));
    toast.error(error.message);
  }
};

export const addUserDetail =
  (payload: UserEditProfilePayload, userId: string, token: string, callback?: Function) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingUserEdit(true));

      const onSuccess = () => {
        dispatch(setRequestingUserEdit(false));
        dispatch(getUserDetail(userId, token));
        if (callback) callback(true);
      };
      const onFailure = () => {
        if (callback) callback(false);
        dispatch(setRequestingUserEdit(false));
      };
      // ADD_USER_DETAIL_URL
      apiCall('POST', ADD_USER_DETAIL_URL(userId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      if (callback) callback(false);
      dispatch(setRequestingUserEdit(false));
    }
  };

export const addUserPicture =
  (userId: string, token: string, file?: File) => (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingUserPicture(true));
      const onSuccess = () => {
        dispatch(getUserDetail(userId, token));
        dispatch(setRequestingUserPicture(true));
      };
      const onFailure = () => {
        dispatch(setRequestingUserPicture(true));
      };

      fileUploadApicall(ADD_USER_PIC_URL(userId), onSuccess, onFailure, token, file);
    } catch (error: any) {
      dispatch(setRequestingUserPicture(true));
    }
  };

export const userResetPassword =
  (payload: UserResetPasswordPayload, userId: string, token: string) => (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingUserResetPassword(true));

      const onSuccess = () => {
        dispatch(setRequestingUserResetPassword(false));
        toast.success('Password updated successfully');
      };
      const onFailure = (error: any) => {
        toast.error(error.message);
        dispatch(setRequestingUserResetPassword(false));
      };

      apiCall('POST', USER_RESET_PASSWORD_URL(userId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setRequestingUserResetPassword(false));
    }
  };
