import { useNavigate } from 'react-router-dom';
import { Col, Typography } from 'antd';
import Default from 'assets/png/Default.png';
import EditFileIcon from 'assets/svg/EditFileIcon';
import { routes } from 'constants/pageRoutes.constants';
import { useAppDispatch } from 'hooks/useReduxHook';
import { setSelectedOrganization } from 'store/organizations';
import { Organization } from 'store/organizations/types';

const { Text } = Typography;

interface OrgCardProps {
  organization: Organization;
}

const OrgCard = ({ organization }: OrgCardProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleEditOrganization = (orgId: number) => {
    dispatch(setSelectedOrganization(organization));
    navigate(`/edit-org/${orgId}?organization_id=${orgId}`);
  };

  const handleNavigateProjectList = (orgId: number) => {
    dispatch(setSelectedOrganization(organization));
    navigate(`${routes.ProjectList}?organization_id=${orgId}`);
  };

  return (
    <Col span={6} className="org-card">
      <div className="inr-org-card">
        <div className="edit-org-btn" onClick={() => handleEditOrganization(organization.id)}>
          <EditFileIcon />
        </div>
        <div
          className="org-card-content"
          onClick={() => handleNavigateProjectList(organization.id)}
        >
          <Text className="org-title">{organization.name}</Text>
          <div
            className="org-logo"
            style={{
              backgroundImage: `url(${organization.logo_url ? organization.logo_url : Default})`
            }}
          />
          <div className="project-count">
            <Text>Projects</Text>
            <Text>{organization.projects}</Text>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default OrgCard;
