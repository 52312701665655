import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const SplitIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 2L14.9999 22" stroke="white" strokeWidth="2.5" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6668 12.0004C24.6668 12.1524 24.6155 12.3037 24.5122 12.4271L21.1788 16.4271C20.9435 16.7097 20.5228 16.7484 20.2402 16.5124C19.9575 16.2771 19.9195 15.8571 20.1548 15.5737L23.1388 11.9931L20.2622 8.41839C20.0315 8.13172 20.0768 7.71172 20.3635 7.48105C20.6502 7.25039 21.0695 7.29572 21.3008 7.58239L24.5195 11.5824C24.6175 11.7044 24.6668 11.8524 24.6668 12.0004Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33318 11.9996C5.33318 11.8476 5.38451 11.6963 5.48785 11.5729L8.82118 7.57295C9.05651 7.29028 9.47718 7.25161 9.75985 7.48761C10.0425 7.72295 10.0805 8.14295 9.84518 8.42628L6.86118 12.0069L9.73785 15.5816C9.96851 15.8683 9.92318 16.2883 9.63651 16.5189C9.34985 16.7496 8.93051 16.7043 8.69918 16.4176L5.48051 12.4176C5.38251 12.2956 5.33318 12.1476 5.33318 11.9996Z"
        fill={color}
      />
    </svg>
  );
};
SplitIcon.defaultProps = {
  color: white,
  width: 30,
  height: 24,
  onClick: () => {}
};

export default SplitIcon;
