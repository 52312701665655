import { Avatar, Space, Typography } from 'antd';
import { OrganizationMember } from 'store/organizations/types';
import { getTeamMember } from 'store/projects';
import { shortLabel } from 'utils/utils';
import './styles.less';

const { Text } = Typography;

interface RemoveMemberOrganizationProps {
  selectedMember: OrganizationMember;
}

const RemoveMemberOrganization = ({ selectedMember }: RemoveMemberOrganizationProps) => {
  const member = getTeamMember(selectedMember);

  return (
    <div className="remove-member-content">
      <Space className="remove-member-title">
        <Text className="emp-title">NAME OF EMPLOYEE</Text>
      </Space>
      <Space className="remove-member-detail">
        <Avatar size={70} className="remove-member-avatar">
          {shortLabel(member.label)}
        </Avatar>
        <Text>{member.label}</Text>
        <Text className="remove-member-text">
          Are you sure you want to remove <Text strong>{member.label}</Text> from the organization?
        </Text>
      </Space>
    </div>
  );
};

export default RemoveMemberOrganization;
