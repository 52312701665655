import { useContext } from 'react';
import moment from 'moment';
import { Typography } from 'antd';
import BagIcon from 'assets/svg/BagIcon';
import WhatchIcon from 'assets/svg/WhatchIcon';
import { DateFormat2, TimeFormat } from 'constants/common.constant';
import { useAppSelector } from 'hooks/useReduxHook';
import { TimezoneContext } from 'context/createusecontext';
import { Project } from 'store/projects/types';

const { Text } = Typography;

interface ProjectDetailVegIntProps {
  project: Project;
}

const ProjectDetailVegInt = ({ project }: ProjectDetailVegIntProps) => {
  const { riskTransmissionLine } = useAppSelector((state) => state.riskanalysis);
  const timeZone = useContext(TimezoneContext);

  return (
    <div className="project-detail">
      {/* <div>
        <IdIcon />
        <Text>{project && project.id ? project.id : '-'}</Text>
      </div> */}
      <div>
        <Text style={{ paddingLeft: 0 }}>
          Total Line Length :{' '}
          {riskTransmissionLine && riskTransmissionLine.length
            ? (riskTransmissionLine.length / 1000).toFixed(2)
            : 0}{' '}
          km
        </Text>
      </div>
      <div>
        <Text style={{ paddingLeft: 0 }}>
          Total No. of Towers : {riskTransmissionLine?.total_towers || 0}
        </Text>
      </div>
      <div>
        <BagIcon />
        <Text>{project && project.type ? project.type : '-'}</Text>
      </div>
      <div>
        <WhatchIcon />
        <Text>
          {project && project.last_updated
            ? `${moment.utc(project.last_updated).local().format(DateFormat2)}, ${moment
                .utc(project.last_updated)
                .local()
                .format(TimeFormat)} ${timeZone?.timezone}`
            : '-'}
        </Text>
      </div>
    </div>
  );
};

export default ProjectDetailVegInt;
