import { FocusEvent, useState } from 'react';
import { debounce, filter, isEmpty } from 'lodash';
import { Input } from 'antd';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { white } from 'constants/color.constant';
import RiskSegmentCardVegIntl from 'components/core/RiskSegmentCardVegIntl';
import ProjectDetailVegInt from 'components/core/ProjectDetailVegInt';
import RiskSegmentDataCardVegINTL from 'components/core/RiskSegmentDataCardVegINTL';
import { useAppSelector } from 'hooks/useReduxHook';
import { TransmissionSegmentType } from 'store/riskanalysis/types';
import { Project } from 'store/projects/types';

interface RiskAnalysisVegIntProps {
  project: Project;
  selectedRiskData: TransmissionSegmentType | null;
  handleShowRiskData: (data: TransmissionSegmentType | null, index: number) => void;
}
const RiskAnalysisVegInt = ({
  project,
  selectedRiskData,
  handleShowRiskData
}: RiskAnalysisVegIntProps) => {
  const { isRequestingRiskanalysis, riskTransmissionSegments } = useAppSelector(
    (state) => state.riskanalysis
  );
  const [searchSegmentVal, setSearchSegmentVal] = useState<string | null>(null);

  const handleClickShowRiskData = (data: TransmissionSegmentType | null, index: number) => {
    handleShowRiskData(data, index);
    setSearchSegmentVal(null);
  };

  const handleonChangeSearchSegment = debounce((e: FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchSegmentVal(value || null);
  }, 500);

  return (
    <div className="riskanalysis-tab">
      {isEmpty(selectedRiskData) ? (
        <div className="risk-segment-main risk-segment-main-intl">
          <div className="search-segment">
            <Input
              className="segment-number"
              onChange={handleonChangeSearchSegment}
              placeholder="Search Segment ID"
            />
          </div>
          {isRequestingRiskanalysis && (
            <div className="flex-box-center">
              <AiOutlineLoading3Quarters color={white} className="loading-icon" />
            </div>
          )}
          {!isRequestingRiskanalysis &&
            !isEmpty(riskTransmissionSegments) &&
            filter(riskTransmissionSegments, (d: TransmissionSegmentType) => {
              if (
                searchSegmentVal &&
                (Boolean(String(d?.name).includes(searchSegmentVal)) ||
                  Boolean(String(d?.id).includes(searchSegmentVal)))
              ) {
                return true;
              }
              if (!searchSegmentVal) {
                return true;
              }
              return false;
            }).map((data: TransmissionSegmentType, index: number) => {
              return (
                <RiskSegmentCardVegIntl
                  key={`${data.name}-${data.id}`}
                  riskData={data}
                  segmentIndex={index}
                  handleClickShowRiskData={handleClickShowRiskData}
                />
              );
            })}
        </div>
      ) : (
        <div className="risk-segment-main risk-segment-data-card risk-segment-main-intl">
          <RiskSegmentDataCardVegINTL selectedRiskData={selectedRiskData} />
        </div>
      )}
      <ProjectDetailVegInt project={project} />
    </div>
  );
};

export default RiskAnalysisVegInt;
