import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { routes as routeName } from 'constants/pageRoutes.constants';
import Layouts from 'layouts/index';
import NotFound from 'pages/404/NotFound';
import AddMembers from 'pages/AddMembers';
import ForgotPassword from 'pages/ForgotPassword';
import Login from 'pages/Login';
import MapView from 'pages/MapView';
import AddOrganization from 'pages/Organization/AddEditOrganization/AddOrganization';
import EditOrganization from 'pages/Organization/AddEditOrganization/EditOrganization';
import OrganizationList from 'pages/Organization/OrganizationList';
import AddProject from 'pages/Projects/AddEditProject/AddProject';
import EditProject from 'pages/Projects/AddEditProject/EditProject';
import ProjectList from 'pages/Projects/ProjectList/ProjectList';
// old project view design
import ProjectView from 'pages/ProjectView';
// Vagetation International project type
import ProjectViewVegInt from 'pages/ProjectView/ProjectViewVegInt';
import ResetPassword from 'pages/ResetPassword';
import RepositoryFile from 'pages/RepositoryFile';
import Settings from 'pages/Settings';
import UserManagement from 'pages/UserManagement';
import { UserRole } from 'store/users/types';
import PrivateRoute from './PrivateRoute';

export const Navigator = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routeName.LoginUrl} element={<Login />} />
        <Route path={routeName.ForgotPass} element={<ForgotPassword />} />
        <Route path={routeName.ResetPass} element={<ResetPassword />} />
        <Route element={<Layouts />}>
          <Route
            path={routeName.Organization}
            element={
              <PrivateRoute roles={[UserRole.SuperAdmin]}>
                <OrganizationList />
              </PrivateRoute>
            }
          />
          <Route
            path={routeName.AddOrganization}
            element={
              <PrivateRoute roles={[UserRole.SuperAdmin]}>
                <AddOrganization />
              </PrivateRoute>
            }
          />
          <Route
            path={routeName.EditOrganization}
            element={
              <PrivateRoute roles={[UserRole.SuperAdmin]}>
                <EditOrganization />
              </PrivateRoute>
            }
          />
          <Route
            path={routeName.ProjectList}
            element={
              <PrivateRoute>
                <ProjectList />
              </PrivateRoute>
            }
          />
          <Route
            path={routeName.AddProject}
            element={
              <PrivateRoute roles={[UserRole.SuperAdmin]}>
                <AddProject />
              </PrivateRoute>
            }
          />
          <Route
            path={routeName.EditProject}
            element={
              <PrivateRoute roles={[UserRole.SuperAdmin]}>
                <EditProject />
              </PrivateRoute>
            }
          />
          <Route
            path={routeName.RepoFile}
            element={
              <PrivateRoute roles={[UserRole.SuperAdmin]}>
                <RepositoryFile />
              </PrivateRoute>
            }
          />
          <Route
            path={routeName.UserManagement}
            element={
              <PrivateRoute roles={[UserRole.SuperAdmin]}>
                <UserManagement />
              </PrivateRoute>
            }
          />
          <Route
            path={routeName.AddMember}
            element={
              <PrivateRoute roles={[UserRole.SuperAdmin]}>
                <AddMembers />
              </PrivateRoute>
            }
          />
          <Route
            path={routeName.Mapview}
            element={
              <PrivateRoute>
                <MapView />
              </PrivateRoute>
            }
          />

          <Route
            path={routeName.Settings}
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path={routeName.ProjectView}
          element={
            <PrivateRoute>
              <ProjectView />
            </PrivateRoute>
          }
        />
        <Route
          path={routeName.ProjectViewVegInt}
          element={
            <PrivateRoute>
              <ProjectViewVegInt />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
