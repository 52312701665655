import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_PROJECT_DATA_LAYERS_EXTRACT_COORDS_URL } from './apiUrls';

let headers: any = {
  'Content-Type': 'application/json',
  accept: 'application/json'
};

// ngrok
// 'ngrok-skip-browser-warning': '69420'

export const Timeout = (time: number) => {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), time * 1000);
  return controller;
};

export async function refreshAccessToken(requestMethod: string, url: string, refreshToken: string) {
  const formData = {
    method: requestMethod,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: refreshToken
    }
  };

  // eslint-disable-next-line
  return await fetch(url, formData)
    .then((response) => {
      return response
        .json()
        .then((responseJson) => {
          return responseJson;
        })
        .catch(() => {
          toast.error('Refresh token expired');
          return null;
        });
    })
    .catch(() => {
      toast.error('Refresh token expired');
      return null;
    });
}

export function apiCall(
  requestMethod: string,
  url: string,
  payload: any,
  onSuccess: Function,
  onFailure: Function,
  accessToken?: string
) {
  if (accessToken) {
    headers = { ...headers, Authorization: accessToken };
  }

  let options: any = {
    method: requestMethod,
    headers,
    signal: Timeout(100).signal
  };
  const body = JSON.stringify(payload);

  if (payload !== undefined && payload !== '') {
    options = { ...options, body };
  }

  fetch(url, options)
    .then((response) => {
      response
        .json()
        .then((responseJson) => {
          if (responseJson.status) {
            onSuccess(responseJson);
          } else {
            onFailure(responseJson);
          }
        })
        .catch((error) => {
          onFailure(error);
        });
    })
    .catch((error) => {
      onFailure(error);
    });
}

export function fileUploadApicall(
  url: string,
  onSuccess: Function,
  onFailure: Function,
  accessToken?: string,
  file?: File,
  lulcStyleFile?: File
) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file, file.name);
  }

  if (lulcStyleFile) {
    formData.append('lulc_style_file', lulcStyleFile, lulcStyleFile.name);
  }
  let uploaddHeader: any = {
    'Content-Type': 'multipart/form-data'
  };
  if (accessToken) {
    uploaddHeader = { ...uploaddHeader, Authorization: accessToken };
  }

  try {
    axios
      .post(url, formData, { headers: uploaddHeader })
      .then((response) => {
        const responseJson = response.data;
        if (responseJson.status) {
          onSuccess(responseJson);
        } else {
          onFailure(responseJson);
        }
      })
      .catch((error) => {
        if (error.response) {
          onFailure(error.response.data);
        } else {
          onFailure(error);
        }
      });
  } catch (error) {
    onFailure(error);
  }
}

export function updateFileUploadApicall(
  url: string,
  onSuccess: Function,
  onFailure: Function,
  file?: File,
  accessToken?: string
) {
  const formData = new FormData();
  if (file) {
    formData.append('file', file);
  }

  let uploaddHeader: any = {
    'Content-Type': 'multipart/form-data'
  };
  if (accessToken) {
    uploaddHeader = { ...uploaddHeader, Authorization: accessToken };
  }

  try {
    axios
      .put(url, formData, { headers: uploaddHeader })
      .then((response) => {
        const responseJson = response.data;
        if (responseJson.status) {
          onSuccess(responseJson);
        } else {
          onFailure(responseJson);
        }
      })
      .catch((error) => {
        if (error.response) {
          onFailure(error.response.data);
        } else {
          onFailure(error);
        }
      });
  } catch (error) {
    onFailure(error);
  }
}

export async function getRepoFileCoordinates(
  orgId: number,
  projectId: number,
  dlId: number,
  fileId: number,
  token: string
) {
  if (token) {
    headers = { ...headers, Authorization: token };
  }
  return axios
    .get(GET_PROJECT_DATA_LAYERS_EXTRACT_COORDS_URL(orgId, projectId, dlId, fileId), { headers })
    .then((response) => {
      const responseJson = response.data;

      if (responseJson.status) {
        return responseJson.data;
      }
      toast.error('Something went wrong!');
      return null;
    })
    .catch(() => {
      toast.error('Something went wrong!');
      return null;
    });
}

export function downloadReadableStreamFile(
  requestMethod: string,
  url: string,
  onSuccess: Function,
  onFailure: Function,
  accessToken?: string
) {
  if (accessToken !== null) {
    headers.Authorization = accessToken;
  }

  const formData = {
    method: requestMethod,
    headers,
    signal: Timeout(100).signal
  };

  fetch(url, formData)
    .then((response) => {
      let filename = response.headers.get('content-disposition');
      filename = filename ? filename.split('=')[1] : '';
      filename = filename || '';

      response
        .blob()
        .then((blob) => {
          onSuccess(blob, filename);
        })
        .catch((error) => {
          onFailure(error);
        });
    })
    .catch((error) => {
      onFailure(error);
    });
}
