import { useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  Upload,
  UploadProps
} from 'antd';
import { toast } from 'react-toastify';
import { IoIosClose } from 'react-icons/io';
import { find, isEmpty } from 'lodash';
import { primary } from 'constants/color.constant';
import { ACCESS_TOKEN } from 'constants/common.constant';
import RequiredTitle from 'components/core/RequiredTitle';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { getFileType, getFileTypeCategory } from 'store/repositoryfile';
import {
  CategoryType,
  FileType,
  RepoFileCategory,
  UploadLulcFilePayload
} from 'store/repositoryfile/types';
import { addRepoFileDataLayer } from 'store/repositoryfile/actions';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const { Option } = Select;
const { Text } = Typography;

interface FileRepositoryFormProps {
  activeLayerId: number | null;
}

const FileRepositoryForm = ({ activeLayerId }: FileRepositoryFormProps) => {
  const dispatch = useAppDispatch();
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const { isRequestingAddRepoFileDataLayer } = useAppSelector((state) => state.repositoryFiles);
  const fileTypes: FileType[] | [] = useAppSelector((state) => getFileType(state));
  const [selectedFileType, setSelectedFileType] = useState<string | null>(null);
  const selectedtypeCategories: Array<CategoryType> | [] = useAppSelector((state) =>
    getFileTypeCategory(state, selectedFileType, selectedProject.type)
  );
  const [form] = Form.useForm();
  const [repoFile, setRepoFile] = useState<File | null>(null);
  const [lulcStyleFile, setLulcStyleFile] = useState<File>();
  const [lulcStylePayload, setLulcStylePayload] = useState<UploadLulcFilePayload | null>(null);

  const uploadProps: UploadProps = {
    maxCount: 1,
    beforeUpload: (file) => {
      setRepoFile(file);
      return false;
    }
  };

  const lulcUploadProps: UploadProps = {
    maxCount: 1,
    beforeUpload: (file) => {
      setLulcStyleFile(file);
      return false;
    }
  };

  const handleFileTypeChange = (value: { value: string; label: string }) => {
    setSelectedFileType(value?.value);
  };

  const handleUploadCallback = () => {
    setRepoFile(null);
    setLulcStylePayload(null);
    setLulcStyleFile(undefined);
    form.resetFields();
  };

  const handleSubmitRepoFile = (values: {
    category_id: number;
    type: { value: string; label: string };
  }) => {
    const categoryName: CategoryType | undefined = find(selectedtypeCategories, [
      'id',
      values.category_id
    ]);

    if (categoryName && categoryName.name === RepoFileCategory.LULC) {
      setLulcStylePayload({
        orgId: +selectedOrganization.id,
        projectId: +selectedProject.id,
        activeLayerId: activeLayerId!,
        categoryId: values.category_id
      });

      return;
    }

    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(
        addRepoFileDataLayer(
          +selectedOrganization.id,
          +selectedProject.id,
          activeLayerId!,
          values.category_id,
          token,
          handleUploadCallback,
          repoFile!
        )
      );
    });
  };

  const handleUploadFile = () => {
    if (activeLayerId) {
      form.submit();
    } else {
      toast.error('Select data layer date');
    }
  };

  const handleUploadLulcStyleFile = () => {
    if (repoFile && lulcStyleFile && lulcStylePayload) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          addRepoFileDataLayer(
            lulcStylePayload.orgId,
            lulcStylePayload.projectId,
            lulcStylePayload.activeLayerId,
            lulcStylePayload.categoryId,
            token,
            handleUploadCallback,
            repoFile,
            lulcStyleFile
          )
        );
      });
    } else {
      toast.error('Something went wrong');
    }
  };

  const handleCancelLulcStyleFile = () => {
    setLulcStyleFile(undefined);
    setLulcStylePayload(null);
  };

  const handleClearFile = () => {
    setRepoFile(null);
  };

  return (
    <>
      <div className="main-repo-form">
        <Form className="repo-form" onFinish={handleSubmitRepoFile} form={form}>
          <Row justify="space-between" align="bottom">
            <Col>
              <RequiredTitle title="Upload File" className="form-input-label" />
              <Form.Item className="input-form-item">
                {!repoFile ? (
                  <Upload {...uploadProps} showUploadList={false}>
                    <Button type="ghost">Select File</Button>
                  </Upload>
                ) : (
                  <Input
                    className="file-input"
                    style={{ padding: '4.4px 11px' }}
                    size="large"
                    value={repoFile.name}
                    readOnly
                    suffix={
                      <IoIosClose
                        size={20}
                        style={{ fill: primary, cursor: 'pointer' }}
                        onClick={handleClearFile}
                      />
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col>
              <RequiredTitle title="File type" className="form-input-label" isRequired />
              <Form.Item
                className="input-form-item"
                name="type"
                rules={[{ required: true, message: '' }]}
              >
                <Select
                  className="form-select"
                  placeholder="Select"
                  labelInValue
                  allowClear
                  onChange={handleFileTypeChange}
                >
                  {fileTypes &&
                    fileTypes.length > 0 &&
                    fileTypes.map((type: FileType) => <Option key={type.name}>{type.name}</Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <RequiredTitle title="Category" className="form-input-label" isRequired />
              <Form.Item
                className="input-form-item"
                name="category_id"
                rules={[{ required: true, message: '' }]}
              >
                <Select className="form-select" placeholder="Select Category" allowClear>
                  {selectedtypeCategories &&
                    selectedtypeCategories.length > 0 &&
                    selectedtypeCategories.map((category: CategoryType) => (
                      <Option key={category.id} value={category.id}>
                        {category.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="repo-file-btn">
              <Button
                type="primary"
                disabled={isEmpty(repoFile)}
                onClick={handleUploadFile}
                loading={isRequestingAddRepoFileDataLayer}
              >
                Upload
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <Modal
        title={null}
        className="approve-modal"
        width={722}
        closable={false}
        open={!!lulcStylePayload}
        footer={null}
        centered
      >
        <div className="approve-modal-content">
          <Text className="modal-title" style={{ marginBottom: '20px' }}>
            Please Upload LULC style file.
          </Text>
          {lulcStyleFile ? (
            <Text style={{ marginBottom: '20px', color: primary, fontSize: '18px' }}>
              {lulcStyleFile.name}
            </Text>
          ) : (
            <Upload {...lulcUploadProps} showUploadList={false} accept=".sld">
              <Button style={{ marginBottom: '20px' }}>Select file</Button>
            </Upload>
          )}
          <div className="modal-content-btn">
            <Button
              type="primary"
              disabled={!lulcStyleFile}
              onClick={handleUploadLulcStyleFile}
              loading={isRequestingAddRepoFileDataLayer}
            >
              Upload
            </Button>
            <Button type="ghost" onClick={handleCancelLulcStyleFile}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FileRepositoryForm;
