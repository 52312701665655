import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const LeftIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666599 5.99976C0.666599 5.80976 0.730766 5.62059 0.859932 5.46643L5.0266 0.466428C5.32077 0.113095 5.8466 0.0647615 6.19993 0.359761C6.55327 0.653928 6.60077 1.17893 6.3066 1.53309L2.5766 6.00893L6.17243 10.4773C6.46077 10.8356 6.4041 11.3606 6.04577 11.6489C5.68743 11.9373 5.16327 11.8806 4.8741 11.5223L0.850766 6.52226C0.728266 6.36976 0.666599 6.18476 0.666599 5.99976Z"
        fill={color}
      />
    </svg>
  );
};
LeftIcon.defaultProps = {
  color: white,
  height: 12,
  width: 7,
  onClick: () => {}
};
export default LeftIcon;
