import { Typography } from 'antd';
import { useAppSelector } from 'hooks/useReduxHook';

const { Text } = Typography;
const RiskPopup = () => {
  const { riskBuffer, riskTransmissionLine } = useAppSelector((state) => state.riskanalysis);
  return (
    <div className="risk-popup-main">
      <Text>
        Route Length :{' '}
        <Text>
          {riskTransmissionLine && riskTransmissionLine.length
            ? (riskTransmissionLine.length / 1000).toFixed(2)
            : 0}{' '}
          km
        </Text>
      </Text>
      <Text>
        Total No. of Towers : <Text>{riskTransmissionLine?.total_towers || 0}</Text>
      </Text>
      <Text>
        Corridor Width : <Text>{riskBuffer?.buffer_width || 0} m</Text>
      </Text>
    </div>
  );
};

export default RiskPopup;
