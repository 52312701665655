import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Typography } from 'antd';
import SatsureSkieswhite from 'assets/svg/SatsureSkieswhite';
import RequiredTitle from 'components/core/RequiredTitle';
import { routes } from 'constants/pageRoutes.constants';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { resetPasswordRequest } from 'store/users/actions';
import { RequestResetPassWordPayload, Status } from 'store/users/types';
import { loginSync } from 'utils/yupsync';
import './styles.less';

const { Text, Link } = Typography;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { isRequestingResetPassword } = useAppSelector((state) => state.user);
  const [isResetPasswordStatus, setresetPasswordStatus] = useState<string>('');

  const handleCallback = (status: boolean) => {
    if (status) {
      setresetPasswordStatus(Status.Success);
    } else {
      setresetPasswordStatus(Status.Error);
    }
  };

  const handleSubmitResetPassword = (values: RequestResetPassWordPayload) => {
    dispatch(resetPasswordRequest(values, handleCallback));
  };
  return (
    <div className="main-login-page main-forgot-pass">
      <div className="satsure-skies-logo">
        <SatsureSkieswhite />
      </div>
      <div className="login-content forgot-pass-main">
        <div className="forgot-pass">
          <Text>Forgot Password ?</Text>
          <Text>Enter email address you use to log in</Text>
        </div>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          className="login-form forgot-pass-form"
          onFinish={handleSubmitResetPassword}
        >
          <RequiredTitle title="Email" className="ann-form-label" isRequired />
          <Form.Item name="email" className="email-input" rules={[loginSync]}>
            <Input placeholder="Enter your mail address" className="form-input" />
          </Form.Item>
          <div className="form-status">
            {isResetPasswordStatus === Status.Error && (
              <>
                <Text style={{ color: '#EB5757' }}>Error!!</Text>
                <Text style={{ color: '#EB5757' }}>
                  The email address you entered is Incorrect or is not associated with SatSure Skies
                </Text>
              </>
            )}
            {isResetPasswordStatus === Status.Success && (
              <>
                <Text style={{ color: '#219653' }}>Success!!</Text>
                <Text style={{ color: '#219653' }}>
                  A link will be sent to your email address to reset the password
                </Text>
              </>
            )}
          </div>
          <Button
            className="login-btn reset-pass-btn"
            type="primary"
            htmlType="submit"
            block
            size="large"
            disabled={Boolean(isResetPasswordStatus === Status.Success)}
            loading={isRequestingResetPassword}
          >
            Reset Password
          </Button>
          <div className="back-to-login">
            <Button type="link" onClick={() => navigate(routes.LoginUrl)}>
              Back to Log In
            </Button>
          </div>
        </Form>
        {isResetPasswordStatus === Status.Error && (
          <div className="bottom-text">
            <Text>
              For any further assistance reach out to us at <Link>support@skies.com</Link>
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
