import { Fragment, useMemo } from 'react';
import { Space, Typography } from 'antd';
import { LatLng } from 'leaflet';
import { filter, isEmpty, last } from 'lodash';
import { Circle, Polygon, Popup } from 'react-leaflet';
import { RiskcatColor } from 'constants/common.constant';
import { useAppSelector } from 'hooks/useReduxHook';
import { TreeType } from 'store/riskanalysis/types';
import { ProjectCategoryTypes } from 'store/projects/types';
import { CoordinateType } from 'types/common.types';
import { getCenterPosition, getCenterPositionPolygon, reformatRiskCoordinates } from 'utils/xml';
import { TreeZindex } from 'constants/map.constants';

const { Text } = Typography;

interface RendorRiskTreesVegIntlProps {
  selectedSegmentTrees: TreeType[] | [];
  riskCats: string[];
}

const RendorRiskTreesVegIntl = ({
  selectedSegmentTrees,
  riskCats
}: RendorRiskTreesVegIntlProps) => {
  const { selectedProject } = useAppSelector((state) => state.projects);
  const rendorTrees = useMemo(() => {
    if (selectedSegmentTrees && selectedSegmentTrees.length === 0) return null;

    return filter(selectedSegmentTrees, (tree: TreeType) => riskCats.includes(tree.risk_cat)).map(
      (data: TreeType, i: number) => {
        let centerLatLng;
        if (data.geometry.type === CoordinateType.MultiPolygon) {
          centerLatLng = getCenterPosition(data.geometry.coordinates);
        } else if (data.geometry.type === CoordinateType.Polygon) {
          centerLatLng = getCenterPositionPolygon(data.geometry.coordinates);
        }

        const reformCoords: any = reformatRiskCoordinates(data);

        if (reformCoords && reformCoords.length === 0) return null;
        const lastCoord: any = !isEmpty(reformCoords) ? last(reformCoords[0]) : [];

        if (isEmpty(centerLatLng)) return null;

        return (
          <Fragment key={`${data.id}-${i}-${data.falling_radius}`}>
            {selectedProject?.type !== ProjectCategoryTypes.VegetationManagementLite && (
              <Circle
                pathOptions={{
                  fillColor: RiskcatColor[data.risk_cat],
                  color: RiskcatColor[data.risk_cat],
                  fillOpacity: 1
                }}
                center={centerLatLng}
                radius={0.5}
              />
            )}
            <Polygon
              positions={reformCoords}
              pathOptions={{
                fillColor: RiskcatColor[data.risk_cat],
                color: RiskcatColor[data.risk_cat],
                fillOpacity: 0.6,
                weight: 2,
                dashArray: [5, 5]
              }}
              pane={TreeZindex[`${data.risk_cat}`]}
            >
              <Popup
                className="annts-point-popup tree-polygon-popup"
                position={new LatLng(lastCoord[1], lastCoord[0])}
                minWidth={232}
                maxHeight={272}
              >
                <Space direction="vertical">
                  {data?.risk_cat && (
                    <div>
                      <Text className="annts-market-title">Risk Category</Text>
                      <Text className="annts-market-data">{data?.risk_cat || '-'}</Text>
                    </div>
                  )}
                  {selectedProject?.type !== ProjectCategoryTypes.VegetationManagementLite && (
                    <div>
                      <Text className="annts-market-title">Tree ID</Text>
                      <Text className="annts-market-data">{data?.id || '-'}</Text>
                    </div>
                  )}
                  <div>
                    <Text className="annts-market-title">Height of the Tree</Text>
                    {selectedProject?.type !== ProjectCategoryTypes.VegetationManagementLite && (
                      <Text className="annts-market-data">
                        {data && data.falling_radius ? `${data.falling_radius.toFixed(2)} m` : '-'}
                      </Text>
                    )}
                    {selectedProject?.type === ProjectCategoryTypes.VegetationManagementLite && (
                      <Text className="annts-market-data">
                        {data && data.height_of_tree
                          ? `${Number(data.height_of_tree)?.toFixed(2)} m`
                          : '-'}
                      </Text>
                    )}
                  </div>
                  {selectedProject?.type === ProjectCategoryTypes.VegetationManagementLite && (
                    <div>
                      <Text className="annts-market-title">Distance to Road</Text>
                      <Text className="annts-market-data">
                        {data && data.distance_to_road
                          ? `${data.distance_to_road.toFixed(2)} m`
                          : '-'}
                      </Text>
                    </div>
                  )}
                  {data?.work_type && (
                    <div>
                      <Text className="annts-market-title">Action Type</Text>
                      <Text className="annts-market-data">{data?.work_type || '-'}</Text>
                    </div>
                  )}
                  <div>
                    <Text className="annts-market-title">
                      {selectedProject?.type !== ProjectCategoryTypes.VegetationManagementLite
                        ? 'Coordinates'
                        : 'Mid coords'}
                    </Text>
                    <Text className="annts-market-data">
                      {!isEmpty(centerLatLng)
                        ? [centerLatLng.lat.toFixed(6), centerLatLng.lng.toFixed(6)].join(',')
                        : '-'}
                    </Text>
                  </div>
                </Space>
              </Popup>
            </Polygon>
          </Fragment>
        );
      }
    );
  }, [selectedSegmentTrees, riskCats]);

  // eslint-disable-next-line
  return <>{rendorTrees}</>;
};

export default RendorRiskTreesVegIntl;
