import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Button, Form, Input } from 'antd';
import { toast } from 'react-toastify';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { addAnnotationsPoint, editAnnotationsPoint } from 'store/annotations/actions';
import { AddedAnnotation, MarkingAddPayload, MarkingEditPayload } from 'store/annotations/types';
import { DataLayerTableType } from 'store/repositoryfile/types';
import { getAsyncStorageValue } from 'utils/localStorage';
import { extractAreaFromCoords } from 'utils/xml';
import RequiredTitle from './RequiredTitle';

interface AddNotationPolygonFormProps {
  isEdit: boolean;
  polygonCoords: any;
  centroidPoint: any;
  activeDataLayer: DataLayerTableType | null;
  editMarkingData: AddedAnnotation | null | undefined;
  handleRemoveMarkerPolygon: Function;
}

const AddNotationPolygonForm = ({
  isEdit,
  polygonCoords,
  centroidPoint,
  activeDataLayer,
  editMarkingData,
  handleRemoveMarkerPolygon
}: AddNotationPolygonFormProps) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { isAddAnnotationPointRequesting } = useAppSelector((state) => state.annotations);

  useEffect(() => {
    const area = extractAreaFromCoords(polygonCoords) / 1000;

    form.setFieldsValue({
      coordinates: `${centroidPoint.lat}, ${centroidPoint.lng}`,
      area
    });

    if (isEdit) {
      if (polygonCoords && polygonCoords.length === 0) return;
      if (isEmpty(editMarkingData)) return;

      const area = extractAreaFromCoords(polygonCoords) / 1000;

      form.setFieldsValue({
        antsname: editMarkingData.properties.name,
        category: editMarkingData.properties.category,
        coordinates: `${centroidPoint.lat}, ${centroidPoint.lng}`,
        note: editMarkingData.properties.note,
        area
      });
    }
  }, [polygonCoords, centroidPoint]);

  const handleCallack = (status: boolean) => {
    if (status) {
      handleRemoveMarkerPolygon();
    } else {
      toast.error('Something went wrong!');
    }
  };

  const handleAddEditPolygon = (values: any) => {
    if (!activeDataLayer) return;
    if (polygonCoords && polygonCoords.length === 0) return;

    if (isEdit && editMarkingData) {
      const payload: MarkingEditPayload = {
        coordinates: polygonCoords,
        properties: {
          name: values?.antsname ? values.antsname : '',
          category: values?.category ? values.category : '',
          area: values?.area ? values.area : '',
          note: values?.note ? values.note : '',
          coordinates: values?.coordinates ? values.coordinates : '',
          count: String(polygonCoords[0].length)
        }
      };

      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          editAnnotationsPoint(
            payload,
            +selectedOrganization.id,
            +selectedProject.id,
            +activeDataLayer.id,
            +editMarkingData.id,
            token,
            handleCallack
          )
        );
      });
    } else {
      const payload: MarkingAddPayload = {
        geometry_type: 'Polygon',
        coordinates: polygonCoords,
        properties: {
          name: values?.antsname ? values.antsname : '',
          category: values?.category ? values.category : '',
          area: values?.area ? values.area : '',
          note: values?.note ? values.note : '',
          coordinates: values?.coordinates ? values.coordinates : '',
          count: String(polygonCoords[0].length)
        }
      };

      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          addAnnotationsPoint(
            payload,
            +selectedOrganization.id,
            +selectedProject.id,
            +activeDataLayer.id,
            token,
            handleCallack
          )
        );
      });
    }
  };

  const handleCancelMarker = () => {
    handleRemoveMarkerPolygon();
  };

  return (
    <Form
      form={form}
      name="add_annotation"
      className="annotation-form"
      onFinish={handleAddEditPolygon}
    >
      <RequiredTitle title="Name" className="ann-form-label" />
      <Form.Item name="antsname" className="annotation-form-item">
        <Input placeholder="Enter Name" />
      </Form.Item>
      <RequiredTitle title="Category" className="ann-form-label" />
      <Form.Item name="category" className="annotation-form-item">
        <Input placeholder="Enter Category" />
      </Form.Item>
      <RequiredTitle title="Area" className="ann-form-label" />
      <Form.Item name="area" className="annotation-form-item">
        <Input placeholder="Enter area" />
      </Form.Item>
      <RequiredTitle title="Centroid Co-ordinates" className="ann-form-label" />
      <Form.Item name="coordinates" className="annotation-form-item">
        <Input placeholder="Enter center point" />
      </Form.Item>
      <RequiredTitle title="Add Note" className="ann-form-label" />
      <Form.Item name="note" className="annotation-form-item">
        <Input.TextArea
          placeholder="Enter Text here"
          style={{ minHeight: '64px', resize: 'none' }}
        />
      </Form.Item>
      <Form.Item style={{ margin: 0 }}>
        <Button
          type="primary"
          htmlType="submit"
          className="annotation-form-button"
          loading={isAddAnnotationPointRequesting}
        >
          Save
        </Button>
        <Button
          type="ghost"
          onClick={handleCancelMarker}
          className="annotation-form-button"
          disabled={isAddAnnotationPointRequesting}
        >
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddNotationPolygonForm;
