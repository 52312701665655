import React, { useMemo, useState } from 'react';
import { Space, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { Polyline, Popup, useMap } from 'react-leaflet';
import { white } from 'constants/color.constant';
import { TransmissionLineType } from 'store/riskanalysis/types';
import { reformatRiskCoordinates } from 'utils/xml';

interface RendorTransmissionLineProps {
  transmissionLine: TransmissionLineType | null;
}

const { Text } = Typography;

const RendorTransmissionLine = ({ transmissionLine }: RendorTransmissionLineProps) => {
  const map = useMap();
  const [isShowLine, setShowLine] = useState<boolean>(false);

  map.on('zoomend', (e: any) => {
    const zoomLevel = e.target.getZoom();
    if (zoomLevel >= 13) {
      setShowLine(true);
    } else {
      setShowLine(false);
    }
  });

  const transmssnLine = useMemo(() => {
    if (isEmpty(transmissionLine)) return null;
    const coords: any = reformatRiskCoordinates(transmissionLine);
    if (coords && coords.length === 0) return null;

    return (
      isShowLine && (
        <Polyline
          // @ts-ignore
          positions={coords}
          pane="shadowPane"
          pathOptions={{
            color: white,
            fillOpacity: 1,
            weight: 2,
            dashArray: [10, 10]
          }}
          eventHandlers={{
            mouseover: (e: any) =>
              e.target.setStyle({
                weight: 4
              }),
            mouseout: (e: any) =>
              e.target.setStyle({
                weight: 2
              })
          }}
        >
          <Popup className="annts-point-popup risk-point-popup" minWidth={232} maxHeight={226}>
            <Space direction="vertical">
              <div>
                <Text className="annts-market-title">Total Line Length</Text>
                <Text className="annts-market-data">
                  {transmissionLine?.length ? `${transmissionLine?.length.toFixed(3)} m` : '-'}
                </Text>
              </div>
              <div>
                <Text className="annts-market-title">Total Towers</Text>
                <Text className="annts-market-data">{transmissionLine?.total_towers || '-'}</Text>
              </div>
            </Space>
          </Popup>
        </Polyline>
      )
    );
  }, [transmissionLine, isShowLine]);

  // eslint-disable-next-line
  return <>{transmssnLine}</>;
};

export default RendorTransmissionLine;
