export const primary = '#6E3BD1';
export const white = '#ffffff';
export const black = '#000000';
export const blackShade = '#030303';
export const lightGrey = '#E0E0E0';
export const red = '#FA2D00';
export const red1 = '#FA2D0099';
export const orange = '#F2994A';
export const orange1 = '#F2994A99';
export const saffron = '#FF9933';
export const green = '#27AE60';
export const green1 = '#27AE6099';
export const lightGreen = '#43ffdd';
export const purple = '#BE66FF';
export const lightGrey1 = '#C7C7CC';
export const blueshade = '#95969d';
