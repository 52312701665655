import { OrganizationPayload } from 'store/organizations/types';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const GET_GEOSERVER_WMS_URL = `${BASE_URL}/geoserver/wms`;
export const GET_GEOSERVER_WMTS_URL = `${BASE_URL}/geoserver/wmts`;

// auth
export const RSA_URL = `${BASE_URL}/rsa`;
export const LOGIN_URL = `${BASE_URL}/login`;
export const LOGOUT_URL = `${BASE_URL}/logout`;
export const RESET_PASSWORD_REQUEST_URL = `${BASE_URL}/resetpasswordrequest`;
export const RESET_PASSWORD_URL = `${BASE_URL}/resetpassword`;
export const RSA_ENCRYPT_URL = `${BASE_URL}/RSA-encrypt`;
export const RSA_DECRYPT_URL = `${BASE_URL}/RSA-decrypt`;
export const AES_ENCRYPT_URL = `${BASE_URL}/aes-encrypt`;
export const AES_DECRYPT_URL = `${BASE_URL}/aes-decrypt`;
export const AES_KEY_URL = `${BASE_URL}/encrypt/`;
export const REFRESH_BOTH_TOKEN = `${BASE_URL}/refresh_both_tokens`;
export const GET_USER_DETAIL_URL = (userId: string) => `${BASE_URL}/users/${userId}/info`;
export const ADD_USER_DETAIL_URL = (userId: string) => `${BASE_URL}/users/${userId}/profile/edit`;
export const ADD_USER_PIC_URL = (userId: string) =>
  `${BASE_URL}/users/${userId}/profile/picture/add`;
export const USER_RESET_PASSWORD_URL = (userId: string) =>
  `${BASE_URL}/users/${userId}/resetpassword`;

// organization
export const GET_ALL_ORGANIZATION_URL = `${BASE_URL}/organisations`;
export const ADD_ORGANIZATION_URL = (payload: OrganizationPayload) =>
  `${BASE_URL}/organisations?organisation_name=${payload.organisation_name}&client_email=${payload.client_email}&client_firstname=${payload.client_firstname}&client_lastname=${payload.client_lastname}`;
export const GET_ORGANIZATION_URL = (orgId: number) => `${BASE_URL}/organisations/${orgId}`;
export const GET_ORGANIZATION_MEMBERS_URL = (orgId: number) =>
  `${BASE_URL}/organisations/${orgId}/members`;
export const ADD_ORGANIZATION_MEMBERS_URL = (orgId: number) =>
  `${BASE_URL}/organisations/${orgId}/members`;
export const USER_DISABLE_ENABLE_URL = (orgId: number) =>
  `${BASE_URL}/organisations/${orgId}/members/access`;
export const EDIT_ORGANIZATION_URL = (orgId: number, orgName: string) =>
  `${BASE_URL}/organisations/${orgId}?OrgName=${orgName}`;

// projects
export const GET_ALL_PROJECT_URL = (orgId: number) => `${BASE_URL}/organisations/${orgId}/projects`;
export const GET_PROJECT_URL = (orgId: number, projectId: number) =>
  `${BASE_URL}/organisations/${orgId}/projects/${projectId}`;
export const ADD_PROJECT_URL = (orgId: number) => `${BASE_URL}/organisations/${orgId}/projects`;
export const EDIT_PROJECT_URL = (orgId: number, projectId: number) =>
  `${BASE_URL}/organisations/${orgId}/projects/${projectId}`;
export const GET_AVAILABLE_PROJECT_MEMBERS_URL = (orgId: number, projectId: number) =>
  `${BASE_URL}/organisations/${orgId}/projects/${projectId}/members`;
export const ADD_PROJECT_MEMBERS_URL = (orgId: number, projectId: number) =>
  `${BASE_URL}/organisations/${orgId}/projects/${projectId}/members`;
export const GET_PROJECT_TYPES_URL = `${BASE_URL}/project/types`;
export const REMOVE_MEMBER_FROM_ORGANIZATION_URL = (orgId: number, memberId: string) =>
  `${BASE_URL}/organisations/${orgId}/members/${memberId}/access`;
export const REMOVE_MEMBER_FROM_PROJECT_URL = (orgId: number, projectId: number) =>
  `${BASE_URL}/organisations/${orgId}/projects/${projectId}/members`;
export const GET_PROJECT_DATA_LAYERS_EXTRACT_COORDS_URL = (
  orgId: number,
  projectId: number,
  dlId: number,
  fileId: number
) =>
  `${BASE_URL}/organisations/${orgId}/projects/${projectId}/data-layers/${dlId}/files/${fileId}/geometry`;

// repositoryFile
export const GET_DATA_LAYERS_URL = (orgId: number, projectId: number) =>
  `${BASE_URL}/organisations/${orgId}/projects/${projectId}/data-layers`;
export const ADD_DATE_DATA_LAYERS_URL = (orgId: number, projectId: number) =>
  `${BASE_URL}/organisations/${orgId}/projects/${projectId}/data-layers`;
export const EDIT_DELETE_DATE_DATA_LAYERS_URL = (orgId: number, projectId: number, dlId: number) =>
  `${BASE_URL}/organisations/${orgId}/projects/${projectId}/data-layers/${dlId}`;
export const GET_FILE_TYPES_URL = `${BASE_URL}/file-types`;
export const ADD_DATA_LAYER_REPO_FILE_URL = (
  orgId: number,
  projectId: number,
  dlId: number,
  categoryId: string
) =>
  `${BASE_URL}/organisations/${orgId}/projects/${projectId}/data-layers/${dlId}/files?category_id=${categoryId}`;
export const EDIT_DELETE_REPOSITORY_FILE_URL = (
  orgId: number,
  projectId: number,
  dlId: number,
  fileId: number
) => `${BASE_URL}/organisations/${orgId}/projects/${projectId}/data-layers/${dlId}/files/${fileId}`;
export const DOWNLOAD_REPO_FILE_URL = (
  orgId: number,
  projectId: number,
  dlId: number,
  fileId: number
) =>
  `${BASE_URL}/organisations/${orgId}/projects/${projectId}/data-layers/${dlId}/files/${fileId}/download`;
export const GET_GEOSERVER_LAYER_STYLE_URL = (layer: string) =>
  `${BASE_URL}/geoserver/layer-legend?layer_name=${layer}`;
export const ENABLE_DISABLE_DATA_LAYER_URL = (orgId: number, projectId: number, dlId: number) =>
  `${BASE_URL}/organisations/${orgId}/projects/${projectId}/data-layers/${dlId}/activation`;

// annotations
export const GET_UPLOADED_ANNOTATIONS_URL = (orgId: number, projectId: number) =>
  `${BASE_URL}/organisations/${orgId}/projects/${projectId}/vector-file-annotations`;
export const GET_ANNOTATIONS_URL = (orgId: number, projectId: number) =>
  `${BASE_URL}/organisations/${orgId}/projects/${projectId}/geo-annotations`;
export const ADD_ANNOTATIONS_URL = (orgId: number, projectId: number, dlId: number) =>
  `${BASE_URL}/organisations/${orgId}/projects/${projectId}/data-layers/${dlId}/geo-annotations`;
export const EDIT_DELETE_ANNOTATIONS_URL = (
  orgId: number,
  projectId: number,
  dlId: number,
  annId: number
) =>
  `${BASE_URL}/organisations/${orgId}/projects/${projectId}/data-layers/${dlId}/geo-annotations/${annId}`;

// risk analysis
export const RISK_ANALYSIS_DATA_URL = (orgId: number, projectId: number, dlId: number) =>
  `${BASE_URL}/organisations/${orgId}/projects/${projectId}/data-layers/${dlId}/risk-analysis`;
