/* eslint-disable */
import { Typography } from 'antd';

const { Text } = Typography;

const NavButton = ({ currentSlide, slideCount, children, ...props }: any) => (
  <Text {...props} className="nav-btn">
    {children}
  </Text>
);

export default NavButton;
