import { Divider, Typography } from 'antd';
import LocationIcon from 'assets/svg/LocationIcon';
import SearchIcon from 'assets/svg/SearchIcon';
import { isEmpty } from 'lodash';
import { TransmissionSegmentType } from 'store/riskanalysis/types';
import { riskstyles } from 'styles/projectView.styles';
import { CoordinateType } from 'types/common.types';
import { getCenterPosition, getCenterPositionPolygon } from 'utils/xml';

const { Text } = Typography;
interface RiskSegmentCardProps {
  riskData: TransmissionSegmentType;
  segmentIndex: number;
  handleClickShowRiskData: (riskData: TransmissionSegmentType, ind: number) => void;
}

const RiskSegmentCard = ({
  riskData,
  segmentIndex,
  handleClickShowRiskData
}: RiskSegmentCardProps) => {
  let centerLatLng;
  if (riskData.geometry.type === CoordinateType.MultiPolygon) {
    centerLatLng = getCenterPosition(riskData.geometry.coordinates);
  } else if (riskData.geometry.type === CoordinateType.Polygon) {
    centerLatLng = getCenterPositionPolygon(riskData.geometry.coordinates);
  }
  return (
    <div className="risk-segment">
      <Text>{riskData.name}</Text>
      <div className="lat-long-content">
        <div>
          <LocationIcon />
          <Text className="lat-long">
            {!isEmpty(centerLatLng)
              ? [centerLatLng.lat.toFixed(6), centerLatLng.lng.toFixed(6)].join(',')
              : '-'}
          </Text>
        </div>
        <div onClick={() => handleClickShowRiskData(riskData, segmentIndex)}>
          <SearchIcon />
        </div>
      </div>
      <Divider style={riskstyles.cardDivider} />
      <div>
        <div className="error-content">
          <Text className="high-risk">No. of High Risk Trees </Text>
          <Text className="high-risk">{riskData.total_high_risk_trees}</Text>
        </div>
        <div className="error-content">
          <Text className="medium-risk">No. of Medium Risk Tress</Text>
          <Text className="medium-risk">{riskData.total_med_risk_trees}</Text>
        </div>
        <div className="error-content">
          <Text className="low-risk">No. of Low Risk Tress</Text>
          <Text className="low-risk">{riskData.total_low_risk_trees}</Text>
        </div>
      </div>
    </div>
  );
};

export default RiskSegmentCard;
