import { useMemo } from 'react';
import { DataLayerFileType, LulcDataType } from 'store/repositoryfile/types';
import WMTSLULCTileLayer from './WMTSLULCTileLayer';
import 'scripts/wmts';

interface LULCTileLayersProps {
  selectedLulcLayer: string[];
  geoserverLayerStyles: LulcDataType[];
  lulcRepoFile: DataLayerFileType;
}

const LULCTileLayers = ({
  selectedLulcLayer,
  geoserverLayerStyles,
  lulcRepoFile
}: LULCTileLayersProps) => {
  const layers = useMemo(() => {
    return geoserverLayerStyles.map((style: LulcDataType) => {
      return (
        <WMTSLULCTileLayer
          key={style.title}
          selectedLulcLayer={selectedLulcLayer}
          lulcRepoFile={lulcRepoFile}
          env={style.value}
        />
      );
    });
  }, [geoserverLayerStyles, selectedLulcLayer]);
  // eslint-disable-next-line
  return <>{layers}</>;
};

export default LULCTileLayers;
