import { Typography } from 'antd';

const { Text } = Typography;
const RiskLegend = () => {
  return (
    <>
      <div className="risk-legend-content">
        <div className="risk-high risk-box" />
        <Text>High Risk Tree</Text>
      </div>
      <div className="risk-legend-content">
        <div className="risk-medium risk-box" />
        <Text>Medium Risk Tree</Text>
      </div>
      <div className="risk-legend-content">
        <div className="risk-low risk-box" />
        <Text>Low/No Risk Tree</Text>
      </div>
    </>
  );
};

export default RiskLegend;
