import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  Modal,
  PageHeader,
  Popconfirm,
  Space,
  Table,
  Tabs,
  Tooltip,
  Typography
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { toast } from 'react-toastify';
import { capitalize, isEmpty } from 'lodash';
import { AiOutlineClose, AiOutlineInfoCircle } from 'react-icons/ai';
import LeftArrowIcon from 'assets/svg/LeftArrowIcon';
import { black, primary, white } from 'constants/color.constant';
import { ACCESS_TOKEN, USER_ROLE } from 'constants/common.constant';
import { routes as routeName } from 'constants/pageRoutes.constants';
import SwitchButtonv2 from 'components/core/SwitchButtonv2';
import AvatarList from 'components/Avatar/AvatarList';
import AddProjectMemberModal from 'components/Modals/AddProjectMember/AddProjectMemberModal';
import RemoveMemberOrganization from 'components/Modals/RemoveMemberOrganization/RemoveMemberOrganization';
import RemoveProjectMemberModal from 'components/Modals/RemoveProjectMember/RemoveProjectMemberModal';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { getOrganizationTeamMembers } from 'store/organizations';
import { enableDisableUser, getOrganizationMembers } from 'store/organizations/actions';
import { EnableDisableUser, OrganizationMember } from 'store/organizations/types';
import {
  getAvailableProjectMembers,
  getProjects,
  removeAddMemberFromOrganization,
  removeMemberFromProject
} from 'store/projects/actions';
import { Project, TeamMember } from 'store/projects/types';
import { UserRole } from 'store/users/types';
import { getAsyncStorageValue, getStorageValue } from 'utils/localStorage';
import { shortLabel } from 'utils/utils';
import './styles.less';

const { Text, Link } = Typography;
const { TabPane } = Tabs;

const UserManagement = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userRole = getStorageValue(USER_ROLE);
  const {
    selectedOrganization,
    isLoadingOrganizationMember,
    isRequestingEnableDisableUser,
    isRequestingRemoveMemberFromOrganization
  } = useAppSelector((state) => state.organizations);
  const { isLoadingProjects, projects, isRequestingRemoveMemberFromProject } = useAppSelector(
    (state) => state.projects
  );
  const orginsationMembers = useAppSelector(getOrganizationTeamMembers);
  const [isOpenAddProjectMemberModal, setAddProjectMemberModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Project | null>(null);
  const [isOpenRemoveOrgMember, setOpenRemoveOrgMember] = useState<boolean>(false);
  const [isDisableEnableUsers, setDisableEnableUsers] = useState<boolean>(true);
  const [selectedOrgMember, setSelectedOrgMember] = useState<OrganizationMember | null>(null);
  const [removeMemberModal, setRemoveMemberModal] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<number | null>(null);
  const [projectMembers, setProjectMembers] = useState<TeamMember[]>([]);
  const [selectedProjectMember, setRemoveSelectedProjectMember] = useState<string[]>([]);

  useEffect(() => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(getProjects(selectedOrganization.id, token));
      if (userRole !== UserRole.Client) {
        dispatch(getOrganizationMembers(selectedOrganization?.id, token));
      }
    });
  }, []);

  const handleOnDisableEnableUser = (isCheck: boolean) => {
    const payload: EnableDisableUser = {
      is_active: isCheck
    };
    setDisableEnableUsers(isCheck);
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(enableDisableUser(payload, +selectedOrganization.id, isCheck, token));
    });
  };

  const handleCloseAddProjectMemberModal = () => {
    setSelectedRow(null);
    setAddProjectMemberModal(false);
  };

  const handleAddProjectMemberForm = (row: Project) => {
    if (userRole !== UserRole.Client) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getAvailableProjectMembers(selectedOrganization.id, row.id, token));
      });
    }
    setAddProjectMemberModal(!isOpenAddProjectMemberModal);
    setSelectedRow(row);
  };

  const handleRemoveMemberOrganizationCallback = (res: boolean) => {
    setSelectedOrgMember(null);
    setOpenRemoveOrgMember(!isOpenRemoveOrgMember);
    if (!res) toast.error('Something Went Wrong');
  };

  const handleRemoveMemberOrganizationCancel = () => {
    setSelectedOrgMember(null);
    setOpenRemoveOrgMember(!isOpenRemoveOrgMember);
  };

  const handleRemoveTeamMemberCallback = (record: OrganizationMember) => {
    setOpenRemoveOrgMember(!isOpenRemoveOrgMember);
    setSelectedOrgMember(record);
  };

  const handleRemoveMemberOrganizationOk = () => {
    if (!isEmpty(selectedOrgMember)) {
      const payload = {
        is_active: false
      };
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          removeAddMemberFromOrganization(
            payload,
            selectedOrgMember.id,
            selectedOrganization?.id,
            token,
            handleRemoveMemberOrganizationCallback
          )
        );
      });
    } else {
      handleRemoveMemberOrganizationCallback(false);
    }
  };

  const handleAddMemberOrganizationCallback = (response: boolean) => {
    if (!response) toast.error('somthing went wrong');
  };

  const handleAddTeamMemberOrg = (record: OrganizationMember) => {
    const payload = {
      is_active: true
    };
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(
        removeAddMemberFromOrganization(
          payload,
          record.id,
          +selectedOrganization.id,
          token,
          handleAddMemberOrganizationCallback
        )
      );
    });
  };

  const handleRemoveMemberProjectCallback = () => {
    setProjectId(null);
    setRemoveSelectedProjectMember([]);
    setRemoveMemberModal(!removeMemberModal);
  };

  const handleRemoveProjectMemberCancel = () => {
    handleRemoveMemberProjectCallback();
  };

  const handleRemoveProjectMember = (project: Project) => {
    setProjectId(project.id);
    setRemoveMemberModal(!removeMemberModal);
    setProjectMembers(project.team_members);
  };

  const handleRemoveProjectMemberOk = () => {
    if (!isEmpty(selectedProjectMember) && projectId) {
      const payload = {
        member_list: selectedProjectMember
      };
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          removeMemberFromProject(
            payload,
            projectId,
            selectedOrganization?.id,
            token,
            handleRemoveMemberProjectCallback
          )
        );
      });
    } else {
      toast.error('Something Went Wrong');
      handleRemoveMemberProjectCallback();
    }
  };

  const handleRemoveProjectMemberSubmit = (members: string[]) => {
    setRemoveSelectedProjectMember(members);
  };

  const userColumns: ColumnsType<OrganizationMember> = [
    {
      title: 'Name of the employee',
      key: 'user_name',
      render: (_, record) => {
        let firstname;
        let lastname;
        let compressName;
        if (record.first_name && record.last_name) {
          firstname = record.first_name;
          lastname = record.last_name;
          compressName = shortLabel(`${firstname} ${lastname}`);
        } else {
          firstname = record.email;
          lastname = '';
          compressName = capitalize(record.email.charAt(0));
        }
        return (
          <div className="user-name-mng">
            <Avatar>{compressName}</Avatar>
            <Text>{`${firstname} ${lastname}`}</Text>
          </div>
        );
      }
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <Text className="user-email-mng">{text}</Text>
    },
    {
      title: 'No of Assigned project',
      dataIndex: 'projects',
      key: 'user_projects',
      align: 'center',
      render: (_, record) =>
        record.projects && record.projects.length > 0 ? (
          <Text className="user-project">
            {record.projects.length}
            <Tooltip
              placement="bottom"
              color={white}
              title={record.projects.map((project: string, index: number) => (
                <Text key={`${project}-${index}`} style={{ display: 'block', color: '#727272' }}>
                  {project}
                </Text>
              ))}
            >
              <AiOutlineInfoCircle size={18} color="#727272" />
            </Tooltip>
          </Text>
        ) : (
          '-'
        )
    },
    {
      title: 'Actions',
      key: 'user-action',
      align: 'center',
      render: (_, record) =>
        record.is_active ? (
          <Link underline onClick={() => handleRemoveTeamMemberCallback(record)}>
            remove from org.
          </Link>
        ) : (
          <>
            <Text italic style={{ color: 'red', marginRight: '5px' }}>
              removed from org.
            </Text>
            <Popconfirm
              placement="top"
              title="You want to add this member organization"
              onConfirm={() => handleAddTeamMemberOrg(record)}
              okText="Yes"
              okButtonProps={{ loading: isRequestingRemoveMemberFromOrganization }}
              cancelText="No"
            >
              <Button type="link" key="add-again" style={{ color: primary, padding: '0' }}>
                <u>add again</u>
              </Button>
            </Popconfirm>
          </>
        )
    }
  ];

  const projectColumns: ColumnsType<Project> = [
    {
      title: 'Project ID',
      key: 'project_id',
      render: (_, record) => (
        <Text className="project-id" key={record.id}>
          {record.id}
        </Text>
      )
    },
    {
      title: 'Name of the project',
      key: 'project_name',
      render: (_, record) => <Text key={record.id}>{record.name}</Text>
    },
    {
      title: 'Assigned team members',
      key: 'project_team_members',
      render: (_, row) => (
        <AvatarList
          className="project-member"
          project={row}
          handleAddProjectMemberForm={handleAddProjectMemberForm}
        />
      )
    },
    {
      title: 'Actions',
      align: 'center',
      key: 'project-action',
      render: (_, record) => (
        <Space size="middle" key={record.id}>
          <Button
            type="link"
            style={{ padding: '0' }}
            onClick={() => handleRemoveProjectMember(record)}
          >
            <u>remove member from project</u>
          </Button>
        </Space>
      )
    }
  ];

  return (
    <div className="main-user-management">
      <PageHeader
        className="user-mng-head"
        onBack={() =>
          navigate(`${routeName.ProjectList}?organization_id=${selectedOrganization?.id}`)
        }
        backIcon={
          <Button
            icon={<LeftArrowIcon color="black" />}
            type="text"
            shape="circle"
            className="back-icon"
          />
        }
        title={<Text className="org-name-title">{selectedOrganization?.name}</Text>}
        extra={[
          <Button className="add-member-btn" key="3" onClick={() => navigate(routeName.AddMember)}>
            Add member
          </Button>
        ]}
      />
      <Tabs
        defaultActiveKey="1"
        className="main-user-table-tab"
        tabBarExtraContent={
          <div className="user-disable-enable">
            <Text className="user-switch-label">
              {isDisableEnableUsers ? 'Enable' : 'Disable'} all users
            </Text>
            <SwitchButtonv2
              isChecked={isDisableEnableUsers}
              isDisabled={false}
              handleOnChange={handleOnDisableEnableUser}
              classname="layer-switch member-switch"
            />
          </div>
        }
      >
        <TabPane
          className="user-table-tabpane"
          tab={<Text className="tab-title">USER MANAGEMENT</Text>}
          key="user-management"
        >
          <Table
            key="user-table"
            className="main-user-mng-table"
            scroll={{ y: 'calc(100vh - 230px)' }}
            loading={isLoadingOrganizationMember || isRequestingEnableDisableUser}
            columns={userColumns}
            // @ts-ignore
            dataSource={orginsationMembers || []}
            pagination={false}
            rowKey={(record) => `user-table-${record.id}`}
          />
        </TabPane>
        <TabPane
          className="user-table-tabpane"
          tab={<Text className="tab-title">PROJECT MANAGEMENT</Text>}
          key="project-management"
        >
          <Table
            key="project-table"
            className="main-user-mng-table main-project-table"
            scroll={{ y: 'calc(100vh - 230px)' }}
            loading={isLoadingProjects}
            columns={projectColumns}
            dataSource={projects || []}
            pagination={false}
            rowKey={(record) => `project-table-${record.id}`}
          />
        </TabPane>
      </Tabs>
      {isOpenAddProjectMemberModal && selectedRow && (
        <Modal
          open={isOpenAddProjectMemberModal}
          okText="Create"
          cancelText="Cancel"
          centered
          width={697}
          className="newModal"
          closable={false}
          footer={null}
        >
          <AddProjectMemberModal
            selectedRow={selectedRow}
            selectedOrganization={selectedOrganization}
            handleCloseAddProjectMemberModal={handleCloseAddProjectMemberModal}
          />
        </Modal>
      )}
      {selectedOrgMember && (
        <Modal
          open={isOpenRemoveOrgMember}
          title={<Text style={{ fontSize: '20px' }}>Remove team member from organization</Text>}
          okText="Remove"
          cancelText="Cancel"
          centered
          width={600}
          closeIcon={
            <AiOutlineClose
              size={16}
              color={black}
              onClick={handleRemoveMemberOrganizationCancel}
            />
          }
          maskClosable={false}
          confirmLoading={isRequestingRemoveMemberFromOrganization}
          onOk={handleRemoveMemberOrganizationOk}
          onCancel={handleRemoveMemberOrganizationCancel}
          className="remove-member-modal remove-member-org-modal"
        >
          <RemoveMemberOrganization selectedMember={selectedOrgMember} />
        </Modal>
      )}
      <Modal
        open={removeMemberModal}
        title={<Text style={{ fontSize: '20px' }}>Remove team member from project</Text>}
        okText="Remove"
        cancelText="Cancel"
        centered
        width={650}
        maskClosable={false}
        closeIcon={
          <AiOutlineClose size={16} color={black} onClick={handleRemoveProjectMemberCancel} />
        }
        confirmLoading={isRequestingRemoveMemberFromProject}
        onOk={handleRemoveProjectMemberOk}
        onCancel={handleRemoveProjectMemberCancel}
        className="remove-member-modal remove-member-org-modal remove-member-project-modal"
      >
        <RemoveProjectMemberModal
          projectMembers={projectMembers}
          handleRemoveMembersCallback={handleRemoveProjectMemberSubmit}
        />
      </Modal>
    </div>
  );
};

export default UserManagement;
