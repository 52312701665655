import { useEffect, useState } from 'react';
import { remove } from 'lodash';
import { IoMdClose } from 'react-icons/io';
import { Button, Checkbox, Form, Input, Select, Typography } from 'antd';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { MemberT, Organization } from 'store/organizations/types';
import { selectAvailableProjectMembers, getTeamMembers } from 'store/projects';
import type { Project } from 'store/projects/types';
import { addProjectMembers } from 'store/projects/actions';
import { UserRole } from 'store/users/types';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const { Option } = Select;
const { Text } = Typography;

interface AddProjectMemberModalProps {
  selectedRow: Project;
  selectedOrganization: Organization;
  handleCloseAddProjectMemberModal: Function;
}

const AddProjectMemberModal = ({
  selectedRow,
  selectedOrganization,
  handleCloseAddProjectMemberModal
}: AddProjectMemberModalProps) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const availableMembers = useAppSelector(selectAvailableProjectMembers);
  const { isAddProjectMemberRequest } = useAppSelector((state) => state.projects);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const existingMembers = getTeamMembers(selectedRow.team_members);

  useEffect(() => {
    if (selectedRow) {
      form.setFieldsValue({
        project_title: selectedRow.name
      });
    }
  }, [selectedRow]);

  const handleSubmitCallback = () => {
    handleCloseAddProjectMemberModal();
    form.resetFields();
  };

  const handleSubmit = () => {
    const payload = {
      members: selectedMembers
    };

    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(
        addProjectMembers(
          payload,
          selectedRow.id,
          selectedOrganization.id,
          token,
          handleSubmitCallback
        )
      );
    });
  };

  const handleSelectMember = (value: string[]) => {
    setSelectedMembers(value);
  };

  const handleRemoveMember = (item: string) => {
    remove(selectedMembers, (member) => {
      return member === item;
    });

    setSelectedMembers([...selectedMembers]);
  };

  const handleCloseModal = () => {
    setSelectedMembers([]);
    handleCloseAddProjectMemberModal();
    form.resetFields();
  };

  return (
    <div className="modalFormContent">
      <p>Add team member to project</p>
      <Form layout="vertical" className="addmember-form" form={form} onFinish={handleSubmit}>
        <Form.Item
          name="project_title"
          rules={[{ required: true }]}
          label="Name of project"
          className="formField"
        >
          <Input disabled placeholder="Enter name of project" className="formInputField" />
        </Form.Item>
        <Form.Item
          name="project_member_ids"
          label="Assign team members to project"
          rules={[{ required: true, message: '' }]}
          className="formField"
        >
          <Select
            showArrow
            mode="multiple"
            onChange={handleSelectMember}
            style={{ width: '100%' }}
            className="formSelectField"
          >
            {availableMembers?.map((item: MemberT, index: number) => {
              return (
                <Option
                  key={index}
                  disabled={item.value === UserRole.SuperAdmin}
                  className="member-dropdown"
                  value={item.value}
                >
                  <Checkbox checked={selectedMembers.includes(item.value)}>{item.label}</Checkbox>
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <div className="selected-list">
          {selectedMembers.length > 0 &&
            availableMembers
              .filter((m: MemberT) => selectedMembers.includes(m.value))
              .map((item: MemberT) => (
                <Text key={item.value} className="member-list">
                  <Text>{item.label}</Text>
                  <IoMdClose onClick={() => handleRemoveMember(item.value)} />
                </Text>
              ))}
          {existingMembers.length > 0 &&
            existingMembers.map((item: MemberT) => (
              <Text key={item.value} className="member-list">
                <Text>{item.label}</Text>
              </Text>
            ))}
        </div>
        <div className="formButton">
          <Button type="ghost" className="formCancelbtn" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            loading={isAddProjectMemberRequest}
            type="primary"
            className="formCreatebtn"
            htmlType="submit"
          >
            Add
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddProjectMemberModal;
