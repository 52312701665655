import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const EditFileIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6141 0.891371C10.4969 0.774161 10.3379 0.708313 10.1722 0.708313C10.0064 0.708313 9.84744 0.774161 9.73023 0.891371L2.06991 8.55169C1.99139 8.63022 1.9352 8.72824 1.90714 8.83568L1.07381 12.026C1.01773 12.2407 1.07968 12.469 1.23658 12.6259C1.39347 12.7828 1.62179 12.8448 1.83647 12.7887L5.02683 11.9554C5.13427 11.9273 5.23229 11.8711 5.31082 11.7926L12.9711 4.13228C13.2152 3.8882 13.2152 3.49247 12.9711 3.24839L10.6141 0.891371ZM3.07371 9.31567L10.1722 2.2172L11.6453 3.69034L4.54684 10.7888L2.55287 11.3096L3.07371 9.31567Z"
        fill={color}
      />
      <path
        d="M1.33334 14.0416C0.988166 14.0416 0.708344 14.3215 0.708344 14.6666C0.708344 15.0118 0.988166 15.2916 1.33334 15.2916H13.8333C14.1785 15.2916 14.4583 15.0118 14.4583 14.6666C14.4583 14.3215 14.1785 14.0416 13.8333 14.0416H1.33334Z"
        fill={color}
      />
    </svg>
  );
};

EditFileIcon.defaultProps = {
  color: primary,
  height: 16,
  width: 15,
  onClick: () => {}
};

export default EditFileIcon;
