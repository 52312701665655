import React from 'react';
import { Typography } from 'antd';
import { isEmpty } from 'lodash';
import EyeCloseIcon from 'assets/svg/EyeCloseIcon';
import EyeOpenIcon from 'assets/svg/EyeOpenIcon';
import { white } from 'constants/color.constant';
import { AddedAnnotation } from 'store/annotations/types';

const { Text } = Typography;
interface AnnotationsPolylineCardProps {
  icon: React.ReactNode;
  anotation: AddedAnnotation;
  selectedMarkings: number[];
  handleShowMarkings: (data: AddedAnnotation) => void;
}

const AnnotationsPolylineCard = ({
  icon,
  anotation,
  selectedMarkings,
  handleShowMarkings
}: AnnotationsPolylineCardProps) => {
  const coords = anotation.geometry.coordinates;
  let centerLatLng: any = [];
  const centerIndex = Math.floor(coords.length / 2);
  centerLatLng = coords[centerIndex];

  return (
    <div className="tab-inr-content">
      <div className="tab-content-head">
        <div>
          {icon}
          <div>
            <Text>{!isEmpty(anotation.properties) ? anotation.properties?.name : ''}</Text>
            <Text>{!isEmpty(anotation.properties) ? anotation.properties?.category : ''}</Text>
          </div>
        </div>
        <div>
          {selectedMarkings.includes(anotation.id) ? (
            <EyeOpenIcon color={white} onClick={() => handleShowMarkings(anotation)} />
          ) : (
            <EyeCloseIcon color={white} onClick={() => handleShowMarkings(anotation)} />
          )}
        </div>
      </div>
      <div className="tab-content-desc">
        <Text>
          Distance - {!isEmpty(anotation.properties) ? anotation.properties?.distance : 0} km
        </Text>
        <Text>
          Coordinates -{' '}
          {
            // eslint-disable-next-line
            !isEmpty(centerLatLng)
              ? centerLatLng[0] > 0
                ? `${centerLatLng[0].toFixed(3)} N,`
                : `${centerLatLng[0].toFixed(3)} S,`
              : '-'
          }
          {
            // eslint-disable-next-line
            !isEmpty(centerLatLng)
              ? centerLatLng[1] > 0
                ? ` ${centerLatLng[1].toFixed(3)} E`
                : ` ${centerLatLng[1].toFixed(3)} W`
              : ''
          }
        </Text>
        <Text>Note - {!isEmpty(anotation.properties) ? anotation.properties?.note : ''}</Text>
      </div>
    </div>
  );
};

export default AnnotationsPolylineCard;
