import { primary } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const CirclePlusIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="25" fill="#D9D9D9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 24H26V18C26 17.447 25.552 17 25 17C24.448 17 24 17.447 24 18V24H18C17.448 24 17 24.447 17 25C17 25.553 17.448 26 18 26H24V32C24 32.553 24.448 33 25 33C25.552 33 26 32.553 26 32V26H32C32.552 26 33 25.553 33 25C33 24.447 32.552 24 32 24Z"
        fill={color}
      />
    </svg>
  );
};
CirclePlusIcon.defaultProps = {
  color: primary,
  height: 50,
  width: 50
};

export default CirclePlusIcon;
