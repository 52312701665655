import { IconProps } from 'types/common.types';

const BagIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.53125 2.14428V1.41316C3.53125 0.871762 3.92734 0.411827 4.46275 0.331515L5.22519 0.217149C6.07033 0.0903776 6.92966 0.0903775 7.7748 0.217148L8.53724 0.331515C9.07266 0.411827 9.46875 0.871762 9.46875 1.41316V2.14428L10.54 2.23075C11.3603 2.29696 12.0293 2.91455 12.1609 3.72687C12.4516 5.52221 12.4516 7.35263 12.1609 9.14796C12.0293 9.96029 11.3603 10.5779 10.54 10.6441L9.37007 10.7385C7.45979 10.8927 5.54019 10.8927 3.62992 10.7385L2.45997 10.6441C1.63973 10.5779 0.97064 9.96029 0.839089 9.14796C0.548347 7.35263 0.548347 5.52221 0.839089 3.72687C0.97064 2.91455 1.63973 2.29696 2.45997 2.23075L3.53125 2.14428ZM5.36426 1.14428C6.11721 1.03134 6.88279 1.03133 7.63573 1.14428L8.39817 1.25864C8.47466 1.27012 8.53125 1.33582 8.53125 1.41316V2.07854C7.17818 2.00137 5.82181 2.00137 4.46875 2.07854V1.41316C4.46875 1.33582 4.52533 1.27012 4.60182 1.25864L5.36426 1.14428ZM3.70535 3.07077C5.56542 2.92063 7.43457 2.92063 9.29464 3.07077L10.4646 3.16521C10.8547 3.1967 11.1729 3.49041 11.2355 3.87674C11.2744 4.11732 11.3078 4.35857 11.3358 4.60029C8.28725 6.10142 4.71274 6.10142 1.66423 4.6003C1.69214 4.35857 1.72557 4.11732 1.76453 3.87674C1.8271 3.49041 2.1453 3.1967 2.5354 3.16521L3.70535 3.07077ZM1.58055 5.59783C4.70518 7.0189 8.29481 7.0189 11.4194 5.59783C11.479 6.73293 11.4177 7.87281 11.2355 8.99809C11.1729 9.38442 10.8547 9.67814 10.4646 9.70962L9.29464 9.80406C7.43456 9.9542 5.56542 9.9542 3.70535 9.80406L2.5354 9.70962C2.1453 9.67814 1.8271 9.38442 1.76453 8.99809C1.5823 7.87281 1.52097 6.73293 1.58055 5.59783Z"
        fill={color}
      />
    </svg>
  );
};
BagIcon.defaultProps = {
  color: '#C7C7CC',
  height: 11,
  width: 13
};

export default BagIcon;
