import { Fragment, useMemo } from 'react';
import { Space, Typography } from 'antd';
import { LatLng } from 'leaflet';
import { isEmpty, last } from 'lodash';
import { Circle, Polygon, Popup } from 'react-leaflet';
import { RiskcatColor } from 'constants/common.constant';
import { TreeType } from 'store/riskanalysis/types';
import { getCenterPosition, getCenterPositionPolygon, reformatRiskCoordinates } from 'utils/xml';
import { CoordinateType } from 'types/common.types';

const { Text } = Typography;

interface RendorRiskTreesProps {
  selectedSegmentTrees: TreeType[] | [];
}

const RendorRiskTrees = ({ selectedSegmentTrees }: RendorRiskTreesProps) => {
  const rendorTrees = useMemo(() => {
    if (selectedSegmentTrees && selectedSegmentTrees.length === 0) return null;

    return selectedSegmentTrees.map((data: TreeType, i: number) => {
      let centerLatLng;
      if (data.geometry.type === CoordinateType.MultiPolygon) {
        centerLatLng = getCenterPosition(data.geometry.coordinates);
      } else if (data.geometry.type === CoordinateType.Polygon) {
        centerLatLng = getCenterPositionPolygon(data.geometry.coordinates);
      }

      const reformCoords: any = reformatRiskCoordinates(data);

      if (reformCoords && reformCoords.length === 0) return null;
      const lastCoord: any = !isEmpty(reformCoords) ? last(reformCoords[0]) : [];

      if (isEmpty(centerLatLng)) return null;

      return (
        <Fragment key={`${data.id}-${i}-${data.falling_radius}`}>
          <Circle
            pathOptions={{
              fillColor: RiskcatColor[data.risk_cat],
              color: RiskcatColor[data.risk_cat],
              fillOpacity: 1
            }}
            center={centerLatLng}
            radius={0.5}
          />
          <Polygon
            positions={reformCoords}
            pathOptions={{
              fillColor: RiskcatColor[data.risk_cat],
              color: RiskcatColor[data.risk_cat],
              fillOpacity: 0.6,
              weight: 2,
              dashArray: [5, 5]
            }}
          >
            <Popup
              className="annts-point-popup tree-polygon-popup"
              position={new LatLng(lastCoord[1], lastCoord[0])}
              minWidth={232}
              maxHeight={272}
            >
              <Space direction="vertical">
                <div>
                  <Text className="annts-market-title">ID</Text>
                  <Text className="annts-market-data">{data?.id || '-'}</Text>
                </div>
                <div>
                  <Text className="annts-market-title">HEIGHT OF THE TREE</Text>
                  <Text className="annts-market-data">
                    {data && data.falling_radius ? `${data.falling_radius.toFixed(2)} m` : '-'}
                  </Text>
                </div>
                <div>
                  <Text className="annts-market-title">DISTANCE TO TRANSMISSION LINE</Text>
                  <Text className="annts-market-data">
                    {data && data.prox_to_line ? `${data.prox_to_line.toFixed(2)} m` : '-'}
                  </Text>
                </div>
                {data?.tree_health && (
                  <div>
                    <Text className="annts-market-title">HEALTH OF THE TREE</Text>
                    <Text className="annts-market-data">{data?.tree_health || '-'}</Text>
                  </div>
                )}
                {data?.work_type && (
                  <div>
                    <Text className="annts-market-title">ACTION</Text>
                    <Text className="annts-market-data">{data?.work_type || '-'}</Text>
                  </div>
                )}
                <div>
                  <Text className="annts-market-title">CO-ORDINATES OF TREE</Text>
                  <Text className="annts-market-data">
                    {!isEmpty(centerLatLng)
                      ? [centerLatLng.lat.toFixed(6), centerLatLng.lng.toFixed(6)].join(',')
                      : '-'}
                  </Text>
                </div>
              </Space>
            </Popup>
          </Polygon>
        </Fragment>
      );
    });
  }, [selectedSegmentTrees]);

  // eslint-disable-next-line
  return <>{rendorTrees}</>;
};

export default RendorRiskTrees;
