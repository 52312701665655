import { memo, useEffect } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SatsureIcon from 'assets/svg/SatsureIcon';
import SatsureSkieswhite from 'assets/svg/SatsureSkieswhite';
import RequiredTitle from 'components/core/RequiredTitle';
import { routes } from 'constants/pageRoutes.constants';
import { USER_ROLE } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { getRSA, userLogin } from 'store/users/actions';
import { LoginPayload, UserRole } from 'store/users/types';
import { rsaEnc } from 'utils/decodeEncodeData';
import { getStorageValue } from 'utils/localStorage';
import { loginSync } from 'utils/yupsync';
import './styles.less';

const { Text } = Typography;
const { Title } = Typography;

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingLogin, rsaKey } = useAppSelector((state) => state.user);
  const [form] = Form.useForm();

  useEffect(() => {
    const userRole = getStorageValue(USER_ROLE);

    if (!rsaKey) {
      dispatch(getRSA());
    }

    if (userRole) {
      handleNavigate(userRole); // eslint-disable-line
    }
  }, []);

  const handleNavigate = (userRole: UserRole) => {
    if (userRole === UserRole.SuperAdmin) {
      navigate(routes.Organization);
    } else {
      navigate(routes.ProjectList);
    }
  };

  const handleLogin = (values: LoginPayload) => {
    if (!rsaKey) {
      toast.error('RSA key not found');
      return;
    }

    dispatch(
      userLogin(
        {
          email: rsaEnc(values.email, rsaKey),
          password: rsaEnc(values.password, rsaKey)
        },
        handleNavigate
      )
    );
  };

  return (
    <div className="main-login-page">
      <div className="satsure-skies-logo">
        <SatsureSkieswhite />
      </div>
      <div className="login-content">
        <div>
          <div className="welcome-label">
            <Title>Welcome to</Title>
            <Title>Skies Discover</Title>
          </div>
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            className="login-form"
            onFinish={handleLogin}
          >
            <RequiredTitle title="Email" className="ann-form-label" isRequired />
            <Form.Item name="email" className="email-input" rules={[loginSync]}>
              <Input placeholder="Enter your mail address" className="form-input" />
            </Form.Item>
            <RequiredTitle title="Password" className="ann-form-label" isRequired />
            <Form.Item name="password" className="password-input" rules={[loginSync]}>
              <Input.Password placeholder="Enter your password" className="form-input" />
            </Form.Item>
            <div className="forgot-password">
              <Button type="link" onClick={() => navigate(routes.ForgotPass)}>
                Forgot password?
              </Button>
            </div>
            <Button
              className="login-btn"
              type="primary"
              htmlType="submit"
              block
              size="large"
              loading={isLoadingLogin}
            >
              Log In
            </Button>
          </Form>
        </div>
        <div className="satsure-logo">
          <Text>Powered By</Text>
          <SatsureIcon />
        </div>
      </div>
    </div>
  );
};

export default memo(Login);
