import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const PolygonIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.7075 0.837776C7.88265 0.710349 8.11992 0.710181 8.29525 0.837361L14.7322 5.50664C14.9075 5.63384 14.981 5.85948 14.9142 6.06555L12.4622 13.6296C12.3954 13.8356 12.2035 13.9752 11.9869 13.9754L4.03444 13.981C3.81783 13.9812 3.62575 13.8418 3.55867 13.6359L1.09595 6.07532C1.02885 5.86934 1.10203 5.64359 1.2772 5.51615L7.7075 0.837776Z"
        stroke={color}
        strokeLinejoin="round"
      />
    </svg>
  );
};
PolygonIcon.defaultProps = {
  color: white,
  height: 15,
  width: 16
};

export default PolygonIcon;
