import { toast } from 'react-toastify';
import { find, includes } from 'lodash';
import { vectorColors } from 'constants/common.constant';
import { vectorCategories } from 'constants/map.constants';
import { DataLayerFileType, DataLayerType } from 'store/repositoryfile/types';
import { ReformatPoints } from 'types/common.types';
import { reformatCoordinates } from 'utils/xml';
import { getRepoFileCoordinates } from 'utils/connect';
import { AppDispatch, RootState } from '..';
import { setRequestingVectorFiles, setVectorFiles } from '.';

export const getExtractVectorFiles =
  (activeDataLayerId: number, token: string) =>
  async (dispatch: AppDispatch, getState: RootState) => {
    try {
      dispatch(setRequestingVectorFiles(true));

      const state = getState();
      const { dataLayers } = state.repositoryFiles;
      const { selectedProject } = state.projects;
      const { selectedOrganization } = state.organizations;
      const layers: DataLayerType | null = find(dataLayers, ['id', activeDataLayerId]);

      const routes: any = [];
      if (layers) {
        const layerFiles: DataLayerFileType[] | [] = layers.files;
        if (layerFiles.length > 0) {
          // eslint-disable-next-line
          const files: DataLayerFileType[] | [] = layerFiles.filter((file: DataLayerFileType) => {
            if (includes(vectorCategories, file.category)) {
              return file;
            }
          });

          if (files.length > 0) {
            // eslint-disable-next-line
            for await (const [i, file] of files.entries()) {
              if (file.gs_layer_name) {
                routes.push({ ...file });
              } else {
                const response = await getRepoFileCoordinates(
                  selectedOrganization.id,
                  selectedProject.id,
                  activeDataLayerId,
                  file.id,
                  token
                );
                let coordinate: ReformatPoints | null = null;

                if (response.length > 0) {
                  coordinate = await reformatCoordinates(response);
                }

                routes.push({
                  ...file,
                  coordinates: coordinate?.points || [],
                  shapType: coordinate?.type,
                  color: vectorColors[i]
                });
              }
            }
          }
        }
      }

      dispatch(setVectorFiles(routes));
      dispatch(setRequestingVectorFiles(false));
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setRequestingVectorFiles(false));
    }
  };
