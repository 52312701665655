export const orgStyles: any = {
  detailDivider: {
    borderColor: '#828282',
    height: '322px',
    top: '-23px'
  },
  uploadImage: {
    opacity: 0,
    position: 'absolute',
    height: '323px',
    width: '100%'
  }
};
