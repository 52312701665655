import 'leaflet-side-by-side';
import { useEffect, useRef } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import { DataLayerFileType } from 'store/repositoryfile/types';
import { getWmtsParams } from 'utils/utils';
import { GET_GEOSERVER_WMTS_URL } from 'utils/apiUrls';

interface SplitMapProps {
  isShowSatelliteImages: boolean;
  leftSataliteImagesRepoFile: DataLayerFileType | null;
  rightSataliteImagesRepoFile: DataLayerFileType | null;
  isShowDEMLegend: boolean;
  selectedDEMStyle: string | null;
  leftDemRepoFile: DataLayerFileType | null;
  rightDemRepoFile: DataLayerFileType | null;
  isShowDSMLegend: boolean;
  selectedDSMStyle: string | null;
  leftDsmRepoFile: DataLayerFileType | null;
  rightDsmRepoFile: DataLayerFileType | null;
  isShowOrthomosiac: boolean;
  leftOrthomosiacRepoFile: DataLayerFileType | null;
  rightOrthomosiacRepoFile: DataLayerFileType | null;
}

export const SplitMapLayer = ({
  isShowSatelliteImages,
  leftSataliteImagesRepoFile,
  rightSataliteImagesRepoFile,
  isShowDEMLegend,
  selectedDEMStyle,
  leftDemRepoFile,
  rightDemRepoFile,
  isShowDSMLegend,
  selectedDSMStyle,
  leftDsmRepoFile,
  rightDsmRepoFile,
  isShowOrthomosiac,
  leftOrthomosiacRepoFile,
  rightOrthomosiacRepoFile
}: SplitMapProps) => {
  const map = useMap();
  const sideBySideLayer: any = useRef(null);
  const leftLayerGroup = L.layerGroup();
  const rightLayerGroup = L.layerGroup();
  const leftLayers: any = [];
  const rightLayers: any = [];

  useEffect(() => {
    if (!map) return;
    if (isShowSatelliteImages && rightSataliteImagesRepoFile && leftSataliteImagesRepoFile) {
      const leftwmtsParams = getWmtsParams(leftSataliteImagesRepoFile);
      const rightwmtsParams = getWmtsParams(rightSataliteImagesRepoFile);
      // @ts-ignore
      const leftLayer = L.tileLayer.wmts(GET_GEOSERVER_WMTS_URL, leftwmtsParams).addTo(map);
      // @ts-ignore
      const rightLayer = L.tileLayer.wmts(GET_GEOSERVER_WMTS_URL, rightwmtsParams).addTo(map);
      leftLayerGroup.addLayer(leftLayer);
      rightLayerGroup.addLayer(rightLayer);
      leftLayers.push(leftLayer);
      rightLayers.push(rightLayer);
    }

    if (isShowDEMLegend && selectedDEMStyle && (leftDemRepoFile || rightDemRepoFile)) {
      if (leftDemRepoFile) {
        const leftwmtsParams = getWmtsParams(leftDemRepoFile, selectedDEMStyle);
        // @ts-ignore
        const leftLayer = L.tileLayer.wmts(GET_GEOSERVER_WMTS_URL, leftwmtsParams).addTo(map);
        leftLayerGroup.addLayer(leftLayer);
        leftLayers.push(leftLayer);
      }
      if (rightDemRepoFile) {
        const rightwmtsParams = getWmtsParams(rightDemRepoFile, selectedDEMStyle);
        // @ts-ignore
        const rightLayer = L.tileLayer.wmts(GET_GEOSERVER_WMTS_URL, rightwmtsParams).addTo(map);
        rightLayerGroup.addLayer(rightLayer);
        rightLayers.push(rightLayer);
      }
    }

    if (isShowDSMLegend && selectedDSMStyle && (leftDsmRepoFile || rightDsmRepoFile)) {
      if (leftDsmRepoFile) {
        const leftwmtsParams = getWmtsParams(leftDsmRepoFile, selectedDSMStyle);
        // @ts-ignore
        const leftLayer = L.tileLayer.wmts(GET_GEOSERVER_WMTS_URL, leftwmtsParams).addTo(map);
        leftLayerGroup.addLayer(leftLayer);
        leftLayers.push(leftLayer);
      }
      if (rightDsmRepoFile) {
        const rightwmtsParams = getWmtsParams(rightDsmRepoFile, selectedDSMStyle);
        // @ts-ignore
        const rightLayer = L.tileLayer.wmts(GET_GEOSERVER_WMTS_URL, rightwmtsParams).addTo(map);
        rightLayerGroup.addLayer(rightLayer);
        rightLayers.push(rightLayer);
      }
    }

    if (isShowOrthomosiac && leftOrthomosiacRepoFile && rightOrthomosiacRepoFile) {
      const leftwmtsParams = getWmtsParams(leftOrthomosiacRepoFile);
      const rightwmtsParams = getWmtsParams(rightOrthomosiacRepoFile);
      // @ts-ignore
      const leftLayer = L.tileLayer.wmts(GET_GEOSERVER_WMTS_URL, leftwmtsParams).addTo(map);
      // @ts-ignore
      const rightLayer = L.tileLayer.wmts(GET_GEOSERVER_WMTS_URL, rightwmtsParams).addTo(map);
      leftLayerGroup.addLayer(leftLayer);
      rightLayerGroup.addLayer(rightLayer);
      leftLayers.push(leftLayer);
      rightLayers.push(rightLayer);
    }

    leftLayerGroup.addTo(map);
    rightLayerGroup.addTo(map);

    // @ts-ignore
    sideBySideLayer.current = L.control.sideBySide(leftLayers, rightLayers).addTo(map);

    // eslint-disable-next-line
    return () => {
      sideBySideLayer.current.remove(map);
      leftLayerGroup.removeFrom(map);
      rightLayerGroup.removeFrom(map);
    };
  }, [
    map,
    isShowSatelliteImages,
    leftSataliteImagesRepoFile,
    rightSataliteImagesRepoFile,
    isShowDEMLegend,
    selectedDEMStyle,
    leftDemRepoFile,
    rightDemRepoFile,
    isShowDSMLegend,
    selectedDSMStyle,
    leftDsmRepoFile,
    rightDsmRepoFile,
    isShowOrthomosiac,
    leftOrthomosiacRepoFile,
    rightOrthomosiacRepoFile
  ]);

  return null;
};

export default SplitMapLayer;
