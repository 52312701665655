/* eslint-disable  */
import 'leaflet-side-by-side';
import { useEffect, useRef } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import { DataLayerFileType, LulcDataType } from 'store/repositoryfile/types';
import { getLULCLeftWmtsParams, getLULCRightWmtsParams } from 'utils/utils';
import { GET_GEOSERVER_WMTS_URL } from 'utils/apiUrls';
import { isEmpty } from 'lodash';

interface SplitMapLayerVegIntlProps {
  isOpenLulcLayerCompareView: boolean;
  leftlulcRepoFile: DataLayerFileType | null;
  rightlulcRepoFile: DataLayerFileType | null;
  geoserverLayerStyles: LulcDataType[] | [];
}

export const SplitMapLayerVegIntl = ({
  isOpenLulcLayerCompareView,
  rightlulcRepoFile,
  leftlulcRepoFile,
  geoserverLayerStyles
}: SplitMapLayerVegIntlProps) => {
  const map = useMap();
  const sideBySideLayer: any = useRef(null);
  const leftLayerGroup = L.layerGroup();
  const rightLayerGroup = L.layerGroup();
  const leftLayers: any = [];
  const rightLayers: any = [];

  const LulcLayerStyle = geoserverLayerStyles
    .map((style: LulcDataType) => {
      return `${style.value}:1;`;
    })
    .join('');

  useEffect(() => {
    if (!map) return;

    if (isOpenLulcLayerCompareView) {
      if (!isEmpty(leftlulcRepoFile)) {
        const leftwmtsParams = getLULCLeftWmtsParams(leftlulcRepoFile, LulcLayerStyle);
        // @ts-ignore
        const leftLayer = L.tileLayer.wmts(GET_GEOSERVER_WMTS_URL, leftwmtsParams).addTo(map);
        leftLayerGroup.addLayer(leftLayer);
        leftLayers.push(leftLayer);
      }
      if (!isEmpty(rightlulcRepoFile)) {
        const rightwmtsParams = getLULCRightWmtsParams(rightlulcRepoFile, LulcLayerStyle);
        // @ts-ignore
        const rightLayer = L.tileLayer.wmts(GET_GEOSERVER_WMTS_URL, rightwmtsParams).addTo(map);
        rightLayerGroup.addLayer(rightLayer);
        rightLayers.push(rightLayer);
      }
    }

    leftLayerGroup.addTo(map);
    rightLayerGroup.addTo(map);

    // @ts-ignore
    sideBySideLayer.current = L.control.sideBySide(leftLayers, rightLayers).addTo(map);

    // eslint-disable-next-line
    return () => {
      sideBySideLayer.current.remove(map);
      leftLayerGroup.removeFrom(map);
      rightLayerGroup.removeFrom(map);
    };
  }, [map, isOpenLulcLayerCompareView, leftlulcRepoFile, rightlulcRepoFile]);

  return null;
};

export default SplitMapLayerVegIntl;
