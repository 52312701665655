import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const LineIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.95703 2.95703L11.05 11.05" stroke={color} strokeWidth="1.5" />
      <circle cx="1.86761" cy="1.86761" r="1" fill={color} stroke={color} strokeWidth="1.73522" />
      <circle cx="12.2426" cy="12.2446" r="1" fill={color} stroke={color} strokeWidth="1.73522" />
    </svg>
  );
};
LineIcon.defaultProps = {
  color: white,
  height: 15,
  width: 15
};

export default LineIcon;
