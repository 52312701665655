import { Switch } from 'antd';

interface SwitchButtonv1Props {
  isDisabled: boolean;
  handleOnChange: any;
  isChecked?: boolean;
  classname?: string;
}

const SwitchButtonv1 = ({
  isDisabled,
  handleOnChange,
  isChecked,
  classname
}: SwitchButtonv1Props) => {
  return (
    <Switch
      size="small"
      checked={isChecked}
      disabled={isDisabled}
      className={classname}
      onChange={handleOnChange}
    />
  );
};
SwitchButtonv1.defaultProps = {
  classname: '',
  isChecked: false
};

export default SwitchButtonv1;
