export type User = {
  accessToken: string;
  refreshToken: string;
  email: string;
  id: string;
  firstname: string | null;
  lastname: string | null;
  organisationid: number | null;
};

export type UserInfo = {
  email: string;
  id: string;
  phoneno: string | null;
  first_name: string | null;
  last_name: string | null;
  empId: string | null;
  department: string | null;
  organisation_id: number | null;
  is_active: boolean;
  pic_url: string | null;
  role: string;
};

export interface UserEditProfilePayload {
  fname: string;
  lname: string;
  empId: string | null;
  department: string | null;
  phoneno: string | null;
}

export interface UserResetPasswordPayload {
  oldpassword: string;
  newpassword: string;
}

export type Rsa = {
  rsa: string;
};

export type LoginPayload = {
  email: string;
  password: string;
};

export type RequestResetPassWordPayload = {
  email: string;
};

export type ResetPassWordPayload = {
  password: string;
};

export type UserDetailPayload = {
  user_id: string;
  first_name: string | null;
  last_name: string | null;
  phone_no: string | null;
  department: string | null;
  emp_id: string | null;
};

export enum UserRole {
  SuperAdmin = 'super_admin',
  Client = 'client'
}

export enum Status {
  Success = 'success',
  Error = 'error'
}

export const userRoleText: any = {
  super_admin: 'Super Admin',
  client: 'Client User'
};

export type UserState = {
  isLoadingRSA: boolean;
  rsaKey: string | null;
  isLoadingLogin: boolean;
  user: User | null;
  isLoadingLogout: boolean;
  isRefreshToken: boolean;
  isRequestingResetPassword: boolean;
  isResetPassword: boolean;
  isRequestingUserDetail: boolean;
  isRequestingUserEdit: boolean;
  isRequestingUserPicture: boolean;
  isRequestingUserResetPassword: boolean;
  userInfo: UserInfo | null;
};
