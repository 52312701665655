import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, PageHeader, Tabs } from 'antd';
import { isEmpty } from 'lodash';
import LeftArrowIcon from 'assets/svg/LeftArrowIcon';
import { ACCESS_TOKEN, USER_ROLE } from 'constants/common.constant';
import { routes } from 'constants/pageRoutes.constants';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { getUserDetail } from 'store/users/actions';
import { UserRole } from 'store/users/types';
import { getAsyncStorageValue, getStorageValue } from 'utils/localStorage';
import NotificationTab from './NotificationTab';
import ProfileTab from './ProfileTab';
import PasswordTab from './PasswordTab';
import './styles.less';

const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userRole = getStorageValue(USER_ROLE);
  const {
    user,
    userInfo,
    isRequestingUserEdit,
    isRequestingUserResetPassword,
    isRequestingUserPicture
  } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (!isEmpty(user)) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getUserDetail(user.id, token));
      });
    }
  }, []);

  const handleBack = () => {
    if (userRole === UserRole.SuperAdmin) {
      navigate(routes.Organization, { replace: true });
    } else {
      navigate(routes.ProjectList, { replace: true });
    }
  };

  return (
    <div className="main-setting">
      <PageHeader
        onBack={handleBack}
        title="Settings"
        backIcon={
          <Button
            icon={<LeftArrowIcon color="black" />}
            type="text"
            shape="circle"
            className="back-icon"
          />
        }
        className="setting-header"
      />
      <div className="setting-content">
        <Tabs defaultActiveKey="1" tabPosition="left" className="setting-tab">
          <Tabs.TabPane className="content-tab profile-tab" tab="Profile" key="1">
            <ProfileTab
              userInfo={userInfo}
              isRequestingUserEdit={isRequestingUserEdit}
              isRequestingUserPicture={isRequestingUserPicture}
            />
          </Tabs.TabPane>
          <Tabs.TabPane className="content-tab password-tab" tab="Password" key="2">
            <PasswordTab
              userInfo={userInfo}
              isRequestingUserResetPassword={isRequestingUserResetPassword}
            />
          </Tabs.TabPane>
          <Tabs.TabPane className="content-tab notification-tab" tab="Notification" key="3">
            <NotificationTab />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Settings;
