import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const CopyIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8.66667V8C6 6.89733 6.89733 6 8 6H8.66667V3.778C8.66667 3.53267 8.46733 3.33333 8.222 3.33333H3.778C3.53267 3.33333 3.33333 3.53267 3.33333 3.778V8.222C3.33333 8.46733 3.53267 8.66667 3.778 8.66667H6ZM6 10H3.778C2.79733 10 2 9.20267 2 8.222V3.778C2 2.79733 2.79733 2 3.778 2H8.222C9.20267 2 10 2.79733 10 3.778V6H12C13.1027 6 14 6.89733 14 8V12C14 13.1027 13.1027 14 12 14H8C6.89733 14 6 13.1027 6 12V10ZM7.33333 8C7.33333 7.63267 7.63267 7.33333 8 7.33333H12C12.368 7.33333 12.6667 7.63267 12.6667 8V12C12.6667 12.3673 12.368 12.6667 12 12.6667H8C7.63267 12.6667 7.33333 12.3673 7.33333 12V8Z"
        fill={color}
      />
    </svg>
  );
};
CopyIcon.defaultProps = {
  color: white,
  height: 16,
  width: 16,
  onClick: () => {}
};

export default CopyIcon;
