import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Spin } from 'antd';
import CirclePlusIcon from 'assets/svg/CirclePlusIcon';
import OrgCard from 'components/Organization/OrgCard';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { routes as routeName } from 'constants/pageRoutes.constants';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { getOrganizations } from 'store/organizations/actions';
import { setSelectedOrganization } from 'store/organizations';
import { Organization } from 'store/organizations/types';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const OrganizationList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingOrganizations, organizations } = useAppSelector((state) => state.organizations);

  useEffect(() => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(getOrganizations(token));
      dispatch(setSelectedOrganization(null));
    });
  }, []);

  return (
    <div className="main-organization">
      <Row className="org-header" justify="space-between">
        <Col className="org-title">Organizations</Col>
        <Col className="org-btn-content">
          <Button
            className="add-org-btn"
            onClick={() => {
              navigate(routeName.AddOrganization);
            }}
          >
            Add Organization
          </Button>
        </Col>
      </Row>
      <div className="main-org-list">
        <Spin spinning={isLoadingOrganizations}>
          <Row gutter={20}>
            {organizations &&
              organizations.length > 0 &&
              organizations.map((organization: Organization) => (
                <OrgCard key={organization.id} organization={organization} />
              ))}
            <Col span={6} className="org-card" onClick={() => navigate(routeName.AddOrganization)}>
              <div className="inr-org-card add-org-card">
                <CirclePlusIcon width={30} />
                <div>ADD ORGANIZATION</div>
              </div>
            </Col>
          </Row>
        </Spin>
      </div>
    </div>
  );
};

export default OrganizationList;
