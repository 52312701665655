import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const UserIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 4.5C3.75 2.15279 5.65279 0.25 8 0.25C10.3472 0.25 12.25 2.15279 12.25 4.5C12.25 6.84721 10.3472 8.75 8 8.75C5.65279 8.75 3.75 6.84721 3.75 4.5ZM8 1.75C6.48122 1.75 5.25 2.98122 5.25 4.5C5.25 6.01878 6.48122 7.25 8 7.25C9.51878 7.25 10.75 6.01878 10.75 4.5C10.75 2.98122 9.51878 1.75 8 1.75Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 11.75C2.75736 11.75 1.75 12.7574 1.75 14V15.1883C1.75 15.2064 1.76311 15.2218 1.78097 15.2247C5.89972 15.8972 10.1003 15.8972 14.219 15.2247C14.2369 15.2218 14.25 15.2064 14.25 15.1883V14C14.25 12.7574 13.2426 11.75 12 11.75H11.6591C11.6328 11.75 11.6066 11.7542 11.5815 11.7623L10.716 12.045C8.95119 12.6212 7.04881 12.6212 5.28398 12.045L4.41847 11.7623C4.39342 11.7542 4.36722 11.75 4.34087 11.75H4ZM0.25 14C0.25 11.9289 1.92893 10.25 4 10.25H4.34087C4.52536 10.25 4.70869 10.2792 4.88407 10.3364L5.74959 10.6191C7.21187 11.0965 8.78813 11.0965 10.2504 10.6191L11.1159 10.3364C11.2913 10.2792 11.4746 10.25 11.6591 10.25H12C14.0711 10.25 15.75 11.9289 15.75 14V15.1883C15.75 15.9415 15.2041 16.5837 14.4607 16.7051C10.1819 17.4037 5.8181 17.4037 1.53927 16.7051C0.795884 16.5837 0.25 15.9415 0.25 15.1883V14Z"
        fill={color}
      />
    </svg>
  );
};
UserIcon.defaultProps = {
  color: white,
  width: 16,
  height: 18
};

export default UserIcon;
