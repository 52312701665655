import { IconProps } from 'types/common.types';

const TrueLayerIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 146 146"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="73" cy="73" r="73" fill="#EBFFF3" />
      <circle cx="73" cy="73" r="57" fill="#DAFFE9" />
      <circle cx="73" cy="73" r="39" fill="#B4FED1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.7442 70.8628H91.7504C92.8983 70.8628 93.8317 71.792 93.8338 72.9399C93.8504 78.5045 91.6983 83.7441 87.7733 87.6899C83.8504 91.6357 78.6254 93.817 73.0608 93.8337H73.0004C67.4567 93.8337 62.2421 91.6837 58.3108 87.7732C54.365 83.8503 52.1838 78.6253 52.1671 73.0607C52.1504 67.4941 54.3025 62.2566 58.2275 58.3107C62.1504 54.3649 67.3754 52.1837 72.94 52.167C74.5963 52.192 76.2838 52.3587 77.9004 52.7462C79.0171 53.017 79.7067 54.142 79.4358 55.2607C79.1671 56.3774 78.0358 57.0649 76.9233 56.7982C75.6296 56.4857 74.2567 56.3545 72.9525 56.3337C68.5004 56.3462 64.3192 58.092 61.1817 61.2482C58.0421 64.4045 56.3213 68.5962 56.3338 73.0482C56.3463 77.5003 58.0921 81.6795 61.2483 84.8191C64.3942 87.9462 68.565 89.667 73.0004 89.667H73.0483C77.5004 89.6545 81.6817 87.9087 84.8192 84.7524C87.9588 81.5941 89.6796 77.4045 89.6671 72.9524C89.665 71.8024 90.5942 70.8649 91.7442 70.8628ZM65.2775 71.5272C66.0921 70.7126 67.4088 70.7126 68.2233 71.5272L72.8983 76.2022L86.0171 61.2105C86.7754 60.3501 88.09 60.2585 88.9567 61.0168C89.8213 61.773 89.9088 63.0897 89.1504 63.9564L74.5671 80.623C74.1879 81.0564 73.6463 81.3126 73.0692 81.3335H73.0004C72.4483 81.3335 71.9192 81.1147 71.5275 80.723L65.2775 74.473C64.4629 73.6585 64.4629 72.3418 65.2775 71.5272Z"
        fill={color}
      />
    </svg>
  );
};
TrueLayerIcon.defaultProps = {
  color: '#219653',
  height: 146,
  width: 146
};

export default TrueLayerIcon;
