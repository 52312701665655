import { useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Form, Input, Row, Typography } from 'antd';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { routes } from 'constants/pageRoutes.constants';
import RequiredTitle from 'components/core/RequiredTitle';
import { useAppDispatch } from 'hooks/useReduxHook';
import { userResetPassword } from 'store/users/actions';
import { UserInfo, UserResetPasswordPayload } from 'store/users/types';
import { encryptValue } from 'utils/decodeEncodeData';
import { getAsyncStorageValue } from 'utils/localStorage';

interface PasswordTabProps {
  isRequestingUserResetPassword: boolean;
  userInfo: UserInfo;
}
const { Link, Paragraph } = Typography;

const PasswordTab = ({ userInfo, isRequestingUserResetPassword }: PasswordTabProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const handleResetPassword = (values: any) => {
    let payload: UserResetPasswordPayload | null = null;

    if (values.old_password && values.re_new_password) {
      payload = {
        oldpassword: encryptValue(values.old_password),
        newpassword: encryptValue(values.re_new_password)
      };

      getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
        dispatch(userResetPassword(payload!, userInfo.id, reToken));
      });
    }
  };

  return (
    <Row className="tab-inr-content-row">
      <Col className="main-change-password" span={10}>
        <Form className="memebr-pass-form" form={form} onFinish={handleResetPassword}>
          <div>
            <RequiredTitle className="member-label" title="Old Password" />
            <div className="member-old-pass">
              <Form.Item
                className="member-pass-item"
                name="old_password"
                rules={[{ required: true, message: 'Please enter old password!' }]}
              >
                <Input.Password className="member-pass-input" />
              </Form.Item>
              <Link onClick={() => navigate(routes.ForgotPass, { replace: true })}>
                Forgot password?
              </Link>
            </div>
          </div>
          <div>
            <RequiredTitle className="member-label" title="New Password" />
            <Form.Item
              className="member-pass-item"
              name="new_password"
              dependencies={['old_password']}
              rules={[
                { required: true, message: 'Please enter new password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('old_password') === value) {
                      return Promise.reject(new Error('old and new password should be different!'));
                    }
                    return Promise.resolve();
                  }
                }),
                {
                  pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                  message: 'Should match the requirment'
                }
              ]}
            >
              <Input.Password className="member-pass-input" />
            </Form.Item>
          </div>
          <div style={{ marginBottom: '43px' }}>
            <RequiredTitle className="member-label" title="Re-enter Password" />
            <Form.Item
              className="member-pass-item"
              name="re_new_password"
              dependencies={['new_password']}
              rules={[
                {
                  required: true,
                  message: 'Please enter confirm password!'
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Your new password and confirmation password do not match.!')
                    );
                  }
                })
              ]}
            >
              <Input.Password className="member-pass-input" />
            </Form.Item>
          </div>
          <div className="member-form-btn">
            <Button type="primary" htmlType="submit" loading={isRequestingUserResetPassword}>
              Update
            </Button>
          </div>
        </Form>
      </Col>
      <Col span={2} style={{ padding: '20px' }}>
        <Divider
          type="vertical"
          style={{ borderColor: '@ghostbtnBack', top: '0', height: '395px' }}
        />
      </Col>
      <Col className="main-condition-password" span={10}>
        <Paragraph className="condition-label">Password requirements</Paragraph>
        <ul>
          <li>Must contain at least 8 characters</li>
          <li>Must contain at least one Upper case letter</li>
          <li>Must contain at least one Lower case letter</li>
          <li>Must contain at least one number</li>
          <li>Must contain at least one special character</li>
        </ul>
      </Col>
    </Row>
  );
};

export default PasswordTab;
