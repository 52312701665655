import { UserRole } from 'store/users/types';

export enum ProjectCategoryTypes {
  RoadInfrastructure = 'Road Infrastructure',
  Infrastructure = 'Infrastructure',
  VegetationManagement = 'Vegetation Management',
  VegetationManagementINTL = 'Vegetation Management INTL',
  VegetationManagementLite = 'Vegetation Management Lite',
  DEM = 'DEM - DSM Generation',
  HighTransmissionLines = 'High Transmission Lines',
  SolarPowerPlant = 'Solar Power Plant'
}

export interface TeamMember {
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  role: UserRole;
}

export interface Project {
  access: boolean;
  coordinates: string[] | [];
  created_at: string;
  id: number;
  last_updated: string | null;
  name: string;
  team_members: Array<TeamMember>;
  type: string;
}

export interface AvailableProjectMember {
  email: string;
  first_name: string | null;
  id: string;
  last_name: string | null;
  role: UserRole;
}

export type ProjectPayload = {
  type: number;
  project_title: string;
  coordinate: number[];
};
export type AddProject = {
  project_title: string;
  type: string;
  coordinate: string;
};

export interface AddProjectMemberPayload {
  members: string[];
}

export interface RemoveProjectMembersPayload {
  member_list: string[];
}

export interface RemoveOrganizationMemberPayload {
  is_active: boolean;
}

export interface ProjectType {
  id: number;
  type: string;
}

export interface ProjectState {
  isLoadingProjects: boolean;
  projects: Array<Project>;
  project: Project | null;
  isAddProjectRequesting: boolean;
  selectedProject: Project | null;
  isRequestProject: boolean;
  isAddProjectMemberRequest: boolean;
  isAvailableProjectMemberRequesting: boolean;
  projectMembers: Array<AvailableProjectMember>;
  isRequestingRemoveMemberFromProject: boolean;
  isRequestingRemoveMemberFromOrganization: boolean;
  isRequestingCloseProject: boolean;
  isRequestingProjectType: boolean;
  projectTypes: ProjectType[] | [];
}
