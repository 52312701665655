import React, { useState } from 'react';
import { Button } from 'antd';
import LineIcon from 'assets/svg/LineIcon';
import LocationIcon from 'assets/svg/LocationIcon';
import PolygonIcon from 'assets/svg/PolygonIcon';
import { blackShade, white } from 'constants/color.constant';
import { ACCESS_TOKEN } from 'constants/common.constant';
import AddAnnotationsTabCard from 'components/core/AddAnnotationsTabCard';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { getAddedAnnotationPoints } from 'store/annotations';
import { deleteAnnotation } from 'store/annotations/actions';
import { AddedAnnotation } from 'store/annotations/types';
import { DataLayerTableType } from 'store/repositoryfile/types';
import { CoordinateType } from 'types/common.types';
import { getAsyncStorageValue } from 'utils/localStorage';
import MarkingsTab from './MarkingsTab';

interface AddAnnotationsProps {
  activeDataLayer: DataLayerTableType | null;
  selectedMarkings: number[];
  editAnnotationMarking: AddedAnnotation | null;
  handleChangeActiveTab: (selectedTab: string | null) => void;
  handleShowMarkings: (data: AddedAnnotation) => void;
  handleEditMarking: (data: AddedAnnotation) => void;
}
const ButtonGroup = Button.Group;

const AddAnnotations = ({
  activeDataLayer,
  selectedMarkings,
  editAnnotationMarking,
  handleChangeActiveTab,
  handleShowMarkings,
  handleEditMarking
}: AddAnnotationsProps) => {
  const dispatch = useAppDispatch();
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const { selectedProject } = useAppSelector((state) => state.projects);
  const addedAnnotations: AddedAnnotation[] | [] = useAppSelector((state) =>
    getAddedAnnotationPoints(state, activeDataLayer?.id!)
  );

  const [activeShape, setActiveShape] = useState<string | null>(null);

  const handleDeleteMarker = (data: AddedAnnotation) => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(
        deleteAnnotation(+selectedOrganization.id, +selectedProject.id, data.dl_id, data.id, token)
      );
    });
  };

  const handleOnClick = (e: any) => {
    setActiveShape(e.currentTarget.value);
    handleChangeActiveTab(e.currentTarget.value);
  };

  const handleCloseMarkingMode = () => {
    setActiveShape(null);
    handleChangeActiveTab(null);
  };

  return (
    <div className="add-annotations">
      <div className="annotations-btns">
        <ButtonGroup className="annotations-shape-btns">
          <Button
            className={
              activeShape === CoordinateType.LineString
                ? 'shape-btns active-shape-btn'
                : 'shape-btns'
            }
            onClick={handleOnClick}
            value={CoordinateType.LineString}
            icon={
              <LineIcon color={activeShape === CoordinateType.LineString ? blackShade : white} />
            }
          />
          <Button
            className={
              activeShape === CoordinateType.Polygon ? 'shape-btns active-shape-btn' : 'shape-btns'
            }
            onClick={handleOnClick}
            value={CoordinateType.Polygon}
            icon={
              <PolygonIcon color={activeShape === CoordinateType.Polygon ? blackShade : white} />
            }
          />
          <Button
            className={
              activeShape === CoordinateType.Point ? 'shape-btns active-shape-btn' : 'shape-btns'
            }
            onClick={handleOnClick}
            value={CoordinateType.Point}
            icon={
              <LocationIcon color={activeShape === CoordinateType.Point ? blackShade : white} />
            }
          />
        </ButtonGroup>
      </div>
      <div className="add-annotations-tabs">
        {activeShape && <AddAnnotationsTabCard handleCloseMarkingMode={handleCloseMarkingMode} />}
        <MarkingsTab
          classname={!activeShape ? 'full-marking-content' : ''}
          markings={addedAnnotations}
          activeShape={activeShape}
          selectedMarkings={selectedMarkings}
          editAnnotationMarking={editAnnotationMarking}
          handleDeleteMarker={handleDeleteMarker}
          handleShowMarkings={handleShowMarkings}
          handleEditMarking={handleEditMarking}
        />
      </div>
    </div>
  );
};

export default AddAnnotations;
