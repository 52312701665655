import { Typography } from 'antd';
import { BiChevronRight } from 'react-icons/bi';
import { white } from 'constants/color.constant';
import { ProjectTabType } from 'constants/common.constant';

const { Text } = Typography;

interface ProjectViewTabProps {
  tabData: ProjectTabType;
  handleClickTab: Function;
  style?: any;
}

const ProjectViewTab = ({ tabData, handleClickTab, style }: ProjectViewTabProps) => {
  return (
    <div className="content-tab" style={style} onClick={() => handleClickTab(tabData.value)}>
      <Text>{tabData.title}</Text>
      <BiChevronRight color={white} size={20} />
    </div>
  );
};

ProjectViewTab.defaultProps = {
  style: {}
};

export default ProjectViewTab;
