import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import TrueLayerIcon from 'assets/svg/TrueLayerIcon';
import { routes } from 'constants/pageRoutes.constants';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { resetPassword } from 'store/users/actions';
import { ResetPassWordPayload } from 'store/users/types';
import './styles.less';

const { Text, Title } = Typography;

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { isResetPassword } = useAppSelector((state) => state.user);
  const [isResetPasswordStatus, setresetPasswordStatus] = useState<boolean>(false);

  const handleCallback = (status: boolean) => {
    if (status) {
      setresetPasswordStatus(true);
    } else {
      setresetPasswordStatus(false);
      toast.error('Something went wrong!');
    }
  };

  const handleNavigateLogin = () => {
    setresetPasswordStatus(false);
    navigate(routes.LoginUrl);
  };

  const handleSubmitResetPassword = (values: {
    new_password: string;
    confirm_password: string;
  }) => {
    const accessToken = searchParams.get('accessToken');
    const payload: ResetPassWordPayload = {
      password: values.confirm_password
    };
    if (accessToken) {
      dispatch(resetPassword(payload, accessToken, handleCallback));
    } else {
      toast.error('Token Expired!');
    }
  };
  return (
    <Row className="main-login-page main-forgot-pass main-reset-pass">
      <Col span={17} className="site-title" />
      <Col span={7} className="login-content">
        <div className="inr-login-content">
          {isResetPasswordStatus ? (
            <div className="reset-success">
              <div className="reset-success-icon">
                <TrueLayerIcon />
              </div>
              <Title level={3} className="pass-reset-title">
                Password Reset
              </Title>
              <Text className="success-title">Success!!</Text>
              <Text className="success-title">
                A link will be sent to your email address to reset the password
              </Text>
              <Title level={5} className="continue-to-login">
                Click on continue to go to Log In page.
              </Title>
              <div className="main-continue-btn">
                <Button
                  className="continue-btn"
                  type="primary"
                  block
                  size="large"
                  onClick={handleNavigateLogin}
                >
                  Continue
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className="forgot-pass">
                <Text>Set New Password</Text>
                <Text>Enter new password to set your password</Text>
              </div>
              <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                className="login-form forgot-pass-form reset-pass-form"
                onFinish={handleSubmitResetPassword}
              >
                <Form.Item
                  name="new_password"
                  label="New Password"
                  className="password-input reset-password-input"
                  rules={[{ required: true }]}
                >
                  <Input.Password placeholder="Enter Password" className="form-input" />
                </Form.Item>
                <Form.Item
                  name="confirm_password"
                  label="Confirm Password"
                  dependencies={['new_password']}
                  className="password-input reset-password-input"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter confirm password!'
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('new_password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('The two passwords that you entered do not match!')
                        );
                      }
                    })
                  ]}
                >
                  <Input.Password placeholder="Enter Password" className="form-input" />
                </Form.Item>
                <Button
                  className="login-btn reset-pass-btn"
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={isResetPassword}
                >
                  Reset Password
                </Button>
              </Form>
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default ResetPassword;
