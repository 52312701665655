import { Col, Row } from 'antd';

const NotificationTab = () => {
  return (
    <Row className="tab-inr-content-row">
      <Col className="coming-soon">Coming Soon</Col>
    </Row>
  );
};

export default NotificationTab;
