import React, { useEffect, useState } from 'react';
import { Divider, Typography } from 'antd';
import { filter, find, isEmpty } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyIcon from 'assets/svg/CopyIcon';
import LocationIcon from 'assets/svg/LocationIcon';
import { primary, white } from 'constants/color.constant';
import { useAppSelector } from 'hooks/useReduxHook';
import { riskstyles } from 'styles/projectView.styles';
import { TowerType, TransmissionSegmentType, TreeType } from 'store/riskanalysis/types';
import { getCenterPosition, getCenterPositionPolygon } from 'utils/xml';
import { CoordinateType } from 'types/common.types';

const { Text } = Typography;
interface RiskSegmentDataCardProps {
  selectedRiskData: TransmissionSegmentType | null;
  handleSelectedRiskCatTrees: (trees: TreeType[] | []) => void;
  handleShowAllRiskTree: (segment: TransmissionSegmentType | null) => void;
}

const RiskSegmentDataCard = ({
  selectedRiskData,
  handleSelectedRiskCatTrees,
  handleShowAllRiskTree
}: RiskSegmentDataCardProps) => {
  const [isCopied, setCopied] = useState(false);
  const [activeRiskCategory, setActiveRiskCategory] = useState<string | null>(null);
  const { riskTowers, riskTrees } = useAppSelector((state) => state.riskanalysis);
  if (isEmpty(selectedRiskData)) return null;
  const startTower: TowerType | null = find(riskTowers, { id: selectedRiskData?.start_tower_id });
  const endTower: TowerType | null = find(riskTowers, { id: selectedRiskData?.end_tower_id });
  let centerLatLng;
  if (selectedRiskData.geometry.type === CoordinateType.MultiPolygon) {
    centerLatLng = getCenterPosition(selectedRiskData.geometry.coordinates);
  } else if (selectedRiskData.geometry.type === CoordinateType.Polygon) {
    centerLatLng = getCenterPositionPolygon(selectedRiskData.geometry.coordinates);
  }

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [isCopied]);

  const sag =
    selectedRiskData && selectedRiskData.sag ? `${selectedRiskData.sag.toFixed(4)} m` : '-';

  const handleShowSelectedRiskTree = (treeIds: number[], riskCat: string) => {
    setActiveRiskCategory(riskCat);
    const trees: TreeType[] = [];
    if (!isEmpty(treeIds)) {
      filter(riskTrees, (tree: TreeType) => {
        if (treeIds.includes(tree.id as never)) trees.push(tree);
      });

      const data = filter(trees, (tree: TreeType) => {
        return tree.risk_cat === riskCat;
      });

      handleSelectedRiskCatTrees(data);
    }
  };

  return (
    <div className="risk-segment-data-content">
      <div className="risk-data-content">
        <Text>{selectedRiskData?.name || selectedRiskData?.id}</Text>
        <div className="lat-long-content">
          <LocationIcon />
          <Text className="lat-long">
            {!isEmpty(centerLatLng)
              ? [centerLatLng.lat.toFixed(6), centerLatLng.lng.toFixed(6)].join(',')
              : '-'}
          </Text>
          <CopyToClipboard
            onCopy={() => setCopied(true)}
            text={
              !isEmpty(centerLatLng)
                ? [centerLatLng.lat.toFixed(6), centerLatLng.lng.toFixed(6)].join(',')
                : '-'
            }
          >
            <CopyIcon color={isCopied ? primary : white} />
          </CopyToClipboard>
          <Text className="copied">{isCopied ? 'Copied' : ''}</Text>
        </div>
        <Divider style={riskstyles.dataCardDivider} />
        <div style={{ width: '100%' }}>
          <div className="error-content">
            <Text
              className={`${
                activeRiskCategory && activeRiskCategory !== 'high'
                  ? 'active-risk-category'
                  : 'high-risk'
              }`}
              style={{ cursor: 'pointer' }}
              onDoubleClick={() => {
                setActiveRiskCategory(null);
                handleShowAllRiskTree(selectedRiskData);
              }}
              onClick={() => handleShowSelectedRiskTree(selectedRiskData.trees_id, 'high')}
            >
              No. of High Risk Trees
            </Text>
            <Text
              className={`${
                activeRiskCategory && activeRiskCategory !== 'high'
                  ? 'active-risk-category'
                  : 'high-risk'
              }`}
            >
              {selectedRiskData?.total_high_risk_trees}
            </Text>
          </div>
          <div className="error-content">
            <Text
              className={`${
                activeRiskCategory && activeRiskCategory !== 'med'
                  ? 'active-risk-category'
                  : 'medium-risk'
              }`}
              style={{ cursor: 'pointer' }}
              onDoubleClick={() => {
                setActiveRiskCategory(null);
                handleShowAllRiskTree(selectedRiskData);
              }}
              onClick={() => handleShowSelectedRiskTree(selectedRiskData.trees_id, 'med')}
            >
              No. of Medium Risk Tress
            </Text>
            <Text
              className={`${
                activeRiskCategory && activeRiskCategory !== 'med'
                  ? 'active-risk-category'
                  : 'medium-risk'
              }`}
            >
              {selectedRiskData?.total_med_risk_trees}
            </Text>
          </div>
          <div className="error-content">
            <Text
              className={`${
                activeRiskCategory && activeRiskCategory !== 'low'
                  ? 'active-risk-category'
                  : 'low-risk'
              }`}
              style={{ cursor: 'pointer' }}
              onDoubleClick={() => {
                setActiveRiskCategory(null);
                handleShowAllRiskTree(selectedRiskData);
              }}
              onClick={() => handleShowSelectedRiskTree(selectedRiskData.trees_id, 'low')}
            >
              No. of Low Risk Tress
            </Text>
            <Text
              className={`${
                activeRiskCategory && activeRiskCategory !== 'low'
                  ? 'active-risk-category'
                  : 'low-risk'
              }`}
            >
              {selectedRiskData?.total_low_risk_trees}
            </Text>
          </div>
        </div>
      </div>
      <Divider style={riskstyles.dataCardDivider1} />
      <div className="risk-data-content-card">
        <div className="content-card">
          <Text>Height of {startTower?.name}</Text>
          <Text>{startTower?.height} m</Text>
        </div>
        <div className="content-card">
          <Text>Height of {endTower?.name}</Text>
          <Text>{endTower?.height} m</Text>
        </div>
        <div className="content-card">
          <Text>Length of Span</Text>
          <Text>
            {selectedRiskData?.span_length
              ? `${Number(selectedRiskData?.span_length).toFixed(2)} m`
              : '-'}
          </Text>
        </div>
        <div className="content-card">
          <Text>Sag of Cable</Text>
          <Text>{sag}</Text>
        </div>
      </div>
      <Divider style={riskstyles.dataCardDivider1} />
      <div className="risk-data-content-card" style={{ width: '100%' }}>
        <div className="content-card">
          <Text>Wind Speed</Text>
          <Text>-</Text>
        </div>
        <div className="content-card">
          <Text>Wind Direction</Text>
          {/* <DeclinedDownArrowIcon /> */}
          <Text>-</Text>
        </div>
        <div className="content-card">
          <Text>Ambient Temperature</Text>
          <Text>-</Text>
        </div>
      </div>
    </div>
  );
};

export default RiskSegmentDataCard;
