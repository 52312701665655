import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Typography } from 'antd';
import { GrFormClose } from 'react-icons/gr';
import { isEmpty } from 'lodash';
import { ACCESS_TOKEN } from 'constants/common.constant';
import RequiredTitle from 'components/core/RequiredTitle';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { getFileType, getFileTypeCategory } from 'store/repositoryfile';
import { editRepositoryFile } from 'store/repositoryfile/actions';
import { CategoryType, DataLayerFileType, FileType } from 'store/repositoryfile/types';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const { Text } = Typography;
const { Option } = Select;

interface EditRepofileProps {
  open: boolean;
  repoFile: DataLayerFileType;
  activeLayerId: number | null;
  handleCancelEditFile: (data: boolean) => void;
}

const EditRepofile = ({
  open,
  repoFile,
  activeLayerId,
  handleCancelEditFile
}: EditRepofileProps) => {
  const dispatch = useAppDispatch();
  const { isRequestingEditRepoFile } = useAppSelector((state) => state.repositoryFiles);
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const fileTypes: FileType[] | [] = useAppSelector((state) => getFileType(state));
  const [selectedFileType, setSelectedFileType] = useState<string | null>(null);
  const [selectedFileExt, setSelectedFileExt] = useState<string | null | undefined>(null);
  const selectedtypeCategories: Array<CategoryType> | [] = useAppSelector((state) =>
    getFileTypeCategory(state, selectedFileType, selectedProject.type)
  );
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...repoFile,
      file_name: !isEmpty(repoFile.name) ? repoFile.name.replace(/\.[^/.]+$/, '') : ''
    });
    setSelectedFileExt(!isEmpty(repoFile.name) ? repoFile.name.split('.').pop() : null);
  }, [repoFile]);

  const handleFileTypeChange = (value: { value: string; label: React.ReactNode }) => {
    setSelectedFileType(value.value);
  };

  const handleCallback = (status: boolean) => {
    if (status) {
      handleCancelEditFile(false);
    }
  };

  const handleUpdateRepoFile = () => {
    const values = form.getFieldsValue();
    const payload: FileType = {
      name: `${values.file_name}.${selectedFileExt}`
    };

    getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
      dispatch(
        editRepositoryFile(
          payload,
          +selectedOrganization.id,
          +selectedProject.id,
          activeLayerId!,
          repoFile.id,
          reToken,
          handleCallback
        )
      );
    });
  };

  const handleCancelFileEdit = () => {
    handleCancelEditFile(false);
  };

  return (
    <Modal
      className="edit-file-modal"
      title="Edit file"
      centered
      open={open}
      closeIcon={<GrFormClose size={20} />}
      okText="Save"
      okButtonProps={{ loading: isRequestingEditRepoFile }}
      maskClosable={false}
      onOk={handleUpdateRepoFile}
      onCancel={handleCancelFileEdit}
    >
      <Form form={form}>
        <RequiredTitle className="edit-file-label" title="NAME OF FILE" />
        <Form.Item name="file_name">
          <Input
            style={{ backgroundColor: '#F9F9F9' }}
            placeholder="NAME OF FILE"
            suffix={selectedFileExt ? <Text>.{selectedFileExt}</Text> : null}
          />
        </Form.Item>
        <RequiredTitle className="edit-file-label" title="FILE TYPE" />
        <Form.Item name="type">
          <Select
            disabled
            allowClear
            className="form-select"
            placeholder="Select"
            labelInValue
            onChange={handleFileTypeChange}
          >
            {fileTypes &&
              fileTypes.length > 0 &&
              fileTypes.map((type: FileType) => <Option key={type.name}>{type.name}</Option>)}
          </Select>
        </Form.Item>
        <RequiredTitle className="edit-file-label" title="CATEGORY" />
        <Form.Item name="category">
          <Select disabled allowClear className="form-select" placeholder="Select category">
            {selectedtypeCategories &&
              selectedtypeCategories.length > 0 &&
              selectedtypeCategories.map((category: CategoryType) => (
                <Option key={category.name} value={category.id.toString()}>
                  {category.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditRepofile;
