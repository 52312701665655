import { memo, useMemo } from 'react';
import { Marker, Polygon, Polyline, Tooltip } from 'react-leaflet';
import { isEmpty } from 'lodash';
import { Button } from 'antd';
import { lightGreen } from 'constants/color.constant';
import { LightLocation } from 'constants/map.constants';
import { CoordinateType } from 'types/common.types';
import { AddedAnnotation } from 'store/annotations/types';

interface RendorEditAnnotationMarkingProps {
  selectedEditMarking: AddedAnnotation | null;
}

const RendorEditAnnotationMarking = ({ selectedEditMarking }: RendorEditAnnotationMarkingProps) => {
  const allAnnotationMarkings = useMemo(() => {
    if (isEmpty(selectedEditMarking)) return null;

    if (selectedEditMarking.geometry.type === CoordinateType.Point) {
      const coords: any = selectedEditMarking.geometry.coordinates;
      if (coords && coords.length === 0) return null;

      return (
        <Marker key={selectedEditMarking.id} position={coords} icon={LightLocation}>
          <Tooltip className="annts-point-tooltip" direction="right">
            <Button type="primary">{selectedEditMarking.properties.name}</Button>
          </Tooltip>
        </Marker>
      );
    }
    if (selectedEditMarking.geometry.type === CoordinateType.LineString) {
      const coords: any = selectedEditMarking.geometry.coordinates;
      if (coords && coords.length === 0) return null;

      return (
        <Polyline
          key={selectedEditMarking.id}
          // @ts-ignore
          positions={coords}
          pathOptions={{
            color: lightGreen,
            fillColor: lightGreen,
            fillOpacity: 0.3,
            weight: 4
          }}
          pane="shadowPane"
        >
          <Tooltip sticky className="annts-point-tooltip" direction="right">
            <Button type="primary">{selectedEditMarking.properties.name}</Button>
          </Tooltip>
        </Polyline>
      );
    }
    if (selectedEditMarking.geometry.type === CoordinateType.Polygon) {
      const coords: any = selectedEditMarking.geometry.coordinates;
      if (coords && coords.length === 0) return null;

      return (
        <Polygon
          key={selectedEditMarking.id}
          positions={coords}
          pathOptions={{
            color: lightGreen,
            fillColor: lightGreen,
            fillOpacity: 0.3,
            weight: 4
          }}
        >
          <Tooltip className="annts-point-tooltip" direction="center">
            <Button type="primary">{selectedEditMarking.properties.name}</Button>
          </Tooltip>
        </Polygon>
      );
    }
    return null;
  }, [selectedEditMarking]);

  // eslint-disable-next-line
  return <>{allAnnotationMarkings}</>;
};

export default memo(RendorEditAnnotationMarking);
