import { Progress, Typography } from 'antd';
import { primary, white } from 'constants/color.constant';
import './styles.less';

const { Text } = Typography;

const FullOverlayLoader = () => {
  return (
    <div className="full-overlay-loader">
      <Text>Data Loading.......</Text>
      <Progress
        type="line"
        strokeColor={primary}
        trailColor={white}
        status="active"
        percent={99.9}
        showInfo={false}
      />
    </div>
  );
};

export default FullOverlayLoader;
