import { Typography } from 'antd';
import CloseIcon from 'assets/svg/CloseIcon';

const { Text } = Typography;
interface AddAnnotationsTabCardProps {
  handleCloseMarkingMode: Function;
}

const AddAnnotationsTabCard = ({ handleCloseMarkingMode }: AddAnnotationsTabCardProps) => {
  return (
    <div className="add-tab-inr-content">
      <div>
        <Text>Marking Mode</Text>
        <Text onClick={() => handleCloseMarkingMode()}>
          <CloseIcon height={8} width={8} />
        </Text>
      </div>
      <div>Select a point on map and start marking points to make a polygon</div>
    </div>
  );
};

export default AddAnnotationsTabCard;
