import React from 'react';
import { IconProps } from 'types/common.types';

const SearchIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1667 12.1667L10.0833 10.0833M6.33333 2.16667L8 0.5L9.66667 2.16667M13.8333 6.33333L15.5 8L13.8333 9.66667M2.16667 6.33333L0.5 8L2.16667 9.66667M6.33333 13.8333L8 15.5L9.66667 13.8333M5.5 8C5.5 8.66304 5.76339 9.29893 6.23223 9.76777C6.70107 10.2366 7.33696 10.5 8 10.5C8.66304 10.5 9.29893 10.2366 9.76777 9.76777C10.2366 9.29893 10.5 8.66304 10.5 8C10.5 7.33696 10.2366 6.70107 9.76777 6.23223C9.29893 5.76339 8.66304 5.5 8 5.5C7.33696 5.5 6.70107 5.76339 6.23223 6.23223C5.76339 6.70107 5.5 7.33696 5.5 8Z"
        stroke={color || 'white'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
