import { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { addAnnotationsPoint, editAnnotationsPoint } from 'store/annotations/actions';
import { AddedAnnotation, MarkingAddPayload, MarkingEditPayload } from 'store/annotations/types';
import { DataLayerTableType } from 'store/repositoryfile/types';
import { CoordinateType } from 'types/common.types';
import { getAsyncStorageValue } from 'utils/localStorage';
import { extractLengthFromCoords } from 'utils/xml';
import RequiredTitle from './RequiredTitle';

interface AddNotationPolylineFormProps {
  isEdit: boolean;
  lineCoords: any;
  centroidPoint: any;
  activeDataLayer: DataLayerTableType | null;
  handleRemoveMarkerLine: Function;
  editMarkingData?: AddedAnnotation | null;
}

const AddNotationPolylineForm = ({
  isEdit,
  lineCoords,
  centroidPoint,
  activeDataLayer,
  handleRemoveMarkerLine,
  editMarkingData
}: AddNotationPolylineFormProps) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { isAddAnnotationPointRequesting } = useAppSelector((state) => state.annotations);

  useEffect(() => {
    let length = 0;
    if (lineCoords.length > 0) {
      const coords: any = lineCoords.map((coords: any) => {
        return [coords[1], coords[0]];
      });
      length = extractLengthFromCoords(coords);
      form.setFieldsValue({
        distance: length,
        coordinates: `${centroidPoint.lat}, ${centroidPoint.lng}`
      });
    }
    if (isEdit) {
      if (isEmpty(editMarkingData)) return;
      form.setFieldsValue({
        antsname: editMarkingData.properties.name,
        category: editMarkingData.properties.category,
        note: editMarkingData.properties.note,
        coordinates: `${centroidPoint.lat}, ${centroidPoint.lng}`,
        distance: extractLengthFromCoords(editMarkingData.geometry.coordinates)
      });
    }
  }, [lineCoords, isEdit, centroidPoint]);

  const handleCancelMarker = () => {
    handleRemoveMarkerLine();
  };

  const handleCallack = (status: boolean) => {
    if (status) {
      handleRemoveMarkerLine();
    } else {
      toast.error('Something went wrong!');
    }
  };

  const handleAddPolyline = (values: any) => {
    if (!activeDataLayer) return;

    if (lineCoords.length === 0) return;
    const coords: any = lineCoords.map((coords: any) => {
      return [coords[1], coords[0]];
    });

    if (isEdit && editMarkingData) {
      const payload: MarkingEditPayload = {
        coordinates: coords,
        properties: {
          name: values?.antsname ? values.antsname : '',
          category: values?.category ? values.category : '',
          note: values?.note ? values.note : '',
          distance: values.distance,
          coordinates: values?.coordinates ? values.coordinates : ''
        }
      };

      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          editAnnotationsPoint(
            payload,
            +selectedOrganization.id,
            +selectedProject.id,
            +activeDataLayer.id,
            +editMarkingData.id,
            token,
            handleCallack
          )
        );
      });
    } else {
      const length = extractLengthFromCoords(coords);
      const payload: MarkingAddPayload = {
        geometry_type: CoordinateType.LineString,
        coordinates: coords,
        properties: {
          name: values?.antsname ? values.antsname : '',
          category: values?.category ? values.category : '',
          note: values?.note ? values.note : '',
          distance: String(length),
          coordinates: values?.coordinates ? values.coordinates : ''
        }
      };

      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          addAnnotationsPoint(
            payload,
            +selectedOrganization.id,
            +selectedProject.id,
            +activeDataLayer.id,
            token,
            handleCallack
          )
        );
      });
    }
  };

  return (
    <Form
      form={form}
      name="add_annotation"
      className="annotation-form"
      onFinish={handleAddPolyline}
    >
      <RequiredTitle title="Name" className="ann-form-label" />
      <Form.Item name="antsname" className="annotation-form-item">
        <Input placeholder="Enter Name" />
      </Form.Item>
      <RequiredTitle title="Category" className="ann-form-label" />
      <Form.Item name="category" className="annotation-form-item">
        <Input placeholder="Enter Category" />
      </Form.Item>
      <RequiredTitle title="Line Length" className="ann-form-label" />
      <Form.Item name="distance" className="annotation-form-item">
        <Input readOnly placeholder="Enter Line Length" value="100" />
      </Form.Item>
      <RequiredTitle title="Centroid Co-ordinates" className="ann-form-label" />
      <Form.Item name="coordinates" className="annotation-form-item">
        <Input placeholder="Enter center point" />
      </Form.Item>
      <RequiredTitle title="Add Note" className="ann-form-label" />
      <Form.Item name="note" className="annotation-form-item">
        <Input.TextArea
          placeholder="Enter Text here"
          style={{ minHeight: '64px', resize: 'none' }}
        />
      </Form.Item>
      <Form.Item style={{ margin: 0 }}>
        <Button
          type="primary"
          htmlType="submit"
          className="annotation-form-button"
          loading={isAddAnnotationPointRequesting}
        >
          Save
        </Button>
        <Button
          type="ghost"
          disabled={isAddAnnotationPointRequesting}
          onClick={handleCancelMarker}
          className="annotation-form-button"
        >
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

AddNotationPolylineForm.defaultProps = {
  editMarkingData: null
};

export default AddNotationPolylineForm;
