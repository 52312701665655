import { IconProps } from 'types/common.types';

const SatsureSkieswhite = ({ height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 171 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Skies_white" clipPath="url(#clip0_202_3562)">
        <g id="Layer_1-2">
          <path
            id="Vector"
            d="M7.84714 38.7266C6.94305 39.6225 6.54123 40.7176 6.54123 41.9123C6.54123 43.1069 6.94305 44.202 7.84714 45.098C8.75124 45.994 9.85624 46.3922 11.0617 46.3922H18.4954C21.4086 46.3922 23.8195 47.3878 25.8286 49.3789C27.8377 51.3699 28.8422 53.8588 28.8422 56.6463C28.8422 59.4338 27.8377 61.9227 25.8286 63.9138C23.8195 65.9049 21.3081 66.9004 18.4954 66.9004H1.41803V61.1263H18.4954C19.8013 61.1263 20.8058 60.728 21.7099 59.8321C22.614 58.9361 23.0158 57.841 23.0158 56.6463C23.0158 55.4517 22.614 54.3566 21.7099 53.4606C20.8058 52.5646 19.7008 52.1664 18.4954 52.1664H11.0617C8.24896 52.1664 5.73759 51.1708 3.72849 49.1798C1.71939 47.1887 0.714844 44.7994 0.714844 41.9123C0.714844 39.0252 1.71939 36.6359 3.72849 34.6448C5.73759 32.6537 8.24896 31.6582 11.0617 31.6582H25.8286V37.4323H11.0617C9.85624 37.4323 8.75124 37.8306 7.84714 38.7266Z"
            fill="#9961F5"
          />
          <path
            id="Vector_2"
            d="M57.5725 31.6582H65.5085L47.6275 49.3789L65.5085 67.0995H57.5725L40.6961 50.2748V67.0995H34.5684V31.6582H40.6961V48.4829L57.5725 31.6582Z"
            fill="#9961F5"
          />
          <path id="Vector_3" d="M72.2383 67V31.6582H78.366V67H72.2383Z" fill="#9961F5" />
          <path
            id="Vector_4"
            d="M114.833 37.1337H92.1297V61.5245H114.933V67H86.002V31.6582H114.833V37.1337ZM96.1479 52.0668V46.5913H113.728V52.0668H96.1479Z"
            fill="#9961F5"
          />
          <path
            id="Vector_5"
            d="M128.996 38.7266C128.091 39.6225 127.69 40.7176 127.69 41.9123C127.69 43.1069 128.091 44.202 128.996 45.098C129.9 45.994 131.005 46.3922 132.21 46.3922H139.644C142.557 46.3922 144.968 47.3878 146.977 49.3789C148.986 51.3699 149.991 53.8588 149.991 56.6463C149.991 59.4338 148.986 61.9227 146.977 63.9138C144.968 65.9049 142.457 66.9004 139.644 66.9004H122.466V61.1263H139.644C140.95 61.1263 141.954 60.728 142.858 59.8321C143.762 58.9361 144.164 57.841 144.164 56.6463C144.164 55.4517 143.762 54.3566 142.858 53.4606C141.954 52.5646 140.849 52.1664 139.644 52.1664H132.21C129.297 52.1664 126.886 51.1708 124.877 49.1798C122.868 47.1887 121.863 44.6998 121.863 41.9123C121.863 39.1248 122.868 36.6359 124.877 34.6448C126.886 32.6537 129.397 31.6582 132.21 31.6582H146.977V37.4323H132.21C130.904 37.4323 129.9 37.8306 128.996 38.7266Z"
            fill="#9961F5"
          />
          <path id="Vector_6" d="M37.3809 3.7832H0.714844V15.1324H37.3809V3.7832Z" fill="#9961F5" />
          <path
            id="Vector_7"
            d="M52.5495 19.1144C51.2436 19.1144 49.9377 18.9153 48.8326 18.4175C47.7276 17.9198 46.8236 17.3224 46.1204 16.4264C45.4172 15.5305 45.0154 14.5349 44.8145 13.3403L48.029 12.8425C48.3304 13.9376 48.8326 14.734 49.7367 15.3314C50.5404 15.9287 51.5449 16.2273 52.7504 16.2273C53.4536 16.2273 54.0563 16.1278 54.659 15.9287C55.2618 15.7296 55.764 15.4309 56.0654 15.0327C56.4672 14.6345 56.5677 14.1367 56.5677 13.6389C56.5677 13.3403 56.5677 13.1412 56.4672 12.9421C56.3668 12.7429 56.2663 12.5438 56.0654 12.3447C55.8645 12.1456 55.6636 12.0461 55.4627 11.847C55.2618 11.6478 54.8599 11.5483 54.5586 11.4487L49.7367 10.055C49.3349 9.95542 48.9331 9.75632 48.4308 9.55721C47.9286 9.3581 47.5267 9.05944 47.1249 8.76077C46.7231 8.36256 46.3213 7.96434 46.1204 7.36701C45.9195 6.76969 45.7185 6.17236 45.7185 5.37593C45.7185 4.18128 46.0199 3.18574 46.6226 2.3893C47.1249 1.59287 47.9286 0.995542 48.9331 0.597325C49.9377 0.199108 51.1431 0 52.3486 0C53.6545 0 54.7595 0.199108 55.764 0.597325C56.7686 0.995542 57.5722 1.59287 58.2754 2.3893C58.9786 3.18574 59.3804 4.18128 59.6818 5.27637L56.3668 5.97325C56.2663 5.27637 55.9649 4.7786 55.5631 4.28083C55.1613 3.78306 54.659 3.4844 54.1568 3.28529C53.6545 3.08618 52.9513 2.88707 52.3486 2.88707C51.7458 2.88707 51.1431 2.98663 50.6408 3.18574C50.1386 3.38484 49.6363 3.68351 49.3349 3.98217C49.0336 4.28083 48.8326 4.7786 48.8326 5.27637C48.8326 5.67459 48.9331 6.07281 49.2345 6.37147C49.5358 6.67013 49.8372 6.86924 50.239 7.06835C50.6408 7.26746 51.0427 7.36701 51.4445 7.46657L54.7595 8.36256C55.2618 8.46211 55.764 8.66122 56.2663 8.86033C56.7686 9.05943 57.3713 9.3581 57.8736 9.75632C58.3759 10.1545 58.8781 10.6523 59.1795 11.1501C59.4809 11.7474 59.6818 12.5438 59.6818 13.4398C59.6818 14.3358 59.4809 15.2318 59.079 15.9287C58.6772 16.6256 58.175 17.2229 57.4718 17.7207C56.7686 18.2184 55.9649 18.5171 55.1613 18.8158C54.3577 19.1144 53.4536 19.214 52.5495 19.1144Z"
            fill="white"
          />
          <path
            id="Vector_8"
            d="M63.2988 18.816L69.1252 0.398438H73.6457L79.4721 18.816H76.2575L71.0339 2.28997H71.737L66.5134 18.816H63.2988ZM66.6138 14.8338V11.9467H76.358V14.8338H66.6138Z"
            fill="white"
          />
          <path
            id="Vector_9"
            d="M86.8046 18.816V3.28551H80.7773V0.398438H95.946V3.28551H89.9187V18.816H86.8046Z"
            fill="white"
          />
          <path
            id="Vector_10"
            d="M106.796 19.1144C105.49 19.1144 104.184 18.9153 103.079 18.4175C101.974 17.9198 101.07 17.3224 100.366 16.4264C99.6633 15.5305 99.2615 14.5349 99.0605 13.3403L102.275 12.8425C102.576 13.9376 103.079 14.734 103.983 15.3314C104.786 15.9287 105.791 16.2273 106.896 16.2273C107.599 16.2273 108.202 16.1278 108.805 15.9287C109.407 15.7296 109.91 15.4309 110.211 15.0327C110.613 14.6345 110.814 14.1367 110.814 13.6389C110.814 13.3403 110.814 13.1412 110.713 12.9421C110.613 12.7429 110.512 12.5438 110.311 12.3447C110.111 12.1456 109.91 12.0461 109.709 11.847C109.508 11.6478 109.106 11.5483 108.805 11.4487L103.983 10.055C103.581 9.95542 103.179 9.75632 102.677 9.55721C102.175 9.3581 101.773 9.05944 101.371 8.76077C100.969 8.36256 100.567 7.96434 100.366 7.36701C100.065 6.76969 99.9646 6.17236 99.9646 5.37593C99.9646 4.18128 100.266 3.18574 100.869 2.3893C101.471 1.59287 102.275 0.995542 103.28 0.597325C104.284 0.199108 105.389 0 106.695 0C108.001 0 109.106 0.199108 110.111 0.597325C111.115 0.995542 111.919 1.59287 112.622 2.3893C113.325 3.18574 113.727 4.18128 114.028 5.27637L110.512 5.97325C110.412 5.27637 110.111 4.7786 109.709 4.28083C109.307 3.78306 108.805 3.4844 108.302 3.28529C107.8 3.08618 107.097 2.88707 106.494 2.88707C105.891 2.88707 105.289 2.98663 104.786 3.18574C104.284 3.38484 103.882 3.68351 103.481 3.98217C103.079 4.28083 102.978 4.7786 102.978 5.27637C102.978 5.67459 103.079 6.07281 103.38 6.37147C103.681 6.67013 103.983 6.86924 104.385 7.06835C104.786 7.26746 105.188 7.36701 105.59 7.46657L108.905 8.36256C109.307 8.46211 109.91 8.66122 110.412 8.86033C110.914 9.05943 111.517 9.3581 112.019 9.75632C112.522 10.1545 112.923 10.6523 113.325 11.1501C113.627 11.7474 113.827 12.5438 113.827 13.4398C113.827 14.3358 113.627 15.2318 113.225 15.9287C112.823 16.6256 112.321 17.2229 111.617 17.7207C110.914 18.2184 110.211 18.5171 109.307 18.8158C108.403 19.1144 107.7 19.214 106.796 19.1144Z"
            fill="white"
          />
          <path
            id="Vector_11"
            d="M126.283 19.1146C124.776 19.1146 123.471 18.816 122.366 18.2186C121.261 17.6213 120.356 16.8249 119.754 15.7298C119.151 14.6347 118.85 13.3405 118.85 11.9467V0.398438H121.964V11.9467C121.964 12.6436 122.064 13.2409 122.265 13.8383C122.466 14.4356 122.868 14.8338 123.27 15.232C123.671 15.6302 124.174 15.9289 124.676 16.128C125.178 16.3271 125.781 16.4267 126.283 16.4267C126.786 16.4267 127.388 16.3271 127.891 16.128C128.393 15.9289 128.895 15.6302 129.297 15.232C129.699 14.8338 130 14.336 130.201 13.8383C130.402 13.2409 130.603 12.6436 130.603 11.9467V0.398438H133.717V11.9467C133.717 13.44 133.416 14.6347 132.813 15.7298C132.21 16.8249 131.306 17.6213 130.201 18.2186C129.096 18.9155 127.79 19.1146 126.283 19.1146Z"
            fill="white"
          />
          <path
            id="Vector_12"
            d="M139.344 18.816V0.398438H146.978C147.179 0.398438 147.38 0.398438 147.682 0.398438C147.983 0.398438 148.184 0.398437 148.485 0.497992C149.59 0.6971 150.494 0.995763 151.097 1.59309C151.8 2.19041 152.302 2.78774 152.704 3.68373C153.106 4.57972 153.207 5.4757 153.207 6.47125C153.207 7.96456 152.805 9.25876 152.102 10.3539C151.298 11.449 150.193 12.1458 148.586 12.3449L147.28 12.4445H142.458V18.9155H139.344V18.816ZM142.458 9.45787H146.878C147.079 9.45787 147.28 9.45787 147.481 9.45787C147.682 9.45787 147.882 9.35832 148.083 9.35832C148.586 9.25876 148.987 8.9601 149.289 8.66144C149.59 8.36278 149.791 7.96456 149.892 7.56634C149.992 7.16813 150.092 6.76991 150.092 6.37169C150.092 5.97347 149.992 5.57526 149.892 5.17704C149.791 4.77882 149.59 4.38061 149.289 4.08194C148.987 3.78328 148.586 3.58417 148.083 3.38506C147.882 3.38506 147.682 3.28551 147.481 3.28551C147.28 3.28551 147.079 3.28551 146.878 3.28551H142.458V9.45787ZM149.992 18.816L146.376 11.3494L149.49 10.6525L153.508 18.816H149.992Z"
            fill="white"
          />
          <path
            id="Vector_13"
            d="M158.33 18.816V0.398438H170.284V3.28551H161.444V7.865H168.777V10.7521H161.444V15.9289H170.385V18.816H158.33Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_202_3562">
          <rect width="169.568" height="67" fill="white" transform="translate(0.716797)" />
        </clipPath>
      </defs>
    </svg>
  );
};
SatsureSkieswhite.defaultProps = {
  width: 171,
  height: 67,
  onClick: () => {}
};

export default SatsureSkieswhite;
