import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { addAnnotationsPoint, editAnnotationsPoint } from 'store/annotations/actions';
import { AddedAnnotation, MarkingEditPayload, MarkingAddPayload } from 'store/annotations/types';
import { DataLayerTableType } from 'store/repositoryfile/types';
import { CoordinateType } from 'types/common.types';
import { getAsyncStorageValue } from 'utils/localStorage';
import RequiredTitle from './RequiredTitle';

interface AddNotationPointFormProps {
  isEdit: boolean;
  pointLatLng: any;
  activeDataLayer: DataLayerTableType | null;
  handleRemoveMarkerPoint: Function;
  editMarkingData: AddedAnnotation | null | undefined;
}

const AddNotationPointForm = ({
  isEdit,
  pointLatLng,
  activeDataLayer,
  handleRemoveMarkerPoint,
  editMarkingData
}: AddNotationPointFormProps) => {
  const dispatch = useAppDispatch();
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { isAddAnnotationPointRequesting } = useAppSelector((state) => state.annotations);
  const [form] = Form.useForm();

  useEffect(() => {
    if (isEdit) {
      if (isEmpty(editMarkingData)) return;
      if (pointLatLng && pointLatLng.length === 0) return;
      const coords = `${pointLatLng[0]}, ${pointLatLng[1]}`;
      form.setFieldsValue({
        antsname: editMarkingData.properties.name,
        category: editMarkingData.properties.category,
        coordinates: coords,
        note: editMarkingData.properties.note
      });
    } else if (pointLatLng && pointLatLng.length > 0) {
      form.setFieldValue('coordinates', `${pointLatLng[0]}, ${pointLatLng[1]}`);
    }
  }, [pointLatLng, isEdit]);

  const handleCallack = (status: boolean) => {
    if (status) {
      handleRemoveMarkerPoint();
    } else {
      toast.error('Something went wrong!');
    }
  };

  const handleAddannotationsPoint = (values: {
    antsname: string;
    category: string;
    coordinates: string;
    note: string;
  }) => {
    if (activeDataLayer) {
      if (pointLatLng && pointLatLng.length === 0) return;
      if (isEdit && editMarkingData) {
        const payload: MarkingEditPayload = {
          coordinates: pointLatLng,
          properties: {
            name: values?.antsname ? values.antsname : '',
            category: values?.category ? values.category : '',
            note: values?.note ? values.note : '',
            coordinates: pointLatLng.join(',')
          }
        };
        getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
          dispatch(
            editAnnotationsPoint(
              payload,
              +selectedOrganization.id,
              +selectedProject.id,
              +activeDataLayer.id,
              +editMarkingData.id,
              token,
              handleCallack
            )
          );
        });
      } else {
        const payload: MarkingAddPayload = {
          geometry_type: CoordinateType.Point,
          coordinates: pointLatLng,
          properties: {
            name: values?.antsname ? values.antsname : '',
            category: values?.category ? values.category : '',
            note: values?.note ? values.note : '',
            coordinates: pointLatLng.join(',')
          }
        };
        getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
          dispatch(
            addAnnotationsPoint(
              payload,
              +selectedOrganization.id,
              +selectedProject.id,
              +activeDataLayer.id,
              token,
              handleCallack
            )
          );
        });
      }
    } else {
      toast.error('Something went wrong!');
    }
  };

  const handleCancelMarker = () => {
    handleRemoveMarkerPoint();
  };

  return (
    <Form
      form={form}
      name="add_annotation"
      className="annotation-form"
      onFinish={handleAddannotationsPoint}
    >
      <RequiredTitle title="Name" className="ann-form-label" />
      <Form.Item name="antsname" className="annotation-form-item">
        <Input placeholder="Enter Name" />
      </Form.Item>
      <RequiredTitle title="Category" className="ann-form-label" />
      <Form.Item name="category" className="annotation-form-item">
        <Input placeholder="Enter Category" />
      </Form.Item>
      <RequiredTitle title="Co-ordinates" className="ann-form-label" />
      <Form.Item name="coordinates" className="annotation-form-item">
        <Input placeholder="Enter Co-rdinates" />
      </Form.Item>
      <RequiredTitle title="Add Note" className="ann-form-label" />
      <Form.Item name="note" className="annotation-form-item">
        <Input.TextArea
          placeholder="Enter Text here"
          style={{ minHeight: '64px', resize: 'none' }}
        />
      </Form.Item>
      <Form.Item style={{ margin: 0 }}>
        <Button
          type="primary"
          htmlType="submit"
          className="annotation-form-button"
          loading={isAddAnnotationPointRequesting}
        >
          Save
        </Button>
        <Button
          type="ghost"
          onClick={handleCancelMarker}
          className="annotation-form-button"
          disabled={isAddAnnotationPointRequesting}
        >
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddNotationPointForm;
