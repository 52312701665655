import { IconProps } from 'types/common.types';

const LocationIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.53124 6.25C2.53124 4.6104 3.86039 3.28125 5.49998 3.28125C7.13958 3.28125 8.46873 4.6104 8.46873 6.25C8.46873 7.8896 7.13958 9.21875 5.49998 9.21875C3.86039 9.21875 2.53124 7.8896 2.53124 6.25ZM5.49998 4.21875C4.37816 4.21875 3.46874 5.12817 3.46874 6.25C3.46874 7.37183 4.37816 8.28125 5.49998 8.28125C6.62181 8.28125 7.53123 7.37183 7.53123 6.25C7.53123 5.12817 6.62181 4.21875 5.49998 4.21875Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.202712 5.53553C0.424167 2.8489 2.66926 0.78125 5.36501 0.78125H5.63497C8.33071 0.78125 10.5758 2.8489 10.7973 5.53553C10.9162 6.97875 10.4704 8.41181 9.55376 9.53286L6.5581 13.1965C6.01122 13.8653 4.98875 13.8653 4.44188 13.1965L1.44621 9.53286C0.529551 8.41181 0.0837503 6.97875 0.202712 5.53553ZM5.36501 1.71875C3.15717 1.71875 1.31842 3.41217 1.13704 5.61255C1.03803 6.8137 1.40906 8.0064 2.17198 8.93942L5.16764 12.603C5.33941 12.8131 5.66056 12.8131 5.83233 12.603L8.828 8.93942C9.59091 8.0064 9.96194 6.8137 9.86293 5.61255C9.68155 3.41217 7.8428 1.71875 5.63497 1.71875H5.36501Z"
        fill={color}
      />
    </svg>
  );
};

LocationIcon.defaultProps = {
  color: '#C7C7CC',
  height: 14,
  width: 11
};

export default LocationIcon;
