import { white } from 'constants/color.constant';
import { IconProps } from 'types/common.types';

const CloseIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.41401 5.99976L11.707 1.70676C12.098 1.31576 12.098 0.683762 11.707 0.292762C11.316 -0.0982383 10.684 -0.0982383 10.293 0.292762L6.00001 4.58576L1.70701 0.292762C1.31601 -0.0982383 0.684006 -0.0982383 0.293006 0.292762C-0.0979942 0.683762 -0.0979942 1.31576 0.293006 1.70676L4.58601 5.99976L0.293006 10.2928C-0.0979942 10.6838 -0.0979942 11.3158 0.293006 11.7068C0.488006 11.9018 0.744006 11.9998 1.00001 11.9998C1.25601 11.9998 1.51201 11.9018 1.70701 11.7068L6.00001 7.41376L10.293 11.7068C10.488 11.9018 10.744 11.9998 11 11.9998C11.256 11.9998 11.512 11.9018 11.707 11.7068C12.098 11.3158 12.098 10.6838 11.707 10.2928L7.41401 5.99976Z"
        fill={color}
      />
    </svg>
  );
};
CloseIcon.defaultProps = {
  color: white,
  height: 12,
  width: 12,
  onClick: () => {}
};

export default CloseIcon;
