import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, PageHeader } from 'antd';
import LeftArrowIcon from 'assets/svg/LeftArrowIcon';
import { routes } from 'constants/pageRoutes.constants';
import RequiredTitle from 'components/core/RequiredTitle';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { addOrganizationMember } from 'store/organizations/actions';
import { AddMemberPayload } from 'store/organizations/types';
import { getAsyncStorageValue } from 'utils/localStorage';
import { encryptValue } from 'utils/decodeEncodeData';
import './styles.less';

const AddMembers = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { isNewOrganizationMember, selectedOrganization } = useAppSelector(
    (state) => state.organizations
  );

  const handleCallBack = (status: boolean) => {
    if (status) {
      form.resetFields();
      navigate(routes.UserManagement);
    }
  };

  const handleNavigate = () => {
    navigate(routes.ProjectList);
  };

  const handleSubmitAddMember = (values: AddMemberPayload) => {
    const payload: AddMemberPayload = {
      firstname: encryptValue(values.firstname),
      lastname: encryptValue(values.lastname),
      email: encryptValue(values.email)
    };
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(addOrganizationMember(payload, selectedOrganization?.id, token, handleCallBack));
    });
  };

  return (
    <div className="main-add-members">
      <PageHeader
        className="add-member-header"
        onBack={() => navigate(routes.UserManagement)}
        backIcon={
          <Button
            icon={<LeftArrowIcon color="black" />}
            type="text"
            shape="circle"
            className="back-icon"
          />
        }
        title="Add member"
      />
      <div className="add-member-content">
        <Form className="add-member-form" form={form} onFinish={handleSubmitAddMember}>
          <RequiredTitle title="Name of the Organization" style={{ marginBottom: '18px' }} />
          <Form.Item className="member-form-email">
            <Input readOnly className="member-email" defaultValue={selectedOrganization?.name} />
          </Form.Item>
          <RequiredTitle title="Name of Client User" isRequired style={{ marginBottom: '18px' }} />
          <div className="member-form-name">
            <Form.Item rules={[{ required: true, message: 'Required!' }]} name="firstname">
              <Input className="member-name" placeholder="Enter first name" />
            </Form.Item>
            <Form.Item name="lastname" rules={[{ required: true, message: 'Required!' }]}>
              <Input className="member-name" placeholder="Enter last name" />
            </Form.Item>
          </div>
          <RequiredTitle
            title="Email address of Client User"
            isRequired
            style={{ marginBottom: '18px' }}
          />
          <Form.Item
            className="member-form-email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'Enter valid E-mail!'
              },
              {
                required: true,
                message: 'Required!'
              }
            ]}
          >
            <Input className="member-email" placeholder="Enter email address of admin" />
          </Form.Item>
          <div className="add-member-btn">
            <Button type="ghost" onClick={handleNavigate}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={isNewOrganizationMember}>
              Add
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddMembers;
