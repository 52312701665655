import { Typography } from 'antd';
import MarkingCard from 'components/core/MarkingCard';
import { white } from 'constants/color.constant';
import { AddedAnnotation } from 'store/annotations/types';

const { Text } = Typography;
interface MarkingsTabProps {
  markings: AddedAnnotation[] | [];
  selectedMarkings: number[];
  editAnnotationMarking: AddedAnnotation | null;
  handleDeleteMarker: (data: AddedAnnotation) => void;
  handleShowMarkings: (data: AddedAnnotation) => void;
  handleEditMarking: (data: AddedAnnotation) => void;
  activeShape: string | null;
  classname?: string | null;
}

const MarkingsTab = ({
  classname,
  markings,
  selectedMarkings,
  activeShape,
  editAnnotationMarking,
  handleDeleteMarker,
  handleShowMarkings,
  handleEditMarking
}: MarkingsTabProps) => {
  return (
    <div className={classname ? `add-tab-content ${classname}` : 'add-tab-content'}>
      <Text>Markings</Text>
      <div className="marking-list">
        {markings && markings.length > 0 ? (
          markings.map((data: AddedAnnotation) => {
            return (
              <MarkingCard
                key={data.id}
                markingData={data}
                activeShape={activeShape}
                selectedMarkings={selectedMarkings}
                editAnnotationMarking={editAnnotationMarking}
                handleEditMarking={handleEditMarking}
                handleDeleteMarker={handleDeleteMarker}
                handleShowMarkings={handleShowMarkings}
              />
            );
          })
        ) : (
          <div style={{ textAlign: 'center', marginTop: '10px', color: white }}>No markings</div>
        )}
      </div>
    </div>
  );
};
MarkingsTab.defaultProps = {
  classname: null
};

export default MarkingsTab;
