import { flatten, isEmpty } from 'lodash';
import { LatLng, computeArea, computeLength } from 'spherical-geometry-js';
import { CoordinateType, ReformatPoints } from 'types/common.types';

export const reformatCoordinates = (coords: any): ReformatPoints => {
  let type = CoordinateType.LineString;
  let length = 0;
  let points = [];

  if (coords.length > 0) {
    let coordinates: any = [];
    let properties: any = null;
    points = coords.map((coord: any) => {
      const coordPoints = coord.geometry;

      type = coordPoints.type;
      coordinates = coordPoints.coordinates;
      properties = coord.properties;
      length = coord.length;

      let coordinateData: any = [];

      if (type === CoordinateType.MultiPolygon) {
        const data = coordinates.map((points: any) => {
          return points.map((point: any) => {
            return point.map((p: any) => {
              return [Number(p[1]), Number(p[0])];
            });
          });
        });
        coordinateData = flatten(data);
      }

      if (type === CoordinateType.MultilineString || type === CoordinateType.Polygon) {
        coordinateData = coordinates.map((points: any) => {
          return points.map((point: any) => {
            return [Number(point[1]), Number(point[0])];
          });
        });
      }

      if (type === CoordinateType.LineString) {
        coordinateData = coordinates.map((point: any) => {
          return [Number(point[1]), Number(point[0])];
        });
      }

      if (type === CoordinateType.MultiPoint) {
        const data = coordinates.map((point: any) => {
          return [Number(point[1]), Number(point[0])];
        });
        coordinateData = flatten(data);
      }

      if (type === CoordinateType.Point) {
        coordinateData = [Number(coordinates[1]), Number(coordinates[0])];
      }

      return {
        coordinate: coordinateData,
        properties
      };
    });
  }

  return {
    points,
    type,
    length
  };
};

export const reformatRiskCoordinates = (coords: any): any => {
  let type = CoordinateType.LineString;
  let coordinates: any = [];
  let coordinateData: any = [];

  if (!isEmpty(coords)) {
    const coordPoints = coords.geometry;

    type = coordPoints.type;
    coordinates = coordPoints.coordinates;

    if (type === CoordinateType.MultiPolygon) {
      const data = coordinates.map((points: any) => {
        return points.map((point: any) => {
          return point.map((p: any) => {
            return [Number(p[1]), Number(p[0])];
          });
        });
      });
      coordinateData = flatten(data);
    }

    if (type === CoordinateType.MultilineString || type === CoordinateType.Polygon) {
      coordinateData = coordinates.map((points: any) => {
        return points.map((point: any) => {
          return [Number(point[1]), Number(point[0])];
        });
      });
    }

    if (type === CoordinateType.LineString) {
      coordinateData = coordinates.map((point: any) => {
        return [Number(point[1]), Number(point[0])];
      });
    }

    if (type === CoordinateType.MultiPoint) {
      const data = coordinates.map((point: any) => {
        return [Number(point[1]), Number(point[0])];
      });
      coordinateData = flatten(data);
    }

    if (type === CoordinateType.Point) {
      coordinateData = [Number(coordinates[1]), Number(coordinates[0])];
    }

    return coordinateData;
  }
  return coordinateData;
};

export const extractAreaFromCoords = (coords: any): number => {
  let area: number = 0;
  if (coords.length > 0) {
    const newLatLngs = coords[0].map((coord: any) => new LatLng(coord[0], coord[1]));
    const polygonArea: number = computeArea(newLatLngs) / 1000;
    area = Number(polygonArea.toFixed(2));
  }
  return area;
};

export const extractLengthFromCoords = (coords: any): number => {
  let length: number = 0;
  if (coords.length > 0) {
    const newLatLngs = coords.map((coord: any) => new LatLng(coord[1], coord[0]));
    const lineLength = computeLength(newLatLngs) / 1000;
    length = Number(lineLength.toFixed(2));
  }
  return length;
};

export const getCenterPosition = (coordinates: any) => {
  // @ts-ignore
  const bounds = new window.google.maps.LatLngBounds();
  const polygonCoords = flatten(coordinates).map((coords: any) => {
    return coords.map((coord: any) => ({ lng: coord[0], lat: coord[1] }));
  });

  flatten(polygonCoords).forEach((element) => {
    bounds.extend(element);
  });

  const centerBound = bounds.getCenter();

  return {
    lat: centerBound.lat(),
    lng: centerBound.lng()
  };
};

export const getCenterPositionPolygon = (coordinates: any) => {
  // @ts-ignore
  const bounds = new window.google.maps.LatLngBounds();
  const polygonCoords = coordinates.map((coords: any) => {
    return coords.map((coord: any) => ({ lng: coord[0], lat: coord[1] }));
  });

  flatten(polygonCoords).forEach((element) => {
    bounds.extend(element);
  });

  const centerBound = bounds.getCenter();

  return {
    lat: centerBound.lat(),
    lng: centerBound.lng()
  };
};
