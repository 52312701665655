import { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { orderBy } from 'lodash';
import { ACCESS_TOKEN } from 'constants/common.constant';
import EditDataLayer from 'components/FileRepository/EditDataLayer';
import FileRepositoryForm from 'components/FileRepository/FileRepositoryForm/FileRepositoryForm';
import FileRepositoryTable from 'components/FileRepository/FileRepositoryTable/FileRepositoryTable';
import ProjectDetailHeader from 'components/ProjectDetailHeader';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { getDataLayerDateList, getDataLayerFiles } from 'store/repositoryfile';
import { getDataLayers, getFileTypes, getGeoserverLayerStyle } from 'store/repositoryfile/actions';
import { DataLayerFileType, DataLayerTableType } from 'store/repositoryfile/types';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const RepositoryFile = () => {
  const dispatch = useAppDispatch();
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const dataLayerDateList: Array<DataLayerTableType> | [] = useAppSelector((state) =>
    getDataLayerDateList(state)
  );
  const [activeLayerDateId, setActiveLayerDateId] = useState<number | null>(null);
  const activeDataLayerFiles: DataLayerFileType[] | [] = useAppSelector((state) =>
    getDataLayerFiles(state, activeLayerDateId)
  );

  const handleActiveLayerId = (id: number) => {
    setActiveLayerDateId(id);
  };

  useEffect(() => {
    if (selectedOrganization && selectedProject) {
      getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
        dispatch(getDataLayers(+selectedOrganization.id, +selectedProject.id, reToken));
        dispatch(getFileTypes(reToken));
      });
    }
  }, []);

  useEffect(() => {
    if (activeLayerDateId) return;
    if (dataLayerDateList && dataLayerDateList.length > 0) {
      setActiveLayerDateId(
        orderBy(
          dataLayerDateList,
          (o: DataLayerTableType) => {
            return moment(o.date);
          },
          ['asc']
        )[0].id
      );
    }
  }, [dataLayerDateList]);

  useEffect(() => {
    if (activeLayerDateId) {
      getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
        dispatch(getGeoserverLayerStyle(+activeLayerDateId, reToken));
      });
    }
  }, [activeLayerDateId]);

  return (
    <>
      <ProjectDetailHeader project={selectedProject} />
      <div className="main-repo-file-content">
        <EditDataLayer
          dataLayerDateList={dataLayerDateList}
          handleActiveLayerId={handleActiveLayerId}
        />
        <FileRepositoryForm activeLayerId={activeLayerDateId} />
        <FileRepositoryTable
          activeLayerId={activeLayerDateId}
          dataLayerFiles={activeDataLayerFiles}
        />
      </div>
    </>
  );
};

export default memo(RepositoryFile);
