import { Typography } from 'antd';
import LocationIcon from 'assets/svg/LocationIcon';
import { isEmpty } from 'lodash';
import { TransmissionSegmentType } from 'store/riskanalysis/types';
import { CoordinateType } from 'types/common.types';
import { getCenterPosition, getCenterPositionPolygon } from 'utils/xml';

const { Text } = Typography;

interface RiskSegmentCardVegIntlProps {
  riskData: TransmissionSegmentType;
  segmentIndex: number;
  handleClickShowRiskData: (riskData: TransmissionSegmentType, ind: number) => void;
}

const RiskSegmentCardVegIntl = ({
  riskData,
  segmentIndex,
  handleClickShowRiskData
}: RiskSegmentCardVegIntlProps) => {
  let centerLatLng;
  if (riskData.geometry.type === CoordinateType.MultiPolygon) {
    centerLatLng = getCenterPosition(riskData.geometry.coordinates);
  } else if (riskData.geometry.type === CoordinateType.Polygon) {
    centerLatLng = getCenterPositionPolygon(riskData.geometry.coordinates);
  }

  return (
    <div
      className="risk-segment risk-segment-intl"
      onClick={() => handleClickShowRiskData(riskData, segmentIndex)}
    >
      <Text>Segment ID: {riskData?.name || riskData?.id}</Text>
      <div className="lat-long-content">
        <div>
          <LocationIcon />
          <Text className="lat-long">
            {!isEmpty(centerLatLng)
              ? [centerLatLng.lat.toFixed(6), centerLatLng.lng.toFixed(6)].join(',')
              : '-'}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default RiskSegmentCardVegIntl;
