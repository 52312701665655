import React from 'react';
import { Typography } from 'antd';
import EyeCloseIcon from 'assets/svg/EyeCloseIcon';
import EyeOpenIcon from 'assets/svg/EyeOpenIcon';
import { white } from 'constants/color.constant';
import { UploadedAnnotation } from 'store/annotations/types';

interface UploadedAnnotationCardProps {
  icon: React.ReactNode;
  annotation: UploadedAnnotation;
  selectedUploadMarkings: number[];
  handleShowUploadedMarkings: (data: number) => void;
}
const { Text } = Typography;

const UploadedAnnotationCard = ({
  icon,
  annotation,
  selectedUploadMarkings,
  handleShowUploadedMarkings
}: UploadedAnnotationCardProps) => {
  return (
    <div className="tab-inr-content">
      <div className="tab-content-head">
        <div>
          {icon}
          <div>
            <Text>Sector 24/B</Text>
            <Text>{annotation.geometry?.type}</Text>
          </div>
        </div>
        <div>
          {selectedUploadMarkings.includes(annotation.id!) ? (
            <EyeOpenIcon color={white} onClick={() => handleShowUploadedMarkings(annotation.id!)} />
          ) : (
            <EyeCloseIcon
              color={white}
              onClick={() => handleShowUploadedMarkings(annotation.id!)}
            />
          )}
        </div>
      </div>
      <div className="tab-content-desc">
        {Object.keys(annotation.properties).map((name: any, index: number) => {
          const value: any = ` - ${annotation.properties[name]}`;
          return <Text key={index}>{`${name} ${value}`}</Text>;
        })}
      </div>
    </div>
  );
};

export default UploadedAnnotationCard;
