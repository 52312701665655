import React, { useMemo } from 'react';
import { Space, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { Polygon, Popup } from 'react-leaflet';
import { purple } from 'constants/color.constant';
import { BufferType } from 'store/riskanalysis/types';
import { reformatRiskCoordinates } from 'utils/xml';

interface RendorBuffersProps {
  buffers: BufferType | null;
}

const { Text } = Typography;

const RendorBuffers = ({ buffers }: RendorBuffersProps) => {
  const rendorBuffer = useMemo(() => {
    if (isEmpty(buffers)) return null;
    const reformCoords: any = reformatRiskCoordinates(buffers);

    if (reformCoords && reformCoords.length === 0) return null;

    return (
      <Polygon
        key={buffers.fid}
        positions={reformCoords}
        pathOptions={{
          fillColor: purple,
          color: purple,
          fillOpacity: 0.3,
          weight: 1
        }}
        pane="tilePane"
      >
        <Popup className="annts-point-popup buffer-point-popup" minWidth={232}>
          <Space direction="vertical">
            {/* <div>
              <Text className="annts-market-title">POWER</Text>
              <Text className="annts-market-data">{buffers?.power || '-'}</Text>
            </div>
            <div>
              <Text className="annts-market-title">FULL ID</Text>
              <Text className="annts-market-data">{buffers?.full_id || '-'}</Text>
            </div>
            <div>
              <Text className="annts-market-title">CABLES</Text>
              <Text className="annts-market-data">{buffers?.cables || '-'}</Text>
            </div> */}
            <div>
              <Text className="annts-market-title">Buffer Width</Text>
              <Text className="annts-market-data">
                {buffers?.buffer_width ? `${Number(buffers?.buffer_width).toFixed(3)} m` : '-'}
              </Text>
            </div>
            {/* <div>
              <Text className="annts-market-title">OSM ID</Text>
              <Text className="annts-market-data">{buffers?.osm_id || '-'}</Text>
            </div>
            <div>
              <Text className="annts-market-title">OSM TYPE</Text>
              <Text className="annts-market-data">{buffers?.osm_type || '-'}</Text>
            </div>
            <div>
              <Text className="annts-market-title">VOLTAGE</Text>
              <Text className="annts-market-data">{buffers?.voltage || '-'}</Text>
            </div> */}
          </Space>
        </Popup>
      </Polygon>
    );
  }, [buffers]);

  // eslint-disable-next-line
  return <>{rendorBuffer}</>;
};

export default RendorBuffers;
