import { Collapse, Typography } from 'antd';
import EyeCloseIcon from 'assets/svg/EyeCloseIcon';
import EyeOpenIcon from 'assets/svg/EyeOpenIcon';
import { lightGrey1 } from 'constants/color.constant';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { ProjectCategoryTypes } from 'store/projects/types';
import { DataLayerFileType } from 'store/repositoryfile/types';
import { Maptype } from 'types/common.types';

const { Text } = Typography;
const { Panel } = Collapse;

interface LayerControlListProps {
  mapType: string;
  satelliteImagesVMLite: string | null;
  riskSegments: string[];
  riskTreesVegIntl: string[];
  isActiveSatellite: boolean;
  isActiveCompare: boolean;
  isOpenLulcLayerCompareView: boolean;
  handleMapType: (data: string) => void;
  handleChangeSegmentVectorLayer: (data: string) => void;
  handleChangeTreesVectorLayer: (data: string) => void;
  handleOnChangeSatelliteVMLite: (data: string | null) => void;
  satelliteImagesRepoFile: DataLayerFileType | undefined;
  satelliteImagesRepoFilesVMLite: DataLayerFileType[] | [];
  handleOnChangeSatellite: (isShowHide: boolean) => void;
  handleOnChangeVegetationRisk: (isShowHide: boolean) => void;
  projectType?: string | null;
}

const LayerControlList = ({
  mapType,
  riskSegments,
  satelliteImagesVMLite,
  riskTreesVegIntl,
  isActiveSatellite,
  satelliteImagesRepoFile,
  satelliteImagesRepoFilesVMLite,
  isActiveCompare,
  isOpenLulcLayerCompareView,
  handleMapType,
  handleOnChangeSatellite,
  handleChangeTreesVectorLayer,
  handleChangeSegmentVectorLayer,
  handleOnChangeVegetationRisk,
  handleOnChangeSatelliteVMLite,
  projectType
}: LayerControlListProps) => {
  const [activePanel, setActivePanel] = useState<string | string[]>([
    'basemap',
    'features',
    'vectorlayers'
  ]);
  const handleOnchangeMapType = (mapType: string) => {
    handleMapType(mapType);
  };

  const handleCollapsePanel = (activeTab: string | string[]) => setActivePanel(activeTab);

  return (
    <div className="layer-control-list-main">
      <Collapse
        className="vector-layer"
        activeKey={activePanel}
        onChange={handleCollapsePanel}
        expandIconPosition="end"
      >
        <Panel className="basemap-panel" collapsible="icon" header="Base Map" key="basemap">
          <div className="panel-content-main">
            <div className="vector-layer-content">
              <Text
                className={`layer-content-title ${
                  mapType === Maptype.satellite ? 'active-layer' : ''
                }`}
              >
                Google Maps Satellite
              </Text>
              <Text onClick={() => handleOnchangeMapType(Maptype.satellite)}>
                {mapType === Maptype.satellite ? (
                  <EyeOpenIcon color="#F2F2F2" />
                ) : (
                  <EyeCloseIcon color={lightGrey1} />
                )}
              </Text>
            </div>
            <div className="vector-layer-content">
              <Text
                className={`layer-content-title ${
                  mapType === Maptype.Roadmap ? 'active-layer' : ''
                }`}
              >
                Google Maps Roads
              </Text>

              <Text onClick={() => handleOnchangeMapType(Maptype.Roadmap)}>
                {mapType === Maptype.Roadmap ? (
                  <EyeOpenIcon color="#F2F2F2" />
                ) : (
                  <EyeCloseIcon color={lightGrey1} />
                )}
              </Text>
            </div>
          </div>
        </Panel>
        {!isActiveCompare && (
          <Panel className="basemap-panel" collapsible="icon" header="Features" key="features">
            <div className="panel-content-main">
              {projectType && projectType === ProjectCategoryTypes.VegetationManagementLite ? (
                satelliteImagesRepoFilesVMLite.map((file: DataLayerFileType) => (
                  <div className="vector-layer-content">
                    <Text
                      className={`layer-content-title ${
                        mapType === Maptype.satellite ? 'active-layer' : ''
                      }`}
                    >
                      {file.name}
                    </Text>
                    <Text onClick={() => handleOnChangeSatelliteVMLite(file.gs_layer_name)}>
                      {file.gs_layer_name === satelliteImagesVMLite ? (
                        <EyeOpenIcon color="#F2F2F2" />
                      ) : (
                        <EyeCloseIcon color={lightGrey1} />
                      )}
                    </Text>
                  </div>
                ))
              ) : (
                <div className="vector-layer-content">
                  <Text
                    className={`layer-content-title ${
                      mapType === Maptype.satellite ? 'active-layer' : ''
                    }`}
                  >
                    Satellite Images
                  </Text>
                  <Text
                    disabled={isEmpty(satelliteImagesRepoFile)}
                    onClick={() =>
                      isEmpty(satelliteImagesRepoFile)
                        ? () => {}
                        : handleOnChangeSatellite(!isActiveSatellite)
                    }
                  >
                    {isActiveSatellite ? (
                      <EyeOpenIcon color="#F2F2F2" />
                    ) : (
                      <EyeCloseIcon color={lightGrey1} />
                    )}
                  </Text>
                </div>
              )}
            </div>
          </Panel>
        )}
        {isActiveCompare && (
          <Panel className="basemap-panel" collapsible="icon" header="Features" key="features">
            <div className="panel-content-main">
              <div className="vector-layer-content">
                <Text
                  className={`layer-content-title ${
                    mapType === Maptype.satellite ? 'active-layer' : ''
                  }`}
                >
                  Vegetation Risk
                </Text>
                <Text
                  disabled={!isActiveCompare}
                  onClick={() => handleOnChangeVegetationRisk(!isOpenLulcLayerCompareView)}
                >
                  {isOpenLulcLayerCompareView ? (
                    <EyeOpenIcon color="#F2F2F2" />
                  ) : (
                    <EyeCloseIcon color={lightGrey1} />
                  )}
                </Text>
              </div>
            </div>
          </Panel>
        )}
        {!isActiveCompare && (
          <Panel
            className="basemap-panel"
            collapsible="icon"
            header="Vector Layers"
            key="vectorlayers"
          >
            {projectType && projectType !== ProjectCategoryTypes.VegetationManagementLite && (
              <>
                <div className="panel-content-main">
                  <div className="vector-layer-content">
                    <div className="risk-high risk-box" />
                    <Text
                      className={`layer-content-title ${
                        mapType === Maptype.satellite ? 'active-layer' : ''
                      }`}
                      style={{ paddingLeft: 6 }}
                    >
                      High Risk Segment
                    </Text>
                    <Text onClick={() => handleChangeSegmentVectorLayer('high')}>
                      {riskSegments.includes('high') ? (
                        <EyeOpenIcon color="#F2F2F2" />
                      ) : (
                        <EyeCloseIcon color={lightGrey1} />
                      )}
                    </Text>
                  </div>
                </div>
                <div className="panel-content-main">
                  <div className="vector-layer-content">
                    <div className="risk-medium risk-box" />
                    <Text
                      className={`layer-content-title ${
                        mapType === Maptype.satellite ? 'active-layer' : ''
                      }`}
                      style={{ paddingLeft: 6 }}
                    >
                      Medium Risk Segment
                    </Text>
                    <Text onClick={() => handleChangeSegmentVectorLayer('med')}>
                      {riskSegments.includes('med') ? (
                        <EyeOpenIcon color="#F2F2F2" />
                      ) : (
                        <EyeCloseIcon color={lightGrey1} />
                      )}
                    </Text>
                  </div>
                </div>
                <div className="panel-content-main">
                  <div className="vector-layer-content">
                    <div className="risk-low risk-box" />
                    <Text
                      className={`layer-content-title ${
                        mapType === Maptype.satellite ? 'active-layer' : ''
                      }`}
                      style={{ paddingLeft: 6 }}
                    >
                      Low Risk Segment
                    </Text>
                    <Text onClick={() => handleChangeSegmentVectorLayer('low')}>
                      {riskSegments.includes('low') ? (
                        <EyeOpenIcon color="#F2F2F2" />
                      ) : (
                        <EyeCloseIcon color={lightGrey1} />
                      )}
                    </Text>
                  </div>
                </div>
                <div style={{ marginTop: 16 }} />
              </>
            )}
            <div className="panel-content-main">
              <div className="vector-layer-content">
                <div className="risk-high risk-box" />
                <Text
                  className={`layer-content-title ${
                    mapType === Maptype.satellite ? 'active-layer' : ''
                  }`}
                  style={{ paddingLeft: 6 }}
                >
                  {projectType && projectType === ProjectCategoryTypes.VegetationManagementLite
                    ? 'Proximity Risk Trees'
                    : 'High Risk Trees'}
                </Text>
                <Text
                  onClick={() =>
                    handleChangeTreesVectorLayer(
                      projectType && projectType === ProjectCategoryTypes.VegetationManagementLite
                        ? 'Proximity'
                        : 'high'
                    )
                  }
                >
                  {riskTreesVegIntl.includes('high') || riskTreesVegIntl.includes('Proximity') ? (
                    <EyeOpenIcon color="#F2F2F2" />
                  ) : (
                    <EyeCloseIcon color={lightGrey1} />
                  )}
                </Text>
              </div>
            </div>
            <div className="panel-content-main">
              <div className="vector-layer-content">
                <div className="risk-medium risk-box" />
                <Text
                  className={`layer-content-title ${
                    mapType === Maptype.satellite ? 'active-layer' : ''
                  }`}
                  style={{ paddingLeft: 6 }}
                >
                  {projectType && projectType === ProjectCategoryTypes.VegetationManagementLite
                    ? 'High Risk Trees'
                    : 'Medium Risk Trees'}
                </Text>
                <Text
                  onClick={() =>
                    handleChangeTreesVectorLayer(
                      projectType && projectType === ProjectCategoryTypes.VegetationManagementLite
                        ? 'HighRisk'
                        : 'med'
                    )
                  }
                >
                  {riskTreesVegIntl.includes('med') || riskTreesVegIntl.includes('HighRisk') ? (
                    <EyeOpenIcon color="#F2F2F2" />
                  ) : (
                    <EyeCloseIcon color={lightGrey1} />
                  )}
                </Text>
              </div>
            </div>
            <div className="panel-content-main">
              <div className="vector-layer-content">
                <div className="risk-low risk-box" />
                <Text
                  className={`layer-content-title ${
                    mapType === Maptype.satellite ? 'active-layer' : ''
                  }`}
                  style={{ paddingLeft: 6 }}
                >
                  Low Risk Trees
                </Text>
                <Text
                  onClick={() =>
                    handleChangeTreesVectorLayer(
                      projectType && projectType === ProjectCategoryTypes.VegetationManagementLite
                        ? 'LowOrNoRisk'
                        : 'low'
                    )
                  }
                >
                  {riskTreesVegIntl.includes('low') || riskTreesVegIntl.includes('LowOrNoRisk') ? (
                    <EyeOpenIcon color="#F2F2F2" />
                  ) : (
                    <EyeCloseIcon color={lightGrey1} />
                  )}
                </Text>
              </div>
            </div>
          </Panel>
        )}
      </Collapse>
    </div>
  );
};

LayerControlList.defaultProps = {
  projectType: null
};

export default LayerControlList;
