import { toast } from 'react-toastify';
import { ACCESS_TOKEN, TOKEN_EXPIRE_ERROR } from 'constants/common.constant';
import { getOrganizationMembers } from 'store/organizations/actions';
import type { Response, Error } from 'types/common.types';
import {
  ADD_PROJECT_MEMBERS_URL,
  ADD_PROJECT_URL,
  GET_ALL_PROJECT_URL,
  GET_AVAILABLE_PROJECT_MEMBERS_URL,
  GET_PROJECT_URL,
  REMOVE_MEMBER_FROM_PROJECT_URL,
  REMOVE_MEMBER_FROM_ORGANIZATION_URL,
  EDIT_PROJECT_URL,
  GET_PROJECT_TYPES_URL
} from 'utils/apiUrls';
import { apiCall } from 'utils/connect';
import { getAsyncStorageValue } from 'utils/localStorage';
import type { AppDispatch } from '..';
import {
  AddProjectMemberPayload,
  AvailableProjectMember,
  Project,
  ProjectPayload,
  RemoveOrganizationMemberPayload,
  RemoveProjectMembersPayload
} from './types';
import {
  setRequestingProjects,
  setProjects,
  setProject,
  setAddProjectRequesting,
  setRequestProject,
  setProjectMemberRequest,
  setAvailableProjectMemberRequesting,
  setAvailableProjectMembers,
  setRequestingRemoveMemberFromProject,
  setRequestingRemoveMemberFromOrganization,
  setSelectedProject,
  setRequestProjectType,
  setProjectType
} from '.';

export const getProjects = (orgId: number, token: string) => (dispatch: AppDispatch) => {
  try {
    dispatch(setRequestingProjects(true));

    const onSuccess = (response: Response<Array<any>>) => {
      dispatch(setProjects(response.data));
      dispatch(setRequestingProjects(false));
    };
    const onFailure = (error: Error) => {
      if (error.message === TOKEN_EXPIRE_ERROR) {
        getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
          dispatch(getProjects(orgId, reToken));
        });
      } else {
        toast.error(error.message);
        dispatch(setRequestingProjects(false));
      }
    };

    apiCall('GET', GET_ALL_PROJECT_URL(orgId), '', onSuccess, onFailure, token);
  } catch (error: any) {
    dispatch(setRequestingProjects(false));
    toast.error(error.message);
  }
};

export const addProject =
  (payload: ProjectPayload, orgId: number, token: string, callback: Function) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setAddProjectRequesting(true));

      const onSuccess = (response: Response<boolean>) => {
        // toast.success('Project added successfully');
        dispatch(getProjects(orgId, token));
        dispatch(setAddProjectRequesting(false));
        callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addProject(payload, orgId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setAddProjectRequesting(false));
        }
      };

      apiCall('POST', ADD_PROJECT_URL(orgId), { ...payload }, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setAddProjectRequesting(false));
      toast.error(error.message);
    }
  };

export const editProject =
  (payload: ProjectPayload, projectId: number, orgId: number, token: string, callback: Function) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setAddProjectRequesting(true));

      const onSuccess = (response: Response<boolean>) => {
        // toast.success('Project added successfully');
        dispatch(getProjects(orgId, token));
        dispatch(setAddProjectRequesting(false));
        callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addProject(payload, orgId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setAddProjectRequesting(false));
        }
      };

      apiCall(
        'PUT',
        EDIT_PROJECT_URL(orgId, projectId),
        { ...payload },
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setAddProjectRequesting(false));
      toast.error(error.message);
    }
  };

export const addProjectMembers =
  (
    payload: AddProjectMemberPayload,
    projectId: number,
    orgId: number,
    token: string,
    callback: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setProjectMemberRequest(true));

      const onSuccess = () => {
        // toast.success('Project members added successfully');
        dispatch(getOrganizationMembers(orgId, token));
        dispatch(getProjects(Number(orgId), token));
        dispatch(setProjectMemberRequest(false));
        callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addProjectMembers(payload, projectId, orgId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setProjectMemberRequest(false));
        }
      };

      apiCall(
        'POST',
        ADD_PROJECT_MEMBERS_URL(orgId, projectId),
        { ...payload },
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setProjectMemberRequest(false));
      toast.error(error.message);
    }
  };

export const getProject =
  (orgId: number, projectId: number, token: string) => (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestProject(true));

      const onSuccess = (response: Response<Project>) => {
        dispatch(setProject(response.data));
        if (response.data) {
          dispatch(setSelectedProject(response.data));
        }
        dispatch(setRequestProject(false));
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            // @ts-ignore
            dispatch(getProject(projectId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestProject(false));
        }
      };

      apiCall('GET', GET_PROJECT_URL(orgId, projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestProject(false));
      toast.error(error.message);
    }
  };

export const getProjectTypes = (token: string) => (dispatch: AppDispatch) => {
  try {
    dispatch(setRequestProjectType(true));

    const onSuccess = (response: Response<Project>) => {
      dispatch(setProjectType(response.data));
      dispatch(setRequestProjectType(false));
    };
    const onFailure = (error: Error) => {
      if (error.message === TOKEN_EXPIRE_ERROR) {
        getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
          dispatch(getProjectTypes(reToken));
        });
      } else {
        toast.error(error.message);
        dispatch(setRequestProjectType(false));
      }
    };

    apiCall('GET', GET_PROJECT_TYPES_URL, '', onSuccess, onFailure, token);
  } catch (error: any) {
    dispatch(setRequestProjectType(false));
    toast.error(error.message);
  }
};

export const getAvailableProjectMembers =
  (orgId: number, projectId: number, token: string) => (dispatch: AppDispatch) => {
    try {
      dispatch(setAvailableProjectMemberRequesting(true));

      const onSuccess = (response: Response<AvailableProjectMember>) => {
        dispatch(setAvailableProjectMembers(response.data));
        dispatch(setAvailableProjectMemberRequesting(false));
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getAvailableProjectMembers(orgId, projectId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setAvailableProjectMemberRequesting(false));
        }
      };

      apiCall(
        'GET',
        GET_AVAILABLE_PROJECT_MEMBERS_URL(orgId, projectId),
        '',
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setAvailableProjectMemberRequesting(false));
      toast.error(error.message);
    }
  };

export const removeMemberFromProject =
  (
    payload: RemoveProjectMembersPayload,
    projectId: number,
    orgId: number,
    token: string,
    callback: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingRemoveMemberFromProject(true));

      const onSuccess = () => {
        // toast.success('Remove members from project successfully');
        dispatch(getProjects(orgId, token));
        dispatch(getOrganizationMembers(orgId, token));
        dispatch(setRequestingRemoveMemberFromProject(false));
        callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(removeMemberFromProject(payload, projectId, orgId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingRemoveMemberFromProject(false));
        }
      };

      apiCall(
        'DELETE',
        REMOVE_MEMBER_FROM_PROJECT_URL(orgId, projectId),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingRemoveMemberFromProject(false));
      toast.error(error.message);
    }
  };

export const removeAddMemberFromOrganization =
  (
    payload: RemoveOrganizationMemberPayload,
    memberId: string,
    orgId: number,
    token: string,
    callback: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingRemoveMemberFromOrganization(true));

      const onSuccess = (response: Response<boolean>) => {
        // toast.success('Remove member from organization successfully');
        dispatch(getProjects(orgId, token));
        dispatch(getOrganizationMembers(orgId, token));
        dispatch(setRequestingRemoveMemberFromOrganization(false));
        callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(removeAddMemberFromOrganization(payload, memberId, orgId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingRemoveMemberFromOrganization(false));
        }
      };

      apiCall(
        'PUT',
        REMOVE_MEMBER_FROM_ORGANIZATION_URL(orgId, memberId),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingRemoveMemberFromOrganization(false));
      toast.error(error.message);
    }
  };
