export const routes = {
  LoginUrl: '/',
  ForgotPass: '/forgot-password',
  ResetPass: '/reset-password',
  Organization: '/organizations',
  AddOrganization: '/add-org',
  TeamOnboarding: '/team-onboarding',
  EditOrganization: '/edit-org/:id',
  ProjectList: '/projects',
  AddProject: '/create-project',
  EditProject: '/edit-project/:id',
  RepoFile: '/repo-file',
  UserManagement: '/user-management',
  AddMember: '/add-member',
  Mapview: '/map-view',
  ProjectView: '/project-view',
  ProjectViewVegInt: '/project-view-veg-int',
  Settings: '/setting'
};
