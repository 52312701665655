import { RootState } from '..';
import { vectorfileSlice } from './slice';

export const { setRequestingVectorFiles, setVectorFiles, clearVectorfile } =
  vectorfileSlice.actions;
export default vectorfileSlice.reducer;

export const getVectorFiles = (state: RootState) => {
  const { vectorFiles } = state.vectorfile;
  return vectorFiles;
};
