import { combineReducers } from '@reduxjs/toolkit';
import user from './users';
import organizations from './organizations';
import projects from './projects';
import repositoryFiles from './repositoryfile';
import vectorfile from './vectorfile';
import annotations from './annotations';
import riskanalysis from './riskanalysis';

const rootReducer = combineReducers({
  user,
  organizations,
  projects,
  repositoryFiles,
  vectorfile,
  annotations,
  riskanalysis
});

export default rootReducer;
