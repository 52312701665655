import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';

const storeUser = createWhitelistFilter('user', ['rsaKey', 'user']);
const storeSelectedOrganization = createWhitelistFilter('organizations', [
  'selectedOrganization',
  'orginsationMembers'
]);
const storeSelectedProject = createWhitelistFilter('projects', [
  'selectedProject',
  'project',
  'projectTypes'
]);
const storeSelectedFileTypes = createWhitelistFilter('repositoryFiles', [
  'fileTypes',
  'dataLayers'
]);

const persistConfig = {
  key: 'root:skies_explorer',
  storage,
  blacklist: ['annotations', 'vectorfile', 'riskanalysis'],
  transforms: [storeUser, storeSelectedOrganization, storeSelectedProject, storeSelectedFileTypes]
};

const middlewares: any = [];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares)
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
