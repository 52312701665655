import { Popconfirm, Typography } from 'antd';
import DeleteIcon from 'assets/svg/DeleteIcon';
import EditFileIcon from 'assets/svg/EditFileIcon';
import EyeCloseIcon from 'assets/svg/EyeCloseIcon';
import EyeOpenIcon from 'assets/svg/EyeOpenIcon';
import { white } from 'constants/color.constant';
import { USER_ROLE } from 'constants/common.constant';
import { AddedAnnotation } from 'store/annotations/types';
import { UserRole } from 'store/users/types';
import { getStorageValue } from 'utils/localStorage';

const { Text } = Typography;

interface MarkingCardProps {
  markingData: AddedAnnotation;
  selectedMarkings: number[];
  activeShape: string | null;
  editAnnotationMarking: AddedAnnotation | null;
  handleEditMarking: (data: AddedAnnotation) => void;
  handleDeleteMarker: (data: AddedAnnotation) => void;
  handleShowMarkings: (data: AddedAnnotation) => void;
}

const MarkingCard = ({
  markingData,
  selectedMarkings,
  activeShape,
  editAnnotationMarking,
  handleDeleteMarker,
  handleShowMarkings,
  handleEditMarking
}: MarkingCardProps) => {
  const userRole = getStorageValue(USER_ROLE);

  const handleEdit = (data: AddedAnnotation) => {
    handleEditMarking(data);
  };

  return (
    <div className="marking-card">
      <Text>{markingData.properties.name}</Text>
      <div
        className={
          // eslint-disable-next-line
          userRole !== UserRole.SuperAdmin
            ? 'client-data'
            : activeShape && markingData.geometry.type === activeShape
            ? ''
            : 'deactive-shape'
        }
      >
        <EditFileIcon
          height={15}
          color={
            userRole === UserRole.SuperAdmin &&
            activeShape &&
            markingData.geometry.type === activeShape
              ? white
              : '#7c7979'
          }
          onClick={() => {
            if (
              userRole === UserRole.SuperAdmin &&
              activeShape &&
              markingData.geometry.type === activeShape
            ) {
              handleEdit(markingData);
            }
          }}
        />
        <Popconfirm
          title="Are you sure to delete this Marker?"
          onConfirm={() => handleDeleteMarker(markingData)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteIcon color={white} height={15} />
        </Popconfirm>
        {
          // eslint-disable-next-line
          editAnnotationMarking && editAnnotationMarking.id === markingData.id ? (
            <EyeCloseIcon color={white} />
          ) : selectedMarkings.includes(markingData.id) ? (
            <EyeOpenIcon color={white} onClick={() => handleShowMarkings(markingData)} />
          ) : (
            <EyeCloseIcon color={white} onClick={() => handleShowMarkings(markingData)} />
          )
        }
      </div>
    </div>
  );
};

export default MarkingCard;
