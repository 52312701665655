import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { DataLayerFileType } from 'store/repositoryfile/types';
import { GET_GEOSERVER_WMS_URL } from 'utils/apiUrls';

interface VectorWMSLayerComponentProps {
  file: DataLayerFileType;
}

const VectorWMSLayerComponent = ({ file }: VectorWMSLayerComponentProps) => {
  const map = useMap();
  const wmsLayerGroup = L.featureGroup();

  useEffect(() => {
    const wmsParams = {
      layers: file.gs_layer_name!,
      format: 'image/png',
      transparent: true,
      maxZoom: 20,
      CQL_FILTER: `file_id=${file.id}`,
      pane: 'overlayPane',
      opacity: 0.8
    };

    const wmslayer = L.tileLayer.wms(GET_GEOSERVER_WMS_URL, wmsParams).addTo(map);
    wmsLayerGroup.addLayer(wmslayer);
    wmsLayerGroup.addTo(map);

    // eslint-disable-next-line
    return () => {
      wmsLayerGroup.removeFrom(map);
    };
  }, [file]);

  return null;
};

export default VectorWMSLayerComponent;
