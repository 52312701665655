import type { UserRole } from 'store/users/types';

export type Response<T> = {
  data: T;
  status: boolean;
  message?: string;
};

export type PostResponse = {
  status: boolean;
  message: string;
};

export type Error = {
  message: string;
};

export type JWT = {
  iat: number;
  nbf: number;
  jti: string;
  exp: number;
  identity: string;
  fresh: boolean;
  type: string;
  user_claims: { role: UserRole; IsActive: boolean };
};

export enum Maptype {
  Roadmap = 'roadmap',
  satellite = 'satellite',
  terrain = 'terrain',
  Hybrid = 'hybrid'
}

export interface IconProps {
  color?: string;
  height?: number;
  width?: number;
  onClick?: () => void;
}

export interface OptionsTypeNumber {
  label: string;
  value: number;
}

export interface LatLongType {
  lat: number;
  lng: number;
}

export enum CoordinateType {
  Point = 'Point',
  MultiPoint = 'MultiPoint',
  Polygon = 'Polygon',
  MultiPolygon = 'MultiPolygon',
  LineString = 'LineString',
  MultilineString = 'MultiLineString'
}

export enum DrawType {
  Polygon = 'polygon',
  Point = 'marker',
  Polyline = 'polyline'
}

export interface PropertiesType {
  id: number;
  Blg_Name: string;
  Floors: number;
}

export interface CoordinatePointsType {
  coordinate: Array<Array<[number, number]>>;
  properties: PropertiesType | null;
}
export interface GeometryType {
  coordinates: any;
  type: string;
}

export interface ReformatPoints {
  points: CoordinatePointsType[] | [];
  type: CoordinateType;
  length: number;
}
