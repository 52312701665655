import { toast } from 'react-toastify';
import type { Response, Error } from 'types/common.types';
import { apiCall, fileUploadApicall, updateFileUploadApicall } from 'utils/connect';
import { ACCESS_TOKEN, TOKEN_EXPIRE_ERROR } from 'constants/common.constant';
import { getProjects } from 'store/projects/actions';
import {
  GET_ALL_ORGANIZATION_URL,
  ADD_ORGANIZATION_URL,
  GET_ORGANIZATION_MEMBERS_URL,
  GET_ORGANIZATION_URL,
  ADD_ORGANIZATION_MEMBERS_URL,
  EDIT_ORGANIZATION_URL,
  USER_DISABLE_ENABLE_URL
} from 'utils/apiUrls';
import { getAsyncStorageValue } from 'utils/localStorage';
import type { AppDispatch, RootState } from '..';
import {
  Organization,
  OrganizationPayload,
  OrganizationMember,
  EnableDisableUser,
  AddMemberPayload
} from './types';
import {
  setRequestingOrganizations,
  setRequestingOrganization,
  setOrganizations,
  setRequestingAddOrganization,
  setOrganizationMembers,
  setRequestingOrganizationMember,
  setSelectedOrganization,
  setNewRequestOrganizationMembers,
  setRequestingEnableDisableUser
} from '.';

export const getOrganizations = (token: string) => (dispatch: AppDispatch) => {
  try {
    dispatch(setRequestingOrganizations(true));

    const onSuccess = (response: Response<Array<Organization>>) => {
      dispatch(setOrganizations(response.data));
      dispatch(setRequestingOrganizations(false));
    };
    const onFailure = (error: any) => {
      if (error.message === TOKEN_EXPIRE_ERROR) {
        getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
          dispatch(getOrganizations(reToken));
        });
      } else {
        toast.error(error.message);
        dispatch(setRequestingOrganization(false));
      }
    };

    apiCall('GET', GET_ALL_ORGANIZATION_URL, '', onSuccess, onFailure, token);
  } catch (error: any) {
    dispatch(setRequestingOrganizations(false));
    toast.error(error.message);
  }
};

export const getOrganization = (orgId: number, token: string) => (dispatch: AppDispatch) => {
  try {
    dispatch(setRequestingOrganization(true));

    const onSuccess = (response: Response<Organization>) => {
      dispatch(setSelectedOrganization(response.data));
      dispatch(setRequestingOrganization(false));
    };
    const onFailure = (error: any) => {
      if (error.message === TOKEN_EXPIRE_ERROR) {
        getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
          dispatch(getOrganization(orgId, reToken));
        });
      } else {
        toast.error(error.message);
        dispatch(setRequestingOrganization(false));
      }
    };

    apiCall('GET', GET_ORGANIZATION_URL(orgId), '', onSuccess, onFailure, token);
  } catch (error: any) {
    dispatch(setRequestingOrganization(false));
    toast.error(error.message);
  }
};

export const addOrganization =
  (payload: OrganizationPayload, token: string, callback: Function, file?: File) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingAddOrganization(true));

      const onSuccess = () => {
        // toast.success('Organization created successfully!');
        dispatch(setRequestingAddOrganization(false));
        callback();
      };
      const onFailure = (error: any) => {
        if (error) {
          if (error.message === TOKEN_EXPIRE_ERROR) {
            getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
              dispatch(addOrganization(payload, reToken, callback, file));
            });
          } else {
            toast.error(error.message);
            dispatch(setRequestingAddOrganization(false));
          }
        } else {
          toast.error('Something went wrong');
        }
      };

      fileUploadApicall(ADD_ORGANIZATION_URL(payload), onSuccess, onFailure, token, file);
    } catch (error: any) {
      dispatch(setRequestingAddOrganization(false));
      toast.error(error.message);
    }
  };

export const editOrganization =
  (orgName: string, orgId: number, token: string, callback: Function, file?: File) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingAddOrganization(true));

      const onSuccess = (response: Response<boolean>) => {
        // toast.success('Organization created successfully!');
        dispatch(getOrganization(orgId, token));
        dispatch(getOrganizations(token));
        dispatch(setRequestingAddOrganization(false));
        callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(editOrganization(orgName, orgId, reToken, callback, file));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingAddOrganization(false));
        }
      };

      updateFileUploadApicall(
        EDIT_ORGANIZATION_URL(orgId, orgName),
        onSuccess,
        onFailure,
        file,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingAddOrganization(false));
      toast.error(error.message);
    }
  };

export const getOrganizationMembers = (orgId: number, token: string) => (dispatch: AppDispatch) => {
  try {
    dispatch(setRequestingOrganizationMember(true));

    const onSuccess = (response: Response<Array<OrganizationMember>>) => {
      dispatch(setOrganizationMembers(response.data));
      dispatch(setRequestingOrganizationMember(false));
    };
    const onFailure = (error: any) => {
      if (error.message === TOKEN_EXPIRE_ERROR) {
        getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
          dispatch(getOrganizationMembers(orgId, reToken));
        });
      } else {
        toast.error(error.message);
        dispatch(setRequestingOrganizationMember(false));
      }
    };

    apiCall('GET', GET_ORGANIZATION_MEMBERS_URL(orgId), '', onSuccess, onFailure, token);
  } catch (error: any) {
    dispatch(setRequestingOrganizationMember(false));
    toast.error(error.message);
  }
};

export const addOrganizationMember =
  (payload: AddMemberPayload, orgId: number, token: string, callback?: Function) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setNewRequestOrganizationMembers(true));

      const onSuccess = (response: Response<boolean>) => {
        // toast.success('Member added successfully');
        dispatch(getOrganizationMembers(orgId, token));
        if (callback) callback(response.status);
        dispatch(setNewRequestOrganizationMembers(false));
      };
      const onFailure = (error: any) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addOrganizationMember(payload, orgId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setNewRequestOrganizationMembers(false));
        }
      };

      apiCall(
        'POST',
        ADD_ORGANIZATION_MEMBERS_URL(orgId),
        { ...payload },
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingAddOrganization(false));
      toast.error(error.message);
    }
  };

export const enableDisableUser =
  (payload: EnableDisableUser, orgId: number, isActive: boolean, token: string) =>
  (dispatch: AppDispatch, getState: RootState) => {
    try {
      // @ts-ignore
      const state = getState();
      dispatch(setRequestingEnableDisableUser(true));

      const onSuccess = (response: Response<boolean>) => {
        if (response.status) {
          dispatch(
            setSelectedOrganization({
              ...state.organizations.selectedOrganization,
              active: isActive
            })
          );
        }
        dispatch(getProjects(orgId, token));
        dispatch(getOrganizationMembers(orgId, token));
        dispatch(setRequestingEnableDisableUser(false));
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(enableDisableUser(payload, orgId, isActive, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingEnableDisableUser(false));
        }
      };

      apiCall('PUT', USER_DISABLE_ENABLE_URL(orgId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingEnableDisableUser(false));
      toast.error(error.message);
    }
  };
