import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Space, Tooltip, Typography } from 'antd';
import { FeatureGroup, MapContainer, Marker, Popup } from 'react-leaflet';
import { join } from 'lodash';
import LeftArrowIcon from 'assets/svg/LeftArrowIcon';
import ListIcon from 'assets/svg/ListIcon';
import MapViewIcon from 'assets/svg/MapViewIcon';
import { ACCESS_TOKEN, defaultCenter } from 'constants/common.constant';
import { PointYellowIcon } from 'constants/map.constants';
import { routes } from 'constants/pageRoutes.constants';
import MapActionControl from 'components/Map/MapActionControl';
import MapLayers from 'components/Map/MapLayers';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { getProjects } from 'store/projects/actions';
import { setSelectedProject } from 'store/projects';
import { Project, ProjectCategoryTypes } from 'store/projects/types';
import { Maptype } from 'types/common.types';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const { Text } = Typography;

const MapView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const allDataFeatureGroupRef: any = useRef();
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const { projects } = useAppSelector((state) => state.projects);
  const [mapType, setMapType] = useState<string>(Maptype.Roadmap);

  useEffect(() => {
    if (selectedOrganization) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getProjects(selectedOrganization.id, token));
      });
    }
  }, []);

  const handleBackNavigate = () => {
    navigate(`${routes.ProjectList}?organization_id=${selectedOrganization?.id}`);
  };

  const handleViewProject = (project: Project) => {
    dispatch(setSelectedProject(project));
    if (
      project &&
      (project.type === ProjectCategoryTypes.VegetationManagementINTL ||
        project.type === ProjectCategoryTypes.VegetationManagementLite)
    ) {
      navigate(
        `${routes.ProjectViewVegInt}?organization_id=${selectedOrganization?.id}&project_id=${project.id}`
      );
    } else {
      navigate(
        `${routes.ProjectView}?organization_id=${selectedOrganization?.id}&project_id=${project.id}`
      );
    }
  };

  const handleMapType = (type: string) => {
    setMapType(type);
  };

  const mapContainer = useMemo(() => {
    return (
      <MapContainer center={defaultCenter} zoom={5} zoomControl={false} className="map-container">
        <FeatureGroup ref={allDataFeatureGroupRef}>
          {projects &&
            projects.length > 0 &&
            projects.map((data: Project) => {
              const coords: any = data.coordinates;
              return (
                <Marker icon={PointYellowIcon} key={data.id} position={coords}>
                  <Popup className="polygon-popup mapview-popup" closeButton={false}>
                    <Space direction="vertical">
                      <Space direction="vertical">
                        <Text className="tooltip-title" style={{ fontFamily: 'Manrope' }}>
                          PROJECT ID
                        </Text>
                        <Text className="tooltip-content" style={{ fontFamily: 'Manrope' }}>
                          {data.id}
                        </Text>
                      </Space>
                      <Space direction="vertical">
                        <Text className="tooltip-title" style={{ fontFamily: 'Manrope' }}>
                          PROJECT NAME
                        </Text>
                        <Text className="tooltip-content" style={{ fontFamily: 'Manrope' }}>
                          {data.name}
                        </Text>
                      </Space>
                      <Space direction="vertical">
                        <Text className="tooltip-title" style={{ fontFamily: 'Manrope' }}>
                          TYPE OF PROJECT
                        </Text>
                        <Text className="tooltip-content" style={{ fontFamily: 'Manrope' }}>
                          {data.type}
                        </Text>
                      </Space>
                      <Space direction="vertical">
                        <Text className="tooltip-title" style={{ fontFamily: 'Manrope' }}>
                          COORDINATES
                        </Text>
                        <Text className="tooltip-content" style={{ fontFamily: 'Manrope' }}>
                          {coords && coords.length > 0 ? join(coords, ',') : '-'}
                        </Text>
                      </Space>
                      <Button
                        style={{ padding: '4px 75.5px', marginTop: 8 }}
                        onClick={() => handleViewProject(data)}
                      >
                        View Project
                      </Button>
                    </Space>
                  </Popup>
                </Marker>
              );
            })}
        </FeatureGroup>
        <MapLayers maptype={mapType} />
        <MapActionControl
          mapType={mapType}
          boundRef={allDataFeatureGroupRef}
          handleMapType={handleMapType}
          classname="map-view-action-control"
          selectedRiskData={null}
        />
      </MapContainer>
    );
  }, [projects, mapType]);

  return (
    <div className="main-map-view">
      <Row className="map-view-head">
        <Col onClick={handleBackNavigate}>
          <Button
            icon={<LeftArrowIcon color="black" />}
            type="text"
            shape="circle"
            className="back-icon"
          />
          <Text>back</Text>
        </Col>
        <Col>
          <Text>{selectedOrganization ? selectedOrganization.name : ''}</Text>
        </Col>
        <Col>
          <Button.Group>
            <Tooltip
              placement="bottom"
              overlayClassName="tooltip-title"
              title={<Text>List View</Text>}
            >
              <Button
                onClick={() =>
                  navigate(`${routes.ProjectList}?organization_id=${selectedOrganization?.id}`)
                }
                icon={<ListIcon color="#231F20" />}
              />
            </Tooltip>
            <Tooltip
              placement="bottomRight"
              overlayClassName="tooltip-title"
              title={<Text>Map View</Text>}
            >
              <Button type="primary" icon={<MapViewIcon />} />
            </Tooltip>
          </Button.Group>
        </Col>
      </Row>
      <div className="map-content">{mapContainer}</div>
    </div>
  );
};

export default MapView;
