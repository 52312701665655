import { green, green1, orange, orange1, red, red1 } from './color.constant';

// Async variable names.
export const ACCESS_TOKEN = 'SKIES_EXP_ACCESS_TOKEN';
export const REFRESH_TOKEN = 'SKIES_EXP_REFRESH_TOKEN';
export const AES_KEY = 'SKIES_EXP_AES_KEY';
export const USER_ROLE = 'SKIES_EXP_USER_ROLE';

// error
export const TOKEN_EXPIRE_ERROR = 'Token Already Expired';

// Date format
export const DateFormat1 = 'Do MMMM YYYY';
export const DateFormat2 = 'D MMM YYYY';
export const DateFormat3 = 'YYYY-MM-DD';
export const DateFormat4 = 'D MMM YYYY, HH:MM:SS';
export const DateFormat5 = 'MM/DD/YYYY HH:MM:SS';
export const DateFormat6 = 'DD MMMM YYYY';
export const DayMonthFormat = 'DD MMMM';
export const YearFormat = 'YYYY';
export const TimeFormat = 'HH:mm:ss';

// Google map
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GMAP_KEY;
export const defaultCenter: any = [20.5937, 78.9629];
export const mapStyleWithoutLabel = [{ elementType: 'labels', stylers: [{ visibility: 'off' }] }];

export interface ProjectTabType {
  title: string;
  value: string;
}

export const elevetion: number[] = [0, 50, 100, 200];
export const MONOCHROME = 'monochrome';
export const HILL_SHADE = 'hs';
export const JET = 'jet';

export enum ProjectViewTabKey {
  FEATURES = 'features',
  LAYERS = 'layers',
  ANNOTATIONS = 'annotations',
  RISKANALYSIS = 'risk analysis'
}

export const projectViewTabs: ProjectTabType[] = [
  { title: 'Features', value: 'features' },
  { title: 'Layers', value: 'layers' }
];

export const projectViewTabs1: ProjectTabType[] = [
  { title: 'Features', value: 'features' },
  { title: 'Layers', value: 'layers' },
  { title: 'Annotations', value: 'annotations' }
];

export const projectViewTabs2: ProjectTabType[] = [
  { title: 'Features', value: 'features' },
  { title: 'Layers', value: 'layers' },
  { title: 'Risk Analysis', value: 'risk analysis' }
];

export const projectCompareViewTabs: ProjectTabType[] = [{ title: 'Features', value: 'features' }];

export enum RiskCategory {
  med = 'med',
  low = 'low',
  high = 'high'
}

export enum RiskPruningStatus {
  inprogress = 'In Progress',
  completed = 'Completed',
  todo = 'To Do'
}

export const RiskcatColor: any = {
  low: green1,
  med: orange1,
  high: red1,
  No_risk: green1,
  Proximity: red1,
  HighRisk: orange1,
  LowOrNoRisk: green1
};

export const RiskLineColor: any = {
  low: green,
  med: orange,
  high: red,
  No_risk: green,
  Proximity: red,
  HighRisk: orange,
  LowOrNoRisk: green
};

export const vectorColors: string[] = [
  '#043E95',
  '#1F76B5',
  '#1DA7C6',
  '#67C9D7',
  '#A7E1EA',
  '#909090',
  '#9C27DD',
  '#267300',
  '#0070D7',
  '#C25D00',
  '#C98544',
  '#CD00B9',
  '#0066FF',
  '#6398FE',
  '#00DE9D',
  '#00FFFF',
  '#D20000',
  '#A10000'
];
