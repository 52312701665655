import { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  Upload,
  UploadProps
} from 'antd';
import { capitalize, isEmpty, upperFirst } from 'lodash';
import EditIcon from 'assets/svg/EditIcon';
import { white } from 'constants/color.constant';
import { ACCESS_TOKEN } from 'constants/common.constant';
import RequiredTitle from 'components/core/RequiredTitle';
import { useAppDispatch } from 'hooks/useReduxHook';
import { addUserDetail, addUserPicture } from 'store/users/actions';
import { UserEditProfilePayload, UserInfo } from 'store/users/types';
import getCroppedImg, { dataURLtoFile } from 'utils/cropImage';
import { decryptValue, encryptValue } from 'utils/decodeEncodeData';
import { getAsyncStorageValue } from 'utils/localStorage';
import { shortLabel } from 'utils/utils';

interface ProfileTabProps {
  isRequestingUserEdit: boolean;
  isRequestingUserPicture: boolean;
  userInfo: UserInfo;
}
const { Text } = Typography;

const ProfileTab = ({
  userInfo,
  isRequestingUserEdit,
  isRequestingUserPicture
}: ProfileTabProps) => {
  const dispatch = useAppDispatch();
  const [profileForm] = Form.useForm();
  const [inputReadOnly, setInputReadOnly] = useState(true);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [compressName, setCompressName] = useState<string | null>(null);
  const [isOpenCropModal, setOpenCropModal] = useState<boolean>(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [file, setFile] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [croppedImage, setCroppedImage] = useState<string | any>('');
  const [profileName, setProfileName] = useState<string>('');

  const uploadProps: UploadProps = {
    maxCount: 1,
    beforeUpload: (file: File) => {
      setFile(URL.createObjectURL(file));
      setOpenCropModal(true);
      setProfileName(profileForm.getFieldValue('department'));
      return false;
    },
    showUploadList: false
  };

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      setCroppedImage(userInfo.pic_url);
      if (userInfo.first_name && userInfo.last_name) {
        setFirstName(upperFirst(decryptValue(userInfo.first_name)));
        setLastName(upperFirst(decryptValue(userInfo.last_name)));
        setCompressName(
          shortLabel(`${decryptValue(userInfo.first_name)} ${decryptValue(userInfo.last_name)}`)
        );
      } else {
        setFirstName(upperFirst(decryptValue(userInfo.email)));
        setCompressName(capitalize(userInfo.email.charAt(0)));
      }
    }
  }, [userInfo]);

  useEffect(() => {
    profileForm.setFieldsValue({
      fname: firstName,
      lname: lastName,
      email: userInfo && userInfo.email && decryptValue(userInfo.email),
      empId: userInfo && userInfo.empId && userInfo.empId,
      department: userInfo && userInfo.department && userInfo.department,
      phoneno: userInfo && userInfo.phoneno && decryptValue(userInfo.phoneno)
    });
  }, [firstName, lastName, compressName]);

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCroppedImage = useCallback(async () => {
    const croppedImage: any = await getCroppedImg(file, croppedAreaPixels);
    const filename = profileName ? profileName.replace(/[^a-zA-Z]/g, '') : 'newprofilepic';
    const cropFile = await dataURLtoFile(croppedImage, filename);

    setSelectedFile(cropFile);
    setCroppedImage(croppedImage);
    setInputReadOnly(false);
    setOpenCropModal(false);
  }, [croppedAreaPixels]);

  const handleCancelCropped = () => {
    setOpenCropModal(false);
    setFile('');
    setSelectedFile(undefined);
    setCroppedImage('');
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  const handleEditProfile = (e: any) => {
    e.preventDefault();
    setInputReadOnly(false);
  };

  const handleDiscardEditProfile = (e: any) => {
    e.preventDefault();
    setInputReadOnly(true);
    setFile('');
    setSelectedFile(undefined);
    setCroppedImage('');
    setOpenCropModal(false);
  };

  const handleSuccessCallback = (status: boolean) => {
    if (status) {
      setInputReadOnly(true);
    }
  };

  const handleSaveProfile = (values: UserEditProfilePayload) => {
    const payload: UserEditProfilePayload = {
      fname: encryptValue(values.fname),
      lname: encryptValue(values.lname),
      empId: values.empId,
      department: values.department,
      phoneno: values.phoneno && encryptValue(values.phoneno)
    };
    if (selectedFile) {
      getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
        dispatch(addUserPicture(userInfo.id, reToken, selectedFile));
      });
    }
    getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
      dispatch(addUserDetail(payload, userInfo.id, reToken, handleSuccessCallback));
    });
  };

  return (
    <Row className="tab-inr-content-row">
      <Col span={5}>
        <div className="user-avatar">
          <Badge
            count={
              <div className="user-edit">
                <Upload {...uploadProps} accept="image/png, image/svg+xml, image/jpeg, image/jpg">
                  <EditIcon color={white} />
                </Upload>
              </div>
            }
          >
            {croppedImage ? (
              <Avatar src={croppedImage} size={170} />
            ) : (
              <Avatar size={170}>{compressName || '-'}</Avatar>
            )}
          </Badge>
        </div>
        <Text style={{ display: 'block', marginTop: '15px', textAlign: 'center' }}>
          {firstName || '-'} {lastName}
        </Text>
      </Col>
      <Col span={2} style={{ padding: '20px' }}>
        <Divider type="vertical" style={{ borderColor: '#E0E0E0', top: '0', height: '386px' }} />
      </Col>
      <Col span={15}>
        <Form className="member-form" form={profileForm} onFinish={handleSaveProfile}>
          <div className="member-form-name">
            <div style={{ width: '47%' }}>
              <RequiredTitle className="member-label" title="First Name" isRequired />
              <Form.Item
                name="fname"
                rules={[{ required: true, message: 'Enter first name' }]}
                className="member-form-item"
              >
                <Input readOnly={inputReadOnly} className="member-input" placeholder="First Name" />
              </Form.Item>
            </div>
            <div style={{ width: '47%' }}>
              <RequiredTitle className="member-label" title="Last Name" isRequired />
              <Form.Item
                name="lname"
                rules={[{ required: true, message: 'Enter last name' }]}
                className="member-form-item"
              >
                <Input readOnly={inputReadOnly} className="member-input" placeholder="Last Name" />
              </Form.Item>
            </div>
          </div>
          <div className="member-form-name">
            <div style={{ width: '47%' }}>
              <RequiredTitle className="member-label" title="Email Address" isRequired />
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Enter email' }]}
                className="member-form-item"
              >
                <Input readOnly className="member-input" placeholder="Email Address" />
              </Form.Item>
            </div>
            <div style={{ width: '47%' }}>
              <RequiredTitle className="member-label" title="Employee ID" isRequired />
              <Form.Item
                name="empId"
                rules={[{ required: true, message: 'Enter employee id ' }]}
                className="member-form-item"
              >
                <Input
                  readOnly={inputReadOnly}
                  className="member-input"
                  placeholder="Employee ID"
                />
              </Form.Item>
            </div>
          </div>
          <div className="member-form-name">
            <div style={{ width: '47%' }}>
              <RequiredTitle className="member-label" title="Department" isRequired />
              <Form.Item
                rules={[{ required: true, message: 'Enter department ' }]}
                name="department"
                className="member-form-item"
              >
                <Input readOnly={inputReadOnly} className="member-input" placeholder="Department" />
              </Form.Item>
            </div>
            <div style={{ width: '47%' }}>
              <RequiredTitle className="member-label" title="Phone Number" isRequired />
              <Form.Item
                rules={[{ required: true, message: 'Enter phone number' }]}
                name="phoneno"
                className="member-form-item"
              >
                <Input
                  readOnly={inputReadOnly}
                  className="member-input"
                  placeholder="Phone Number"
                  minLength={10}
                  maxLength={10}
                />
              </Form.Item>
            </div>
          </div>
          <div className="member-form-btn">
            {inputReadOnly ? (
              <Button
                className="member-btn"
                type="primary"
                htmlType="button"
                onClick={handleEditProfile}
              >
                Edit Profile
              </Button>
            ) : (
              <>
                <Button
                  className="member-btn"
                  type="primary"
                  style={{ padding: '4px 31px' }}
                  htmlType="submit"
                  loading={isRequestingUserEdit || isRequestingUserPicture}
                >
                  Save changes
                </Button>
                <Button
                  className="member-btn"
                  type="text"
                  htmlType="button"
                  onClick={handleDiscardEditProfile}
                >
                  Discard changes
                </Button>
              </>
            )}
          </div>
        </Form>
      </Col>
      <Modal
        key="userProfile"
        open={isOpenCropModal}
        centered
        width={700}
        title="Crop Picture"
        closable
        className="crop-image-modal"
        footer={[
          <input
            key={22}
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e: any) => {
              setZoom(e.target.value);
            }}
            className="zoom-range"
          />,
          <div className="crop-modal-btn" key={33}>
            <Button type="ghost" onClick={handleCancelCropped}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleCroppedImage}>
              Save
            </Button>
          </div>
        ]}
      >
        <div className="cropper-content" key={11}>
          <div className="crop-container">
            <Cropper
              image={file}
              crop={crop}
              zoom={zoom}
              maxZoom={3}
              aspect={16 / 9}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
        </div>
      </Modal>
    </Row>
  );
};

export default ProfileTab;
