import { userSlice } from './slice';

export const {
  setRsaRequest,
  setRSA,
  setLoginRequest,
  setUser,
  setLogoutRequest,
  setRequestingRefreshToken,
  setRequestingResetPassword,
  setResetPassword,
  setRequestingUserDetail,
  setUserInfo,
  setRequestingUserEdit,
  setRequestingUserPicture,
  setRequestingUserResetPassword
} = userSlice.actions;
export default userSlice.reducer;
