import { find, isEmpty, remove, reverse, take, xor } from 'lodash';
import { Fragment, useState } from 'react';
import { Tooltip, Typography } from 'antd';
import { BiPlus } from 'react-icons/bi';
import { BsThreeDots } from 'react-icons/bs';
import { white } from 'constants/color.constant';
import { USER_ROLE } from 'constants/common.constant';
import MemberListModal from 'components/Modals/MemberList/MemberListModal';
import { useAppSelector } from 'hooks/useReduxHook';
import { Project } from 'store/projects/types';
import { UserRole } from 'store/users/types';
import { MemberT } from 'store/organizations/types';
import { getTeamMembers } from 'store/projects';
import { shortLabel } from 'utils/utils';
import { getStorageValue } from 'utils/localStorage';
import './styles.less';

const { Text } = Typography;

interface AvatarListProps {
  project: Project;
  handleAddProjectMemberForm: Function;
  className?: string;
}

const AvatarList = ({ project, handleAddProjectMemberForm, className }: AvatarListProps) => {
  const { user } = useAppSelector((state) => state.user);
  const userRole = getStorageValue(USER_ROLE);
  const [isOpenProjectMemberListModal, setOpenProjectMemberListModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const teamMembers = project.team_members;
  const managers = getTeamMembers(teamMembers);
  const currentUser = user?.id;
  const loginUser = find(managers, { value: currentUser });

  remove(managers, (data: MemberT) => data.value === currentUser);

  let memberNum = 2;
  let hasMoreMembers: Array<MemberT> | [] = [];
  const managerMembers = take(managers, memberNum);

  if (userRole !== UserRole.SuperAdmin) {
    if (!isEmpty(managers) && managers.length > 4) {
      memberNum = 3;
    } else {
      memberNum = 4;
    }
  }

  if (managers && managers.length > 0) {
    hasMoreMembers = xor(managers, managerMembers);
  }

  const handleMoreMembers = (project: Project) => {
    setOpenProjectMemberListModal(true);
    setSelectedProject(project);
  };

  const handleCloseModal = () => {
    setOpenProjectMemberListModal(false);
    setSelectedProject(null);
  };

  return (
    <div className={`avatars ${className}`}>
      {userRole === UserRole.SuperAdmin && (
        <Tooltip
          title="Add members to project"
          color={white}
          placement="bottom"
          overlayClassName="avtar-tooltip"
        >
          <Text className="avatar" onClick={() => handleAddProjectMemberForm(project)}>
            <div className="last-div">
              <Text>
                <BiPlus />
              </Text>
            </div>
          </Text>
        </Tooltip>
      )}
      {!isEmpty(hasMoreMembers) && (
        <Tooltip
          title="Show more member in this project name"
          color={white}
          placement="bottom"
          overlayClassName="avtar-tooltip"
        >
          <Text className="avatar" onClick={() => handleMoreMembers(project)}>
            <div className="last-div">
              <Text>
                <BsThreeDots color="#D9D9D9" size={19} />
              </Text>
            </div>
          </Text>
        </Tooltip>
      )}
      {managerMembers?.length > 0 &&
        reverse(managerMembers).map((item: MemberT, i: number) => {
          return (
            <Fragment key={i}>
              <Tooltip
                title={
                  <div className="avtars-name">
                    <Text>{item.label}</Text>
                    <Text>{item.role}</Text>
                  </div>
                }
                color={white}
                placement="bottom"
                overlayClassName="avtar-tooltip"
              >
                <Text className="avatar">
                  <div className={item.value === currentUser ? 'first-div' : ''}>
                    <Text>{shortLabel(item.label)}</Text>
                  </div>
                </Text>
              </Tooltip>
            </Fragment>
          );
        })}
      {loginUser && (
        <Tooltip
          title={
            <div className="avtars-name">
              <Text>{loginUser.label}</Text>
              <Text>{loginUser.role}</Text>
            </div>
          }
          color={white}
          placement="bottom"
          overlayClassName="avtar-tooltip"
        >
          <Text className="avatar">
            <div className={loginUser.value === currentUser ? 'first-div' : ''}>
              <Text>{shortLabel(loginUser.label)}</Text>
            </div>
          </Text>
        </Tooltip>
      )}
      <MemberListModal
        isOpen={isOpenProjectMemberListModal}
        selectedProject={selectedProject}
        managers={managers}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

AvatarList.defaultProps = {
  className: ''
};

export default AvatarList;
