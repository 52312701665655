import { Maptype } from 'types/common.types';
import { LayersControl, Pane } from 'react-leaflet';
import GoogleMapLayer from './GoogleMapLayer';

const { BaseLayer } = LayersControl;
interface MapLayersProps {
  maptype?: string;
}

const MapLayers = ({ maptype }: MapLayersProps) => {
  return (
    <Pane name="baseMapLayerPane" style={{ zIndex: 100 }}>
      <LayersControl position="topright">
        <BaseLayer checked={maptype === Maptype.Roadmap} name="Google Maps Roads">
          <GoogleMapLayer maptype={Maptype.Roadmap} />
        </BaseLayer>
        <BaseLayer checked={maptype === Maptype.satellite} name="Google Maps Satellite">
          <GoogleMapLayer maptype={Maptype.satellite} />
        </BaseLayer>
      </LayersControl>
    </Pane>
  );
};

MapLayers.defaultProps = {
  maptype: Maptype.Roadmap
};

export default MapLayers;
