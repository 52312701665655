import { createSelector } from '@reduxjs/toolkit';
import { filter, find } from 'lodash';
import { RootState } from '..';
import { riskAnalysisSlice } from './slice';
import { RiskLinesType, TransmissionSegmentType } from './types';

export const {
  setRequestingRiskanalysis,
  setRiskTrees,
  setRiskBuffer,
  setRiskTowers,
  setRiskTransmissionLine,
  setriskTransmissionSegments,
  setRiskLines,
  clearRiskAnalysis
} = riskAnalysisSlice.actions;
export default riskAnalysisSlice.reducer;

const riskLinesData = (state: RootState) => state.riskanalysis.riskLines;
const riskSegmentsData = (state: RootState) => state.riskanalysis.riskTransmissionSegments;
const selectedRiskSegmentData = (
  state: RootState,
  riskSegmentData: TransmissionSegmentType | null
) => riskSegmentData;
const selectedSegTreeRisk = (state: RootState, riskcats: string[] | []) => riskcats;

export const getSelectedRiskLines = createSelector(
  [riskLinesData, selectedSegTreeRisk],
  (riskLines, riskCats) => {
    if (riskLines && riskLines.length === 0) {
      return [];
    }

    // @ts-ignore
    return filter(riskLines, (line: RiskLinesType) => riskCats.includes(line?.risk_cat));
  }
);

export const getSelectedRiskSegmentsData = createSelector(
  [riskSegmentsData, selectedRiskSegmentData],
  (segments, selectedSegment) => {
    if (segments && segments.length === 0) {
      return null;
    }
    if (!selectedSegment) {
      return null;
    }

    return find(
      segments,
      (segment: TransmissionSegmentType) =>
        segment.name === selectedSegment.name || segment.id === selectedSegment.id
    );
  }
);
