import 'leaflet-kml';
import { Fragment, memo, useMemo } from 'react';
import { filter, includes, isEmpty, upperCase } from 'lodash';
import { Space, Typography } from 'antd';
import { Polygon, Polyline, Popup, CircleMarker } from 'react-leaflet';
import VectorWMSLayerComponent from 'components/Map/VectorWMSLayerComponent';
import { DataLayerFileType } from 'store/repositoryfile/types';
import { CoordinateType } from 'types/common.types';

const { Text } = Typography;

interface RenderVectorLayerProps {
  vectorRepoFiles: Array<DataLayerFileType>;
  vectorLayerIds?: Array<number> | [];
}

const RenderVectorLayer = ({ vectorRepoFiles, vectorLayerIds }: RenderVectorLayerProps) => {
  const allVectorLayers = useMemo(() => {
    if (vectorLayerIds && vectorLayerIds.length === 0) return null;

    const vectorLayerFiles = filter(vectorRepoFiles, (file: DataLayerFileType) =>
      includes(vectorLayerIds, file.id)
    );

    return vectorLayerFiles.map((file, i) => {
      if (file.gs_layer_name) {
        return <VectorWMSLayerComponent key={i} file={file} />;
      }
      if (file.shapType === CoordinateType.Point || file.shapType === CoordinateType.MultiPoint) {
        return (
          file.coordinates &&
          // @ts-ignore
          file.coordinates.map((data: any, i: number) => {
            if (data.coordinate && data.coordinate.length > 0) {
              return (
                <CircleMarker
                  key={i}
                  center={data && data.coordinate}
                  pane="shadowPane"
                  radius={3}
                  pathOptions={{
                    color: file.color,
                    fillColor: file.color
                  }}
                >
                  {!isEmpty(data.properties) ? (
                    <Popup className="polygon-popup">
                      <Space direction="vertical">
                        {Object.keys(data.properties).map((key: any) => (
                          <Space direction="vertical" key={key}>
                            <Text className="tooltip-title" style={{ fontFamily: 'Manrope' }}>
                              {upperCase(key).replace(/[^a-zA-Z0-9 ]/g, ' ')}
                            </Text>
                            <Text className="tooltip-content" style={{ fontFamily: 'Manrope' }}>
                              {data.properties[key] || '-'}
                            </Text>
                          </Space>
                        ))}
                      </Space>
                    </Popup>
                  ) : (
                    <Popup className="polygon-popup">
                      <Space style={{ display: 'flex', justifyContent: 'center' }}>
                        <Text className="tooltip-content" style={{ fontFamily: 'Manrope' }}>
                          No data found
                        </Text>
                      </Space>
                    </Popup>
                  )}
                </CircleMarker>
              );
            }

            return null;
          })
        );
      }
      if (
        file.shapType === CoordinateType.Polygon ||
        file.shapType === CoordinateType.MultiPolygon
      ) {
        return (
          file.coordinates &&
          // @ts-ignore
          file.coordinates.map((data: any, i: number) => {
            return (
              <Polygon
                key={i}
                // @ts-ignore
                positions={data && data.coordinate}
                pathOptions={{
                  color: file.color,
                  fillColor: file.color,
                  fillOpacity: 0.3,
                  weight: 1
                }}
                pane="shadowPane"
              >
                {!isEmpty(data.properties) ? (
                  <Popup className="polygon-popup">
                    <Space direction="vertical">
                      {Object.keys(data.properties).map((key: any) => (
                        <Space direction="vertical" key={key}>
                          <Text className="tooltip-title" style={{ fontFamily: 'Manrope' }}>
                            {upperCase(key).replace(/[^a-zA-Z0-9 ]/g, ' ')}
                          </Text>
                          <Text className="tooltip-content" style={{ fontFamily: 'Manrope' }}>
                            {data.properties[key] || '-'}
                          </Text>
                        </Space>
                      ))}
                    </Space>
                  </Popup>
                ) : (
                  <Popup className="polygon-popup">
                    <Space style={{ display: 'flex', justifyContent: 'center' }}>
                      <Text className="tooltip-content" style={{ fontFamily: 'Manrope' }}>
                        No data found
                      </Text>
                    </Space>
                  </Popup>
                )}
              </Polygon>
            );
          })
        );
      }
      if (
        file.shapType === CoordinateType.MultilineString ||
        file.shapType === CoordinateType.LineString
      ) {
        return (
          file.coordinates &&
          // @ts-ignore
          file.coordinates.map((data: any, i: number) => {
            return (
              <Polyline
                key={i}
                // @ts-ignore
                positions={data && data.coordinate}
                pathOptions={{
                  color: file.color,
                  fillColor: file.color,
                  fillOpacity: 0.3,
                  weight: 1
                }}
                pane="shadowPane"
              >
                {!isEmpty(data.properties) ? (
                  <Popup className="polygon-popup">
                    <Space direction="vertical">
                      {Object.keys(data.properties).map((key: any) => (
                        <Space direction="vertical" key={key}>
                          <Text className="tooltip-title" style={{ fontFamily: 'Manrope' }}>
                            {upperCase(key).replace(/[^a-zA-Z0-9 ]/g, ' ')}
                          </Text>
                          <Text className="tooltip-content" style={{ fontFamily: 'Manrope' }}>
                            {data.properties[key] || '-'}
                          </Text>
                        </Space>
                      ))}
                    </Space>
                  </Popup>
                ) : (
                  <Popup className="polygon-popup">
                    <Space style={{ display: 'flex', justifyContent: 'center' }}>
                      <Text className="tooltip-content" style={{ fontFamily: 'Manrope' }}>
                        No data found
                      </Text>
                    </Space>
                  </Popup>
                )}
              </Polyline>
            );
          })
        );
      }

      return (
        <Polyline
          key={i}
          pane="shadowPane"
          // @ts-ignore
          positions={file.coordinates ? file.coordinates : []}
          pathOptions={{ color: file.color, fillColor: file.color }}
        />
      );
    });
  }, [vectorLayerIds, vectorRepoFiles]);

  // eslint-disable-next-line
  return <>{allVectorLayers}</>;
};

RenderVectorLayer.defaultProps = {
  vectorLayerIds: []
};

export default memo(RenderVectorLayer);
