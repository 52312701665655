import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Outlet } from 'react-router-dom';
import MainHeader from 'components/MainHeader';

const Layouts = () => {
  return (
    <Layout>
      <MainHeader />
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default Layouts;
