import { createSlice } from '@reduxjs/toolkit';
import { RepoFileState } from './types';

const initialState: RepoFileState = {
  isLoadingDataLayers: false,
  isRequestingAddDateDataLayer: false,
  isRequestingEditDateDataLayer: false,
  isRequestingFileTypes: false,
  isRequestingAddRepoFileDataLayer: false,
  isRequestingEditRepoFile: false,
  isRequestingDeleteDateDataLayer: false,
  isRequestingDeleteRepoFile: false,
  isRequestingDownloadRepoFile: false,
  isRequestingGeoserverLayerStyles: false,
  isEnableDisableDatalayer: false,
  dataLayers: [],
  fileTypes: [],
  geoserverLayerStyles: []
};

export const repoFileSlice = createSlice({
  name: 'repositoryFiles',
  initialState,
  reducers: {
    setRequestingDataLayers: (state, action) => {
      state.isLoadingDataLayers = action.payload;
    },
    setDataLayers: (state, action) => {
      state.dataLayers = action.payload;
    },
    setRequestAddDateDataLayers: (state, action) => {
      state.isRequestingAddDateDataLayer = action.payload;
    },
    setRequestEditDateDataLayers: (state, action) => {
      state.isRequestingEditDateDataLayer = action.payload;
    },
    setRequestDeleteDateDataLayers: (state, action) => {
      state.isRequestingDeleteDateDataLayer = action.payload;
    },
    setRequestFileTypes: (state, action) => {
      state.isRequestingFileTypes = action.payload;
    },
    setFileTypes: (state, action) => {
      state.fileTypes = action.payload;
    },
    setRequestAddRepoFileDataLayers: (state, action) => {
      state.isRequestingAddRepoFileDataLayer = action.payload;
    },
    setRequestEditRepoFile: (state, action) => {
      state.isRequestingEditRepoFile = action.payload;
    },
    setRequestingDeleteRepoFile: (state, action) => {
      state.isRequestingDeleteRepoFile = action.payload;
    },
    setRequestingDownloadRepoFile: (state, action) => {
      state.isRequestingDownloadRepoFile = action.payload;
    },
    setRequestingGeoserverLayerStyles: (state, action) => {
      state.isRequestingGeoserverLayerStyles = action.payload;
    },
    setGeoserverLayerStyles: (state, action) => {
      state.geoserverLayerStyles = action.payload;
    },
    setEnableDisableDatalayer: (state, action) => {
      state.isEnableDisableDatalayer = action.payload;
    },
    clearRepositoryFiles: () => {
      return initialState;
    }
  }
});
