import { useEffect, useState } from 'react';
import moment from 'moment';
import { orderBy } from 'lodash';
import { toast } from 'react-toastify';
import { Button, DatePicker, DatePickerProps, Dropdown, MenuProps, Modal, Typography } from 'antd';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import CloseIcon from 'assets/svg/CloseIcon';
import ThreeDotIcon from 'assets/svg/ThreeDotIcon';
import { black, primary, white } from 'constants/color.constant';
import { items1, items2 } from 'constants/repoFile.constants';
import { ACCESS_TOKEN, DateFormat6, DayMonthFormat, YearFormat } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import {
  AddDateDataLayerPayload,
  DataLayerTableType,
  DataLayerType
} from 'store/repositoryfile/types';
import {
  addDateDataLayer,
  deleteDateDataLayer,
  editDateDataLayer,
  enableDisableDatalayer
} from 'store/repositoryfile/actions';
import { getAsyncStorageValue } from 'utils/localStorage';

const { Text } = Typography;

interface EditDataLayerProps {
  dataLayerDateList: Array<DataLayerTableType> | [];
  handleActiveLayerId: Function;
}

const EditDataLayer = ({ dataLayerDateList, handleActiveLayerId }: EditDataLayerProps) => {
  const dispatch = useAppDispatch();
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const {
    dataLayers,
    isRequestingAddDateDataLayer,
    isRequestingEditDateDataLayer,
    isRequestingDeleteDateDataLayer,
    isEnableDisableDatalayer
  } = useAppSelector((state) => state.repositoryFiles);
  const { selectedProject } = useAppSelector((state) => state.projects);
  const [activeLayerDateId, setActiveLayerDateId] = useState<number | null>(null);
  const [activeLayerDate, setActiveLayerDate] = useState<DataLayerTableType | null>(null);
  const [editLayerId, setEditLayerId] = useState<number | null>(null);
  const [isOpenAddDatePickerModal, setOpenAddDatePickerModal] = useState<boolean>(false);
  const [isOpenDeleteDataLayerModal, setDeleteDataLayerModal] = useState<boolean>(false);
  const [isOpenDisableDataLayerModal, setDisableDataLayerModal] = useState<boolean>(false);
  const [addDate, setAddDate] = useState<string | null>(null);
  const [dateTitle, setDateTitle] = useState<string | null>(null);
  const [enableDisableText, setEnableDisableText] = useState<string | null>(null);
  const [selectedDeleteDisableData, setselectedDeleteDisableData] =
    useState<DataLayerTableType | null>(null);

  useEffect(() => {
    if (activeLayerDateId) return;
    if (dataLayerDateList && dataLayerDateList.length > 0) {
      setActiveLayerDateId(
        orderBy(
          dataLayerDateList,
          (o: DataLayerTableType) => {
            return moment(o.date);
          },
          ['asc']
        )[0].id
      );
      setActiveLayerDate(
        orderBy(
          dataLayerDateList,
          (o: DataLayerTableType) => {
            return moment(o.date);
          },
          ['asc']
        )[0]
      );
    }
  }, [dataLayerDateList]);

  const handleClickLayerDate = (dataLayer: DataLayerType) => {
    setActiveLayerDateId(dataLayer.id);
    handleActiveLayerId(dataLayer.id);
    setEnableDisableText(dataLayer.is_enabled ? 'Disable' : 'Enable');
    setActiveLayerDate({
      id: dataLayer.id,
      date: dataLayer.date
    });
  };

  const handleCancelNewChangeDate = () => {
    setAddDate(null);
    setEditLayerId(null);
    setDateTitle(null);
    setOpenAddDatePickerModal(false);
  };

  const handleOnChange: DatePickerProps['onChange'] = (date, dateString) => {
    setAddDate(dateString);
  };

  const handleAddDateDataLayerCallback = (status: boolean) => {
    if (status) {
      setAddDate(null);
      setDateTitle(null);
      setEditLayerId(null);
      setOpenAddDatePickerModal(false);
      setEnableDisableText(null);
    }
  };

  const handleOnAddDate = () => {
    setDateTitle('Add Date');
    setOpenAddDatePickerModal(true);
  };

  const handleChangeDateCallback = (status: boolean) => {
    if (status) {
      setAddDate(null);
      setDateTitle(null);
      setEditLayerId(null);
      setOpenAddDatePickerModal(false);
      setEnableDisableText(null);
    }
  };

  const handleOnAddNewDate = () => {
    if (addDate) {
      setActiveLayerDate({ id: activeLayerDateId!, date: addDate });
      const payload: AddDateDataLayerPayload = {
        date: addDate
      };
      if (editLayerId) {
        getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
          dispatch(
            editDateDataLayer(
              payload,
              +selectedOrganization.id,
              +selectedProject.id,
              +editLayerId,
              reToken,
              handleChangeDateCallback
            )
          );
        });
      } else {
        getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
          dispatch(
            addDateDataLayer(
              payload,
              +selectedOrganization.id,
              +selectedProject.id,
              reToken,
              handleAddDateDataLayerCallback
            )
          );
        });
      }
    } else {
      toast.error('Something went wrong');
    }
  };

  const handleExistDataLayerChangeDate = (data: DataLayerTableType) => {
    setDateTitle('Change Date');
    setAddDate(data.date);
    setEditLayerId(data.id);
    setOpenAddDatePickerModal(true);
  };

  const handleDeleteDataLayer = (dataLayer: DataLayerTableType) => {
    setselectedDeleteDisableData(dataLayer);
    setDeleteDataLayerModal(true);
  };

  const handleDisableDataLayer = (dataLayer: DataLayerTableType) => {
    setselectedDeleteDisableData(dataLayer);
    setDisableDataLayerModal(true);
  };

  const handleDeleteDisableLayerCallback = (status: boolean) => {
    if (status) {
      setselectedDeleteDisableData(null);
      setDeleteDataLayerModal(false);
      setDisableDataLayerModal(false);
      setEnableDisableText(null);
    } else {
      toast.error('Something went wrong');
    }
  };

  const handleOnDeleteDate = () => {
    if (!selectedDeleteDisableData) {
      return;
    }
    getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
      dispatch(
        deleteDateDataLayer(
          +selectedOrganization.id,
          +selectedProject.id,
          +selectedDeleteDisableData.id!,
          reToken,
          handleDeleteDisableLayerCallback
        )
      );
    });
  };

  const handleOnDisableDate = () => {
    if (!selectedDeleteDisableData) {
      return;
    }
    getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
      dispatch(
        enableDisableDatalayer(
          +selectedOrganization.id,
          +selectedProject.id,
          +selectedDeleteDisableData.id!,
          reToken,
          handleDeleteDisableLayerCallback
        )
      );
    });
  };

  const handleCancelDeleteDisableDate = () => {
    setselectedDeleteDisableData(null);
    setDeleteDataLayerModal(false);
    setDisableDataLayerModal(false);
  };

  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'change_date') {
      if (activeLayerDate) handleExistDataLayerChangeDate(activeLayerDate);
    } else if (key === 'delete') {
      if (activeLayerDate) handleDeleteDataLayer(activeLayerDate);
    } else if (key === 'disable' || key === 'enable') {
      if (activeLayerDate) handleDisableDataLayer(activeLayerDate);
    }
  };

  return (
    <>
      <div className="data-layer">
        <Text>Time Series Data</Text>
      </div>
      {dataLayers && dataLayers.length > 0 ? (
        <div className="data-layer-date">
          <div className="date-layer-div">
            {orderBy(
              dataLayers,
              (o: DataLayerType) => {
                return moment(o.date);
              },
              ['asc']
            ).map((dataLayer: DataLayerType) => (
              <div
                key={dataLayer.id}
                className={`datalayer-date-btn${
                  // eslint-disable-next-line
                  activeLayerDateId === dataLayer.id
                    ? dataLayer.is_enabled
                      ? ' active-date'
                      : ' active-date disable-active-date'
                    : !dataLayer.is_enabled
                    ? ' disable-active-date'
                    : ''
                }`}
                onClick={() => handleClickLayerDate(dataLayer)}
              >
                <Text>
                  <Text>{moment(dataLayer.date).format(DayMonthFormat)}</Text>
                  <Text>{moment(dataLayer.date).format(YearFormat)}</Text>
                  {!dataLayer.is_enabled && <Text className="disabled-text">Disabled</Text>}
                </Text>
                <Text>
                  <Dropdown
                    menu={{
                      items: dataLayer.is_enabled ? items1 : items2,
                      onClick: activeLayerDateId === dataLayer.id ? handleMenuClick : () => {}
                    }}
                    overlayClassName="datalayer-dropdown"
                    placement="bottomRight"
                    trigger={['click']}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <ThreeDotIcon
                        height={12}
                        color={activeLayerDateId === dataLayer.id ? primary : white}
                      />
                    </a>
                  </Dropdown>
                </Text>
              </div>
            ))}
            <Button
              className="layer-date"
              onClick={handleOnAddDate}
              style={{ padding: '8px 13px 8px 21px', fontSize: 16 }}
              icon={<AiOutlinePlusCircle color={primary} size={20} style={{ marginRight: 9 }} />}
            >
              Add Date
            </Button>
          </div>
        </div>
      ) : (
        <div className="data-layer-date">
          <div className="date-layer-div">
            <Button
              className="layer-date active-date"
              onClick={handleOnAddDate}
              style={{ padding: '8px 13px 8px 21px', fontSize: 16 }}
              icon={<AiOutlinePlusCircle color={white} size={20} style={{ marginRight: 9 }} />}
            >
              Add Date
            </Button>
          </div>
        </div>
      )}

      {/* Add / Edit date modal */}
      <Modal
        title={dateTitle}
        className={`add-date-modal ${addDate ? 'edit-date-modal' : ''}`}
        width={280}
        centered
        closeIcon={<CloseIcon color={black} height={10} width={10} />}
        open={isOpenAddDatePickerModal}
        okText={editLayerId ? 'Change' : 'Add'}
        okButtonProps={{ loading: isRequestingAddDateDataLayer || isRequestingEditDateDataLayer }}
        onOk={handleOnAddNewDate}
        onCancel={handleCancelNewChangeDate}
      >
        {!editLayerId ? (
          <DatePicker
            open
            showToday={false}
            // @ts-ignore
            getPopupContainer={() => document.getElementById('date-popup')}
            defaultPickerValue={moment(new Date())}
            onChange={handleOnChange}
            className="add-date-picker"
            superNextIcon={null}
            superPrevIcon={null}
            calendarStartDay={1}
            popupStyle={{
              position: 'relative'
            }}
          />
        ) : (
          <>
            <Text className="edit-date-title">
              Current Date - {moment(addDate).format(DateFormat6)}
            </Text>
            <DatePicker
              open
              calendarStartDay={1}
              showToday={false}
              // @ts-ignore
              getPopupContainer={() => document.getElementById('date-popup')}
              value={moment(addDate)}
              onChange={handleOnChange}
              superNextIcon={null}
              superPrevIcon={null}
              popupStyle={{
                position: 'relative'
              }}
              className="add-date-picker edit-date-picker"
            />
          </>
        )}
        <div
          id="date-popup"
          className={`${editLayerId ? 'edit-date-main-popup' : 'add-date-main-popup'}`}
        />
      </Modal>

      {/* Delete modal */}
      <Modal
        title={isOpenDeleteDataLayerModal ? 'Delete Date' : `${enableDisableText} Date`}
        className="add-date-modal delete-date-modal"
        width={500}
        centered
        open={isOpenDeleteDataLayerModal || isOpenDisableDataLayerModal}
        closeIcon={<CloseIcon color={black} />}
        okText={isOpenDeleteDataLayerModal ? 'Delete' : enableDisableText}
        okButtonProps={{
          loading: isRequestingDeleteDateDataLayer || isEnableDisableDatalayer
        }}
        onOk={isOpenDeleteDataLayerModal ? handleOnDeleteDate : handleOnDisableDate}
        onCancel={handleCancelDeleteDisableDate}
      >
        <div className="delete-data-content">
          {isOpenDeleteDataLayerModal && (
            <Text>
              Are you sure you want to delete date
              {selectedDeleteDisableData ? (
                <Text style={{ color: primary, padding: '0 7px' }}>
                  {`"${moment(selectedDeleteDisableData.date).format(DateFormat6)}"`}
                </Text>
              ) : (
                ''
              )}
              from the file repository?
            </Text>
          )}
          {isOpenDisableDataLayerModal && (
            <Text>
              Are you sure you want to disable date
              {selectedDeleteDisableData ? (
                <Text style={{ color: primary, padding: '0 7px' }}>
                  {`"${moment(selectedDeleteDisableData.date).format(DateFormat6)}"`}
                </Text>
              ) : (
                ''
              )}
              from the file repository?
            </Text>
          )}
        </div>
      </Modal>
    </>
  );
};

export default EditDataLayer;
