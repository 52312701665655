import { useMemo } from 'react';
import 'moment-timezone';
import moment from 'moment';
import { TimezoneContext } from 'context/createusecontext';
import { Navigator } from 'routes/navigator';
import './App.css';

const App = () => {
  const zone = moment.tz.guess();
  const timezone = moment.tz(zone).zoneAbbr();
  const zoneText = useMemo(() => ({ timezone }), []);

  return (
    <TimezoneContext.Provider value={zoneText}>
      <Navigator />
    </TimezoneContext.Provider>
  );
};

export default App;
